import React, { useEffect, useState } from 'react';
import LocationFilter from '../../components/Filter/LocationFilter';
import SearchIcon from '../../images/search.svg';
import './JobStyles.css';

export default function JobSearchBar({ savedSearch, searchState, locationState, searchJobs }) {
  const [location, setLocation] = locationState;
  const [inputValue, setInputValue] = useState();
  const [search, setSearch] = searchState;

  useEffect(() => {
    if (savedSearch) {
      setInputValue(search);
    }
  }, []);

  return (
    <div className="JobSearchBar-js relative flex flex-col lg:flex-row w-full">
      <div className="mb-md lg:m-0 capitalize flex items-center flex-row justify-between  mr-md lg:w-auto" style={{ fontSize: 21, fontWeight: 500 }}>
        Search Jobs
      </div>
      <div className="relative flex-1 lg:px-md mb-md lg:mb-0 job-search-bar">
        <img src={SearchIcon} style={{ position: 'absolute', top: 11, width: 16 }} />
        <input
          value={inputValue}
          onKeyDown={(e) => {
            if (e.which === 13) {
              searchJobs();
            } else {
              setSearch(e.target.value);
            }
          }}
          onChange={(e) => setInputValue(e.target.value)}
          style={{ height: 40, paddingLeft: 35 }}
          className="bg-white w-full shadow rounded p-sm text-xs placeholder-primary font-normal mr-md"
          placeholder={`Search for Jobs`}
        />
      </div>
      <LocationFilter value={location} update={setLocation} type="job-search" />
      <button onClick={() => searchJobs()} style={{ height: 40 }} className="bg-red text-white rounded lg:ml-md mt-md lg:mt-0 text-sm px-md font-medium">
        Search Jobs
      </button>
    </div>
  );
}
