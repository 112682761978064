import React from 'react';
import CaretDownGray from '../../images/caret-down-gray.png';
function AlphabeticalSortButton({ sortDirection, setSortDirection }) {
  function setType() {
    if (sortDirection === 'desc') {
      setSortDirection('asc');
    } else {
      setSortDirection('desc');
    }
  }

  return (
    <div className="flex mr-md">
      <div onClick={setType} className={`text-sm ${'bg-darkblue text-white'}   flex flex-row items-center font-medium cursor-pointer px-sm rounded`}>
        A - Z
        <img alt="" src={CaretDownGray} style={{ height: 5, margin: 0, transform: `rotate(${sortDirection === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }} />
      </div>
    </div>
  );
}

export default AlphabeticalSortButton;
