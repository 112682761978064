import React, { useState, useEffect } from "react";
import NewCompanyForm from "../../forms/company/NewCompanyForm";
import firebase from "firebase/app";
import { navigate } from "gatsby";
import "firebase/auth";
import "firebase/firestore";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

/*graphql query to create new company*/
const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $name: String
    $websiteURL: String
    $description: String
    $size: String
    $logoURL: String
    $location: jsonb
    $industries: jsonb
    $adminID:String
  ) {
    insert_companies(objects: {
      name: $name,
      websiteURL: $websiteURL,
      description: $description,
      size: $size,
      logoURL: $logoURL,
      location: $location,
      industries: $industries
      adminID:$adminID
    }) {
      returning {
        id
        name
        websiteURL
        description
        size
        logoURL
        location
        industries
        adminID
      }
    }
  }  
`;

/*graphql query to update the company detail*/
const UPDATE_COMPANY = gql`
mutation updateCompany($id: uuid, $changedFields: companies_set_input) {
    update_companies(where: {id: {_eq: $id}}, _set: $changedFields) {
      affected_rows
      returning {
        id
        name
        websiteURL
        description
        size
        logoURL
        location
        industries
      }
    }
  }
`;

export default function CreateCompanyForm({
    company
}) {
    const accountState = useState(company);

    /*mutation to create new company*/
    const [createCompany, { loading: create_loading, data: createResponseData }] = useMutation(
        CREATE_COMPANY
    );

    /*mutation to update existing company*/
    const [updateCompany, { loading: update_loading, data: updateResponseData }] = useMutation(
        UPDATE_COMPANY
    );

    /*function to upload the image into firebase storage*/
    async function uploadImage(file, location) {
        const storageRef = firebase
            .storage()
            .ref()
            .child(`images/${location}/${file.name}`);
        const logoURL = await storageRef
            .put(file)
            .then(snapshot => snapshot.ref.getDownloadURL());
        return logoURL;
    }

    async function saveCompanyCreate(company) {
        const {
            name,
            websiteURL,
            description,
            size,
            logoURL,
            location,
            industries
        } = company;
        const newCompany = {
            name,
            websiteURL,
            description,
            size,
            logoURL:
                !logoURL ? null : typeof logoURL === "string"
                    ? logoURL
                    : await uploadImage(logoURL, "newcompanylogos"),
            location,
            industries,
            adminID: firebase.auth().currentUser && firebase.auth().currentUser.uid
        };
        createCompany({
            variables: newCompany
        });
    }

    async function saveCompanyEdit(updated_company) {
        const {
            name,
            websiteURL,
            description,
            size,
            logoURL,
            location,
            industries
        } = updated_company;
        const newCompany = {
            name,
            websiteURL,
            description,
            size,
            logoURL:
                !logoURL ? null : typeof logoURL === "string"
                    ? logoURL
                    : await uploadImage(logoURL, "newcompanylogos"),
            location,
            industries
        };

        updateCompany({
            variables: {
                id: company.id,
                changedFields: newCompany
            },
        });
    };

    /*navigate to the companies list after creating new company*/
    useEffect(() => {
        if (createResponseData) {
            navigate(`/dashboard/companies`)
        }
    }, [createResponseData]);

    /*navigate to the company detail with the updated data*/
    useEffect(() => {
        if (updateResponseData) {
            navigate(`/companies/${updateResponseData.update_companies.returning[0].id}/`,
                {
                    state: {
                        company_id: updateResponseData.update_companies.returning[0].id
                    }
                });
        }
    }, [updateResponseData]);

    return (
        <NewCompanyForm
            action={company ? saveCompanyEdit : saveCompanyCreate}
            globalFormData={accountState}
            isEdit={company ? true : false}
            loading={company ? update_loading : create_loading}
        />
    );
}
