import React, { useEffect, useState } from 'react';
import SelectDropdown from './SelectDropdown';

export default function CompanySize({ update, error, value }) {
  const [size, setSize] = useState(value);

  useEffect(() => {
    update(size);
  }, [size]);

  const company_sizes = ['1-4', '5-9', '10-19', '20-49', '50-99', '100-249', '500-999', '1000+'];

  return (
    <>
      <div style={{ marginTop: '15px', fontWeight: 500 }}>
        <label style={{ marginTop: '15px', fontWeight: 500 }}>Number of employees</label>
      </div>
      <SelectDropdown value={size} error={error} update={setSize} list={company_sizes} placeholder={'Number of Employees'} dontClear={true} />
    </>
  );
}
