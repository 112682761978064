import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';

const GET_ALL_RECRUITERS = gql`
  query getAllRecruiters {
    recruiter(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export default function RecruiterFilter({ update, recruiters }) {
  return (
    <div>
      <div className="relative">
        <RecruiterDropdown value={recruiters} update={update} style={{ height: 40, paddingLeft: 15, paddingRight: 15 }} className={'rounded shadow relative text-xs'} />
      </div>
    </div>
  );
}

function RecruiterDropdown({ value, update, error, className, style, containerStyle }) {
  const placeholder = 'Search Recruiter';
  const [inputValue, setInputValue] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { data } = useQuery(GET_ALL_RECRUITERS);
  const [allRecruiters, setAllRecruiters] = useState([]);
  const [recruitersToShow, setRecruitersToShow] = useState([]);

  function hideDropdown() {
    setDropdownVisible(false);
  }

  useEffect(() => {
    if (dropdownVisible) {
      window.addEventListener('click', hideDropdown);
    } else {
      setInputValue('');
      return window.removeEventListener('click', hideDropdown);
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (data) {
      setAllRecruiters(data.recruiter);
      setRecruitersToShow(data.recruiter);
    }
  }, [data]);

  return (
    <>
      <div className={`relative`} style={containerStyle}>
        <input
          onClick={(e) => e.stopPropagation()}
          autoComplete="new-password"
          value={inputValue}
          onFocus={() => setDropdownVisible(true)}
          onChange={(e) => {
            const newValue = e.target.value;
            setInputValue(e.target.value);

            setRecruitersToShow(
              allRecruiters.filter((a) => {
                if (newValue) {
                  return new RegExp(newValue, 'gi').test(a.name);
                }

                return true;
              }),
            );
          }}
          placeholder={placeholder}
          className={className}
          style={{ ...style, zIndex: dropdownVisible ? 501 : 499, borderColor: error ? '#E31C25' : '#c4cad3' }}
        />
        {dropdownVisible && (
          <div className="absolute bg-white w-full border-r border-l overflow-auto shadow" style={{ top: 'calc(100% - 10px)', zIndex: 500, paddingTop: 10, maxHeight: 350 }}>
            {recruitersToShow.map((recruiter, index) => {
              const selected = value.filter((a) => a.id === recruiter.id)[0];

              return (
                <div
                  className={`cursor-pointer ${selected ? 'bg-green text-white' : 'hover:bg-lightgray'} flex justify-between items-center`}
                  style={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (selected) {
                      update(value.filter((a) => a.id !== recruiter.id));
                    } else {
                      update([...value, recruiter]);
                    }

                    update([...value, recruiter]);
                  }}
                >
                  {recruiter.name}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
