const certifications = [
  {
    Name: "Certificate in Investment Performance Measurement",
    "Post-nominal": "CIPM",
  },
  {
    Name: "Chartered Financial Analyst",
    "Post-nominal": "CFA",
  },
  {
    Name: "Chartered Business Valuator",
    "Post-nominal": "CBV",
  },
  {
    Name: "Chartered Market Technician",
    "Post-nominal": "CMT",
  },
  {
    Name: "Chartered Alternative Investment Analyst",
    "Post-nominal": "CAIA",
  },
  {
    Name: "Chartered Management Consultant",
    "Post-nominal": "ChMC",
  },
  {
    Name: "Chartered Global Management Accountant",
    "Post-nominal": "CGMA",
  },
  {
    Name: "Certified Accounts Payable Associate",
    "Post-nominal": "CAPA",
  },
  {
    Name: "Certified Accounts Payable Professional",
    "Post-nominal": "CAPP",
  },
  {
    Name: "Certified Public Accountant",
    "Post-nominal": "CPA",
  },
  {
    Name: "Certified Financial Planner",
    "Post-nominal": "CFP",
  },
  {
    Name: "Certified Anti-Money Laundering Specialist",
    "Post-nominal": "CAMS",
  },
  {
    Name: "Certified Financial Consultant",
    "Post-nominal": "CFC",
  },
  {
    Name: "Certified Credit Union Financial Counselor",
    "Post-nominal": "CCUFC",
  },
  {
    Name: "Chartered Life Underwriter",
    "Post-nominal": "CLU",
  },
  {
    Name: "Certified Fraud Examiner",
    "Post-nominal": "CFE",
  },
  {
    Name: "Certified Internal Auditor",
    "Post-nominal": "CIA",
  },
  {
    Name: "Certification in Risk Management Assurance",
    "Post-nominal": "CRMA",
  },
  {
    Name: "Certified Government Auditing Professional",
    "Post-nominal": "CGAP",
  },
  {
    Name: "Certified Payroll Professional",
    "Post-nominal": "CPP",
  },
  {
    Name: "Fundamental Payroll Certification",
    "Post-nominal": "FPC",
  },
  {
    Name: "Certified California Municipal Treasurer",
    "Post-nominal": "CCMT",
  },
  {
    Name: "Certified Government Financial Manager",
    "Post-nominal": "CGFM",
  },
  {
    Name: "Certified Government Finance Officer",
    "Post-nominal": "CGFO",
  },
  {
    Name: "Certified Management Accountant",
    "Post-nominal": "CMA",
  },
  {
    Name: "Certified Municipal Finance Officer",
    "Post-nominal": "CMFO",
  },
  {
    Name: "Certified Public Finance Administrator",
    "Post-nominal": "CPFA",
  },
  {
    Name: "Certified Public Finance Officer",
    "Post-nominal": "CPFO",
  },
  {
    Name: "Chartered Property Casualty Underwriter",
    "Post-nominal": "CPCU",
  },
  {
    Name: "Certified Defense Financial Manager",
    "Post-nominal": "CDFM",
  },
  {
    Name: "Communication Management Professional",
    "Post-nominal": "CMP",
  },
  {
    Name: "Registered Tax Return Preparer",
    "Post-nominal": "RTRP",
  },
  {
    Name: "Enrolled Agent",
    "Post-nominal": "EA",
  },
  {
    Name: "Financial Modeling and Valuation Analyst",
    "Post-nominal": "FMVA",
  },
  {
    Name: "Fraternal Insurance Counselor Fellow",
    "Post-nominal": "FICF",
  },
  {
    Name: "Fraternal Insurance Counselor",
    "Post-nominal": "FIC",
  },
  {
    Name: "Certified Corporate FP&A Professional",
    "Post-nominal": "FP&A",
  },
  {
    Name: "ISSP Sustainability",
    "Post-nominal": "",
  },
  {
    Name: "Certified Treasury Professional",
    "Post-nominal": "CTP",
  },
  {
    Name: "Certified Trust and Financial Advisor",
    "Post-nominal": "CTFA",
  },
  {
    Name: "Certified Turnaround Professional",
    "Post-nominal": "CTP",
  },
  {
    Name: "Registered Financial Planner",
    "Post-nominal": "RFP",
  },
  {
    Name: "Financial Risk Manager",
    "Post-nominal": "FRM",
  },
  {
    Name: "Professional Risk Manager",
    "Post-nominal": "PRM",
  },
  {
    Name: "Strategic Communication Management Professional",
    "Post-nominal": "SCMP",
  },
  {
    Name: "Licensed Public Accountant",
    "Post-nominal": "LPA",
  },
  {
    Name: "Accredited Business Accountant/Advisor",
    "Post-nominal": "ABA",
  },
  {
    Name: "Investment Adviser Certified Compliance Professional",
    "Post-nominal": "IACCP",
  },
  {
    Name: "Designated Aquatics Professional",
    "Post-nominal": "AqP",
  },
  {
    Name: "Certified Meeting Professional",
    "Post-nominal": "CMP",
  },
  {
    Name: "Certified in Exhibition Management",
    "Post-nominal": "CEM",
  },
  {
    Name: "Certified Hospitality Digital Marketer",
    "Post-nominal": "CHDM",
  },
  {
    Name: "Certified Revenue Management Executive",
    "Post-nominal": "CRME",
  },
  {
    Name: "Certified in Hospitality Business Acumen",
    "Post-nominal": "CHBA",
  },
  {
    Name: "Certified in Hospitality Sales Competences",
    "Post-nominal": "CHSC",
  },
  {
    Name: "Certified Hospitality Sales Executive",
    "Post-nominal": "CHSE",
  },
  {
    Name: "Certified Guest Service Professional",
    "Post-nominal": "CGSP",
  },
  {
    Name: "Certified Hospitality Administrator",
    "Post-nominal": "CHA",
  },
  {
    Name: "Certified Hospitality Educator",
    "Post-nominal": "CHE",
  },
  {
    Name: "Certified Hospitality Instructor",
    "Post-nominal": "CHI",
  },
  {
    Name: "Certified Room Division Executive",
    "Post-nominal": "CRDE",
  },
  {
    Name: "Certified Hospitality Revenue Manager",
    "Post-nominal": "CHRM",
  },
  {
    Name: "Certified Lodging Security Director",
    "Post-nominal": "CLSD",
  },
  {
    Name: "Certified Interpretive Guide",
    "Post-nominal": "CIG",
  },
  {
    Name: "Certified Park & Recreation Professional",
    "Post-nominal": "CPRP",
  },
  {
    Name: "Certified Park & Recreation Executive",
    "Post-nominal": "CPRE",
  },
  {
    Name: "Certified Playground Safety Inspector",
    "Post-nominal": "CPSI",
  },
  {
    Name: "Certified Youth Sports Administrator",
    "Post-nominal": "CYSA",
  },
  {
    Name: "Certified Wedding and Event Planner",
    "Post-nominal": "CWEP",
  },
  {
    Name: "Certified Wedding Planner",
    "Post-nominal": "CWP",
  },
  {
    Name: "Master Sommelier",
    "Post-nominal": "MS",
  },
  {
    Name: "SHRM Certified Professional",
    "Post-nominal": "SHRM-CP",
  },
  {
    Name: "SHRM Senior Certified Professional",
    "Post-nominal": "SHRM-SCP",
  },
  {
    Name: "Certified Benefits Professional",
    "Post-nominal": "CBP",
  },
  {
    Name: "Certified Compensation Professional",
    "Post-nominal": "CCP",
  },
  {
    Name: "Global Remuneration Professional",
    "Post-nominal": "GRP",
  },
  {
    Name: "Associate Professional in Human Resources",
    "Post-nominal": "aPHR",
  },
  {
    Name: "Professional in Human Resources",
    "Post-nominal": "PHR",
  },
  {
    Name: "Professional in Human Resources – California",
    "Post-nominal": "PHRca",
  },
  {
    Name: "Senior Professional in Human Resources",
    "Post-nominal": "SPHR",
  },
  {
    Name: "Global Professional in Human Resources",
    "Post-nominal": "GPHR",
  },
  {
    Name: "Certified Employee Benefits Specialist",
    "Post-nominal": "CEBS",
  },
  {
    Name: "Group Benefits Associate",
    "Post-nominal": "GBA",
  },
  {
    Name: "Compensation Management Specialist",
    "Post-nominal": "CMS",
  },
  {
    Name: "Retirement Plans Associate",
    "Post-nominal": "RPA",
  },
  {
    Name: "Certification in Volunteer Administration",
    "Post-nominal": "CVA",
  },
  {
    Name: "Certified in Conflict Manager",
    "Post-nominal": "CCM",
  },
  {
    Name: "Certified Computer Examiner",
    "Post-nominal": "CCE",
  },
  {
    Name: "Certified Digital Marketing Professional",
    "Post-nominal": "CDMP",
  },
  {
    Name: "Certified Green IT Professional",
    "Post-nominal": "IFGICT",
  },
  {
    Name: "Certified Chief Information Security Officer",
    "Post-nominal": "CCISO",
  },
  {
    Name: "Certified Ethical Hacker",
    "Post-nominal": "CEH",
  },
  {
    Name: "Certified Forensic Computer Examiner",
    "Post-nominal": "CFCE",
  },
  {
    Name: "CloudMASTER",
    "Post-nominal": "N/A",
  },
  {
    Name: "CyberSec First Responder",
    "Post-nominal": "CFR",
  },
  {
    Name: "Certified Information Professional",
    "Post-nominal": "CIP",
  },
  {
    Name: "Certified Information Systems Auditor",
    "Post-nominal": "CISA",
  },
  {
    Name: "Certified Information Security Manager",
    "Post-nominal": "CISM",
  },
  {
    Name: "Certified Information Systems Security Professional",
    "Post-nominal": "CISSP",
  },
  {
    Name: "Certified Network Professional",
    "Post-nominal": "CNP",
  },
  {
    Name: "Certified in Risk and Information Systems Control",
    "Post-nominal": "CRISC",
  },
  {
    Name: "Certified Scrum Master",
    "Post-nominal": "CSM",
  },
  {
    Name: "Certified Scrum Professional",
    "Post-nominal": "CSP",
  },
  {
    Name: "Certified Scrum Product Owner",
    "Post-nominal": "CSPO",
  },
  {
    Name: "Certified Secure Computer User",
    "Post-nominal": "CSCU",
  },
  {
    Name: "Certified Secure Software Lifecycle Professional",
    "Post-nominal": "CSSLP",
  },
  {
    Name: "Certified Software Tester",
    "Post-nominal": "CSTE",
  },
  {
    Name: "Certified Technology Specialist",
    "Post-nominal": "CTS",
  },
  {
    Name: "Certified Virtualization Professional",
    "Post-nominal": "CVP",
  },
  {
    Name: "Certified Wireless Network Administrator",
    "Post-nominal": "CWNA",
  },
  {
    Name: "Certified Wireless Analysis Professional",
    "Post-nominal": "CWAP",
  },
  {
    Name: "Certified Wireless Design Professional",
    "Post-nominal": "CWDP",
  },
  {
    Name: "Certified Wireless Security Professional",
    "Post-nominal": "CWSP",
  },
  {
    Name: "Certified Wireless Network Expert",
    "Post-nominal": "CWNE",
  },
  {
    Name: "Comptia Advanced Security Practitioner",
    "Post-nominal": "CASP",
  },
  {
    Name: "Cisco Certified Network Associate",
    "Post-nominal": "CCNA",
  },
  {
    Name: "Cisco Certified Network Professional",
    "Post-nominal": "CCNP",
  },
  {
    Name: "Cisco Certified Internetworking Expert",
    "Post-nominal": "CCIE",
  },
  {
    Name: "CyberSAFE",
    "Post-nominal": "CBS",
  },
  {
    Name: "Cyber Secure Coder",
    "Post-nominal": "CSC",
  },
  {
    Name: "Certified Usability Analyst",
    "Post-nominal": "CUA",
  },
  {
    Name: "Certified User Experience Analyst",
    "Post-nominal": "CXA",
  },
  {
    Name: "IT Infrastructure Library Certified",
    "Post-nominal": "ITIL",
  },
  {
    Name: "Lean IT Kaizen",
    "Post-nominal": "LITK",
  },
  {
    Name: "Lean IT Leadership",
    "Post-nominal": "LITL",
  },
  {
    Name: "Lean IT Professional",
    "Post-nominal": "LITP",
  },
  {
    Name: "Master Mobile Application Developer",
    "Post-nominal": "MMAD",
  },
  {
    Name: "Master of Mobile Application Security",
    "Post-nominal": "MMAS",
  },
  {
    Name: "Microsoft Certified Database Administrator",
    "Post-nominal": "MCDBA",
  },
  {
    Name: "Microsoft Certified Professional",
    "Post-nominal": "MCP",
  },
  {
    Name: "Microsoft Certified Professional Developer",
    "Post-nominal": "MCPD",
  },
  {
    Name: "Microsoft Certified Solutions Associate",
    "Post-nominal": "MCSA",
  },
  {
    Name: "Microsoft Certified Solutions Developer",
    "Post-nominal": "MCSD",
  },
  {
    Name: "Microsoft Certified Solutions Expert",
    "Post-nominal": "MCSE",
  },
  {
    Name: "Microsoft Certified Systems Administrator",
    "Post-nominal": "MCSA",
  },
  {
    Name: "Microsoft Certified Systems Engineer",
    "Post-nominal": "MCSE",
  },
  {
    Name: "Microsoft Certified Trainer",
    "Post-nominal": "MCT",
  },
  {
    Name: "Modern Certified Classroom Trainer",
    "Post-nominal": "MCCT",
  },
  {
    Name: "Offensive Security Certified Professional",
    "Post-nominal": "OSCP",
  },
  {
    Name: "Oracle Certified Associate",
    "Post-nominal": "OCA",
  },
  {
    Name: "Oracle Certified Professional",
    "Post-nominal": "OCP",
  },
  {
    Name: "Oracle Certified Master",
    "Post-nominal": "OCM",
  },
  {
    Name: "PMI Agile Certified Practitioner",
    "Post-nominal": "PMI-ACP",
  },
  {
    Name: "Professional Scrum Master I",
    "Post-nominal": "PSM I",
  },
  {
    Name: "Professional Scrum Master II",
    "Post-nominal": "PSM II",
  },
  {
    Name: "Professional Scrum Master III",
    "Post-nominal": "PSM II",
  },
  {
    Name: "Red Hat Certified Architect",
    "Post-nominal": "RHCA",
  },
  {
    Name: "SAFe Agilist",
    "Post-nominal": "SA",
  },
  {
    Name: "SAFe Advanced Scrum Masters",
    "Post-nominal": "SASM",
  },
  {
    Name: "Training from the BACK of the Room Certified Trainer",
    "Post-nominal": "TBR-CT",
  },
  {
    Name: "User Experience Certification",
    "Post-nominal": "UXC",
  },
  {
    Name: "User Experience Master Certification",
    "Post-nominal": "UXMC",
  },
  {
    Name: "VMware Certified Professional",
    "Post-nominal": "VCP",
  },
  {
    Name: "Member of the Appraisal Institute",
    "Post-nominal": "MAI",
  },
  {
    Name: "Senior Residential Appraiser",
    "Post-nominal": "SRA",
  },
  {
    Name: "Accredited Member",
    "Post-nominal": "AM",
  },
  {
    Name: "Accredited Senior Appraiser",
    "Post-nominal": "ASA",
  },
  {
    Name: "Assessment Administration Specialist",
    "Post-nominal": "AAS",
  },
  {
    Name: "Certified Assessment Evaluator",
    "Post-nominal": "CAE",
  },
  {
    Name: "Cadastral Mapping Specialist",
    "Post-nominal": "CMS",
  },
  {
    Name: "Personal Property Specialist",
    "Post-nominal": "PPS",
  },
  {
    Name: "Residential Evaluation Specialist",
    "Post-nominal": "RES",
  },
  {
    Name: "Master in Residential Marketing",
    "Post-nominal": "MIRM",
  },
  {
    Name: "Certified Green Professional",
    "Post-nominal": "CGP",
  },
  {
    Name: "Certified Graduate Builder",
    "Post-nominal": "CGB",
  },
  {
    Name: "Certified Graduate Associate",
    "Post-nominal": "CGA",
  },
  {
    Name: "Master Certified New Home Sales Professional",
    "Post-nominal": "Master CSP",
  },
  {
    Name: "Certified Aging-in-Place Specialist",
    "Post-nominal": "CAPS",
  },
  {
    Name: "Certified New Home Marketing Professional",
    "Post-nominal": "CMP",
  },
  {
    Name: "Housing Credit Certified Professional",
    "Post-nominal": "HCCP",
  },
  {
    Name: "Certified New Home Sales Professional",
    "Post-nominal": "CSP",
  },
  {
    Name: "Graduate Master Remodeler",
    "Post-nominal": "GMR",
  },
  {
    Name: "Graduate Master Builder",
    "Post-nominal": "GMB",
  },
  {
    Name: "Certified Graduate Remodeler",
    "Post-nominal": "CGR",
  },
  {
    Name: "Facilities Management Professional",
    "Post-nominal": "FMP",
  },
  {
    Name: "Sustainability Facility Professional",
    "Post-nominal": "SFP",
  },
  {
    Name: "Certified Facilities Manager",
    "Post-nominal": "CFM",
  },
  {
    Name: "Accredited Buyer's Representative",
    "Post-nominal": "ABR",
  },
  {
    Name: "Graduate Realtor Institute",
    "Post-nominal": "GRI",
  },
  {
    Name: "Accredited Land Consultant",
    "Post-nominal": "ALC",
  },
  {
    Name: "Certified International Property Specialist",
    "Post-nominal": "CIPS",
  },
  {
    Name: "Certified Property Manager",
    "Post-nominal": "CPM",
  },
  {
    Name: "Certified Commercial Investment Member",
    "Post-nominal": "CCIM",
  },
  {
    Name: "Certified Corporate Housing Professional",
    "Post-nominal": "CCHP",
  },
  {
    Name: "Member of the National Association of Appraisers",
    "Post-nominal": "MNAA",
  },
  {
    Name: "General Appraiser",
    "Post-nominal": "MRA",
  },
  {
    Name: "Residential Appraiser",
    "Post-nominal": "RA",
  },
  {
    Name: "Juris Doctor",
    "Post-nominal": "J.D.",
  },
  {
    Name: "Doctor of Jurisprudence",
    "Post-nominal": "J.D.",
  },
  {
    Name: "Master of Laws",
    "Post-nominal": "LL.M.",
  },
  {
    Name: "Bachelor of Civil Law",
    "Post-nominal": "BCL",
  },
  {
    Name: "Esquire",
    "Post-nominal": "Esq.",
  },
  {
    Name: "Justice of the Peace",
    "Post-nominal": "J.P.",
  },
  {
    Name: "Notary Public",
    "Post-nominal": "N.P.",
  },
  {
    Name: "Board Certified Civil Trial Attorney",
    "Post-nominal": "",
  },
  {
    Name: "Board Certified Criminal Trial Attorney",
    "Post-nominal": "",
  },
  {
    Name: "Board Certified Family Law Attorney",
    "Post-nominal": "",
  },
  {
    Name: "Certified Legal Assistant",
    "Post-nominal": "CLA",
  },
  {
    Name: "Certified Paralegal",
    "Post-nominal": "CP",
  },
  {
    Name: "Advanced Certified Paralegal",
    "Post-nominal": "ACP",
  },
  {
    Name: "Accredited Legal Professional",
    "Post-nominal": "ALP",
  },
  {
    Name: "Professional Legal Secretary",
    "Post-nominal": "PLS",
  },
  {
    Name: "Professional Paralegal",
    "Post-nominal": "PP",
  },
  {
    Name: "Registered Paralegal",
    "Post-nominal": "RP",
  },
  {
    Name: "CORE Registered Paralegal",
    "Post-nominal": "CRP",
  },
  {
    Name: "American Board of Professional Psychology",
    "Post-nominal": "ABPP",
  },
  {
    Name: "Basic Life Support Instructor",
    "Post-nominal": "BLS-I",
  },
  {
    Name: "Certified Genetic Counselor",
    "Post-nominal": "CGC",
  },
  {
    Name: "Certified Nursing Assistant",
    "Post-nominal": "CNA",
  },
  {
    Name: "Certified Health Education Specialist",
    "Post-nominal": "CHES",
  },
  {
    Name: "Master Certified Health Education Specialist",
    "Post-nominal": "MCHES",
  },
  {
    Name: "Master of Public Health",
    "Post-nominal": "MPH",
  },
  {
    Name: "Certified Director of Assisted Living",
    "Post-nominal": "CDAL",
  },
  {
    Name: "Certified Healthcare Simulation Educator",
    "Post-nominal": "CHSE",
  },
  {
    Name: "Certified Healthcare Simulation Educator (Advanced)",
    "Post-nominal": "CHSE-A",
  },
  {
    Name: "Certified Healthcare Simulation Operations Specialist",
    "Post-nominal": "CHSOS",
  },
  {
    Name: "Certified HIPAA Privacy Associate",
    "Post-nominal": "CHPA",
  },
  {
    Name: "Certified HIPAA Privacy Expert",
    "Post-nominal": "CHPE",
  },
  {
    Name: "Certified HIPAA Privacy Security Expert",
    "Post-nominal": "CHPSE",
  },
  {
    Name: "Certified HIPAA Professional",
    "Post-nominal": "CHP",
  },
  {
    Name: "Certified Professional in Infection Control",
    "Post-nominal": "CIC",
  },
  {
    Name: "Certified Hospice and Palliative Licensed Nurse",
    "Post-nominal": "CHPLN",
  },
  {
    Name: "Certified HIPAA Security Expert",
    "Post-nominal": "CHSE",
  },
  {
    Name: "Certified HIPAA Security Specialist",
    "Post-nominal": "CHSS",
  },
  {
    Name: "Certified Tissue Banking Specialist",
    "Post-nominal": "CTBS",
  },
  {
    Name: "Certified Veterinary Assistant",
    "Post-nominal": "CVA",
  },
  {
    Name: "Doctor of Veterinary Medicine",
    "Post-nominal": "DVM",
  },
  {
    Name: "Diplomate of the American College of Veterinary Internal Medicine",
    "Post-nominal": "DACVIM",
  },
  {
    Name: "Doctor of Chiropractic",
    "Post-nominal": "DC",
  },
  {
    Name: "Doctor of Dental Surgery",
    "Post-nominal": "DDS",
  },
  {
    Name: "Doctor of Psychology",
    "Post-nominal": "PsyD",
  },
  {
    Name: "Dentariae Medicinae Doctoris (Doctor of Dental Medicine)",
    "Post-nominal": "DMD",
  },
  {
    Name: "Doctor of Medicine",
    "Post-nominal": "MD",
  },
  {
    Name: "Doctor of Nursing Practice",
    "Post-nominal": "DNP",
  },
  {
    Name: "Doctor of Optometry",
    "Post-nominal": "OD",
  },
  {
    Name: "Doctor of Osteopathic Medicine",
    "Post-nominal": "DO",
  },
  {
    Name: "Doctor of Physical Therapy",
    "Post-nominal": "DPT",
  },
  {
    Name: "Doctor of Podiatric Medicine",
    "Post-nominal": "D.P.M.",
  },
  {
    Name: "Emergency Medical Dispatcher",
    "Post-nominal": "EMD",
  },
  {
    Name: "National Board for Certification in Hearing Instrument Sciences",
    "Post-nominal": "NBC-HIS",
  },
  {
    Name:
      "Nationally Registered Emergency Medical Responder (Begin January 1, 2012)",
    "Post-nominal": "NREMR",
  },
  {
    Name: "Emergency Medical Technician - Basic (End December 31, 2011)",
    "Post-nominal": "EMT-B",
  },
  {
    Name:
      "Nationally Registered Emergency Medical Technician (Begin January 1, 2012)",
    "Post-nominal": "NREMT",
  },
  {
    Name: "Emergency Medical Technician – Intermediate/85 (End March 31, 2013)",
    "Post-nominal": "EMT-I/85",
  },
  {
    Name:
      "Emergency Medical Technician – Intermediate/99 (End December 31, 2013)",
    "Post-nominal": "EMT-I/99",
  },
  {
    Name:
      "Nationally Registered Advanced Emergency Medical Technician (Begin June 1, 2011)",
    "Post-nominal": "NRAEMT",
  },
  {
    Name: "Emergency Medical Technician - Paramedic (End December 31, 2012)",
    "Post-nominal": "EMT-P",
  },
  {
    Name: "Nationally Registered Paramedic (Begin January 1, 2013)",
    "Post-nominal": "NRP",
  },
  {
    Name: "Board Certified Critical Care Paramedic",
    "Post-nominal": "CCP-C",
  },
  {
    Name: "Board Certified Flight Paramedic",
    "Post-nominal": "FP-C",
  },
  {
    Name: "Board Certified Community Paramedic",
    "Post-nominal": "CP-C",
  },
  {
    Name: "Mobile Intensive Care Paramedic (Alaska)",
    "Post-nominal": "MICP",
  },
  {
    Name: "Licensed Paramedic (Texas)",
    "Post-nominal": "LP",
  },
  {
    Name: "Licensed Practical Nurse (much of U.S.)",
    "Post-nominal": "LPN",
  },
  {
    Name: "Licensed Vocational Nurse (California and Texas)",
    "Post-nominal": "LVN",
  },
  {
    Name: "Registered Nurse",
    "Post-nominal": "RN",
  },
  {
    Name: "Advanced Practice Nurse",
    "Post-nominal": "APN",
  },
  {
    Name: "Advanced Practice Registered Nurse",
    "Post-nominal": "APRN",
  },
  {
    Name: "Certified Massage Therapist",
    "Post-nominal": "CMT",
  },
  {
    Name: "Licensed Massage Therapist",
    "Post-nominal": "LMT",
  },
  {
    Name: "Licensed Clinical Massage Therapist",
    "Post-nominal": "LCMT",
  },
  {
    Name: "Licensed Midwife",
    "Post-nominal": "LM",
  },
  {
    Name: "Certified Midwife",
    "Post-nominal": "CM",
  },
  {
    Name: "Certified Professional Midwife",
    "Post-nominal": "CPM",
  },
  {
    Name: "Certified Nurse-Midwife",
    "Post-nominal": "CNM",
  },
  {
    Name: "Certified Registered Nurse Anesthetist",
    "Post-nominal": "CRNA",
  },
  {
    Name: "Nurse Practitioner",
    "Post-nominal": "NP",
  },
  {
    Name: "Marine Physician Assistant",
    "Post-nominal": "MPA",
  },
  {
    Name: "Occupational Therapist",
    "Post-nominal": "OT",
  },
  {
    Name: "Physician Assistant",
    "Post-nominal": "PA-C",
  },
  {
    Name: "Registered Veterinary Technician",
    "Post-nominal": "RVT",
  },
  {
    Name: "Physical Therapist",
    "Post-nominal": "PT",
  },
  {
    Name: "Physical Therapy Assistant",
    "Post-nominal": "PTA",
  },
  {
    Name: "Certified in Public Health",
    "Post-nominal": "CPH",
  },
  {
    Name: "Medical Laboratory Scientist",
    "Post-nominal": "MLS",
  },
  {
    Name: "Medical Technologist",
    "Post-nominal": "MT",
  },
  {
    Name: "Medical Laboratory Technician",
    "Post-nominal": "MLT",
  },
  {
    Name: "Licensed Professional Counselor",
    "Post-nominal": "LPC",
  },
  {
    Name: "Licensed Associate Counselor",
    "Post-nominal": "LAC",
  },
  {
    Name: "Licensed Master Social Worker",
    "Post-nominal": "LMSW",
  },
  {
    Name: "Licensed Clinical Social Worker",
    "Post-nominal": "LCSW",
  },
  {
    Name: "Traumatic Event Manager",
    "Post-nominal": "TEM",
  },
  {
    Name: "Licensed Veterinary Technician",
    "Post-nominal": "LVT",
  },
  {
    Name: "Qualified Clinical Social Worker",
    "Post-nominal": "QCSW",
  },
  {
    Name: "Certified Tissue Bank Specialist",
    "Post-nominal": "CTBS",
  },
  {
    Name: "Certified Hypnotherapist",
    "Post-nominal": "CHt",
  },
  {
    Name: "Certified Handwriting Analyst",
    "Post-nominal": "CHA",
  },
  {
    Name: "Certified Advanced Handwriting Analyst",
    "Post-nominal": "CAHA",
  },
  {
    Name: "National Board Certified Clinical Hypnotherapist",
    "Post-nominal": "NBCCH",
  },
  {
    Name: "National Board Certified Clinical Hypnotherapist in Public Service",
    "Post-nominal": "NBCCH-PS",
  },
  {
    Name: "National Board Certified Diplomate in Clinical Hypnotherapy",
    "Post-nominal": "NBCDCH",
  },
  {
    Name:
      "National Board Certified Diplomate in Clinical Hypnotherapy in Public Service",
    "Post-nominal": "NBCDCH-PS",
  },
  {
    Name: "National Board Certified Fellow in Clinical Hypnotherapy",
    "Post-nominal": "NBCFCH",
  },
  {
    Name:
      "National Board Certified Fellow in Clinical Hypnotherapy in Public Service",
    "Post-nominal": "NBCFCH-PS",
  },
  {
    Name: "Licensed Acupuncturist",
    "Post-nominal": "L.Ac.",
  },
  {
    Name: "Diplomate in Acupuncture",
    "Post-nominal": "Dipl.Ac.",
  },
  {
    Name: "Diplomate in Oriental Medicine",
    "Post-nominal": "Dipl.O.M.",
  },
  {
    Name: "Diplomate of the American Board of Family Medicine",
    "Post-nominal": "DABFM",
  },
  {
    Name: "Diplomate of the American Board of Venous & Lymphatic Medicine",
    "Post-nominal": "DABVLM",
  },
  {
    Name: "Fellow of the American Academy of Family Physicians",
    "Post-nominal": "FAAFP",
  },
  {
    Name: "Fellow of the American Society for Parenteral and Enteral Nutrition",
    "Post-nominal": "FASPEN",
  },
  {
    Name: "American Geriatrics Society Fellow",
    "Post-nominal": "AGSF",
  },
  {
    Name: "Fellow of the American Academy of Neurology",
    "Post-nominal": "FAAN",
  },
  {
    Name: "Fellow of the American Society of Health-System Pharmacists",
    "Post-nominal": "FASHP",
  },
  {
    Name: "Fellow of the American College of Emergency Physicians",
    "Post-nominal": "FACEP",
  },
  {
    Name: "Fellow of the Aerospace Medical Association",
    "Post-nominal": "FAsMA",
  },
  {
    Name: "Associate Fellow of the Aerospace Medical Association",
    "Post-nominal": "AFAsMA",
  },
  {
    Name: "Certified Aerospace Physiologist",
    "Post-nominal": "CAsP",
  },
  {
    Name: "Aviation Medical Examiner",
    "Post-nominal": "AME",
  },
  {
    Name: "Senior Aviation Medical Examiner",
    "Post-nominal": "SAME",
  },
  {
    Name: "Fellow of the American Academy of Pediatrics",
    "Post-nominal": "FAAP",
  },
  {
    Name: "Fellow of the American College of Cardiology",
    "Post-nominal": "FACC",
  },
  {
    Name: "Fellow of the American College of Dentists",
    "Post-nominal": "FACD",
  },
  {
    Name: "Fellow of the American College of Endocrinology",
    "Post-nominal": "FACE",
  },
  {
    Name: "Fellow of the American College of Physicians",
    "Post-nominal": "FACP",
  },
  {
    Name:
      "Fellow of the American Vein & Lymphatic Society (formerly American College of Phlebology)",
    "Post-nominal": "FACPh",
  },
  {
    Name: "Fellow of the American College of Surgeons",
    "Post-nominal": "FACS",
  },
  {
    Name: "Fellow of the American College of Osteopathic Family Physicians",
    "Post-nominal": "FACOFP",
  },
  {
    Name: "Fellow of the American Congress of Obstetricians and Gynecologists",
    "Post-nominal": "FACOG",
  },
  {
    Name: "Fellow of the Health Advocacy and Medical Exploration Society",
    "Post-nominal": "FHAMES",
  },
  {
    Name: "Doctor of Pharmacy",
    "Post-nominal": "PharmD",
  },
  {
    Name: "Certified Pharmacy Technician",
    "Post-nominal": "CPhT",
  },
  {
    Name: "Registered Pharmacist",
    "Post-nominal": "RPh",
  },
  {
    Name: "Registered Respiratory Therapist",
    "Post-nominal": "RRT",
  },
  {
    Name: "Registered Respiratory Therapist, Neonatal & Pediatric Specialist",
    "Post-nominal": "RRT-NPS",
  },
  {
    Name: "Registered Respiratory Therapist, Sleep Disorder Specialist",
    "Post-nominal": "RRT-SDS",
  },
  {
    Name: "Registered Respiratory Therapist, Adult Critical Care Specialist",
    "Post-nominal": "RRT-ACCS",
  },
  {
    Name: "Respiratory practitioner",
    "Post-nominal": "RP",
  },
  {
    Name: "Certified Respiratory Therapy Technician",
    "Post-nominal": "CRTT",
  },
  {
    Name: "Certified Respiratory Therapist",
    "Post-nominal": "CRT",
  },
  {
    Name: "Certified Phlebotomy Technician",
    "Post-nominal": "CPT",
  },
  {
    Name: "Certified Personal Trainer",
    "Post-nominal": "CPT",
  },
  {
    Name: "Certified Athletic trainer",
    "Post-nominal": "ATC",
  },
  {
    Name: "Certified Medical Assistant",
    "Post-nominal": "CMA",
  },
  {
    Name: "Nutrition and Dietetics Technician, Registered",
    "Post-nominal": "NDTR",
  },
  {
    Name: "Registered Dental Hygienist",
    "Post-nominal": "RDH",
  },
  {
    Name: "Registered Dietitian/Nutritionist",
    "Post-nominal": "RD",
  },
  {
    Name: "Registered Diagnostic Medical Sonographer",
    "Post-nominal": "RDMS",
  },
  {
    Name: "Registered Vascular Technologist",
    "Post-nominal": "RVT",
  },
  {
    Name: "Registered Diagnostic Cardiac Sonographer",
    "Post-nominal": "RDCS",
  },
  {
    Name: "Registered Musculoskeletal Sonographer",
    "Post-nominal": "RMSKS",
  },
];

export default certifications.map(certification => {
  const name = certification["Name"];
  const abbr = certification["Post-nominal"];
  return `${name} (${abbr})`;
});
