import { Field, Form, Formik } from 'formik';
import React from 'react';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';

export default function EditSettingForm({ initialValues, saveCreditRate, navigate, loading }) {
  const settingSchema = Yup.object().shape({
    credit_rate: Yup.number().required('Please enter credit rate'),
    daily_candidate_request_limit: Yup.number().required('Please enter daily candidate request limit'),
    weekly_candidate_request_limit: Yup.number().required('Please enter weekly candidate request limit'),
    monthly_candidate_request_limit: Yup.number().required('Please enter monthly candidate request limit'),
  });

  const labels = {
    credit_rate: 'Credit Rate',
    daily_candidate_request_limit: 'Daily candidate request limit',
    weekly_candidate_request_limit: 'Weekly candidate request limit',
    monthly_candidate_request_limit: 'Monthly candidate request limit',
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col w-1/2 mt-xl">
        <div className="text-darkblue">Cost per credit: {'$' + initialValues.credit_rate}</div>
      </div>
      <br />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          saveCreditRate(values);
        }}
        validationSchema={settingSchema}
      >
        {({ setFieldValue }) => (
          <Form className="flex flex-col h-full">
            {Object.entries(initialValues).map(([label, value], index) => {
              switch (label) {
                default:
                  return (
                    <div key={index}>
                      {labels[label]}
                      <Field name={label}>
                        {({ field, form, meta }) => {
                          return (
                            <>
                              <input
                                className="text-sm  px-md rounded w-full border border-darkgray"
                                style={{ height: 55, marginTop: 16, marginBottom: 16, borderColor: '#c4cad3' }}
                                placeholder={label}
                                {...field}
                              />
                              {form.errors[label] && form.touched[label] && <div className="text-xs text-red font-medium mt-sm text-center">{form.errors[label]}</div>}
                            </>
                          );
                        }}
                      </Field>
                    </div>
                  );
              }
            })}
            <div className="flex w-full" style={{ marginTop: 20 }}>
              <button
                onClick={() => navigate('Profile')}
                type="button"
                style={{ height: 55 }}
                className="bg-lightgray flex items-center justify-center shadow text-xs font-medium flex-1 text-darkgray rounded mr-sm p-sm"
              >
                Cancel
              </button>
              <button
                type="submit"
                style={{ height: 55, boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)' }}
                className="bg-red flex items-center justify-center  shadow ml-sm text-xs font-medium flex-1 text-white rounded p-sm"
              >
                {loading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Update'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
