import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import React, { useContext, useEffect, useState } from 'react';
import { Arrow } from '../../components/common';
import { EXPRESS_SERVER_URL } from '../../config';
import UserRoleContext from '../../context/UserRole';
import JobForm from '../../forms/job/JobForm';
import { defaultCompanyLogo } from '../../utils/constants';

const CREATE_COMPANY = gql`
  mutation createCompany($company: [companies_insert_input!]!) {
    insert_companies(objects: $company) {
      returning {
        id
      }
    }
  }
`;
// const CREATE_COMPANY = gql`
//   mutation createCompany($company: [company_insert_input!]!) {
//     insert_company(objects: $company) {
//       returning {
//         id
//       }
//     }
//   }
// `;
const UPDATE_JOB = gql`
  mutation updateJob($id: uuid, $changes: job_set_input) {
    update_job(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
        industry
        location
        notes
        company {
          id
          name
          logoURL
        }
        optionalAttributes
        requiredAttributes
        requiredExperience
        salaryMax
        salaryMin
        status
        title
        hiddenFromCandidates
        filled
        description
        compensation
        compensation_list
      }
    }
  }
`;

export default function EditJob({ location, navigate }) {
  const [userRole] = useContext(UserRoleContext);
  const isSubscriber = userRole === 'company';
  const isAdmin = userRole === 'admin';

  const [job, setJob] = useState();
  const [createCompany, { data: companyData, loading: companyLoading }] = useMutation(CREATE_COMPANY);
  const [updateJob, { data: jobData, loading: jobLoading }] = useMutation(UPDATE_JOB);
  const [jobChanges, setJobChanges] = useState({});

  const uploadPhoto = async (photo, directory) => {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/${directory}/${photo.name}`);
    const photoURL = await storageRef.put(photo).then((snapshot) => snapshot.ref.getDownloadURL());
    return photoURL;
  };

  async function saveJob(submission) {
    let changes = Object.fromEntries(
      Object.entries(job)
        .filter(([field, value]) => {
          switch (field) {
            case 'id':
              return null;
            case 'recruiterName':
              return null;
            case 'requiredExperience':
              return submission[field][0] !== value[0] || submission[field][1] !== value[1];
            case 'attributes':
              return submission[field].required !== value.required || submission[field].optional !== value.optional;
            case 'salaryMin':
              return submission.salary[0] !== value;
            case 'salaryMax':
              return submission.salary[1] !== value;
            default:
              return submission[field] !== value;
          }
        })
        .map(([field]) => {
          if (field === 'company' && submission.company && submission.company.id) {
            return ['company_id', submission.company.id];
          } else if (field === 'salaryMin' || field === 'salaryMax') {
            return field === 'salaryMin' ? [field, submission.salary[0]] : [field, submission.salary[1]];
          } else {
            return [field, submission[field]];
          }
        }),
    );

    if (changes.attributes) {
      const { required, optional } = changes.attributes;
      changes = Object.fromEntries(Object.entries(changes).filter(([label]) => label !== 'attributes'));
      changes.requiredAttributes = required;
      changes.optionalAttributes = optional;
    }

    setJobChanges(changes);

    if (isSubscriber) {
      changes.company = undefined;
    }

    if (changes.company) {
      createCompany({
        variables: {
          company: {
            name: changes.company.name,
            size: changes.company.size,
            websiteURL: changes.company.websiteURL,
            description: changes.company.bio,
            logoURL: changes.company.logoURL !== '' ? await uploadPhoto(changes.company.logoURL, 'newcompanylogos') : defaultCompanyLogo,
            adminID: firebase.auth().currentUser && firebase.auth().currentUser.uid,
          },
        },
      });
    } else {
      await updateJob({ variables: { id: job.id, changes } });

      await fetch(`${EXPRESS_SERVER_URL}/matchCandidatesWithNewJob`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event: {
            data: {
              new: {
                id: job.id,
                requiredExperience: submission.requiredExperience,
                salaryMin: submission.salary[0],
                salaryMax: submission.salary[1],
                location: submission.location,
                requiredAttributes: submission.attributes.required,
                closed: false,
                allowsRemote: submission.allowsRemote,
              },
            },
          },
        }),
      });
    }
  }

  useEffect(() => {
    if (companyData) {
      const {
        insert_companies: {
          returning: [{ id: companyId }],
        },
      } = companyData;
      const changes = Object.fromEntries(
        Object.entries(jobChanges).map(([label, value]) => {
          if (label === 'company') {
            return ['company_id', companyId];
          } else {
            return [label, value];
          }
        }),
      );
      updateJob({ variables: { id: job.id, changes } });
    }
  }, [companyData]);

  useEffect(() => {
    if (jobData) {
      navigate(`/jobs/${jobData.update_job.returning[0].id}`, { state: { jobID: jobData.update_job.returning[0].id } });
    }
  }, [jobData]);

  useEffect(() => {
    if (location.state && location.state.job) {
      setJob(location.state.job);
    } else {
      navigate('/jobs');
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="container flex flex-col items-center justify-center relative">
        <div className="w-full pl-lg pt-md flex">
          <div
            onClick={() => navigate(`/jobs/${location.state.job.id}`, { state: { jobID: location.state.job.id } })}
            className="flex text-darkgray font-medium text-xs items-center cursor-pointer hover:underline"
          >
            <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} />
            {'Job Details'}
          </div>
        </div>
        <div className="text-darkblue font-medium text-center" style={{ fontSize: 21, paddingTop: 40, paddingBottom: 58 }}>
          Edit Job
        </div>
        <div className="w-full bg-white rounded shadow flex-1 flex flex-col" style={{ paddingRight: 30, paddingLeft: 30 }}>
          {job && <JobForm job={job} onSubmit={saveJob} loading={companyLoading || jobLoading} isAdmin={isAdmin} />}
        </div>
      </div>
    </div>
  );
}
