import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';

const GET_ALL_SUBSCRIBERS = gql`
  query getAllSubscribers {
    company(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export default function SubscriberDropdown({ value, update, error }) {
  const [inputValue, setInputValue] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { data } = useQuery(GET_ALL_SUBSCRIBERS);
  const [allSubscribers, setAllSubscribers] = useState([]);
  const [subscribersToShow, setSubscribersToShow] = useState([]);

  function hideDropdown() {
    setDropdownVisible(false);
  }

  useEffect(() => {
    if (dropdownVisible) {
      window.addEventListener('click', hideDropdown);
    } else {
      setInputValue('');
      return window.removeEventListener('click', hideDropdown);
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (data) {
      setAllSubscribers(data.company);
      setSubscribersToShow(data.company);
    }
  }, [data]);

  return (
    <>
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <input
          onClick={(e) => e.stopPropagation()}
          autoComplete="new-password"
          value={inputValue}
          onFocus={() => setDropdownVisible(true)}
          onChange={(e) => {
            const newValue = e.target.value;
            setInputValue(e.target.value);

            setSubscribersToShow(
              allSubscribers.filter((a) => {
                if (newValue) {
                  return new RegExp(newValue, 'gi').test(a.name);
                }

                return true;
              }),
            );
          }}
          className="text-xs"
          style={{
            height: 28,
            paddingLeft: 15,
            paddingRight: 15,
            zIndex: dropdownVisible ? 501 : 499,
            borderColor: error ? '#E31C25' : '#c4cad3',
            display: 'inline-block',
            position: 'relative',
            border: '1px solid black',
            width: 200,
          }}
        />
        {dropdownVisible && (
          <div className="absolute bg-white w-full border-r border-l overflow-auto shadow" style={{ top: 'calc(100% - 10px)', zIndex: 500, paddingTop: 10, maxHeight: 350 }}>
            {subscribersToShow.map((subscriber, index) => {
              const selected = value.filter((a) => a.id === subscriber.id)[0];

              return (
                <div
                  className={`cursor-pointer ${selected ? 'bg-green text-white' : 'hover:bg-lightgray'} flex justify-between items-center`}
                  style={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (selected) {
                      update(value.filter((a) => a.id !== subscriber.id));
                    } else {
                      update([...value, subscriber]);
                    }
                  }}
                >
                  {subscriber.name}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
