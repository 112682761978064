import React, { useState, useEffect } from "react";
import { useSubscription } from "@apollo/react-hooks";
import { SelectDropdown } from "../../forms/fields";
import { gql } from "apollo-boost";

const ALL_SKILLS_CATEGORY = gql`
subscription getSkills {
    skill(distinct_on: [category], order_by: [{category: asc}]) {
      category
    }
  }
`;

export default function CategoryFilter({ update, value }) {
  const [category, setCategory] = useState(value);
  const [attributes] = useState([]);

  const { data } = useSubscription(ALL_SKILLS_CATEGORY);

  useEffect(() => {
    update(category);
  }, [category]);

  useEffect(() => {
    if (data) {
      data.skill.map(skill => {
        attributes.push(skill.category)
      })
    }
  }, [data]);

  useEffect(()=>{
    setCategory(value);
  },[value]);

  return (
    <div className="flex-1" style={{ paddingRight: 40 }}>
      <div className="relative flex-1">
        <SelectDropdown
          value={category}
          update={setCategory}
          list={attributes}
          placeholder={"Search for category"}
          dontClear={true}
        />
      </div>
    </div>
  );
}
