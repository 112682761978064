import React, { useEffect, useState } from 'react';
import CategoryFilter from './CategoryFilter';
import SearchTermsFilter from './SearchTermsFilter';

const ClearButton = ({ updateField }) => (
  <div
    onClick={updateField}
    className="absolute lowercase cursor-pointer bg-darkgray text-white font-medium px-sm"
    style={{ top: 0, marginLeft: 15, borderRadius: 45.39, lineHeight: '20px' }}
  >
    clear
  </div>
);

export default function AttrFilter({ filterState, type }) {
  const [filterValue, update] = filterState;
  const sections = {
    search: filterValue.search ? filterValue.search : [],
    category: filterValue.category ? filterValue.category : null,
  };
  const [options, setOptions] = useState(sections);

  const updateField = (value, name) => setOptions({ ...options, [name]: value });

  const clearFilterField = (label) => {
    setOptions({ search: null, category: null });
  };

  const renderFilterField = (name) => {
    switch (name) {
      case 'search':
        return <SearchTermsFilter type={type} value={filterValue.search} update={(value) => updateField(value, name)} />;
      case 'category':
        return <CategoryFilter value={filterValue.category} update={(value) => updateField(value, name)} />;
      default:
        return <div></div>;
    }
  };

  const renderClearButton = (label) => {
    return <ClearButton updateField={() => clearFilterField(label)} />;
  };

  useEffect(() => {}, [filterValue]);

  useEffect(() => {
    let filter = {};
    const { search, category } = options;

    if (search && search.length > 0) {
      filter = { ...filter, search };
    }
    if (category) {
      filter = { ...filter, category };
    }
    update(filter);
  }, [options]);

  return (
    <div className="flex w-full flex-wrap" style={{ marginBottom: 30 }}>
      {Object.entries(sections).map(([label], index) => {
        return (
          <div key={index} className={`flex flex-col ${label}-filter`} style={{ flexGrow: 1 }}>
            <div
              className="text-darkgray flex items-center capitalize font-medium"
              style={{ fontSize: 12, height: 20, marginBottom: 15, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {options && options.category && (
                <div className="flex-1 relative" style={{ height: 20 }}>
                  {renderClearButton(label)}
                </div>
              )}
            </div>
            {renderFilterField(label)}
          </div>
        );
      })}
    </div>
  );
}
