import React from 'react';
import FacebookIcon from '../../../images/facebook.svg';
import InstagramIcon from '../../../images/instagram.svg';
import TwitterIcon from '../../../images/twitter.svg';
import ProfileChangeUI from './ProfileChangeUI';
import { prepareUrl } from '../../../utils/formatter';

export default function CandidateSocial ({ value, changes, approvedChanges, approveProfileChangeRequest, declineProfileChangeRequest }) {
  const getSocialValue = (label) => {
    if (changes && changes[label]) {
      return changes[label];
    } else if (approvedChanges && approvedChanges[label]) {
      return approvedChanges[label];
    } else if (value[label] && value[label] !== '') {
      return value[label];
    } else {
      return null;
    }
  };

  const socialEntries = {
    facebook: {
      url: 'https://facebook.com/',
      handle: getSocialValue('facebook'),
      icon: FacebookIcon,
    },
    instagram: {
      url: 'https://instagram.com/',
      handle: getSocialValue('instagram'),
      icon: InstagramIcon,
    },
    twitter: {
      url: 'https://twitter.com/',
      handle: getSocialValue('twitter'),
      icon: TwitterIcon,
    },
  };

  const isEmpty = () => {
    if (socialEntries.facebook.handle || socialEntries.instagram.handle || socialEntries.twitter.handle) {
      return false;
    } else {
      return true;
    }
  };

  return (
    !isEmpty() && (
      <div className="w-full flex flex-col mb-md">
        <div className="capitalize text-darkgray pb-md font-medium w-full">Social</div>
        <div className={`flex ${changes ? 'flex-col' : 'flex-col'}`}>
          {Object.entries(socialEntries).map(([label, { url, handle, icon }]) => {
            return (
              handle && (
                <div className="flex mb-md items-center">
                  <a href={prepareUrl((label == 'instagram' || label == 'twitter' ? url : '') + handle)} target="_blank" className="cursor-pointer mr-sm flex">
                    <img src={icon} style={{ width: 24, margin: 0, marginRight: 12 }} />
                    <div className="font-medium text-sm">{`${handle}`}</div>
                  </a>
                  {changes && changes[label] && (
                    <ProfileChangeUI approveProfileChangeRequest={approveProfileChangeRequest} declineProfileChangeRequest={declineProfileChangeRequest} type={label} />
                  )}
                </div>
              )
            );
          })}
        </div>
      </div>
    )
  );
}
