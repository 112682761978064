import { Link } from 'gatsby';
import React, { useRef } from 'react';

const dashboardRoutes = ['find-candidate', 'jobs', 'completed-jobs', 'requests-sent', 'interested-candidates'];

export default function TabNav({ activeTab, type }) {
  const tabs = dashboardRoutes;
  const containerRef = useRef();

  return (
    <div className="w-full border-b border-darkgray" style={{ marginTop: 40 }}>
      <div ref={containerRef} className="flex mb-sm" style={{ maxWidth: type === 'dashboard' ? 750 : 400 }}>
        {tabs.map((tab, index) => (
          <div key={index} style={{ flexGrow: 1 }} className="relative">
            <Link
              to={`${type === 'dashboard' ? '/dashboard' : ''}/${tab}`}
              className={`capitalize cursor-pointer ${activeTab === tab ? 'text-darkblue' : 'text-darkgray'}`}
              style={{
                fontSize: 16,
                fontWeight: 600,
                transition: 'color 0.3s ease-in-out',
              }}
            >
              {tab === 'find-candidate'
                ? 'Find a Candidate'
                : tab == 'jobs'
                ? 'My Jobs'
                : tab == 'requests-sent'
                ? 'Requests Sent'
                : tab == 'interested-candidates'
                ? 'Interested Candidates'
                : tab == 'completed-jobs'
                ? 'Completed Jobs'
                : tab}
            </Link>
            {activeTab === tab && <div id="nav-slider" className="bg-red absolute" style={{ height: 3, width: 22, bottom: -10 }} />}
          </div>
        ))}
      </div>
    </div>
  );
}
