import 'firebase/auth';
import React from 'react';
import ProfilePicture from './profile-picture';

export default function ViewProfile({ currentUser, isAdmin, isSubscriber }) {
  const recruiterFields = isAdmin ? ['email'] : isSubscriber ? ['email', 'size', 'websiteURL'] : ['email', 'phone', 'title', 'industries'];

  return (
    <div className="bg-white p-lg rounded flex flex-col" style={{ minHeight: 370 }}>
      {currentUser && (
        <React.Fragment>
          <div className="flex items-center">
            <ProfilePicture source={isSubscriber ? currentUser.logoURL : currentUser.profilePhoto} isAdmin={isAdmin} />
            <div className="flex flex-col">
              <div className="text-darkblue font-medium">{currentUser.name}</div>
              <div className="text-darkgray font-medium text-sm">{currentUser.company && currentUser.company.name}</div>
            </div>
          </div>
          <div className="flex flex-wrap font-medium text-sm">
            {recruiterFields.map((field, index) => (
              <div key={index} className="flex flex-col w-1/2 mt-xl">
                <div className="capitalize">{field == 'size' ? 'Number of employees' : field == 'websiteURL' ? 'Website URL' : field}</div>
                <div className={`${field === 'industries' ? 'flex flex-wrap font-medium text-sm' : 'text-darkgray'}`}>
                  {field == 'industries'
                    ? currentUser.industries &&
                      currentUser.industries.map((industry) => (
                        <div className="bg-lightgray text-darkgray mr-md mt-md px-md flex items-center" style={{ height: 30, borderRadius: 30 }}>
                          {industry}
                        </div>
                      ))
                    : currentUser[field]
                    ? currentUser[field]
                    : 'N/A'}
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
