import React from 'react';
import { REQUEST_STATUSES as STATUSES } from '../../../utils/constants';
import CandidateDashboard from '../candidate-dashboard';

const RequestsSent = () => <CandidateDashboard
  BASE_FILTER={`where: {company_requests: {_and: {status: {_in: ["${STATUSES.requested}"]}, company: {adminID: {_eq: $admin_id}}}}}`}
  searchPlaceholder="Job title"
  context="request-send-candidate"
  showRequestedCandidates={true}
/>

export default RequestsSent;
