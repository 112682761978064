import React from 'react';
import AttributesDropdown from '../../forms/fields/AttributesDropdown';

export default function AttributesFilter({ update, attributes, style = {} }) {
  return (
    <div>
      <div className="relative">
        <AttributesDropdown value={attributes} update={update} style={{ height: 40, paddingLeft: 15, paddingRight: 15, ...style }} className={'rounded shadow relative text-xs'} />
      </div>
    </div>
  );
}
