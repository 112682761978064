import React, { useState, useEffect } from 'react';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import DownIcon from '../../../images/down-arrow.png';
import CheckIcon from '../../../images/check-white.png';

const ADD_CANDIDATES_TO_PROJECT = gql`
  mutation addCandidatesToProject($candidates: [project_candidate_insert_input!]!) {
    insert_project_candidate(objects: $candidates, on_conflict: { constraint: project_candidate_pkey, update_columns: candidate_id }) {
      affected_rows
    }
  }
`;

const ProjectsQuery = gql`
  subscription getProjectsByRecruiter($user_id: String) {
    project(where: { recruiter_id: { _eq: $user_id } }) {
      id
      name
    }
  }
`;


const AddToProject = ({ selectedCandidates, clearCandidates }) => {
  const [selectedProject, setSelectedProject] = useState('');
  const [addCandidates] = useMutation(ADD_CANDIDATES_TO_PROJECT);
  const [projects, setProjects] = useState([]);

  function addCandidatesToProject() {
    if (selectedCandidates.length > 0 && selectedProject) {
      const candidates = selectedCandidates.map((candidate) => ({
        candidate_id: candidate,
        project_id: selectedProject,
      }));
      addCandidates({ variables: { candidates } });
      clearCandidates();
      setSelectedProject();
    }
  }

  const { data: projectsData } = useSubscription(ProjectsQuery, {
    variables: {
      user_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (projectsData) {
      if (projectsData) {
        setProjects(projectsData.project);
      }
    }
  }, [projectsData]);

  return (
    <>
      <div className="relative overflow-hidden rounded" style={{ marginRight: 6 }}>
        <select
          value={selectedProject}
          onChange={(e) => setSelectedProject(e.target.value)}
          className={`bg-white rounded text-darkblue flex items-center cursor-pointer relative`}
          style={{ opacity: selectedProject ? 1 : 0, height: 40, width: 200, fontSize: 14, paddingLeft: 15, zIndex: 20, WebkitAppearance: 'none' }}
        >
          <option value="" disabled>
            Add Project
          </option>
          {projects.map(({ id, name }) => (
            <option key={id} value={id}>{name}</option>
          ))}
        </select>
        <img alt="" className="absolute" src={DownIcon} style={{ width: 15, height: 7.5, right: 15, top: 17, zIndex: 21 }} />
        {!selectedProject && (
          <div className="bg-white absolute flex inset-0 items-center text-darkgray" style={{ fontSize: 14, paddingLeft: 15, right: 6, width: 200 }}>
            Add to Project
          </div>
        )}
      </div>
      <div
        onClick={addCandidatesToProject}
        className={`${selectedCandidates.length > 0 ? 'bg-green' : 'border border-darkgray'} rounded cursor-pointer flex items-center justify-center`}
        style={{ height: 40, width: 40 }}
      >
        {selectedCandidates.length > 0 && <img alt="" src={CheckIcon} style={{ width: 15, height: 15, margin: 0 }} />}
      </div>
    </>
  );
};

export default AddToProject;