import React from 'react';
import CandidateDashboard from '../candidate-dashboard';

const FavoriteCandidates = () => <CandidateDashboard
  BASE_FILTER={`where: { fav_candidates: { company_firebase_id: { _eq: $admin_id } } }`}
  searchPlaceholder="Job title"
  context="favorite-candidate"
  showFavoriteCandidates={true}
/>

export default FavoriteCandidates;
