import React from "react";
import { Button } from "../../components/common";
import { Link } from "gatsby";

export default function DashboardCompany() {
  return (
    <div className="flex h-full justify-center items-center">
      <div className="bg-white rounded shadow" style={{ padding: "30px 35px" }}>
        <div
          className="font-thin text-darkblue text-center"
          style={{ fontSize: 48 }}
        >
          Welcome
        </div>
        <div className="text-center mb-xl" style={{ fontSize: 14 }}>
          What would you like to do first?
        </div>
        <div className="flex flex-col">
          <Link to="/dashboard/find-candidate">
            <Button color="red" width={310} height={55} mb={20}>
              <div className="text-lg mr-sm">+</div>
              Find a Candidate
            </Button>
          </Link>
          <Link to="/dashboard/saved-candidates">
            <Button color="red" width={310} height={55} mb={20}>
              <div className="text-lg mr-sm">+</div>
              View Saved Candidates
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
