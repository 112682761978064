import moment from 'moment';
import React from 'react';

export default function EducationEntry ({ entry, schoolHidden }) {
  const endDate = entry.tenure.current ? 'Present' : moment(entry.tenure.endDate).format('MMM YYYY');

  return (
    <div className="text-darkblue font-medium w-full" style={{ paddingTop: 15 }} key="{entry.degree}">
      <div style={{ fontSize: 16 }}>{entry.degree}</div>
      <div className="text-darkgray" style={{ fontSize: 14 }}>
        <div style={{ filter: `blur(${schoolHidden ? '5' : '0'}px)`, display: 'inline-block', marginRight: 8 }}>{schoolHidden ? 'School/Institution Name' : entry.school}</div>
        <div style={{ fontSize: 26, display: 'inline-block', position: 'relative', top: 2, lineHeight: '14px' }}>•</div> {endDate}
      </div>
    </div>
  );
}
