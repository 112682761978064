import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "../../images/search.svg";

export default function FindChat({ value, update }) {
  const [searchActive, setSearchActive] = useState(false);
  const inputEl = useRef(null);

  return (
    <div className="flex-1 pt-sm">
      <div className="relative flex-1">
        <img
          alt="search"
          src={SearchIcon}
          style={{ position: "absolute", left: 10, top: 11, width: 16 }}
        />
        <input
          ref={inputEl}
          onFocus={() => setSearchActive(true)}
          value={value}
          onChange={e => {
            update(e.target.value)
          }}
          style={{ height: 40, paddingLeft: 35, paddingRight: 60 }}
          className="bg-white w-full shadow rounded p-sm text-xs placeholder-primary font-normal"
          placeholder={`Search for candidate`}
        />
        {value && value.length > 0 && (
          <div
            role="button"
            onClick={() => update("")}
            className="bg-lightgray px-sm rounded text-xs text-darkgray cursor-pointer hover:bg-darkgray hover:text-lightgray"
            style={{ position: "absolute", right: 10, top: 10 }}>
            clear
          </div>
        )}
      </div>
    </div>
  );
}
//
