const industries = [
  {
    Industries: "Accounting",
  },
  {
    Industries: "Agriculture",
  },
  {
    Industries: "Architecture",
  },
  {
    Industries: "Automotives",
  },
  {
    Industries: "Banking",
  },
  {
    Industries: "Biotechnology",
  },
  {
    Industries: "Computers/Internet",
  },
  {
    Industries: "Construction",
  },
  {
    Industries: "Consumer Goods",
  },
  {
    Industries: "Defense",
  },
  {
    Industries: "Digital",
  },
  {
    Industries: "Ecommerce",
  },
  {
    Industries: "Electric Utilities",
  },
  {
    Industries: "Engineering",
  },
  {
    Industries: "Entertainment",
  },
  {
    Industries: "Hedge Funds",
  },
  {
    Industries: "Private Equity",
  },
  {
    Industries: "Financial Services",
  },
  {
    Industries: "Government",
  },
  {
    Industries: "Asset Management",
  },
  {
    Industries: "Green Technology",
  },
  {
    Industries: "Hardware/Electronices",
  },
  {
    Industries: "Healthcare",
  },
  {
    Industries: "Hospitality",
  },
  {
    Industries: "Human Resources",
  },
  {
    Industries: "Consulting",
  },
  {
    Industries: "Insurance",
  },
  {
    Industries: "Legal",
  },
  {
    Industries: "Manufacturing/Distribution",
  },
  {
    Industries: "Non Profit",
  },
  {
    Industries: "Professional Services",
  },
  {
    Industries: "Commercial Real Estate",
  },
  {
    Industries: "Real Estate",
  },
  {
    Industries: "Residential Estate",
  },
  {
    Industries: "Semiconductor",
  },
  {
    Industries: "Software",
  },
  {
    Industries: "Staffing Services",
  },
  {
    Industries: "Technology",
  },
  {
    Industries: "Travel",
  },
  {
    Industries: "Web Media",
  },
  {
    Industries: "Social Media",
  },
  {
    Industries: "Retail",
  },
  {
    Industries: "Luxury Goods",
  },
  {
    Industries: "Marketing",
  },
  {
    Industries: "Property Management",
  },
  {
    Industries: "Service",
  },
  {
    Industries: "Fund Administration",
  },
  {
    Industries: "Public Accounting",
  },
  {
    Industries: "Food & Beverage",
  },
  {
    Industries: "Family Office",
  },
  {
    Industries: "Apparel",
  },
  {
    Industries: "Forensics",
  },
  {
    Industries: "Sports",
  },
  {
    Industries: "Advertising",
  },
]
  .map(item => item.Industries)
  .sort();

export default industries;
