import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import EditSettingForm from '../../forms/profile/EditSettingForm';

const UPDATE_SETTINGS = gql`
  mutation updateSettings($id: uuid, $changes: settings_set_input) {
    update_settings(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
        credit_rate
      }
    }
  }
`;

export default function CreditCost({ navigate, settings }) {
  // const [creditRate, setCreditRate] = useState();

  // const { data: setting_data } = useSubscription(SETTINGS);
  // useEffect(() => {
  //   if (setting_data && setting_data.settings[0] && setting_data.settings[0].credit_rate) {
  //     setCreditRate(setting_data.settings[0].credit_rate);
  //   }
  // }, [setting_data])

  const initialFields = {
    credit_rate: settings.credit_rate,
    daily_candidate_request_limit: settings.daily_candidate_request_limit,
    weekly_candidate_request_limit: settings.weekly_candidate_request_limit,
    monthly_candidate_request_limit: settings.monthly_candidate_request_limit,
  };

  const [updateSettings, { loading }] = useMutation(UPDATE_SETTINGS);

  const saveCreditRate = async (props) => {
    const changes = {};

    changes.credit_rate = props.credit_rate;
    changes.daily_candidate_request_limit = props.daily_candidate_request_limit;
    changes.weekly_candidate_request_limit = props.weekly_candidate_request_limit;
    changes.monthly_candidate_request_limit = props.monthly_candidate_request_limit;

    updateSettings({
      variables: {
        id: 'b8a33041-6c9d-4b5f-a219-70519b4d3dab',
        changes: changes,
      },
    });
  };

  return (
    <div className="flex justify-center items-center pb-lg mx-md" style={{ top: 20, left: 0, right: 0, minHeight: '100%' }}>
      <div className="w-full bg-white rounded flex flex-col" style={{ minHeight: 756, padding: 40 }} onClick={(e) => e.stopPropagation()}>
        <EditSettingForm initialValues={initialFields} saveCreditRate={saveCreditRate} navigate={navigate} loading={loading} />
      </div>
    </div>
  );
}
