import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVDownloader } from 'react-papaparse';
import { TabTitle } from '../../components/common';
import './candidates.css';

const ModalContext = React.createContext();

const ALL_SUBSCRIBER_ACTIVITY = gql`
  query getSubscriberActivity($start_date: date, $end_date: date, $startDateString: String, $endDateString: String) {
    company(order_by: { name: asc }) {
      id
      name
      email
      remainingCredit
      subscription_type
      candidate_requests_aggregate(where: { date: { _gte: $start_date, _lte: $end_date } }) {
        aggregate {
          count
        }
        nodes {
          candidate_id
          company_id
          status
          credit_amount
          date
        }
      }
      jobs_aggregate(where: { created_date: { _gte: $start_date, _lte: $end_date } }) {
        aggregate {
          count
        }
        nodes {
          id
          filled
          closed
          created_date
        }
      }
      company_credits(where: { date: { _gte: $startDateString, _lte: $endDateString } }) {
        candidate_id
        company_id
        credits
        credit_amount
        date
        type
      }
    }
  }
`;

const ActivityHeading = () => {
  return (
    <div className="flex items-center border-b" style={{ height: 100, opacity: 1, transition: 'opacity 0.3s ease-in-out' }}>
      <div className="flex justify-center" style={{ width: 100 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Date'}
        </div>
      </div>
      <div style={{ paddingLeft: 0, paddingRight: 0, minWidth: 250 }}>
        <div className="flex flex-col">
          <div className="lg:p-0 text-darkblue lg:text-sm" style={{ fontWeight: 'bold' }}>
            {'Company Subscriber'}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14 }}>
            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
              {'Subscriber contact email'}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Requests sent'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Credits purchased'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 200 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Credits Added by Admin | Credits Removed by Admin'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Credits Remaining'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 200 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'No. of Jobs Posted | Jobs Filled | Jobs Closed'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'No. of Saved Candidates/Credits Used'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Credits Purchased'}
        </div>
      </div>
    </div>
  );
};
export { ActivityHeading };

const SingleActivityTr = ({ activity }) => {
  return (
    <tr>
      <td>
        <div className="flex flex-col">
          <div className="lg:p-0 lg:text-sm" style={{ fontWeight: 500, maxWidth: 230 }}>
            {activity.name}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14, maxWidth: 230 }}>
            <div className="text-darkgray mr-sm" style={{ textOverflow: 'ellipsis', maxWidth: 230 }}>
              {activity.email}
            </div>
          </div>
        </div>
      </td>

      <td>
        <div className="font-medium text-darkgray" style={{ fontSize: 14, textAlign: 'center' }}>
          {activity.candidate_requests_aggregate.aggregate.count}
        </div>
      </td>

      <td>
        <div className="font-medium text-darkgray" style={{ fontSize: 14, textAlign: 'center' }}>
          {activity.company_credits ? _.sum(activity.company_credits.filter((a) => [1].includes(a.type)).map((a) => a.credits)) : 0}
        </div>
      </td>

      <td>
        <div className="font-medium text-darkgray" style={{ fontSize: 14, textAlign: 'center' }}>
          {activity.company_credits ? _.sum(activity.company_credits.filter((a) => [3].includes(a.type)).map((a) => a.credits)) : 0}
          {' | '}
          {activity.company_credits ? _.sum(activity.company_credits.filter((a) => [4].includes(a.type)).map((a) => a.credits)) : 0}
        </div>
      </td>

      <td>
        <div className="font-medium text-darkgray" style={{ fontSize: 14, textAlign: 'center' }}>
          {activity.remainingCredit}
        </div>
      </td>

      <td>
        <div className="font-medium text-darkgray" style={{ fontSize: 14, textAlign: 'center' }}>
          {activity.jobs_aggregate.aggregate.count}
          {' | '}
          {activity.jobs_aggregate.nodes.length > 0 ? activity.jobs_aggregate.nodes.filter((node) => node.filled === true).length : 0}
          {' | '}
          {activity.jobs_aggregate.nodes.length > 0 ? activity.jobs_aggregate.nodes.filter((node) => node.closed === true).length : 0}
        </div>
      </td>

      <td>
        <div className="font-medium text-darkgray" style={{ fontSize: 14, textAlign: 'center' }}>
          {activity.candidate_requests_aggregate.nodes.length > 0 ? activity.candidate_requests_aggregate.nodes.filter((node) => node.status === 'approved').length : 0}
        </div>
      </td>
    </tr>
  );
};

export const SingleActivity = ({ activity }) => {
  return (
    <div className="flex items-center border-b" style={{ height: 100, opacity: 1, transition: 'opacity 0.3s ease-in-out' }}>
      <div className="flex justify-center" style={{ width: 100 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {moment(activity.jobs_aggregate.nodes.created_date).format('MM/DD/YYYY')}
        </div>
      </div>
      <div style={{ paddingLeft: 0, paddingRight: 0, width: 250 }}>
        <div className="flex flex-col">
          <div className="lg:p-0 lg:text-sm" style={{ fontWeight: 500 }}>
            {activity.name}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14, maxWidth: 420 }}>
            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
              {activity.email}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.candidate_requests_aggregate.aggregate.count}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.company_credits ? activity.company_credits.length : 0}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 200 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.company_credits ? activity.company_credits.filter((cc) => cc.type === 3).length : 0}
          {' | '}
          {activity.company_credits ? activity.company_credits.filter((cc) => cc.type === 4).length : 0}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.remainingCredit}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.jobs_aggregate.aggregate.count}
          {' | '}
          {activity.jobs_aggregate.nodes.length > 0 ? activity.jobs_aggregate.nodes.filter((node) => node.filled === true).length : 0}
          {' | '}
          {activity.jobs_aggregate.nodes.length > 0 ? activity.jobs_aggregate.nodes.filter((node) => node.closed === true).length : 0}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.candidate_requests_aggregate.nodes.length > 0 ? activity.candidate_requests_aggregate.nodes.filter((node) => node.status === 'approved').length : 0}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {'$ ' + activity.company_credits ? activity.company_credits.length * 1000 : 0}
        </div>
      </div>
    </div>
  );
};

export default function SubscriberActivity ({ isAdmin, inProcess, isDashboard }) {
  const [modal, setModal] = useState({ data: null, open: false });
  const [activityData, setActivityData] = useState();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  const { data: subscriberActivityResponse, loading } = useQuery(ALL_SUBSCRIBER_ACTIVITY, {
    variables: {
      start_date: startDate != null ? moment(startDate).format('YYYY-MM-DD') : null,
      end_date: moment(endDate).format('YYYY-MM-DD'),
      startDateString: startDate != null ? moment(startDate).format('YYYY-MM-DD') : null,
      endDateString: moment(endDate).format('YYYY-MM-DD'),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (subscriberActivityResponse) {
      setActivityData(subscriberActivityResponse.company);
    }
  }, [subscriberActivityResponse]);

  const csvData = (activityData || []).map((activity) => {
    return {
      'Subscriber Name': activity.name,
      'Subscriber Email': activity.email,
      'Requests Sent': activity.candidate_requests_aggregate.aggregate.count,
      'Credits Purchased': activity.company_credits ? _.sum(activity.company_credits.filter((a) => [1].includes(a.type)).map((a) => a.credits)) : 0,
      'Credits Added by Admin': activity.company_credits ? _.sum(activity.company_credits.filter((a) => [3].includes(a.type)).map((a) => a.credits)) : 0,
      'Credits Removed by Admin': activity.company_credits ? _.sum(activity.company_credits.filter((a) => [4].includes(a.type)).map((a) => a.credits)) : 0,
      'Credits Remaining': activity.remainingCredit,
      'No. of Jobs Posted': activity.jobs_aggregate.aggregate.count,
      'No. of Jobs Filled': activity.jobs_aggregate.nodes.length > 0 ? activity.jobs_aggregate.nodes.filter((node) => node.filled === true).length : 0,
      'No. of Jobs Closed': activity.jobs_aggregate.nodes.length > 0 ? activity.jobs_aggregate.nodes.filter((node) => node.closed === true).length : 0,
      'No. of Saved Candidates/Credits Used':
        activity.candidate_requests_aggregate.nodes.length > 0 ? activity.candidate_requests_aggregate.nodes.filter((node) => node.status === 'approved').length : 0,
    };
  });
  return (
    <div className="w-full flex-1 candidates">
      <ModalContext.Provider value={[modal, setModal]}>
        <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
          <TabTitle showBackButton={true}>{'Subscriber Activity'}</TabTitle>
          {activityData && activityData.length > 0 && (
            <CSVDownloader
              data={csvData}
              type="anchor"
              filename={'subscriber_activity'}
              className={`bg-red text-white font-medium rounded flex items-center justify-center`}
              style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
            >
              <span style={{ fontSize: 22, marginRight: 8 }}></span> Download CSV
            </CSVDownloader>
          )}
        </div>
        <div className="h-full flex flex-col" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
          <div style={{ marginRight: 20 }}>
            <span>Start Date: </span>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={endDate ? endDate : moment(new Date()).toDate()} />
          </div>
          <div>
            <span>End Date: </span>
            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={moment(new Date()).toDate()} />
          </div>
        </div>
        <div className="flex-1 bg-white rounded shadow candidates-container candidate-blank-dash" style={{ overflowX: 'auto' }}>
          <table style={{ width: '98%', marginLeft: '1%', marginRight: '1%' }}>
            <thead style={{ fontSize: 14, fontWeight: 'bold' }}>
              <tr style={{ position: 'sticky', top: 0, background: 'white' }}>
                <th>
                  <div className="text-sm justify-left" style={{ fontWeight: '500' }}>
                    Subscriber Name
                    <br />
                    <div className="text-darkgray">Subscriber Email</div>
                  </div>
                </th>

                <th>
                  <div className="text-sm justify-left" style={{ fontWeight: '500', textAlign: 'center' }}>
                    Requests sent
                  </div>
                </th>

                <th>
                  <div className="text-sm justify-left" style={{ fontWeight: '500', textAlign: 'center' }}>
                    Credits purchased
                  </div>
                </th>

                <th>
                  <div className="text-sm justify-left" style={{ fontWeight: '500', textAlign: 'center' }}>
                    Credits added | Credits removed by admin
                  </div>
                </th>

                <th>
                  <div className="text-sm justify-left" style={{ fontWeight: '500', textAlign: 'center' }}>
                    Credits remaining
                  </div>
                </th>

                <th>
                  <div className="text-sm justify-left" style={{ fontWeight: '500', textAlign: 'center' }}>
                    Jobs Posted | Jobs Filled | Jobs Closed
                  </div>
                </th>

                <th>
                  <div className="text-sm justify-left" style={{ fontWeight: '500', textAlign: 'center' }}>
                    No. of saved candidates (Credits used)
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {!loading &&
                (activityData || []).map((activity) => {
                  return <SingleActivityTr key={activity.id} activity={activity} />;
                })}
            </tbody>
          </table>

          {loading && (
            <div className={`flex justify-center items-center flex-1 candidate-blank`} style={{ width: '100%' }}>
              <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                Loading
              </div>
            </div>
          )}

          {!loading && activityData && activityData.length === 0 && (
            <div className={`flex justify-center items-center flex-1 candidate-blank`} style={{ width: '100%' }}>
              <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                No records found
              </div>
            </div>
          )}
        </div>

        <br />
      </ModalContext.Provider>
    </div>
  );
}
