import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import ProjectCard from './project-card';
import ProjectDetails from './project-details';

const RECRUITER_PROJECTS = gql`
  subscription projectsByRecruiterId($recruiter_id: String) {
    project(where: { recruiter_id: { _eq: $recruiter_id } }) {
      id
      tags
      name
      candidates_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export default function Projects () {
  const [currentProject, setCurrentProject] = useState();
  const [projects, setProjects] = useState([]);
  const { data, loading } = useSubscription(RECRUITER_PROJECTS, {
    variables: {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setProjects(data.project);
    }
  }, [data]);

  return (
    <div className={`flex flex-col flex-1`} style={{ maxHeight: 'calc(100vh - 176px)', overflow: 'auto' }}>
      {!currentProject && projects.length > 0 && (
        <div className="flex justify-between mb-xxl lg:mb-lg" style={{ marginTop: 30 }}>
          <div className="font-medium" style={{ fontSize: 21 }}>
            Projects
          </div>
          <button
            onClick={() => navigate('/dashboard/create-project')}
            className="bg-red flex  items-center font-medium text-white rounded px-md"
            style={{ height: 40, fontSize: 14 }}
          >
            <span style={{ fontSize: 23, marginRight: 15 }}>+</span>
            Add Project
          </button>
        </div>
      )}
      <div className="flex flex-wrap pb-lg flex-1">
        {currentProject ? (
          <ProjectDetails project={currentProject} viewAllProjects={() => setCurrentProject()} />
        ) : projects.length === 0 && !loading ? (
          <div className="w-full text-center text-3xl font-medium flex-col flex-1 flex items-center justify-center" style={{ minHeight: 'calc(100vh - 185px)' }}>
            No Projects
            <button
              onClick={() => navigate('/dashboard/create-project')}
              className="bg-red flex  items-center font-medium text-white rounded px-md mt-lg"
              style={{ height: 40, fontSize: 14 }}
            >
              <span style={{ fontSize: 23, marginRight: 15 }}>+</span>
              Add Project
            </button>
          </div>
        ) : (
          <div className="flex self-start content-start flex-wrap w-full">
            {projects.map((project, index) => (
              <ProjectCard key={index} project={project} viewProject={setCurrentProject} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
