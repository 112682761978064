import { navigate } from 'gatsby';
import fetch from 'node-fetch';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { EXPRESS_SERVER_URL } from '../../config';
import LocationPin from '../../images/location.svg';
import JobSearchBar from './JobSearchBar';

function SaveJobModal() {}

// might be deprecated since indreed is deprecated
export default function JobSearch({ nav }) {
  const [jobs, setJobs] = useState(nav.state && nav.state.jobs);
  const [loading, setLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState();
  const [search, setSearch] = useState(nav.state && nav.state.search);
  const [location, setLocation] = useState(nav.state && nav.state.location ? nav.state.location : {});

  function searchJobs() {
    setLoading(true);
    fetch(`${EXPRESS_SERVER_URL}/jobSearch`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ search, location }),
    })
      .then((res) => res.json())
      .then((response) => {
        setJobs(response);
        setLoading(false);
      });
  }

  /* useEffect(() => {
    navigator.geolocation.getCurrentPosition(location => {
      const {coords: {latitude, longitude}} = location
      console.log({latitude, longitude})
      const apiKey = '${process.env.GATSBY_GOOGLE_PLACES_KEY}'
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`)
      .then(res => res.json()).then(console.log).catch(console.log)
    }, (e) => {
      console.log(e)
    })
  }, []); */

  return (
    <div className="job-search-js flex-1 flex flex-col pb-lg" style={{ marginTop: 30 }}>
      <div className="flex flex-col lg:flex-row justify-between items-center" style={{ marginBottom: 30 }}>
        <JobSearchBar savedSearch={nav.state && nav.state.search} searchJobs={searchJobs} searchState={[search, setSearch]} locationState={[location, setLocation]} />
      </div>
      <div className="bg-white rounded shadow flex-1 flex flex-col w-full" style={{ maxWidth: 1340 }}>
        {loading ? (
          <div className="flex-1 flex flex-col items-center justify-center">
            <Loader type="TailSpin" color="#E31C25" height={40} width={40} />
            <div className="text-sm p-lg">Loading jobs...</div>
          </div>
        ) : (
          jobs &&
          jobs.map((job) => (
            <div className="flex text-sm font-medium px-lg">
              <div className="flex flex-col lg:flex-row border-b flex-1 py-md justify-between">
                <div className="flex flex-wrap lg:flex-col justify-center">
                  <a href={job.url} target="_blank" className="text-darkblue cursor-pointer hover:pointer hover:underline w-1/2 lg:w-auto">
                    {job.title}
                  </a>
                  <div className="text-darkgray w-1/2 lg:w-auto">{job.company}</div>
                  <div className="lg:hidden items-center text-darkgray flex w-1/2" style={{ whiteSpace: 'nowrap' }}>
                    <img src={LocationPin} style={{ height: 14, margin: 0, marginRight: 10 }} />
                    {job.location}
                  </div>
                </div>
                <div className="flex-1 relative my-lg">
                  <div className="absolute inset-0 flex items-center">
                    <div
                      className="lg:px-xl text-sm text-darkgray"
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        flexGrow: 1,
                      }}
                    >
                      {job.summary}
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="lg:flex items-center mr-md text-darkgray hidden">
                    <img src={LocationPin} style={{ height: 14, margin: 0, marginRight: 10 }} />
                    {job.location}
                  </div>
                  <button
                    onClick={() =>
                      navigate('/jobs/new-job', {
                        state: {
                          job: {
                            ...job,
                            summary: `<p>${job.summary}</p>`,
                          },
                          search: { jobs, search, location },
                        },
                      })
                    }
                    className="bg-lightgreen w-full lg:w-auto py-sm text-green hover:bg-green hover:text-white px-md  rounded"
                  >
                    Save Job
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
