import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { navigate } from 'gatsby';
import parse from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react';
import { Arrow } from '../../../components/common';
import CreditModal from '../../../components/common/CreditModal';
import FieldTitle from '../../../components/common/FieldTitle';
import TrashIcon from '../../../components/common/TrashIcon';
import { ConfirmModalContext } from '../../../context';
import EditIcon from '../../../images/edit.svg';
import LocationIcon from '../../../images/location.svg';
import ProfilePicture from './profilepicture';

// query for getting the jobs requested to this company
const COMPANY_JOBS = gql`
  query getAllJobs($id: uuid) {
    job(where: { company_id: { _eq: $id } }) {
      id
      industry
      location
      company {
        id
        name
        logoURL
      }
      status
      title
      description
    }
  }
`;

// query to get company detail by using id
const GET_COMPANY = gql`
  query getCompany($id: uuid) {
    companies(where: { id: { _eq: $id } }) {
      id
      name
      websiteURL
      description
      size
      logoURL
      location
      industries
    }
  }
`;

// query to delete company as well as job associated with this
const REMOVE_COMPANY = gql`
  mutation removeCompany($id: uuid) {
    delete_job(where: { company_id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
    delete_companies(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export default function CompanyDetails ({ location }) {
  const [modal, setModal] = useState({ data: null, open: false });

  const [company, setCompany] = useState();
  const [jobs, setJobs] = useState();
  const [, setConfirmModal] = useContext(ConfirmModalContext);

  const { data: jobsdata } = useQuery(COMPANY_JOBS, { variables: { id: location.state.company_id } });
  const { data: companydata } = useQuery(GET_COMPANY, { variables: { id: location.state.company_id } });

  useEffect(() => {
    if (companydata) {
      setCompany(companydata.companies[0]);
    }
  }, [companydata]);

  useEffect(() => {
    if (jobsdata) {
      setJobs(jobsdata.job);
    }
  }, [jobsdata]);

  // mutation used to remove company with the jobs
  const [deleteCompany, { data: removeCompanyResponse }] = useMutation(REMOVE_COMPANY);

  // function to set confirm modal when deleting company
  function openConfirmModal () {
    setConfirmModal({
      open: true,
      title: `Confirm Company Removal`,
      message: `Are you sure you want to remove this company ?`,
      buttonText: 'Remove Company',
      action: removeCompany,
    });
  }

  // this function trigger when select confirm from modal
  function removeCompany () {
    deleteCompany({ variables: { id: location.state.company_id } });
  }

  // closing the modal after successfull removal of the company
  useEffect(() => {
    if (removeCompanyResponse) {
      setConfirmModal({ loading: false, open: false });
      // window.history.back();
      navigate(`/dashboard/companies`);
    }
  }, [removeCompanyResponse]);

  return (
    <div className="w-full h-full pb-lg">
      <div className="flex pb-sm pt-md">
        <div
          className="cursor-pointer text-darkgray flex items-center capitalize hover:underline"
          style={{ fontSize: 12 }}
          onClick={() => navigate(location.state.from ? location.state.from : location.state.activeTab ? location.state.activeTab : '/dashboard/companies')}
        >
          <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} />
          {location.state.activeTab ? 'Jobs' : 'Companies'}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col w-full" style={{ maxWidth: 530 }}>
          <div className="flex py-sm mb-sm justify-between items-center">
            <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
              Company Detail
            </div>
            <div className="flex mr-md">
              <img
                alt=""
                onClick={() => navigate(`/companies/${company && company.id}/edit`, { state: { company } })}
                className="cursor-pointer"
                src={EditIcon}
                style={{ width: 40, marginBottom: 0 }}
              />
              <TrashIcon onClick={openConfirmModal} />
            </div>
          </div>
          <div className="bg-white p-lg rounded  flex flex-col shadow" style={{ minHeight: 370 }}>
            {company && (
              <React.Fragment>
                <div className="flex items-center">
                  <ProfilePicture source={company.logoURL} />
                  <div className="flex flex-col">
                    <div className="text-darkblue font-medium">{company.name}</div>
                  </div>
                </div>
                <div className="flex flex-wrap font-medium text-sm">
                  <div className="flex flex-col w-1/2 mt-xl">
                    <div className="capitalize">Website Link</div>
                    <div className="text-darkgray">
                      <a
                        href={company.websiteURL.includes('https') || company.websiteURL.includes('http') ? company.websiteURL : 'http://' + company.websiteURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {company.websiteURL}
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2 mt-xl">
                    <div className="capitalize">Number of employees</div>
                    <div className="text-darkgray">{company.size}</div>
                  </div>
                </div>
                <div className="flex flex-wrap font-medium text-sm">
                  <div className="flex flex-col w-1/2 mt-xl">
                    <div className="capitalize">Location</div>
                    <div className="text-darkgray">{company.location ? company.location.city : 'N/A'}</div>
                  </div>
                </div>
                <div className="flex flex-wrap mt-lg font-medium text-sm">
                  {company.industries &&
                    company.industries.map((industry) => (
                      <div className="bg-lightgray text-darkgray mr-md mt-md px-md flex items-center" style={{ height: 30, borderRadius: 30 }}>
                        {industry}
                      </div>
                    ))}
                </div>
                <div className="w-full">
                  <div className="flex">
                    <div className="flex-1">
                      <FieldTitle title="Company description" />
                      <div className="text-darkgray mt-sm" style={{ fontSize: 14 }}>
                        {company.description ? parse(company.description) : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="flex flex-col flex-1 lg:ml-lg w-full lg:w-auto">
          <div className="flex py-sm mb-sm items-center justify-between">
            <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
              Jobs
            </div>
            <div style={{ height: 40 }} />
          </div>
          <div className="bg-white rounded shadow flex-1" style={{ minHeight: 530, maxHeight: 'calc(100vh - 210px)', overflow: 'auto' }}>
            {jobs && jobs.length > 0 ? (
              jobs.map((job) => (
                <div style={{ padding: 30, paddingBottom: 0 }}>
                  <div className="flex border-b" style={{ paddingBottom: 30 }}>
                    <div className="flex flex-col" style={{ paddingLeft: 15, flexGrow: 1, minWidth: 225, maxWidth: 225 }}>
                      <div
                        className="font-medium text-darkblue cursor-pointer hover:underline"
                        style={{ fontSize: 16, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingRight: 10 }}
                        onClick={() => navigate(`/jobs/${job.id}`, { state: { jobID: job.id, company_id: company.id } })}
                      >
                        {job.title}
                      </div>
                    </div>
                    <div className="flex-col font-medium" style={{ fontSize: 14, flexGrow: 1, minWidth: 208, maxWidth: 208 }}>
                      {job.company && job.company.name != '' && <div className="text-darkgray">{job.industry}</div>}
                    </div>
                    <div className="flex flex-col" style={{ fontSize: 14, minWidth: 173, maxWidth: 173 }}>
                      {job.location && (
                        <div className="flex font-medium text-darkgray items-center">
                          <div style={{ width: 25 }}>
                            <img alt="" src={LocationIcon} style={{ width: 10.2, height: 14.5, marginBottom: 1, marginLeft: 2, marginRight: 10 }} />
                          </div>
                          {job.location.city}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full h-full flex text-sm items-center py-xl lg:py-0 text-center justify-center text-darkgray font-medium">
                There is no job related to this company
              </div>
            )}
          </div>
        </div>
      </div>
      <CreditModal state={[modal, setModal]} />
    </div>
  );
}
