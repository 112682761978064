import React, { useContext, useEffect, useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import CompanyTabNav from '../../components/layout/CompanyTabNav';
import { HasJobsOrCandidatesContext } from '../../components/layout/layout';
import TabNav from '../../components/layout/TabNav';
import { UserRoleContext } from '../../context';
import { Attributes } from '../attributes';
import { Candidates } from '../candidates';
import CandidateQueue from '../candidates/candidate-queue';
import { FindCandidates, RequestsSent } from '../candidates/company-candidates';
import InterestedCandidates from '../candidates/company-candidates/interested-candidates';
import Companies from '../companies/companies';
import { CompletedJobs } from '../jobs';
import JobQueue from '../jobs/job-queue';
import Jobs from '../jobs/jobs';
import Projects from '../projects/projects';
import Recruiters from '../recruiters/recruiters';
import CandidateIntroductionReport from '../reports/candidate-introduction-report';
import CreditTransactionHistory from '../reports/credit-transaction-history';
import RecruiterActivity from '../reports/recruiter-activity';
import RecruiterCreditActivity from '../reports/recruiter-credit-activity';
import RecruiterJobOutput from '../reports/recruiter-job-output';
import Reports from '../reports/reports';
import SubscriberActivity from '../reports/subscriber-activity';
import SubscriberCreditReport from '../reports/subscriber-credit-report';
import Subscribers from '../subscribers/subscribers';
import DashboardCompany from './dashboard-company';
import DashboardWelcome from './dashboard-welcome';
import './dashboard.css';
import Interviews from './interviews';

const TabWrapper = ({ children }) => {
  return children;
};

export default function Dashboard({ navigate, location }) {
  // const recruiterTabIndexes = {
  //   "candidates-in-process": 0,
  //   interviews: 1,
  //   candidates: 2,
  //   projects: 3,
  //   queue: 4,
  //   companies: 5,
  //   reports: 6
  // };
  const recruiterTabIndexes = {
    candidates: 0,
    interviews: 1,
    projects: 2,
    queue: 3,
    companies: 4,
    reports: 5,
  };
  const adminTabIndexes = {
    candidates: 0,
    jobs: 1,
    'job-queue': 2,
    recruiters: 3,
    subscribers: 4,
    companies: 5,
    reports: 6,
    attributes: 7,
    'subscriber-credit-report': 6,
    'recruiter-activity': 6,
    'subscriber-activity': 6,
    'candidate-introduction-report': 6,
    'credit-transaction-history': 6,
  };
  const [userRole] = useContext(UserRoleContext);
  const [hasJobsOrCandidates] = useContext(HasJobsOrCandidatesContext);
  const isCompany = userRole === 'company';
  const isRecruiter = userRole === 'recruiter';
  const isAdmin = userRole === 'admin';
  const [activeTab, setActiveTab] = useState();
  const [direction, setDirection] = useState('right');

  const renderDashboardPage = (active, location) => {
    switch (active) {
      case 'candidates-in-process':
        return (
          <TabWrapper key="candidates">
            <Candidates inProcess={true} isDashboard={true} location={location} />
          </TabWrapper>
        );

      case 'candidates':
        return (
          <TabWrapper key="candidates">
            <Candidates isDashboard={true} location={location} />
          </TabWrapper>
        );

      case 'projects':
        return (
          <TabWrapper key="projects">
            <Projects />
          </TabWrapper>
        );
      case 'interviews':
        return (
          <TabWrapper key="interviews">
            <Interviews />
          </TabWrapper>
        );
      case 'queue':
        return (
          <TabWrapper key="queue">
            <CandidateQueue />
          </TabWrapper>
        );
      case 'companies':
        return (
          <TabWrapper key="queue">
            <Companies />
          </TabWrapper>
        );
      case 'reports':
        return (
          <TabWrapper key="reports">
            <Reports />
          </TabWrapper>
        );
      case 'recruiter-credit-activity':
        return (
          <TabWrapper key="recruiter-credit-activity">
            <RecruiterCreditActivity isRecruiter={isRecruiter} />
          </TabWrapper>
        );
      case 'recruiter-job-output':
        return (
          <TabWrapper key="recruiter-job-output">
            <RecruiterJobOutput isRecruiter={isRecruiter} />
          </TabWrapper>
        );
      case 'index':
        return <TabWrapper key="welcome">{hasJobsOrCandidates ? <Candidates inProcess={true} isDashboard={true} location={location} /> : <DashboardWelcome />}</TabWrapper>;
      default:
        return (
          <TabWrapper key="welcome">
            <DashboardWelcome />
          </TabWrapper>
        );
    }
  };

  const renderCompanyDashboardPage = (active) => {
    switch (active) {
      case 'find-candidate':
        return <FindCandidates />;
      case 'dashboard':
        return <DashboardCompany />;
      case 'jobs':
        return (
          <TabWrapper key="jobs">
            <Jobs admin={true} location={{ pathname: '/dashboard/jobs' }} />
          </TabWrapper>
        );
      case 'completed-jobs':
        return (
          <TabWrapper key="completed-jobs">
            <CompletedJobs admin={true} location={{ pathname: '/dashboard/completed-jobs' }} />
          </TabWrapper>
        );
      case 'requests-sent':
        return <RequestsSent />;
      case 'interested-candidates':
        return <InterestedCandidates />;
      default:
        return;
    }
  };

  const renderAdminPage = (active) => {
    switch (active) {
      case 'candidates':
        return (
          <TabWrapper key="candidates">
            <Candidates isAdmin={true} location={location} />
          </TabWrapper>
        );
      case 'jobs':
        return (
          <TabWrapper key="jobs">
            <Jobs admin={true} location={{ pathname: '/dashboard/jobs' }} />
          </TabWrapper>
        );
      case 'job-queue':
        return (
          <TabWrapper key="job-queue">
            <JobQueue admin={true} location={{ pathname: '/dashboard/job-queue' }} />
          </TabWrapper>
        );
      case 'recruiters':
        return (
          <TabWrapper key="recruiters">
            <Recruiters />
          </TabWrapper>
        );
      case 'subscribers':
        return (
          <TabWrapper key="subscribers">
            <Subscribers />
          </TabWrapper>
        );
      case 'companies':
        return (
          <TabWrapper key="companies">
            <Companies />
          </TabWrapper>
        );
      case 'reports':
        return (
          <TabWrapper key="reports">
            <Reports />
          </TabWrapper>
        );
      case 'attributes':
        return (
          <TabWrapper key="queue">
            <Attributes />
          </TabWrapper>
        );
      case 'recruiter-activity':
        return (
          <TabWrapper key="recruiter-activity">
            <RecruiterActivity isAdmin={isAdmin} />
          </TabWrapper>
        );
      case 'subscriber-activity':
        return (
          <TabWrapper key="subscriber-activity">
            <SubscriberActivity isAdmin={isAdmin} />
          </TabWrapper>
        );
      case 'subscriber-credit-report':
        return (
          <TabWrapper key="subscriber-credit-report">
            <SubscriberCreditReport isAdmin={isAdmin} />
          </TabWrapper>
        );
      case 'candidate-introduction-report':
        return (
          <TabWrapper key="candidate-introduction-report">
            <CandidateIntroductionReport isAdmin={isAdmin} />
          </TabWrapper>
        );
      case 'credit-transaction-history':
        return (
          <TabWrapper key="credit-transaction-history">
            <CreditTransactionHistory />
          </TabWrapper>
        );

      default:
        return;
    }
  };

  useEffect(() => {
    if (location.pathname.split('/').length > 2 && location.pathname.split('/')[2] !== '') {
      const route = location.pathname.split('/')[2];

      setActiveTab(route);
      if (location.state && location.state.previousTab) {
        setDirection(tabIndexes[location.state.previousTab] > tabIndexes[route] ? 'left' : 'right');
      }
    } else {
      // navigate(
      //   `/dashboard/${isAdmin ? "candidates" : isCompany ? "find-candidate" : (!hasJobsOrCandidates ? "index" : "candidates-in-process")}`
      // );
      navigate(`/dashboard/${isAdmin ? 'candidates' : isCompany ? 'find-candidate' : !hasJobsOrCandidates ? 'index' : 'candidates'}`);
    }
  }, [location]);

  const tabIndexes = isAdmin ? adminTabIndexes : recruiterTabIndexes;

  return (
    // !hasJobsOrCandidates ?
    //   <DashboardWelcome /> :
    <div className="flex-1 flex justify-center w-full h-full relative">
      <div className="dashboard-js container flex flex-col">
        {(isRecruiter || isAdmin) && activeTab && (
          <TabNav isAdmin={isAdmin} type={'dashboard'} route={activeTab} routeIndex={tabIndexes[activeTab]} hasJobsOrCandidates={activeTab} />
        )}
        {(isRecruiter || isAdmin) && activeTab && (
          <ReactCSSTransitionGroup
            className={`dash-slider relative flex flex-col flex-1`}
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
            transitionName={{
              enter: `enter-${direction}`,
              enterActive: `enter-${direction}-active`,
              leave: `leave-${direction}`,
              leaveActive: `leave-${direction}-active`,
              appear: `appear-${direction}`,
              appearActive: `appear-${direction}-active`,
            }}
          >
            <div key={activeTab} className="dash-tab flex-1 ">
              {isRecruiter ? renderDashboardPage(activeTab, location) : renderAdminPage(activeTab, location)}
            </div>
          </ReactCSSTransitionGroup>
        )}
        {isCompany && <CompanyTabNav activeTab={activeTab} type="dashboard" />}
        {isCompany && renderCompanyDashboardPage(activeTab)}
      </div>
    </div>
  );
}
