import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import { find, isEmpty, isNil, not, propOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { RequestInterviewButton } from '../../../components/common';
import RescindButton from '../../../components/common/RescindButton';
import UnblockButton from '../../../components/common/UnblockButton';
import DollarSign from '../../../images/dollarsign.svg';
import BriefcaseIcon from '../../../images/experience.png';
import EyeIcon from '../../../images/eye-solid.svg';
import favIcon from '../../../images/favorite.png';
import LocationPin from '../../../images/location.svg';
import unfavIcon from '../../../images/unfavorite.png';
import UserAvatar from '../../../images/user-avatar.png';
import { formatSalary } from '../../../utils/formatter';
import Loader from 'react-loader-spinner';
import RemoveConfirmModal from '../../jobs/remove-confirm-modal';

const setCandidate = (candidate) => ({
  id: propOr('no-id', 'id')(candidate),
  profilePicture: propOr(UserAvatar, 'profilePicture')(candidate),
  name: propOr('Candidate', 'name')(candidate),
  employment: propOr([], 'employment')(candidate),
  location: propOr({ city: 'No city provided' }, 'location')(candidate),
  experience: propOr(0, 'experience')(candidate),
  salaryMax: propOr(0, 'salaryMax')(candidate),
  salaryMin: propOr(0, 'salaryMin')(candidate),
});

const Name = ({ candidate, name, blur, status, isRequestedCandidates, isInterestedCandidates, state }) => {
  const [setDetailModal] = state;

  const getCandidateDetail = () => {
    setDetailModal({ open: true, data: { candidate, status } });
  };

  return (
    <div style={{ display: 'inline-flex' }}>
      <div
        className="lg:p-0 cursor-pointer "
        onClick={() => navigate(`/candidates/${candidate.id}`, { state: { candidate, status, isRequestedCandidates, isInterestedCandidates } })}
        style={{ fontWeight: 500, color: blur && 'transparent', textShadow: blur && '0 0 7px black' }}
      >
        {blur ? 'Candidate Name' : name}
      </div>
      <div className="flex items-center my-0">
        <img alt="" src={EyeIcon} onClick={() => getCandidateDetail(candidate)} style={{ width: 17, height: 17, cursor: `pointer`, marginLeft: 10, marginBottom: 0 }} />
      </div>
    </div>
  );
};

const CurrentEmployer = ({ employment, blur }) => {
  const currentEmployer = find((em) => em.tenure.current, employment);
  const title = propOr('', 'title')(currentEmployer);
  const companyName = propOr('', 'companyName')(currentEmployer);
  return (
    <>
      {not(isNil(currentEmployer)) && (
        <div className="items-center font-medium" style={{ fontSize: 14 }}>
          {not(isEmpty(title)) && <div className="text-darkblue mr-sm">{title}</div>}
          {not(isEmpty(companyName)) && (
            <div className="text-darkgray" style={{ fontSize: 14, filter: blur && 'blur(3px)' }}>
              {blur ? 'Company Name' : companyName}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const Details = ({ candidate, name, employment, blur, status, isRequestedCandidates, isInterestedCandidates, state }) => {
  const [setDetailModal] = state;

  return (
    <div className="flex flex-col">
      <Name
        name={name}
        blur={blur}
        candidate={candidate}
        status={status}
        isRequestedCandidates={isRequestedCandidates}
        isInterestedCandidates={isInterestedCandidates}
        state={[setDetailModal]}
      />
      <CurrentEmployer employment={employment} blur={blur} />
    </div>
  );
};

const UPDATE_FAVORITE = gql`
  mutation insertFavCand($candidate_id: String, $company_id: String) {
    insert_favorite_candidates(objects: { candidate_id: $candidate_id, company_firebase_id: $company_id }) {
      affected_rows
      returning {
        candidate_id
        company_firebase_id
      }
    }
  }
`;

const DELETE_FAVORITE = gql`
  mutation deleteFavCand($candidate_id: String, $company_id: String) {
    delete_favorite_candidates(where: { candidate_id: { _eq: $candidate_id }, company_firebase_id: { _eq: $company_id } }) {
      affected_rows
      returning {
        candidate_id
        company_firebase_id
      }
    }
  }
`;

const REMOVE_COMPANY_REQUEST = gql`
  mutation deleteCompanyRequest($candidate_id: String, $company_id: uuid) {
    delete_company_request(where: { candidate_id: { _eq: $candidate_id }, company_id: { _eq: $company_id } }) {
      affected_rows
    }
  }
`;

const CandidateListItem = ({
  candidate: { candidate, status, isFavorite, isDirectRequest },
  companyID,
  remainingCredit,
  isRequestedCandidates,
  isInterestedCandidates,
  subscriptionType,
  isSavedCandidates,
  isFavoriteCandidates,
  state,
  onChange,
}) => {
  const { id, name, employment, location, experience, salaryMin } = setCandidate(candidate);
  const mutualInterest = status === 'approved';

  const companyId = firebase.auth().currentUser && firebase.auth().currentUser.uid;

  const [setDetailModal] = state;

  const [favStatus, setFavStatus] = useState(isFavorite);
  const [loading, setLoading] = useState(false);

  const [updateFavorite] = useMutation(UPDATE_FAVORITE);

  const [deleteFavorite] = useMutation(DELETE_FAVORITE);

  useEffect(() => {
    setFavStatus(isFavorite || candidate.isFavorite);
  }, [candidate]);

  const favUnfavCand = (isfav) => {
    if (!isfav) {
      const insertdata = {
        company_id: companyId,
        candidate_id: candidate.id,
      };
      updateFavorite({
        variables: insertdata,
      });
      setFavStatus(true);
    } else {
      const deletedata = {
        company_id: companyId,
        candidate_id: candidate.id,
      };
      deleteFavorite({
        variables: deletedata,
      });
      setFavStatus(false);
    }
    if (onChange) {
      const updates = _.cloneDeep(candidate);
      // Temporary key to mark candidate favorite's
      updates.isFavorite = !isfav;
      onChange(updates);
    }
  };

  const [open, setOpen] = React.useState(false);
  const [removeCompanyRequest] = useMutation(REMOVE_COMPANY_REQUEST);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getCandidateStatus(candidate) {
    return candidate.status;
  }

  /**
   * If the candidate has a request from this company, return the request status, otherwise return the
   * candidate status.
   * @param candidate - The candidate object
   * @returns The status of the candidate or the request.
   */
  function getCandidateOrRequestStatus(candidate) {
    const request = (candidate.company_requests || []).find((request) => request.company_id == companyId || request.company?.adminID == companyId);
    return request?.status || candidate.status;
  }
  /**
   * It returns true if the candidate's status is either approved or accepted
   * @param candidate - The candidate object
   * @returns A boolean value.
   */
  function getCandidateOrRequestStatusIsApproved(candidate) {
    const status = getCandidateOrRequestStatus(candidate);
    return status === 'approved';
  }
  /**
   * It returns true if the candidate's status is approved, requested, pending, request, or if the
   * company request is approved
   * @param candidate - The candidate object
   */
  function getCandidateCanBeInterviewed(candidate) {
    const status = candidate.status;
    return status === 'approved' || status === 'requested' || status === 'pending' || status === 'request' || getCompanyRequestIsApproved(candidate);
  }

  /**
   * If the candidate has a company request for the company, and the request is accepted, return true.
   * @param candidate - The candidate object
   * @returns A boolean value.
   */
  function getCompanyRequestIsApproved(candidate) {
    const request = (candidate.company_requests || []).find((request) => request.company_id == companyId || request.company?.adminID == companyId);
    return request.status === 'approved';
  }

  /**
   * If the candidate's status is in the array of statuses, return true, otherwise return false.
   * @param candidate - The candidate object
   * @param status - The status you want to check for.
   */
  function getCandidateIs(candidate, status) {
    const candidateStatus = getCandidateOrRequestStatus(candidate);
    return status instanceof Array ? status.indexOf(candidateStatus) > -1 : candidateStatus === status;
  }

  const candidateIsInterested = isInterestedCandidates !== undefined ? isInterestedCandidates : getCandidateIs(candidate, 'interested');
  const candidateIsRequested = isRequestedCandidates !== undefined ? isRequestedCandidates : getCandidateIs(candidate, ['requested', 'request']);

  return (
    <>
      <td>
        <div className="w-12 pl-4">
          <div
            style={{
              margin: 0,
              height: 40,
              width: 40,
              borderRadius: 40,
              backgroundImage: `url('${!mutualInterest ? UserAvatar : candidate.profilePictureURL ? candidate.profilePictureURL : UserAvatar}')`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              filter: !mutualInterest && 'blur(6px)',
            }}
          />
        </div>
      </td>
      <td>
        <div style={{ width: '250px' }}>
          <Details
            candidate={candidate}
            name={name}
            employment={employment}
            blur={!mutualInterest}
            status={status}
            isRequestedCandidates={candidateIsInterested}
            isInterestedCandidates={candidateIsInterested}
            state={[setDetailModal]}
          />
        </div>
      </td>
      <td className="px-0">
        <div className="w-32  ">
          <div className={`text-darkgray flex items-center  `} style={{ fontWeight: 500 }}>
            <img alt="" src={BriefcaseIcon} className="mx-2 w-4 my-0" />
            {`${experience} years`}
          </div>
        </div>
      </td>
      <td className="px-0">
        <div className="w-40 ">
          <div className=" text-darkgray flex items-center mr-sm lg:mr-0 justify-center" style={{ fontWeight: 500 }}>
            {salaryMin ? <img alt="" src={DollarSign} style={{ height: 15, margin: 0, marginLeft: 2, marginRight: 8 }} /> : ''}
            {salaryMin ? `${formatSalary({ salary: salaryMin })} +` : 'Negotiable'}
          </div>
        </div>
      </td>
      <td className="px-0">
        <div style={{ width: '200px', paddingLeft: '30px' }}>
          <div className={`  flex justify-start items-center mr-sm lg:mr-0 `} style={{ color: 'hsla(0, 0%, 0%, 0.8)' }}>
            <img alt="" src={LocationPin} className="mx-2 w-4 my-0" />
            {location.city}
          </div>
        </div>
      </td>
      <td>
        <div className="w-16 pr-0">
          <div className="h-full w-full flex justify-center items-center">
            <img alt="" src={favStatus ? favIcon : unfavIcon} onClick={() => favUnfavCand(favStatus)} style={{ width: 20, cursor: 'pointer', height: 20 }} className="mb-0" />
          </div>
        </div>
      </td>
      <td>
        <div className="w-40 pr-0">
          <div className="w-full flex justify-center items-center">
            {candidateIsRequested ? (
              <RescindButton candidateID={id} status={status} companyID={companyID} remainingCredit={remainingCredit} />
            ) : candidateIsInterested ? (
              <UnblockButton candidateID={id} status={status} />
            ) : (
              <RequestInterviewButton
                candidateID={id}
                candidate={candidate}
                status={status}
                companyID={companyID}
                remainingCredit={remainingCredit}
                subscriptionType={subscriptionType}
              />
            )}
          </div>
          {isDirectRequest ? (
            <div className="w-full flex justify-center items-center">
              <button
                onClick={handleClickOpen}
                style={{
                  ...{ cursor: 'pointer', color: '#D8000C', borderColor: 'rgb(86, 208, 0)', backgroundColor: '#FFD2D2', border: '1px solid' },
                  ...{ borderRadius: 5, height: 40, width: '100%', minWidth: 200, marginBottom: 2, paddingLeft: 5, paddingRight: 5 },
                }}
                className={`text-xs flex justify-center items-center rounded`}
              >
                {loading ? <Loader type="TailSpin" color="#000000" height={20} width={20} /> : `Remove Candidate`}
              </button>

              <RemoveConfirmModal
                open={open}
                loading={loading}
                onFailure={handleClose}
                onSuccess={async () => {
                  handleClose();

                  setLoading(true);

                  await removeCompanyRequest({
                    variables: { candidate_id: id, company_id: companyID },
                  });

                  setLoading(false);
                }}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </td>
    </>
  );
};

export default CandidateListItem;
