/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { TabTitle } from '../../components/common';
import CompanyFilter from '../../components/Filter/CompanyFilter';
import { GlobalFilterContext } from '../../components/layout/layout';
import UserRoleContext from '../../context/UserRole';
import CaretDownGray from '../../images/caret-down-gray.png';
import CaretDown from '../../images/caret-down.png';
import CompanyEntry from './company-entry';
import CompanyImportModal from './company-import-modal';
import './pagination.css';
import SampleCsvCompanies from './sample-csv-companies';
import CherryPagination from '../../components/common/Pagination/Pagination';
import { DEFAULT_PER_PAGE } from '../../utils/constants';

// graphql query to get all companies
const ALL_COMPANIES = gql`
  subscription getCompanies($adminID: String, $perPage: Int, $offset: Int, $order_by: order_by) {
    companies(order_by: { name: $order_by }, where: { adminID: { _eq: $adminID } }, limit: $perPage, offset: $offset) {
      id
      name
      websiteURL
      description
      size
      logoURL
      location
      industries
    }
  }
`;

const ALL_COMPANIES_TOTAL = gql`
  subscription getCompanies($adminID: String) {
    companies_aggregate(where: { adminID: { _eq: $adminID } }) {
      aggregate {
        count
      }
    }
  }
`;

// component for sorting companies alphabetically
function AlphabeticalSort({ sortState }) {
  const [sortType, setSortType] = sortState;
  const [direction, setDirection] = useState('desc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ type: 'alphabetical', direction: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ type: 'alphabetical', direction: 'desc' });
    }
  }
  return (
    <div className="flex mr-md">
      <div
        onClick={setType}
        className={`text-sm ${
          sortType.type === 'alphabetical' ? 'bg-darkblue text-white' : 'text-darkgray border border-darkgray'
        }   flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
      >
        A - Z
        <img
          alt=""
          src={sortType.type === 'alphabetical' ? CaretDown : CaretDownGray}
          style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }}
        />
      </div>
    </div>
  );
}

export default function Companies() {
  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);
  const [flag, setFlag] = useState(true);

  function handlePaginationChange(event) {
    setPage(event.page);
    setPerPage(event.perPage);
  }

  const [companies, setCompanies] = useState();
  const [allCompanies, setAllCompanies] = useState();

  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';

  // Aggregate of the companies that is use to show the page count
  const { data: companiesTotal } = useSubscription(ALL_COMPANIES_TOTAL, {
    variables: {
      adminID: !isAdmin ? firebase.auth().currentUser && firebase.auth().currentUser.uid : null,
    },
  });
  useEffect(() => {
    if (companiesTotal) {
      setTotal(companiesTotal?.companies_aggregate?.aggregate.count || 0);
    }
  }, [companiesTotal]);

  const [sortType, setSortType] = useState({ type: 'alphabetical', direction: 'asc' });

  const { globalFilter, setGlobalFilter } = useContext(GlobalFilterContext);
  const [filter, setFilter] = useState(globalFilter[`companies-filter`] ? globalFilter[`companies-filter`] : {});

  const [modal, setModal] = useState({ data: null, open: false });

  useEffect(() => {
    if (!isEmpty(filter)) {
      setFlag(false);
    } else {
      setFlag(true);
    }
  }, [filter, flag]);

  // All companies data according to user logged in
  const { data: companiesQuery, loading } = useSubscription(
    ALL_COMPANIES,
    // flag ?
    {
      variables: {
        adminID: !isAdmin ? firebase.auth().currentUser && firebase.auth().currentUser.uid : null,
        perPage: flag ? perPage : null,
        offset: flag ? page * perPage : null,
        order_by: sortType.direction,
      },
    },
    // :
    // {
    //   variables: {
    //     adminID: !isAdmin ? firebase.auth().currentUser && firebase.auth().currentUser.uid : null,
    //     perPage: null,
    //     offset: null
    //   }
    // }
  );

  // setting initial state of the companies data
  useEffect(() => {
    if (companiesQuery) {
      // setCompanies(companiesQuery.companies);
      setAllCompanies(companiesQuery.companies);
    }
  }, [companiesQuery]);

  function isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  // function to get distance between two coordinates in miles
  function calcDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 0.621371;
  }

  // Converts numeric degrees to radians
  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  function _filterCompanies(company) {
    for (const name in filter) {
      const value = filter[name];

      switch (name) {
        case 'search':
          if (!company.name.toLowerCase().includes(value.toLowerCase())) {
            return false;
          }
          break;
        case 'location':
          if (value && company.location) {
            const {
              city: { lat, lng },
            } = value;
            const radius = value.radius ? value.radius : process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 30;

            const distance = calcDistance(lat, lng, company.location.lat, company.location.lng);
            if (distance > radius) {
              return false;
            }
          } else {
            return false;
          }
          break;
        default:
          break;
      }
    }

    return true;
  }

  // Filter by company name and location with distance
  function filterCompanies() {
    if (isEmpty(filter)) {
      // setCompanies(sortType ? sortCompanies(allCompanies) : allCompanies);
      setCompanies(allCompanies);
    } else {
      const filteredCompanies = !allCompanies ? [] : allCompanies.filter(_filterCompanies);
      if (sortType) {
        setCompanies(filteredCompanies);
        // setCompanies(sortCompanies(filteredCompanies));
      } else {
        setCompanies(filteredCompanies);
      }
    }
  }

  useEffect(() => {
    filterCompanies();
    setGlobalFilter({
      ...globalFilter,
      [`companies-filter`]: filter,
    });
    setPage(0);
  }, [filter]);

  useEffect(() => {
    if (sortType && companies) {
      setCompanies(companies);
      // setCompanies(sortCompanies(companies))
    }
  }, [sortType]);

  useEffect(() => {
    if (!isEmpty(filter)) {
      filterCompanies();
    } else {
      setCompanies(allCompanies);
    }
  }, [allCompanies]);

  return (
    <div className="flex flex-col h-full pb-lg">
      <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
        <TabTitle>{'Companies'}</TabTitle>
        <div className="import_csv flex justify-between lg:mt-0 mt-sm">
          <SampleCsvCompanies />
          <a
            href="#"
            className={`bg-red text-white font-medium rounded flex items-center justify-center`}
            style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
            onClick={() => setModal({ data: null, open: true })}
          >
            <span style={{ fontSize: 22, marginRight: 8 }}>+</span>
            Import Companies
          </a>
          <Link className={`bg-green text-white font-medium rounded flex items-center justify-center`} style={{ fontSize: 14, height: 40, width: 160 }} to="/companies/new-company">
            <span style={{ fontSize: 22, marginRight: 8 }}>+</span> Add Company
          </Link>
        </div>
      </div>
      <CompanyFilter filterState={[filter, setFilter]} type="companies" />
      <div className="flex mb-sm">
        <div className="text-sm mr-sm">Sort:</div>
        <AlphabeticalSort sortState={[sortType, setSortType]} />
      </div>
      <div className="bg-white rounded shadow flex-1 w-full" style={{ maxWidth: 1340, overflowX: 'auto', height: '700px' }}>
        {loading ? (
          <div style={{ marginLeft: 600, marginTop: 100 }}>
            <Loader type="TailSpin" color="#E31C25" height={50} width={50} />
          </div>
        ) : companies && companies.length > 0 ? (
          companies.map((company, index) => <CompanyEntry key={index} company={company} />)
        ) : (
          <div className="w-full h-full flex items-center justify-center text-darkgray">No companies available</div>
        )}
      </div>
      {isEmpty(filter) && (
        <div className="candidate-pagination">
          <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
        </div>
      )}
      <CompanyImportModal state={[modal, setModal]} isAdmin={isAdmin} />
    </div>
  );
}
