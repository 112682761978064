import { Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const TabLink = styled(Link)`
  font-size: 16px;
  font-weight: 600;
  transition: color 0.3s ease-in-out;
  text-transform: capitalize;
  cursor: pointer;

  @media only screen and (max-width: 640px) {
    font-size: 10px;
  }
`;

// const dashboardRoutes = [
//   "candidates-in-process",
//   "interviews",
//   "candidates",
//   "projects",
//   "queue",
//   "companies",
//   "reports"
// ];
const dashboardRoutes = ['candidates', 'interviews', 'projects', 'queue', 'companies', 'reports'];

// const jobRoutes = ["/jobs/open-jobs", "/jobs/all-jobs", "/jobs/search"];
const jobRoutes = ['/jobs/open-jobs', '/jobs/all-jobs', '/jobs/completed-jobs'];
const adminRoutes = ['candidates', 'jobs', 'job-queue', 'recruiters', 'subscribers', 'companies', 'reports', 'attributes'];

export default function TabNav({ type, route, routeIndex, isAdmin }) {
  const tabs = type === 'jobs' ? jobRoutes : isAdmin ? adminRoutes : dashboardRoutes;
  const [tabWidths, setTabWidths] = useState([]);
  const [hideSliderIndicator, setHideSliderIndicator] = useState(true);
  const containerRef = useRef();

  useEffect(() => {
    const widths = [...containerRef.current.children].map((child) => child.offsetWidth);
    const allWidths = widths.reduce((arr, width, i) => {
      return (arr = [...arr, width + (i > 0 ? arr[i - 1] : 0)]);
    }, []);
    setTabWidths([0, ...allWidths]);
  }, [containerRef, isAdmin]);

  useEffect(() => {
    setHideSliderIndicator(true);
    setTimeout(() => {
      setHideSliderIndicator(false);
    }, 300);
  }, []);

  return (
    <div className="w-full border-b border-darkgray" style={{ marginTop: 40 }}>
      <div ref={containerRef} className="flex mb-sm relative" style={{ maxWidth: type === 'dashboard' ? 800 : 420 }}>
        {/* <div
        ref={containerRef}
        className="flex mb-sm relative"
        style={{ maxWidth: type === "dashboard" ? 800 : 550 }}
      > */}
        {tabs.map((tab, index) => {
          return (
            <div key={index} style={{ flexGrow: 1 }} className="relative">
              <TabLink to={`${type === 'dashboard' ? '/dashboard/' : ''}${tab}`} state={{ previousTab: route }} className={`${route === tab ? 'text-darkblue' : 'text-darkgray'}`}>
                {tab === 'candidates-in-process'
                  ? 'Candidates in Process'
                  : tab === '/jobs/all-jobs'
                  ? 'All Jobs'
                  : tab === '/jobs/open-jobs'
                  ? 'Open Jobs'
                  : tab === '/jobs/search'
                  ? 'Search Jobs'
                  : tab === 'candidates'
                  ? 'Candidates'
                  : tab === '/jobs/all-jobs'
                  ? 'All Jobs'
                  : tab === '/jobs/open-jobs'
                  ? 'Open Jobs'
                  : tab === '/jobs/search'
                  ? 'Search Jobs'
                  : tab === 'job-queue'
                  ? 'Job Queue'
                  : tab === '/jobs/completed-jobs'
                  ? 'Completed Jobs'
                  : tab}
              </TabLink>
              {route === tab && hideSliderIndicator && <div id="nav-slider" className="bg-red absolute" style={{ height: 3, width: 22, bottom: -10 }} />}
            </div>
          );
        })}
        <div
          id="nav-slider"
          className="bg-red absolute"
          style={{
            height: 3,
            width: 22,
            bottom: -10,
            opacity: hideSliderIndicator ? 0 : route == 'recruiter-credit-activity' || route == 'recruiter-job-output' ? 0 : 1,
            transition: 'transform 0.3s ease-in-out',
            transform: `translateX(${tabWidths[routeIndex]}px)`,
          }}
        />
      </div>
    </div>
  );
}
