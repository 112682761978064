import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import React, { useContext, useState } from 'react';
import { EXPRESS_SERVER_URL } from '../../../config';
import UserRoleContext from '../../../context/UserRole';
import EditProfileForm from './edit-profile-form';

const UPDATE_RECRUITER_PROFILE = gql`
  mutation updateRecruiterProfile($recruiter_id: String, $changes: recruiter_set_input) {
    update_recruiter(where: { id: { _eq: $recruiter_id } }, _set: $changes) {
      affected_rows
      returning {
        id
        name
        phone
        position
        industries
        profilePhoto
        title
        email
        availability
        company {
          name
        }
      }
    }
  }
`;

const UPDATE_SUBSCRIBER_PROFILE = gql`
  mutation updateCompanyProfile($subscriber_id: String, $changes: company_set_input) {
    update_company(where: { adminID: { _eq: $subscriber_id } }, _set: $changes) {
      affected_rows
      returning {
        id
        name
        logoURL
        email
      }
    }
  }
`;

export default function EditProfile({ currentUser, navigate }) {
  const [userRole] = useContext(UserRoleContext);
  const isSubscriber = userRole === 'company';

  const [errors, setErrors] = useState({});

  const { profilePhoto, name, title, industries, company, position, email, phone, availability, logoURL, size, websiteURL } = currentUser;

  const initialFields = isSubscriber
    ? {
        logoURL,
        companyName: name,
        email,
        size,
        websiteURL,
      }
    : {
        profilePhoto,
        name,
        title,
        industries: typeof industries === 'string' ? JSON.parse(industries) : industries,
        company,
        email,
        phone,
      };

  const [updateRecruiter, { data }] = useMutation(UPDATE_RECRUITER_PROFILE);
  const [updateSubscriber, { data: subscriberData }] = useMutation(UPDATE_SUBSCRIBER_PROFILE);

  const [loading, setLoading] = useState(false); /*eslint-disable-line*/

  const uploadPhoto = async (photo, directory) => {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/${directory}/${photo.name}`);
    const photoURL = await storageRef.put(photo).then((snapshot) => snapshot.ref.getDownloadURL());
    return photoURL;
  };

  const saveProfile = async (profile) => {
    setLoading(true);

    let changes = {};
    let profilePhoto;
    let logoURL;

    if (profile.profilePhoto !== initialFields.profilePhoto) {
      profilePhoto = await uploadPhoto(profile.profilePhoto, 'profilephotos');
    }

    if (profile.logoURL !== initialFields.logoURL) {
      logoURL = await uploadPhoto(profile.logoURL, 'newcompanylogos');
    }

    Object.entries(profile).map(async ([label, value]) => {
      if (initialFields[label] !== value) {
        switch (label) {
          case 'companyName':
            changes.name = value;
            break;
          case 'industries':
            if (JSON.stringify(initialFields.industries) !== JSON.stringify(value)) {
              changes.industries = value;
            }
            break;
          case 'company':
            changes.company_id = value.id;
            break;
          case 'websiteURL':
            changes.websiteURL = profile.websiteURL;
            break;
          case 'size':
            changes.size = profile.size;
            break;
          default:
            if (initialFields[label]) {
              changes[label] = value;
            }
        }
      }
    });

    if (isSubscriber) {
      const user = await fetch(`${EXPRESS_SERVER_URL}/getUserFirebaseAccount`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: changes.email }),
      }).then((response) => response.json());

      if (user.statusCode == 200) {
        setErrors({
          email: 'An account with that email already exists. Please choose another.',
        });
      } else {
        await fetch(`${EXPRESS_SERVER_URL}/updateUserEmailFirebase`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ uid: firebase.auth().currentUser.uid, email: changes.email }),
        }).then((response) => response.json());

        updateSubscriber({
          variables: { subscriber_id: firebase.auth().currentUser.uid, changes: logoURL ? { ...changes, logoURL } : changes },
        });
      }
    } else {
      const user = await fetch(`${EXPRESS_SERVER_URL}/getUserFirebaseAccount`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: changes.email }),
      }).then((response) => response.json());

      if (user.statusCode == 200) {
        setErrors({
          email: 'An account with that email already exists. Please choose another.',
        });
      } else {
        await fetch(`${EXPRESS_SERVER_URL}/updateUserEmailFirebase`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ uid: firebase.auth().currentUser && firebase.auth().currentUser.uid, email: changes.email }),
        }).then((response) => response.json());

        updateRecruiter({
          variables: { recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid, changes: profilePhoto ? { ...changes, profilePhoto } : changes },
        });
      }
    }
    setLoading(false);
  };

  if (data || subscriberData) {
    navigate('Profile');
  }

  return (
    <div className="edit-profile flex justify-center items-center pb-lg mx-md" style={{ top: 20, left: 0, right: 0, minHeight: '100%' }}>
      <div className="w-full bg-white rounded flex flex-col" style={{ minHeight: `${isSubscriber ? 650 : 756}`, padding: 30 }} onClick={(e) => e.stopPropagation()}>
        <EditProfileForm
          profileState={currentUser}
          availability={availability}
          initialValues={initialFields}
          saveProfile={saveProfile}
          navigate={navigate}
          loading={loading}
          errors={errors}
        />
      </div>
    </div>
  );
}
