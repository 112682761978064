import React from "react";
import ProfileAvatar from "../../images/user-avatar.png";
import Moment from "moment";
import { navigate } from "gatsby";

export default function AllMessages({ messages, setSelectedCandidate }) {
  const isSameDate = (someDate, today = new Date()) => {
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  const sortByChronologicalOrder = array =>
    array.sort((a, b) => {
      const aDate = new Date(a.messages[a.messages.length - 1].date);
      const bDate = new Date(b.messages[b.messages.length - 1].date);
      return bDate - aDate;
    });

  const messagesObject = Object.entries(messages).reduce(
    (messagesObj, [id, candidate], index) => {
      if (
        candidate.messages.filter(msg => !msg.read && msg.from === "candidate")
          .length > 0
      ) {
        return {
          ...messagesObj,
          unread: [...messagesObj.unread, { id, ...candidate }],
        };
      } else {
        return {
          ...messagesObj,
          read: [...messagesObj.read, { id, ...candidate }],
        };
      }
    },
    { read: [], unread: [] }
  );

  return [
    ...sortByChronologicalOrder(messagesObject.unread),
    ...sortByChronologicalOrder(messagesObject.read),
  ].map(candidate => {
    const lastMessage = candidate.messages[candidate.messages.length - 1];
    const isUnread =
      candidate.messages.filter(msg => !msg.read && msg.from === "candidate").length !== 0;

    return (
      <div 
        key={candidate.id}
        onClick={() => setSelectedCandidate(candidate)}
        style={{paddingLeft: 30, paddingRight: 30}}
        className='hover:bg-mediumgray cursor-pointer relative'>
        {isUnread &&
          <div
            className='absolute flex items-center'
            style={{top: 0, bottom: 0, left: 30}}>
            <div
              className='bg-red relative'
              style={{height: 8, width: 8, borderRadius: 10, bottom: 2}}
            />
          </div>
        }
        <div
          className="flex items-center w-full px-lg border-b pt-lg  pb-lg  cursor-pointer relative"
          style={{ borderColor: "#e8ecf2" }}>
          <div
            className={`flex items-center ${
              isUnread ? "text-darkblue font-bold" : "text-darkgray font-medium"
            } w-full`}
            style={{ fontSize: 16 }}>
            <div>
              <div
                className="h-10 w-10 lg:w-12 lg:h-12"
                style={{
                  borderRadius: 100,
                  marginRight: 16,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: candidate.profilePictureURL
                    ? `url(${candidate.profilePictureURL})`
                    : `url(${ProfileAvatar}`,
                }}
              />
            </div>
            <div className="flex flex-row flex-wrap lg:flex-row flex-1 relative">
              <div
                onClick={() =>
                  navigate(`/candidates/${candidate.id}`, {
                    state: { candidate: { id: candidate.id }, from: "/chat" },
                  })
                }
                className="hover:underline cursor-pointer lg:w-1/3">
                {candidate.name}
              </div>
              <div className="px-sm lg:hidden">-</div>
              <div className="flex-1 lg:overflow-visible relative">
                <div
                  className="overflow-hidden"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: 405,
                  }}>
                  {lastMessage.message}
                </div>
                <div
                  className="hidden lg:flex absolute"
                  style={{ top: "100%", left: 0 }}>
                  {Moment(lastMessage.date).format("MM/DD/YYYY - h:mm a")}
                </div>
              </div>
              <div className="lg:hidden w-full" style={{ top: "100%", left: 0 }}>
                {Moment(lastMessage.date).format("MM/DD/YYYY - h:mm a")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
}
