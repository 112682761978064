import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { EXPRESS_SERVER_URL } from '../../config';
import { ApolloContext } from '../../context/Apollo';
import NewSubscriberForm from './NewSubscriberForm';

// graphql query to create new company
const CREATE_SUBSCRIBER = gql`
  mutation createSubscriber(
    $name: String
    $websiteURL: String
    $bio: String
    $size: String
    $logoURL: String
    $email: String
    $adminID: String
    $recruiter_id: String
    $adminName: String
  ) {
    insert_company(
      objects: {
        name: $name
        websiteURL: $websiteURL
        bio: $bio
        size: $size
        logoURL: $logoURL
        email: $email
        adminID: $adminID
        recruiter_id: $recruiter_id
        adminName: $adminName
      }
    ) {
      returning {
        id
        name
        websiteURL
        bio
        size
        logoURL
        email
        adminID
      }
    }
  }
`;

// graphql query to update the company detail
const UPDATE_SUBSCRIBER = gql`
  mutation updateSubscriber($id: uuid, $changedFields: company_set_input) {
    update_company(where: { id: { _eq: $id } }, _set: $changedFields) {
      affected_rows
      returning {
        id
        name
        websiteURL
        bio
        size
        logoURL
      }
    }
  }
`;

export default function CreateSubscriberForm({ subscriber }) {
  const accountState = useState(subscriber);
  const { apolloClient } = useContext(ApolloContext);
  const [openSnackbar] = useSnackbar();

  // mutation to create new company
  const [createSubscriber, { loading: createLoading, data: createResponseData }] = useMutation(CREATE_SUBSCRIBER);

  // mutation to update existing company
  const [updateSubscriber, { loading: updateLoading, data: updateResponseData }] = useMutation(UPDATE_SUBSCRIBER);

  const [loading, setLoading] = useState(updateLoading || createLoading ? true : false);

  // function to upload the image into firebase storage
  async function uploadImage(file, location) {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/${location}/${file.name}`);
    const logoURL = await storageRef.put(file).then((snapshot) => snapshot.ref.getDownloadURL());
    return logoURL;
  }

  async function saveSubscriberCreate(subscriber) {
    setLoading(true);
    const user = await fetch(`${EXPRESS_SERVER_URL}/createUserFirebaseAccount`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: subscriber.email, password: subscriber.password, name: subscriber.name }),
    }).then((response) => response.json());

    const { name, email, websiteURL, bio, size, logoURL, adminName } = subscriber;
    const newSubscriber = {
      name,
      email,
      websiteURL,
      bio,
      size,
      logoURL: !logoURL ? null : typeof logoURL === 'string' ? logoURL : await uploadImage(logoURL, 'newcompanylogos'),
      adminID: user.uid,
      adminName,
    };

    // If error in creating subscriber, then delete from firebase
    try {
      await apolloClient.mutate({
        mutation: CREATE_SUBSCRIBER,
        variables: newSubscriber,
      });
      setLoading(false);

      navigate(`/dashboard/subscribers`);
    } catch (e) {
      console.log(e);
      setLoading(false);
      openSnackbar(e.message);

      await fetch(`${EXPRESS_SERVER_URL}/remove-user-firebase-account`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.uid }),
      }).then((response) => response.json());
    }
  }

  async function saveSubscriberEdit(updatedSubscriber) {
    setLoading(true);

    const { name, email, websiteURL, bio, size, logoURL, adminName } = updatedSubscriber;

    const editedSubscriber = {
      name,
      email,
      websiteURL,
      bio,
      size,
      logoURL: !logoURL ? null : typeof logoURL === 'string' ? logoURL : await uploadImage(logoURL, 'newcompanylogos'),
      adminName,
    };

    const user = await fetch(`${EXPRESS_SERVER_URL}/getUserFirebaseAccount`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: subscriber.email }),
    }).then((response) => response.json());

    if (user.statusCode === 200) {
      await fetch(`${EXPRESS_SERVER_URL}/updateUserEmailFirebase`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid: user.data.uid, email: email }),
      }).then((response) => response.json());
    } else {
      await fetch(`${EXPRESS_SERVER_URL}/createUserFirebaseAccount`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: 'Password1!', name: name }),
      }).then((response) => response.json());
    }

    updateSubscriber({ variables: { id: subscriber.id, changedFields: editedSubscriber } });

    setLoading(false);
  }

  // navigate to the companies list after creating new company
  useEffect(() => {
    if (createResponseData) {
      navigate(`/dashboard/subscribers`);
    }
  }, [createResponseData]);

  // navigate to the company detail with the updated data
  useEffect(() => {
    if (updateResponseData) {
      navigate(`/subscribers/${updateResponseData.update_company.returning[0].id}/`, { state: { subscriber: updateResponseData.update_company.returning[0] } });
    }
  }, [updateResponseData]);

  return <NewSubscriberForm action={subscriber ? saveSubscriberEdit : saveSubscriberCreate} globalFormData={accountState} isEdit={subscriber ? true : false} loading={loading} />;
}
