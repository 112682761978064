import React, { useEffect, useState, useRef } from 'react';
import closeIcon from '../../../images/close-gray.svg';

/**
 * Validation popup for filter search
 * @param {Object} props Modal props
 * @param {Boolean} props.open View Modal
 * @param {String} props.validationError Validation Message
 * @param {Function} props.onChange On Change
 * 
 * @returns 
 */
export const FilterValidation = (props) => {
  const [fadeDelay, setFadeDelay] = useState(false);

  // Ref's
  const modalRef = useRef();

  useEffect(() => {
    if (props.open) {
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
  }, [props]);

  const handleChanges = (value) => {
    if (props.onChange) {
      props.onChange(value);
    }
  }

  return (
    <div
      className={`fixed inset-0 items-center justify-center`}
      onClick={() => handleChanges(false)}
      style={{
        transition: 'opacity 0.3s ease-in-out',
        opacity: props.open ? 1 : 0,
        zIndex: 3000000,
        display: fadeDelay ? 'flex' : 'none',
        backgroundColor: 'rgba(34, 46, 66, 0.9)',
      }}>
      <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ minHeight: 140 }}>
        <img src={closeIcon} onClick={() => handleChanges(false)} style={{
          width: 14,
          height: 14,
          marginBottom: 15,
          cursor: 'pointer',
          marginLeft: '100%',
        }} />
        <p>
          {props.children}
        </p>
        <div className="flex flex-1 justify-center items-end" style={{ marginTop: 10, marginBottom: 10 }}>
          <button
            className="rounded bg-red ml-md font-medium  text-sm text-white"
            style={{ width: '25%', height: 40, fontSize: 14 }}
            onClick={() => handleChanges(false)}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  )
}

export default FilterValidation;