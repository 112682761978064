import 'firebase/auth';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';

export default function ChangePasswordForm({ initialValues, saveProfile, navigate, loading, errors }) {
  const passwordErrorMessage = 'Password must be at least 8 characters, have one capital letter, and one special character';
  const passwordRequireMessage = 'Invalid password';

  const ProfileSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(8, passwordErrorMessage)
      .matches(/(?=.*[A-Z])/, passwordErrorMessage)
      .matches(/\W|_/, passwordErrorMessage)
      .required(passwordRequireMessage),
    new_password: Yup.string()
      .min(8, passwordErrorMessage)
      .matches(/(?=.*[A-Z])/, passwordErrorMessage)
      .matches(/\W|_/, passwordErrorMessage)
      .required(passwordRequireMessage),
    confirm_password: Yup.string()
      .min(8, passwordErrorMessage)
      .matches(/(?=.*[A-Z])/, passwordErrorMessage)
      .matches(/\W|_/, passwordErrorMessage)
      .required(passwordRequireMessage)
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
  });

  return (
    <div className="flex flex-col flex-1 w-full">
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          saveProfile(values);
        }}
        validationSchema={ProfileSchema}
      >
        {({ setFieldValue }) => (
          <Form className="flex flex-col h-full w-full">
            {Object.entries(initialValues).map(([label, value], index) => {
              switch (label) {
                case 'old_password':
                  return (
                    <div key={index}>
                      <Field name="old_password">
                        {({ field, form, meta }) => {
                          return (
                            <>
                              <input
                                type="password"
                                className="text-sm  px-md rounded w-full border border-darkgray"
                                style={{ height: 55, marginTop: 16, borderColor: '#c4cad3' }}
                                placeholder="Old Password"
                                {...field}
                              />
                              {errors && errors.old_password && <div className="text-xs text-red font-medium mt-sm text-center">{errors.old_password}</div>}
                              {form.errors['old_password'] && form.touched['old_password'] && (
                                <div className="text-xs text-red font-medium mt-sm text-center">{form.errors['old_password']}</div>
                              )}
                            </>
                          );
                        }}
                      </Field>
                    </div>
                  );
                case 'new_password':
                  return (
                    <div key={index}>
                      <Field name="new_password">
                        {({ field, form, meta }) => {
                          return (
                            <>
                              <input
                                type="password"
                                className="text-sm  px-md rounded w-full border border-darkgray"
                                style={{ height: 55, marginTop: 16, borderColor: '#c4cad3' }}
                                placeholder="New Password"
                                {...field}
                              />
                              {form.errors['new_password'] && form.touched['new_password'] && (
                                <div className="text-xs text-red font-medium mt-sm text-center">{form.errors['new_password']}</div>
                              )}
                            </>
                          );
                        }}
                      </Field>
                    </div>
                  );
                case 'confirm_password':
                  return (
                    <div key={index}>
                      <Field name="confirm_password">
                        {({ field, form, meta }) => {
                          return (
                            <>
                              <input
                                type="password"
                                className="text-sm  px-md rounded w-full border border-darkgray"
                                style={{ height: 55, marginTop: 16, borderColor: '#c4cad3' }}
                                placeholder="Confirm Password"
                                {...field}
                              />
                              {form.errors['confirm_password'] && form.touched['confirm_password'] && (
                                <div className="text-xs text-red font-medium mt-sm text-center">{form.errors['confirm_password']}</div>
                              )}
                            </>
                          );
                        }}
                      </Field>
                    </div>
                  );
                default:
                  return (
                    <div key={index}>
                      <Field name={label}>
                        {({ field, form, meta }) => {
                          return (
                            <>
                              <input
                                className="text-sm  px-md rounded w-full border border-darkgray"
                                style={{ height: 55, marginTop: 16, borderColor: '#c4cad3' }}
                                placeholder={label}
                                {...field}
                              />
                              {form.errors[label] && form.touched[label] && <div className="text-xs text-red font-medium mt-sm text-center">{form.errors[label]}</div>}
                            </>
                          );
                        }}
                      </Field>
                    </div>
                  );
              }
            })}
            <div className="flex w-full" style={{ marginTop: 67 }}>
              <button
                onClick={() => navigate('Profile')}
                type="button"
                style={{ height: 55 }}
                className="bg-lightgray flex items-center justify-center shadow text-xs font-medium flex-1 text-darkgray rounded mr-sm p-sm"
              >
                Cancel
              </button>
              <button
                type="submit"
                style={{ height: 55, boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)' }}
                className="bg-red flex items-center justify-center  shadow ml-sm text-xs font-medium flex-1 text-white rounded p-sm"
              >
                {loading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Save'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
