import { isEmpty, cloneDeep } from 'lodash';
import { QUERY_PARAMS, FILTER, QUERY_FILTERS } from '../utils/constants';

export const filterCandidates = (filter, isUnlocked = false) => {
  return new Promise((resolve) => {
    const filters = cloneDeep({ ...QUERY_FILTERS });
    // Reset search if filters are empty
    if (isEmpty(filter)) {
      return resolve({ ...QUERY_FILTERS });
    }

    for (const key in filter) {
      const value = filter[key];
      switch (key) {
        case QUERY_PARAMS.SEARCH: {
          filters[FILTER.SEARCH_TERM] = value;
          filters[FILTER.IS_UNLOCKED] = isUnlocked ? 'true' : null;
          break;
        }
        case QUERY_PARAMS.EXPERIENCE: {
          filters[FILTER.MIN_EXPERIENCE] = +value.min;
          filters[FILTER.MAX_EXPERIENCE] = +value?.max === 0 ? null : +value?.max;
          break;
        }
        case QUERY_PARAMS.LOCATION: {
          filters[FILTER.LOCATION_LAT] = value?.city?.lat;
          filters[FILTER.LOCATION_LNG] = value?.city?.lng;
          filters[FILTER.LOCATION_RADIUS] = value?.radius;
          break;
        }
        case QUERY_PARAMS.ATTRIBUTES: {
          filters[FILTER.ATTRIBUTES] = value && Array.isArray(value) && value.length ? JSON.stringify(value) : null;
          break;
        }
        case QUERY_PARAMS.SALARY: {
          filters[FILTER.MIN_SALARY] = +value?.min;
          filters[FILTER.MAX_SALARY] = +value?.max === 0 ? null : +value?.max;
          break;
        }
        case QUERY_PARAMS.LOCATION_PREFERENCES: {
          filters[FILTER.LOCATION_PREFERENCES_RELOCATE] = `${!!value?.willingToRelocate}`;
          filters[FILTER.LOCATION_PREFERENCES_REMOTE] = `${!!value?.remote}`;
          break;
        }
        case QUERY_PARAMS.IS_LOGIN: {
          filters[FILTER.IS_LOGIN] = `${value}`;
          break;
        }
      }
    }
    return resolve({ ...QUERY_FILTERS, ...filters });
  });
};