import React from "react";
import { CSVDownloader } from 'react-papaparse'


export default function SampleCsvCompanies() {
    return (
        <CSVDownloader
            data={[
                {
                    "Company Name": "Test company one",
                    "Website": "http://testcompanyone.com",
                    "Description": "lorem ipsurm description",
                    "Size": "259-500",
                    "Industry": "Computing,Accounting",
                    "Location": "New York, NY, USA"
                },
                {
                    "Company Name": "Test company two",
                    "Website": "http://testcompanytwo.com",
                    "Description": "lorem ipsurm description",
                    "Size": "100-250",
                    "Industry": "Architecture,Banking",
                    "Location": "Miami, FL, USA"
                },
            ]}
            type="anchor"
            filename={'sample_companies'}
            className={`bg-red text-white font-medium rounded flex items-center justify-center`}
            style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
        >
            <span style={{ fontSize: 22, marginRight: 8 }}></span> Download Sample CSV
        </CSVDownloader>
    );
}