import React, { useEffect, useState } from 'react';
import SearchIcon from '../../images/search.svg';

export default function SearchTermsFilter({ update, value, type, placeholder, style = {} }) {
  const [inputValue, setInputValue] = useState(value ? value : type === 'job' ? [] : '');

  useEffect(() => {
    if (type === 'candidate') {
      update(inputValue);
    }
    if (type === 'company') {
      update(inputValue);
    }
    if (type === 'companies') {
      update(inputValue);
    }
    if (type === 'attributes') {
      update(inputValue);
    }
    if (type === 'loxo_name') {
      update(inputValue);
    }
    if (type === 'loxo_title') {
      update(inputValue);
    }
    if (type === 'jobtitle') {
      update(inputValue);
    }
  }, [inputValue]);

  return (
    <div className="SearchTermsFilter-js flex-1" style={{ paddingRight: 40, ...style }}>
      <div className="relative flex-1" style={{}}>
        <img alt="" src={SearchIcon} style={{ position: 'absolute', left: 10, top: 11, width: 16 }} />
        <input
          value={value ? inputValue : ''}
          onKeyDown={(e) => {
            if (e.which === 13 && type === 'job') {
              if (value) {
                update([inputValue, ...value]);
              } else {
                update([inputValue]);
              }

              setInputValue('');
            }
          }}
          onChange={(e) => setInputValue(e.target.value)}
          style={{ height: 40, paddingLeft: 35 }}
          className="SearchTermsFilter-js bg-white w-full shadow rounded p-sm text-xs placeholder-primary font-normal"
          placeholder={
            placeholder
              ? placeholder
              : type === 'loxo_name'
                ? 'Candidate Names'
                : type === 'loxo_title'
                  ? 'Job Title'
                  : `Search for ${type === 'companies' || type === 'attributes' ? type : type + 's'}`
          }
        />
      </div>
      {type === 'job' && value && value.length > 0 && (
        <div className="flex flex-wrap" style={{ marginTop: 6 }}>
          {value.map((term) => (
            <div
              className="border flex text-darkgray border-darkgray flex items-center px-md"
              style={{ height: 28, fontSize: 12, borderRadius: 28, marginRight: 10, marginTop: 10 }}
            >
              {term}
              <div onClick={() => update(value.filter((val) => val !== term))} className="ml-sm text-sm relative cursor-pointer" style={{ left: 5, width: 10, height: 10 }}>
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(45deg)', borderRadius: 1.5 }} />
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(-45deg)', borderRadius: 1.5 }} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
