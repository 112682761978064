import React, { useEffect, useState } from 'react';
import FieldTitle from '../../../components/common/FieldTitle';
import EmploymentEntry from './employment-entry';
import ProfileChangeUI from './ProfileChangeUI';

export default function CandidateEmployment({ isAdmin, changes, value, approveProfileChangeRequest, declineProfileChangeRequest, approvedChanges }) {
  const [employment, setEmployment] = useState({ current: [], past: [] });
  const [hasChanges, setHasChanges] = useState({ current: false, past: false });

  const employmentReducer = (entries) => {
    return entries.reduce(
      ({ current, past }, entry) => {
        if (entry.tenure.current) {
          return { current: [...current, entry], past };
        } else {
          return { current, past: [...past, entry] };
        }
      },
      { current: [], past: [] },
    );
  };

  useEffect(() => {
    const entries = approvedChanges ? approvedChanges : changes ? changes : typeof employment === 'string' ? JSON.parse(value) : value;

    setEmployment(employmentReducer(entries));

    if (changes) {
      const originalEntries = employmentReducer(approvedChanges ? approvedChanges : value);
      const requestedEntries = employmentReducer(changes);

      setHasChanges({
        current: JSON.stringify(originalEntries.current) !== JSON.stringify(requestedEntries.current),
        past: JSON.stringify(originalEntries.past) !== JSON.stringify(requestedEntries.past),
      });
    }
  }, [value, changes, approvedChanges]);

  return (
    <div className="w-full">
      {employment.current.length > 0 && (
        <>
          <div className="flex">
            <div className="flex-1">
              <FieldTitle title="Current Employment" />
            </div>
            {!isAdmin && changes && hasChanges.current && (
              <div className="flex items-center" style={{ marginTop: 40, marginLeft: 15 }}>
                <ProfileChangeUI approveProfileChangeRequest={approveProfileChangeRequest} declineProfileChangeRequest={declineProfileChangeRequest} type="employment" />
              </div>
            )}
          </div>
          {employment.current.map((job, index) => {
            return <EmploymentEntry entry={job} key={index} />;
          })}
        </>
      )}
      {employment.past.length > 0 && (
        <>
          <div className="flex">
            <div className="flex-1">
              <FieldTitle title="Previous Employment" />
            </div>
            {!isAdmin && changes && hasChanges.past && (
              <div className="flex items-center" style={{ marginTop: 40, marginLeft: 15 }}>
                <ProfileChangeUI approveProfileChangeRequest={approveProfileChangeRequest} declineProfileChangeRequest={declineProfileChangeRequest} type="employment" />
              </div>
            )}
          </div>
          {employment.past.map((job, index) => {
            return <EmploymentEntry entry={job} key={index} />;
          })}
        </>
      )}
    </div>
  );
}
