import { useQuery, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react';
import FieldTitle from '../../components/common/FieldTitle';
import LinkIcon from '../../images/link.png';
import UserAvatar from '../../images/user-avatar.png';
import { bioLorem, REQUEST_STATUSES } from '../../utils/constants';
import { formatSalary } from '../../utils/formatter';
import EducationEntry from '../candidates/candidate-details/education-entry';
import EmploymentEntry from '../candidates/candidate-details/employment-entry';
import { ViewResumeButton } from './view-resume-button';

const APPROVED_COMPANY_REQUEST = gql`
  subscription approvedCompanyRequests($admin_id: String, $candidate_id: String) {
    company_request(where: { company: { adminID: { _eq: $admin_id } }, _and: { status: { _eq: "approved" }, _and: { candidate_id: { _eq: $candidate_id } } } }) {
      status
      candidate_id
      candidate {
        name
        profilePictureURL
        bio
        phone
        email
        resumeFileName
        resumeURL
        linkedin
        location
        salaryMin
        salaryMax
      }
    }
  }
`;

const COMPANY_REQUEST = gql`
  subscription companyRequest($admin_id: String, $candidate_id: String) {
    company_request(where: { company: { adminID: { _eq: $admin_id } }, _and: { candidate_id: { _eq: $candidate_id } } }) {
      status
      candidate_id
    }
  }
`;

const GET_CANDIDATE = gql`
  subscription getCompanyCandidate($candidate_id: String) {
    candidate(where: { id: { _eq: $candidate_id } }) {
      experience
      attributes
      education
      salaryMax
      salaryMin
      location
      attributes
      employment
      id
      locationPreferences
      is_login
      device_token
      invite_code
      loxo_id
      recruiter_id
      resumeURL
      interestedCity
    }
  }
`;

const GET_COMPANY_ID = gql`
  query getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      remainingCredit
      subscription_type
    }
  }
`;

const CandidateEmployment = ({ entries, approved }) => {
  const employment = entries.reduce(
    ({ current, past }, entry) => {
      if (entry.tenure.current) {
        return { current: [...current, entry], past };
      } else if ((entry.tenure?.startDate || '').length > 0) {
        return { current, past: [...past, entry] };
      } else {
        return { current, past };
      }
    },
    { current: [], past: [] },
  );

  return (
    <React.Fragment>
      {employment.current.length > 0 && <FieldTitle title="Current Employment" marginTop={1} />}
      {employment.current.map((entry, index) => (
        <EmploymentEntry key={index} entry={entry} approved={approved} isCompany={true} />
      ))}
      {employment.past.length > 0 && <FieldTitle title="Previous Employment" marginTop={employment.current.length === 0 && 1} />}
      {employment.past.map((entry, index) => (
        <EmploymentEntry key={index} entry={entry} approved={approved} isCompany={true} />
      ))}
    </React.Fragment>
  );
};

const Skills = ({ skills }) => {
  return (
    <div className="flex flex-wrap">
      {skills.map((skill, index) => (
        <div
          className="bg-lightgray mt-md shadow px-md text-darkgray flex items-center mr-sm font-medium"
          key={index}
          style={{ fontSize: 12, height: 28, borderRadius: 28, color: '#9499a2' }}
        >
          {skill}
        </div>
      ))}
    </div>
  );
};

const LocationPreferences = ({ locationPreferences }) => {
  return (
    <div className="w-full relative">
      <div className="flex flex-wrap">
        {locationPreferences.remote && (
          <div className={`bg-lightgray text-darkgray mt-md shadow px-md flex items-center mr-sm font-medium`} style={{ fontSize: 12, height: 28, borderRadius: 28 }}>
            Remote
          </div>
        )}
        {locationPreferences.willingToRelocate && (
          <div className={`bg-lightgray text-darkgray mt-md shadow px-md flex items-center mr-sm font-medium`} style={{ fontSize: 12, height: 28, borderRadius: 28 }}>
            Wlling To Relocate
          </div>
        )}
        {locationPreferences.currentLocation && (
          <div className={`bg-lightgray text-darkgray mt-md shadow px-md flex items-center mr-sm font-medium`} style={{ fontSize: 12, height: 28, borderRadius: 28 }}>
            Current Location
          </div>
        )}
      </div>
    </div>
  );
};

export default function SubscriberCandidateDetail({ state }) {
  const [modal, setModal] = state;
  const [fadeDelay, setFadeDelay] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    if (modal.open) {
      document.querySelector('html').style.overflow = 'hidden';
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        document.querySelector('html').style.overflow = 'auto';
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  const [approved, setApproved] = useState(false);
  const [status, setStatus] = useState(`${modal.data && modal.data.status ? modal.data.status : null}`);

  // const [companyID, setCompanyID] = useState();
  // const [remainingCredit, setRemainingCredit] = useState();

  const [candidate, setCandidate] = useState();
  const { data: candidateData } = useSubscription(GET_CANDIDATE, {
    variables: { candidate_id: modal.data && modal.data.candidate ? modal.data.candidate.id : null },
  });
  const { data: company } = useQuery(GET_COMPANY_ID, {
    variables: { admin_id: firebase.auth().currentUser ? firebase.auth().currentUser.uid : 'not-logged-in' },
  });
  const { data: approvedRequest } = useSubscription(APPROVED_COMPANY_REQUEST, {
    variables: {
      admin_id: firebase.auth().currentUser ? firebase.auth().currentUser.uid : 'not-logged-in',
      candidate_id: modal.data && modal.data.candidate ? modal.data.candidate.id : 'query-loading',
    },
    shouldResubscribe: true,
  });

  const { data: anyRequest } = useSubscription(COMPANY_REQUEST, {
    variables: {
      admin_id: firebase.auth().currentUser ? firebase.auth().currentUser.uid : 'not-logged-in',
      candidate_id: modal.data && modal.data.candidate ? modal.data.candidate.id : 'query-loading',
    },
    shouldResubscribe: true,
  });

  useEffect(() => {
    if (candidateData) {
      if (candidateData.candidate.length > 0) {
        if (approvedRequest && approvedRequest.company_request.length > 0) {
          setCandidate({ ...candidateData.candidate[0], ...approvedRequest.company_request[0].candidate });
        } else {
          setCandidate(candidateData.candidate[0]);
        }
      } else {
        window.history.back();
      }
    }
  }, [candidateData]);

  useEffect(() => {
    if (company) {
      // setCompanyID(company.company[0].id);
      // setRemainingCredit(company.company[0].remainingCredit);
    }
  }, [company]);

  useEffect(() => {
    if (approvedRequest && approvedRequest.company_request.length > 0) {
      setApproved(true);
      setCandidate({ ...candidate, ...approvedRequest.company_request[0].candidate });
    }
  }, [approvedRequest]);

  useEffect(() => {
    if (status !== 'accepted') {
      if (anyRequest && anyRequest.company_request.length > 0) {
        setStatus(anyRequest.company_request[0].status);
      } else {
        if (status !== 'interested') {
          setStatus('request');
        }
      }
    }
  }, [anyRequest]);

  const smallTitledFields = candidate
    ? {
      name: candidate.name,
      email: candidate.email,
      phone: candidate.phone,
      resume: candidate.resumeFileName,
      LinkedIn: candidate.linkedin,
      location: candidate.location,
      salary: candidate.salaryMin ? `$ ${formatSalary({ salary: candidate.salaryMin })} +` : `Negotiable`,
      experience: `${candidate.experience}`,
    }
    : {};

  return (
    <React.Fragment>
      <tr
        className={`fixed items-center justify-end`}
        style={{
          transition: 'opacity 0.3s ease-in-out',
          opacity: modal.open ? 1 : 0,
          zIndex: 3000000,
          display: fadeDelay ? 'flex' : 'none',
          backgroundColor: 'none',
          top: 0,
          bottom: 0,
          right: 0,
          width: 745,
        }}
      >
        <td
          ref={modalRef}
          className="bg-white py-md rounded shadow flex flex-col"
          style={{ paddingLeft: 40, paddingRight: 40, height: '100%', width: '745px', overflowY: 'scroll', overflowX: 'hidden' }}
        >
          <div style={{ display: 'inline-flex' }} className="text-lg font-medium pb-sm border-b mb-lg">
            <div>Candidate Detail</div>
            <div style={{ marginLeft: 450, cursor: 'pointer' }} onClick={() => setModal({ ...modal, open: false, data: null })}>
              x
            </div>
          </div>
          <div className="bg-white rounded flex flex-col mb-lg pb-xl" style={{ minHeight: 'calc(100vh - 125px)' }}>
            {candidate && (
              <div className="flex w-full flex-wrap pb-16">
                {approved && (
                  <div className="flex w-full items-center mb-xl">
                    <div
                      style={{
                        margin: 0,
                        height: 80,
                        width: 80,
                        borderRadius: 40,
                        marginRight: 15,
                        backgroundPosition: 'center',
                        backgroundImage: `url('${approved ? candidate.profilePictureURL : UserAvatar}')`,
                        backgroundSize: 'cover',
                        filter: !approved && 'blur(6px)',
                      }}
                    />
                    <div className="flex flex-col font-medium text-darkblue">
                      <div className="flex flex-col justify-center">
                        <div className="text-darkblue mr-md">{candidate.name}</div>

                        <ViewResumeButton resumeUrl={candidate.resumeURL} candidateLoxoId={candidate.loxo_id} recruiterId={candidate.recruiter_id} />

                        {/* {candidate.resumeURL && (
                          <div className="flex">
                            <div
                              onClick={() => window.open(candidate.resumeURL)}
                              className="bg-lightgray flex shadow cursor-pointer rounded hover:underline items-center text-darkgray"
                              style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, marginTop: 5, fontSize: 12 }}
                            >
                              <img alt="" src={LinkIcon} style={{ height: 13, width: 13, marginBottom: 0, marginRight: 10 }} />
                              View Resume
                            </div>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-wrap">
                  {Object.entries(smallTitledFields).map(([title, value]) => (
                    <div key={title} className="flex flex-col w-1/2">
                      <div className=" text-sm text-darkgray font-medium capitalize">{title}</div>
                      <div
                        className="text-sm text-darkblue font-medium pt-md pb-lg"
                        style={{
                          filter: `blur(${approved || title === 'location' || title === 'salary' || title === 'experience' ? '0' : '5'}px)`,
                        }}
                      >
                        {title === 'location' && value ? (
                          value.city ? (
                            value.city
                          ) : (
                            'N/A'
                          )
                        ) : title === 'salary' ? (
                          value
                        ) : title === 'experience' ? (
                          (value === 'null' ? 0 : value) + ' years'
                        ) : !approved ? (
                          `Candidate ${title}`
                        ) : title === 'LinkedIn' ? (
                          <a href={`${candidate.linkedin}`} target="_blank" className="hover:underline" rel="noreferrer">
                            {candidate.linkedin}
                          </a>
                        ) : (
                          value
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="w-full pr-xl pb-xl">
                  <FieldTitle title="Short Bio" />
                  <div className="text-sm pt-md text-darkgray" style={{ filter: `blur(${approved ? '0' : '5'}px)` }}>
                    {approved ? candidate.bio : bioLorem}
                  </div>
                </div>
                {candidate.employment && <CandidateEmployment entries={candidate.employment} approved={approved} />}

                <FieldTitle title="Education" />
                {candidate.education && candidate.education.map((entry, index) => <EducationEntry key={index} entry={entry} schoolHidden={!approved} />)}
                {candidate.attributes && candidate.attributes.length > 0 && (
                  <>
                    <FieldTitle title="Candidate's Attributes" />
                    <Skills skills={candidate.attributes} />
                  </>
                )}
                {candidate.locationPreferences && (
                  <>
                    <FieldTitle title="Location Preferences" />
                    <LocationPreferences locationPreferences={candidate.locationPreferences} />
                  </>
                )}
                {candidate.interestedCity && Array.isArray(candidate.interestedCity) ? (
                  <div className="w-full relative interested-city">
                    <FieldTitle title="Interested Cities" />
                    <div className="flex flex-wrap">
                      {candidate.interestedCity.map(({ city = '' }, idx) => (
                        <div
                          key={idx}
                          className={`bg-lightgray text-darkgray mt-md shadow px-md flex items-center mr-sm font-medium`}
                          style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                        >
                          {city || ''}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                {candidate.preferredSkills && candidate.preferredSkills.length > 0 && (
                  <>
                    <FieldTitle title="Desired Job Attributes" />
                    <Skills skills={candidate.preferredSkills} />
                  </>
                )}
              </div>
            )}
          </div>
        </td>
      </tr>

      {modal.open && (
        <div
          onClick={() => setModal({ ...modal, open: false, data: null, reset: true })}
          style={{ position: 'fixed', top: 0, right: 0, height: '100%', width: '100%', background: 'black', opacity: '0.5', zIndex: 1000001 }}
        ></div>
      )}
    </React.Fragment>
  );
}
