import _ from 'lodash';
import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { UserRoleContext } from '../../context';
import EditIcon from '../../images/edit-green.png';
import { CandidateExperience, CandidateSkills, EducationField, EmploymentField, LocationField, SocialInput, TextInput } from '../fields';
import Form from '../Form';
import { getEducation, getEmployment, getExperience, getSkills } from './CandidateFormUtils';

export default function CandidateDetailsForm ({ globalFormData, goForward, goBack }) {
  const [customErrors, setCustomErrors] = useState();
  const [hasErrors, setHasErrors] = useState();
  const candidate = globalFormData.candidate ? globalFormData.candidate : {};
  const resume = globalFormData.resume ? globalFormData.resume : {};
  const { setCandidate } = globalFormData;
  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';

  const basicInfo = {
    name: candidate.name,
    email: candidate.email,
    phone: candidate.phone,
    resume: candidate.resume && candidate.resume.name,
  };

  const adminFields = {
    referralName: {
      value: candidate.referralName ? candidate.referralName : '',
      component: TextInput,
      placeholder: 'Referral Name',
    },
    linkedin: {
      value: candidate.linkedinURL ? candidate.linkedinURL : '',
      component: SocialInput,
    },
    facebook: {
      value: candidate.facebookURL ? candidate.facebookURL : '',
      component: SocialInput,
    },
    instagram: {
      value: candidate.instagramURL ? candidate.instagramURL : '',
      component: SocialInput,
    },
    twitter: {
      value: candidate.twitterURL ? candidate.twitterURL : '',
      component: SocialInput,
    },
    bio: {
      title: 'Bio',
      value: candidate.bio ? candidate.bio : resume.Summary ? resume.Summary : '',
      component: TextInput,
      validation: Yup.string().required('A short bio is required'),
    },
    location: {
      title: 'Location',
      value: '',
      component: LocationField,
      validation: Yup.string().required('A City, State is required'),
    },
    experience: {
      title: 'Years of Experience',
      value: candidate.experience ? candidate.experience : getExperience(resume),
      component: CandidateExperience,
    },
    employment: {
      title: 'Employment',
      value: candidate.employment ? candidate.employment : getEmployment(resume),
      component: EmploymentField,
    },
    education: {
      title: 'Education',
      value: candidate.education ? candidate.education : getEducation(resume),
      component: EducationField,
    },
    certifications: {
      title: 'Certifications or Licenses',
      type: 'certifications',
      value: candidate.certifications ? candidate.certifications : [],
      component: CandidateSkills,
    },
    attributes: {
      title: 'Candidate Attributes',
      type: 'skills',
      value: _.uniq([...(candidate.attributes || []), ...(getSkills(resume) || [])]),
      component: CandidateSkills,
      validation: Yup.mixed().test({
        name: 'Attributes',
        message: 'At least one attribute for the candidate is required',
        test: (value) => (value.length === 0 ? false : true),
      }),
    },
    preferredSkills: {
      title: 'Job Preferences',
      type: 'skills',
      value: candidate.preferredSkills ? candidate.preferredSkills : [],
      component: CandidateSkills,
      placeholder: 'Job Preferences',
    },
  };

  const fields = {
    referralName: {
      value: candidate.referralName ? candidate.referralName : '',
      component: TextInput,
      placeholder: 'Referral Name',
    },
    // recruiterName: {
    //   value: candidate.recruiterName ? candidate.recruiterName : (globalFormData.recruiter ? globalFormData.recruiter.name : ''),
    //   component: TextInput,
    //   placeholder: "Recruiter Name",
    //   validation: Yup.string().required("Recruiter name is required"),
    // },
    linkedin: {
      value: candidate.linkedin ? candidate.linkedin : '',
      component: SocialInput,
    },
    facebook: {
      value: candidate.facebook ? candidate.facebook : '',
      component: SocialInput,
    },
    instagram: {
      value: candidate.instagram ? candidate.instagram : '',
      component: SocialInput,
    },
    twitter: {
      value: candidate.twitter ? candidate.twitter : '',
      component: SocialInput,
    },
    bio: {
      title: 'Bio',
      value: candidate.bio ? candidate.bio : resume.Summary ? resume.Summary : '',
      component: TextInput,
      validation: Yup.string().required('A short bio is required'),
    },
    location: {
      title: 'Location',
      value: '',
      component: LocationField,
      validation: Yup.string().required('A City, State is required'),
    },
    experience: {
      title: 'Years of Experience',
      value: candidate.experience ? candidate.experience : getExperience(globalFormData.resume),
      component: CandidateExperience,
    },
    employment: {
      title: 'Employment',
      value: candidate.employment ? candidate.employment : getEmployment(globalFormData.resume),
      component: EmploymentField,
    },
    education: {
      title: 'Education',
      value: candidate.education ? candidate.education : getEducation(globalFormData.resume),
      component: EducationField,
    },
    certifications: {
      title: 'Certifications or Licenses',
      type: 'certifications',
      value: candidate.certifications ? candidate.certifications : [],
      component: CandidateSkills,
    },
    attributes: {
      title: 'Candidate Attributes',
      value: _.uniq([...(candidate.attributes || []), ...(getSkills(globalFormData.resume) || [])]),
      component: CandidateSkills,
      type: 'skills',
      validation: Yup.mixed().test({
        name: 'Attributes',
        message: 'At least one attribute for the candidate is required',
        test: (value) => (value.length === 0 ? false : true),
      }),
    },
    preferredSkills: {
      title: 'Job Preferences',
      type: 'skills',
      value: candidate.preferredSkills ? candidate.preferredSkills : [],
      component: CandidateSkills,
      placeholder: 'Job Preferences',
    },
  };

  const onSubmit = (values) => {
    console.log(`This is in CandidateDetailsForm.js onSubmit`);

    setHasErrors(false);
    const employmentEntries = values.employment.length > 0 && Object.entries(values.employment[0]).filter(([label, val]) => {
      if (label === 'companyLogo') {
        return false;
      } else {
        return !val || val === '';
      }
    });
    const educationEntries = values.education.length > 0 && Object.values(values.education[0]).filter((val) => val === '');
    console.log(employmentEntries, educationEntries);

    if (values.employment.length == 0 || values.education.length == 0) {
      setCustomErrors({
        employment: values.employment.length == 0 && 'Please completely fill out at least one employment entry',
        education: values.education.length == 0 && 'Please completely fill out at least one education entry',
      });
    } else {
      setCandidate({ ...candidate, ...values });
      goForward();
    }
  };

  return (
    <div className="CandidateDetailsForm-js container flex flex-col lg:flex-row w-full pb-lg">
      <div className="flex flex-col flex-1  mr-0 lg:mr-xl" style={{ maxWidth: 572 }}>
        <div className="font-semibold" style={{ fontSize: 16, marginBottom: 28 }}>
          Profile Preview
        </div>
        <div className="flex bg-white relative flex-wrap rounded shadow p-lg lg:p-xl w-full">
          <div className="absolute cursor-pointer" style={{ right: 40 }}>
            <img src={EditIcon} style={{ height: 18 }} />
          </div>
          {Object.entries(basicInfo).map(([label, value], index) => (
            <div key={index} className="w-full lg:w-1/2" style={{ marginBottom: 34, fontSize: 14 }}>
              <div className="text-darkgray capitalize" style={{ marginBottom: 14 }}>
                {label}
              </div>
              <div className="text-darkblue font-medium">{value}</div>
            </div>
          ))}

          {globalFormData.candidate && globalFormData.resume && (
            <Form fields={isAdmin ? adminFields : fields} onSubmit={onSubmit} customErrors={customErrors} setCustomErrors={setCustomErrors}>
              {(customErrors || hasErrors) && (
                <div className="font-medium text-red text-sm text-center relative" style={{ top: 25 }}>
                  Please completely fill out all required fields
                </div>
              )}
              <div className="flex relative justify-center" style={{ height: 55, marginBottom: 32, marginTop: 60 }}>
                <button onClick={() => goBack()} type="button" className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray" style={{ width: 137, fontSize: 14 }}>
                  Cancel
                </button>
                <button
                  onClick={() => setHasErrors(true)}
                  type="submit"
                  className="rounded border ml-sm font-medium text-sm bg-red text-white"
                  style={{
                    width: 157,
                    fontSize: 14,
                    boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)',
                  }}
                >
                  Save & Continue
                </button>
              </div>
            </Form>
          )}
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="font-semibold" style={{ fontSize: 16, marginBottom: 28 }}>
          Resume Preview
        </div>
        {candidate.resume && (
          <iframe
            style={{ width: '572px', maxWidth: '100%', height: 800 }}
            src={candidate.resume.TemplateOutput ? candidate.resume.TemplateOutput.TemplateOutputData : URL.createObjectURL(candidate.resume)}
          />
        )}
      </div>
    </div>
  );
}
