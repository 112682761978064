import React from "react";
import UserAvatar from "../../../images/user-avatar.png";

export default function ProfilePicture({ source, isAdmin }) {
  return (
    <div
      style={{
        margin: 0,
        height: 80,
        width: isAdmin ? 350 : 80,
        borderRadius: 40,
        marginRight: 15,
        backgroundImage: `url('${source ? source : UserAvatar}')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    />
  );
}
