import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { CSVReader } from 'react-papaparse';
const buttonRef = React.createRef();

/* graphql query to create new company*/
const CREATE_ATTRIBUTE = gql`
  mutation CreateAttribute($name: String, $category: String) {
    insert_skill(objects: { name: $name, category: $category }) {
      returning {
        name
        category
        id
      }
    }
  }
`;

export default function AttributeImportModal({ state, isAdmin }) {
  const [modal, setModal] = state;
  const [, setFadeDelay] = useState(false);
  const modalRef = useRef();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (modal.open) {
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  const [addAttribute, { loading }] = useMutation(CREATE_ATTRIBUTE);

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data) => {
    if (data.length > 0) {
      setDataLoading(true);
      for (let i = 1; i < data.length; i++) {
        const companyObject = {
          name: data[i].data[0],
          category: data[i].data[1],
        };

        addAttribute({
          variables: companyObject,
        });
      }

      if (buttonRef.current) {
        buttonRef.current.removeFile(data);
      }

      if (!loading) {
        setDataLoading(false);
        setModal({
          open: false,
        });
        navigate('/dashboard/attributes');
      }
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  return (
    <React.Fragment>
      <div
        onClick={() => setModal({ data: null, open: true })}
        className={`fixed inset-0 ${modal.open ? 'flex' : 'hidden'} items-center justify-center font-main`}
        style={{ backgroundColor: 'rgba(34, 46, 66, 0.9)', zIndex: 20000 }}
        data-keyboard="false"
      >
        <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ minHeight: 200, minWidth: 450 }}>
          <div className="text-darkblue font-medium border-b flex justify-between pb-sm" style={{ maxWidth: 'calc(100vw - 30px)' }}>
            Import Attributes
            <div onClick={() => setModal({ data: null, open: false })} className="text-darkgray hover:text-darkblue cursor-pointer">
              x
            </div>
          </div>
          <div className="text-darkblue text-sm text-center py-lg">
            <CSVReader
              ref={buttonRef}
              onFileLoad={handleOnFileLoad}
              onError={handleOnError}
              noClick
              noDrag
              config={{
                skipEmptyLines: true,
              }}
            >
              {({ file }) => (
                <aside
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 10,
                  }}
                >
                  <button
                    type="button"
                    onClick={handleOpenDialog}
                    disabled={dataLoading ? true : false}
                    style={{
                      borderRadius: 0,
                      marginLeft: 0,
                      marginRight: 0,
                      width: '40%',
                      paddingLeft: 0,
                      paddingRight: 0,
                      background: '#366992',
                      color: '#FFF',
                    }}
                  >
                    {dataLoading ? 'loading...' : 'Select to upload CSV file'}
                  </button>
                  <div
                    style={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: '#ccc',
                      height: 45,
                      lineHeight: 2.5,
                      paddingLeft: 13,
                      paddingTop: 3,
                      width: '60%',
                    }}
                  >
                    {file && file.name}
                  </div>
                </aside>
              )}
            </CSVReader>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
