import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVDownloader } from 'react-papaparse';
import { TabTitle } from '../../components/common';
import './candidates.css';

const ModalContext = React.createContext();

const ALL_RECRUITER_ACTIVITY = gql`
  subscription getRecruiterActivity($start_date: date, $end_date: date) {
    recruiter(order_by: { created_at: desc }) {
      id
      name
      email
      created_at
      candidates_aggregate(where: { approved: { _eq: true } }) {
        aggregate {
          count
        }
      }
      jobs_aggregate(where: { subscriber_id: { _is_null: true }, created_date: { _gte: $start_date, _lte: $end_date } }) {
        aggregate {
          count
        }
        nodes {
          id
          filled
          closed
          created_date
        }
      }
      candidates {
        company_requests(where: { date: { _gte: $start_date, _lte: $end_date } }) {
          candidate_id
          company_id
          date
          status
        }
      }
      company {
        id
        name
      }
    }
  }
`;

const ActivityHeading = () => {
  return (
    <div className="flex items-center border-b" style={{ height: 100, opacity: 1, transition: 'opacity 0.3s ease-in-out' }}>
      <div className="flex justify-center" style={{ width: 100 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Date'}
        </div>
      </div>
      <div style={{ paddingLeft: 0, paddingRight: 0, width: 353, minWidth: 353 }}>
        <div className="flex flex-col">
          <div className="lg:p-0 text-darkblue lg:text-sm" style={{ fontWeight: 'bold' }}>
            {'Recruiter Name'}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14, maxWidth: 420 }}>
            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
              {'Recruiter Company | '}
            </div>
            <div className="mr-sm text-darkblue" style={{ fontWeight: 'bold' }}>
              {'Recruiter Email'}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Candidates in database'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Jobs Posted'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 200 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Jobs Filled | Jobs Closed'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Candidates Receiving Requests from companies'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Accepted Requests by candidates'}
        </div>
      </div>
    </div>
  );
};
export { ActivityHeading };

export const SingleActivity = ({ activity }) => {
  let receivedRequests = 0;
  let acceptedRequests = 0;

  for (const candidate of activity.candidates || []) {
    if ((candidate.company_requests || []).length) {
      receivedRequests++;
    }

    for (const request of candidate.company_requests || []) {
      if (['accepted', 'approved'].includes(request.status)) {
        acceptedRequests++;
        break;
      }
    }
  }

  return (
    <div className="flex items-center border-b" style={{ height: 100, opacity: 1, transition: 'opacity 0.3s ease-in-out' }}>
      <div className="flex justify-center" style={{ width: 100 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {moment(activity.jobs_aggregate.nodes.created_date).format('MM/DD/YYYY')}
        </div>
      </div>
      <div style={{ paddingLeft: 0, paddingRight: 0, width: 353, minWidth: 353 }}>
        <div className="flex flex-col">
          <div className="lg:p-0 lg:text-sm" style={{ fontWeight: 500 }}>
            {activity.name}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14, maxWidth: 420 }}>
            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
              {activity.company ? activity.company.name + ' | ' : ''}
            </div>
            <div className="mr-sm text-darkgray">{activity.email}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.candidates_aggregate.aggregate.count}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.jobs_aggregate.aggregate.count}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 200 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.jobs_aggregate.nodes.length > 0 ? activity.jobs_aggregate.nodes.filter((node) => node.filled === true).length : 0}
          {' | '}
          {activity.jobs_aggregate.nodes.length > 0 ? activity.jobs_aggregate.nodes.filter((node) => node.closed === true).length : 0}
        </div>
      </div>

      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {receivedRequests}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {acceptedRequests}
        </div>
      </div>
    </div>
  );
};

export default function RecruiterActivity ({ isAdmin, inProcess, isDashboard }) {
  const [modal, setModal] = useState({ data: null, open: false });
  const [activityData, setActivityData] = useState();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  const { data: recruiterActivityResponse, loading } = useSubscription(ALL_RECRUITER_ACTIVITY, {
    variables: {
      start_date: startDate != null ? moment(startDate).format('YYYY-MM-DD') : null,
      end_date: moment(endDate).format('YYYY-MM-DD'),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (recruiterActivityResponse) {
      setActivityData(recruiterActivityResponse.recruiter);
    }
  }, [recruiterActivityResponse]);

  function _mapActivityData (activity) {
    return {
      Date: moment(activity.jobs_aggregate.nodes.created_date).format('MM/DD/YYYY'),
      'Recruiter Company': activity.company ? activity.company.name : 'N/A',
      'Recruiter Email': activity.email,
      'Recruiter Name': activity.name,
      'Candidates in database': activity.candidates_aggregate.aggregate.count,
      'Jobs posted': activity.jobs_aggregate.aggregate.count,
      'Jobs filled': 0,
      'Jobs closed': 0,
      'Candidates receiving requested from companies': 0,
      'Accepted Requests by candidates': 0,
    };
  }

  const csvData = activityData ? activityData.map(_mapActivityData) : [];

  return (
    <div className="w-full flex-1 candidates">
      <ModalContext.Provider value={[modal, setModal]}>
        <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
          <TabTitle showBackButton={true}>{'Recruiter Activity'}</TabTitle>
          {activityData && activityData.length > 0 && (
            <CSVDownloader
              data={csvData}
              type="anchor"
              filename={'recruiter_activity'}
              className={`bg-red text-white font-medium rounded flex items-center justify-center`}
              style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
            >
              <span style={{ fontSize: 22, marginRight: 8 }}></span> Download CSV
            </CSVDownloader>
          )}
        </div>
        <div className="h-full flex flex-col" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
          <div style={{ marginRight: 20 }}>
            <span>Start Date: </span>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={moment(new Date()).toDate()} />
          </div>
          <div>
            <span>End Date: </span>
            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={moment(new Date()).toDate()} />
          </div>
        </div>
        <div className={`flex-1 bg-white rounded shadow candidates-container candidate-blank-dash`} style={{ overflowX: 'auto' }}>
          <table style={{ width: '100%' }}>
            <thead style={{ fontSize: 14, fontWeight: 'bold' }}>
              <tr style={{ position: 'sticky', top: 0, background: 'white' }}>
                <th style={{ paddingLeft: '35px', textAlign: 'center' }}>Date</th>
                <th style={{}}>
                  <div className="flex flex-col">
                    <div className="lg:p-0" style={{}}>
                      Recruiter Name
                    </div>
                    <div className="lg:p-0 font-medium" style={{}}>
                      <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                        Recruiter Company
                      </div>
                    </div>
                    <div className="lg:p-0 font-medium" style={{}}>
                      <div className="mr-sm text-darkgray">Recruiter Email</div>
                    </div>
                  </div>
                </th>
                <th style={{ textAlign: 'center' }}>Candidates in database</th>
                <th style={{ textAlign: 'center' }}>Jobs Posted</th>
                <th style={{ textAlign: 'center' }}>Jobs Filled | Jobs Closed</th>
                <th style={{ textAlign: 'center' }}>Candidates Receiving Requests from companies</th>
                <th style={{ textAlign: 'center', paddingRight: '35px' }}>Accepted Requests by candidates</th>
              </tr>
            </thead>

            <tbody>
              {(activityData || []).map((activity) => {
                let receivedRequests = 0;
                let acceptedRequests = 0;

                for (const candidate of activity.candidates || []) {
                  if ((candidate.company_requests || []).length) {
                    receivedRequests++;
                  }

                  for (const request of candidate.company_requests || []) {
                    if (['accepted', 'approved'].includes(request.status)) {
                      acceptedRequests++;
                      break;
                    }
                  }
                }

                return (
                  <tr>
                    <td style={{ paddingLeft: '35px' }}>
                      <div className="font-medium flex items-center" style={{ fontSize: 14, justifyContent: 'center' }}>
                        {moment(activity.created_at).format('MM/DD/YYYY')}
                      </div>
                    </td>
                    <td>
                      <div className="flex flex-col">
                        <div className="lg:p-0 lg:text-sm" style={{ fontWeight: 500 }}>
                          {activity.name}
                        </div>
                        <div className="flex items-center font-medium" style={{ fontSize: 14 }}>
                          <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                            {activity.company ? activity.company.name : ''}
                          </div>
                        </div>
                        <div className="flex items-center font-medium" style={{ fontSize: 14 }}>
                          <div className="mr-sm text-darkgray">{activity.email}</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14, justifyContent: 'center' }}>
                        {activity.candidates_aggregate.aggregate.count}
                      </div>
                    </td>
                    <td>
                      <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14, justifyContent: 'center' }}>
                        {activity.jobs_aggregate.aggregate.count}
                      </div>
                    </td>
                    <td>
                      <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14, justifyContent: 'center' }}>
                        {activity.jobs_aggregate.nodes.length > 0 ? activity.jobs_aggregate.nodes.filter((node) => node.filled === true).length : 0}
                        {' | '}
                        {activity.jobs_aggregate.nodes.length > 0 ? activity.jobs_aggregate.nodes.filter((node) => node.closed === true).length : 0}
                      </div>
                    </td>
                    <td>
                      <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14, justifyContent: 'center' }}>
                        {receivedRequests}
                      </div>
                    </td>
                    <td>
                      <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14, justifyContent: 'center', paddingRight: '35px' }}>
                        {acceptedRequests}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {loading && (
            <div className={`flex justify-center items-center flex-1 candidate-blank${isDashboard ? '-dash' : ''}`} style={{ width: '100%' }}>
              <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                Loading
              </div>
            </div>
          )}
        </div>
      </ModalContext.Provider>
    </div>
  );
}
