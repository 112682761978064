import React, { useEffect } from 'react'
import { gql } from 'apollo-boost'
import { useSubscription } from '@apollo/react-hooks'
import { navigate } from 'gatsby'

const GET_INTERESTED_JOBS = gql`
  subscription getInterestedJobs($candidate_id: String) {
    job_candidate(
      where: {
        candidate_id: { _eq: $candidate_id }
        _and: { status: { _eq: "interested" } }
      }
      order_by: { created_at: desc }
    ) {
      job {
        id
        title
        company {
          id
          name
          logoURL
        }
        subscriber{
          id
          name
          logoURL
        }
      }
    }
  }
`;


export default function InterestedJobs ({ candidateID, setModal, candidate, isAdmin }) {
  const { data: interestedJobs, loading } = useSubscription(
    GET_INTERESTED_JOBS,
    { variables: { candidate_id: candidateID } }
  );

  useEffect(() => {
    if (interestedJobs) {
      ({ interestedJobs });
    }
  }, [interestedJobs]);

  return (
    <React.Fragment>
      <div
        className="flex items-center justify-between py-sm"
        style={{ height: 53, marginBottom: 12 }}>
        <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
          Interested Jobs
        </div>
      </div>
      <div
        className="bg-white rounded shadow mb-lg w-full text-darkgray text-center  text-sm h-full"
        style={{ maxWidth: 767, minHeight: 103, maxHeight: 300, overflow: 'auto' }}>
        {interestedJobs && interestedJobs.job_candidate.length > 0 ? (
          interestedJobs.job_candidate.map(({ job }) => (
            <div
              className="flex border-b border-lightgray"
              style={{
                paddingTop: 40,
                paddingBottom: 40,
                marginRight: 40,
                marginLeft: 40,
              }}>
              <div className="flex justify-center items-center" style={{ width: 130 }}>
                <div>
                  <img
                    src={job.company ? job.company.logoURL : job.subscriber.logoURL}
                    style={{ margin: 0, maxWidth: 60 }}
                  />
                </div>
              </div>
              <div className="flex flex-col text-left">
                <div
                  onClick={() => navigate(`/jobs/${job.id}`, { state: { jobID: job.id } })}
                  className="text-darkblue font-medium hover:underline cursor-pointer"
                  style={{ fontSize: 16 }}>
                  {job.title}
                </div>
                <div className="font-medium" style={{ fontSize: 14 }}>
                  {job.company ? job.company.name : job.subscriber.name}
                </div>
              </div>
              <div className="flex-1 flex justify-end">
                {!isAdmin &&
                  <button
                    onClick={() => {
                      setModal({
                        open: true,
                        data: {
                          candidate,
                          type: "phone",
                          job,
                        },
                      });
                    }}
                    className="border rounded border-green text-green font-medium"
                    style={{ padding: "10px 17px" }}>
                    Schedule Interview
                  </button>}
              </div>
            </div>
          ))
        ) : loading ? null : (
          <div style={{ padding: 40 }}>
            Not currently interested in any companies
          </div>
        )}
      </div>
    </React.Fragment>
  );
}