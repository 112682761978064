import React, { useEffect, useState } from 'react';
import EditCandidateForm from '../../forms/candidate/EditCandidateForm';

export default function EditCandidate({ location }) {
  const [candidate, setCandidate] = useState();

  useEffect(() => {
    if (location && location.state && location.state.candidate) {
      setCandidate(location.state.candidate);
    }
  }, []);

  return candidate ? (
    <div className="edit-candidate-js flex flex-col items-center h-full w-full">
      <div className="text-darkblue font-medium text-center" style={{ fontSize: 21, paddingTop: 40, marginBottom: 28 }}>
        Edit Candidate
      </div>
      <EditCandidateForm candidate={candidate} />
    </div>
  ) : (
    <div></div>
  );
}
