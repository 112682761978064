import React from "react";
import { Button } from "../../components/common";
import { Link, navigate } from "gatsby";

export default function DashboardWelcome() {
  return (
    <div
      className="flex justify-center items-center"
      style={{ minHeight: "calc(100vh - 156px)" }}
    >
      <div className="bg-white rounded shadow" style={{ padding: "30px 35px" }}>
        <div
          className="font-thin text-darkblue text-center"
          style={{ fontSize: 48 }}
        >
          Welcome
        </div>
        <div className="text-center mb-xl" style={{ fontSize: 14 }}>
          What would you like to do first?
        </div>
        <div className="flex flex-col">
          <Link to="/candidates/new-candidate">
            <Button color="red" width={310} height={55} mb={20}>
              <div className="text-lg mr-sm">+</div>
              Add Candidate
            </Button>
          </Link>
          <Button
            onClick={() => navigate("/jobs/new-job")}
            color="lightgray"
            width={310}
            height={55}
          >
            <div className="text-lg mr-sm">+</div>
            Create Job Posting
          </Button>
        </div>
      </div>
    </div>
  );
}
