import React from 'react';
import CandidateDashboard from '../candidate-dashboard';
import { REQUEST_STATUSES as STATUSES } from '../../../utils/constants';

const SavedCandidates = () => <CandidateDashboard
  BASE_FILTER={`where: {company_requests: {_and: {status: {_in: ["${STATUSES.approved}", "${STATUSES.accepted}"]}, company: {adminID: {_eq: $admin_id}}}}}`}
  searchPlaceholder="Name / Job title / Company"
  context="saved-candidates"
  showSavedCandidates={true}
/>;

export default SavedCandidates;
