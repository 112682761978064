import React, { useState } from 'react';

const AppDownloadedFilter = ({ value, update }) => {
  const [appdownloaded, toggleAppDownloaded] = useState(value);

  const setIslogin = (status) => {
    toggleAppDownloaded(status);
    return status;
  };

  return (
    <div className="flex items-center loc_preference_main" style={{ fontSize: 14, marginRight: 45, paddingBottom: 25, marginLeft: 10 }}>
      <div
        onClick={() => update(setIslogin(!appdownloaded))}
        className={`${appdownloaded ? 'bg-green' : ''} border border-green cursor-pointer`}
        style={{ width: 14, height: 14, marginRight: 10 }}
      />
      <div className="cursor-pointer font-medium">App Downloaded</div>
    </div>
  );
};

export default AppDownloadedFilter;
