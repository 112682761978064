import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

const ADD_JOB_NOTE = gql`
  mutation addJobNote($id: uuid, $notes: jsonb) {
    update_job(where: { id: { _eq: $id } }, _set: { notes: $notes }) {
      returning {
        notes
      }
      affected_rows
    }
  }
`;

export default function AdditionalNotes({ notes, jobID }) {
  const [saveNotes] = useMutation(ADD_JOB_NOTE);
  const textareaRef = useRef();
  const [value, setValue] = useState();
  const [jobNotes, setJobNotes] = useState(Array.isArray(notes) ? notes : JSON.parse(notes));
  const [textareaVisible, showTextArea] = useState(false);

  const isToday = (aDate) => {
    const today = new Date();
    const someDate = new Date(aDate);
    return someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear();
  };

  const addNote = () => {
    if (value) {
      const newNote = { date: new Date(), value };
      setJobNotes([newNote, ...jobNotes]);
      saveNotes({ variables: { id: jobID, notes: [newNote, ...jobNotes] } });
    }
  };

  useEffect(() => {
    if (textareaVisible) {
      textareaRef.current.focus();
    }
  }, [showTextArea]);

  return (
    <div className="w-full mt-lg">
      <div className="flex items-center mb-md">
        <div className="darkblue mr-md" style={{ fontSize: 18 }}>
          Additional Notes
        </div>
        <div className="border-b flex-1" />
        <div
          onClick={() => {
            showTextArea(true);
          }}
          className="text-green flex cursor-pointer items-center font-medium ml-md"
        >
          <span className="text-lg mr-sm">+</span>Add Notes
        </div>
      </div>
      <textarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Enter a note about this job"
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            showTextArea(false);
            addNote();
            setValue('');
          }
        }}
        onBlur={() => {
          showTextArea(false);
          addNote();
          setValue('');
        }}
        style={{ display: textareaVisible ? 'block' : 'none', minHeight: 100, padding: 15 }}
        ref={textareaRef}
        className="w-full border rounded mb-lg"
      />
      {jobNotes.map((note, index) => (
        <div key={index} className="text-darkgray mb-lg">
          <div className="text-darkblue font-medium mb-sm">{moment(note.date).format(`${isToday(note.date) ? 'h:mm a' : 'MMM. D'}`)}</div>
          {note.value}
        </div>
      ))}
    </div>
  );
}
