import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';
import CherryPickerLogo from '../../../images/loading-logo.png';
import { formatSalary } from '../../../utils/formatter';

const POSTER_PATH = 'https://image.tmdb.org/t/p/w154';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    paddingTop: 50,
    paddingBottom: 50,
  },
  item: {
    width: '100%',
    fontWeight: 500,
    flexDirection: 'column',
    display: 'inline-block',
    marginBottom: -10,
  },
  item_name: {
    textTransform: 'capitalize',
    opacity: 1,
    paddingBottom: 11,
    alignItems: 'center',
    display: 'block',
    fontSize: '0.875',
  },
  item_value: {
    color: '#555',
    paddingBottom: 10,
    paddingRight: 24,
    marginRight: 5,
    fontFamily: 'open-sans',
    fontSize: 16,
  },
  item_value_n: {
    color: '#111',
    paddingBottom: 30,
    paddingRight: 24,
    fontSize: 15,
  },
  mainclass: {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    width: '100%',
    position: 'relative',
    padding: 22,
    display: 'block',
    marginTop: 20,
    backgroundColor: 'rgba(255, 255, 255, var(--bg-opacity))',
  },
  heading: {
    background: '#f1f1f1',
    padding: 10,
    borderRadius: 8,
    width: '100%',
    position: 'relative',
    display: 'block',
    textAlign: 'center',
    fontWeight: 600,
    marginBottom: 15,
  },
  image: {
    width: '12%',
    marginTop: -30,
  },
  centerImage: {
    alignItems: 'center',
    flexGrow: 1,
    marginTop: -30,
  },
});

export function DownloadProfileData({ data, selectedFields }) {
  // function returnSrc() {
  //     const path = "https://firebasestorage.googleapis.com/v0/b/cherry-picker-a3314.appspot.com/o/images%2Favatars%2FUl_hxz9DM6o.jpg?alt=media&token=61757bd4-6455-46aa-b526-85722dfbf0c5";
  //     const selectedMethod = 'GET';
  //     return { uri: path, method: selectedMethod, body: '', headers: '' };
  // }

  return (
    <Document>
      <Page style={styles.page}>
        {selectedFields.length > 0 ? (
          <View style={styles.mainclass}>
            <View style={styles.centerImage}>
              <Image style={styles.image} source={CherryPickerLogo} />
            </View>
            {selectedFields.includes('name') && (
              <View>
                <View style={styles.item}>
                  <Text style={styles.item_value_n}>
                    <Text style={{ color: 'darkgray' }}>Name:</Text> {data.name}
                  </Text>
                </View>
              </View>
            )}
            {selectedFields.includes('email') && (
              <View>
                <View style={styles.item}>
                  <Text style={styles.item_value_n}>
                    <Text style={{ color: 'darkgray' }}>Email:</Text> {data.email}
                  </Text>
                </View>
              </View>
            )}
            {selectedFields.includes('phone') && (
              <View>
                <View style={styles.item}>
                  <Text style={styles.item_value_n}>
                    <Text style={{ color: 'darkgray' }}>Phone:</Text> {data.phone}
                  </Text>
                </View>
              </View>
            )}
            {selectedFields.includes('linkedin') && (
              <View>
                <View style={styles.item}>
                  <Text style={styles.item_value_n}>
                    <Text style={{ color: 'darkgray' }}>LinkedIn:</Text> {data.linkedin ? data.linkedin : 'N/A'}
                  </Text>
                </View>
              </View>
            )}
            {selectedFields.includes('referralName') && (
              <View>
                <View style={styles.item}>
                  <Text style={styles.item_value_n}>
                    <Text style={{ color: 'darkgray' }}>Referral Name:</Text> {data.referralName ? data.referralName : 'N/A'}
                  </Text>
                </View>
              </View>
            )}
            {selectedFields.includes('recruiterName') && (
              <View>
                <View style={styles.item}>
                  <Text style={styles.item_value_n}>
                    <Text style={{ color: 'darkgray' }}>Recruiter Name:</Text> {data.recruiter ? data.recruiter.name : 'N/A'}
                  </Text>
                </View>
              </View>
            )}
            {selectedFields.includes('location') && (
              <View>
                <View style={styles.item}>
                  <Text style={styles.item_value_n}>
                    <Text style={{ color: 'darkgray' }}>Location:</Text> {data.location ? data.location.city : 'N/A'}
                  </Text>
                </View>
              </View>
            )}
            {selectedFields.includes('salary') && (
              <View>
                <View style={styles.item}>
                  <Text style={styles.item_value_n}>
                    <Text style={{ color: 'darkgray' }}>Salary:</Text> {data.salaryMin ? `${formatSalary({ salary: data.salaryMin })}+` : 'Negotiable'}
                  </Text>
                </View>
              </View>
            )}
            {selectedFields.includes('experience') && (
              <View>
                <View style={styles.item}>
                  <Text style={styles.item_value_n}>
                    <Text style={{ color: 'darkgray' }}>Experience:</Text> {data.experience ? data.experience : 0} years
                  </Text>
                </View>
              </View>
            )}
            {selectedFields.includes('bio') && (
              <View>
                <View style={styles.item}>
                  <Text style={{ color: 'darkgray', fontSize: 15 }}>Short Bio:</Text>
                  <Text style={styles.item_value_n}>{data.bio ? data.bio.replace(/<[^>]+>/g, '') : 'N/A'}</Text>
                </View>
              </View>
            )}

            {selectedFields.includes('education') && (
              <View>
                <View style={styles.item}>
                  <Text style={{ color: 'darkgray', fontSize: 15 }}>Education:</Text>
                  <View>
                    {data.education &&
                      data.education.map((edu) => (
                        <View>
                          <Text style={{ position: 'relative', display: 'block', fontSize: 15 }}>{edu.degree}</Text>
                          <Text style={{ position: 'relative', display: 'block', fontSize: 10, marginBottom: 5 }}>{edu.school}</Text>
                        </View>
                      ))}
                  </View>
                </View>
              </View>
            )}
            {selectedFields.includes('attributes') && (
              <View>
                <View style={styles.item}>
                  <Text style={{ color: 'darkgray', fontSize: 15, marginTop: 20 }}>Candidate Attributes : </Text>
                  <Text>
                    {data.education &&
                      data.attributes.map((attr) => (
                        <Text
                          style={{
                            position: 'relative',
                            display: 'inline-block',
                            fontSize: 12,
                            padding: 4,
                            borderRadius: 10,
                            marginLeft: 5,
                          }}
                        >
                          {attr + ', '}
                        </Text>
                      ))}
                  </Text>
                </View>
              </View>
            )}
            {selectedFields.includes('locationPreferences') && (
              <View>
                <View style={styles.item}>
                  <Text style={{ color: 'darkgray', fontSize: 15, marginTop: 20 }}>Location Preferences:</Text>
                  <Text>
                    {data.locationPreferences.remote && (
                      <Text
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          fontSize: 12,
                          padding: 4,
                          borderRadius: 10,
                          marginLeft: 5,
                        }}
                      >
                        Remote,
                      </Text>
                    )}
                    {data.locationPreferences.currentLocation && (
                      <Text
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          fontSize: 12,
                          padding: 4,
                          borderRadius: 10,
                          marginLeft: 5,
                        }}
                      >
                        Current Location,
                      </Text>
                    )}
                    {data.locationPreferences.willingToRelocate && (
                      <Text
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                          fontSize: 12,
                          padding: 4,
                          borderRadius: 10,
                          marginLeft: 5,
                        }}
                      >
                        Willing To Relocate
                      </Text>
                    )}
                  </Text>
                </View>
              </View>
            )}

            {selectedFields.includes('interestedCity') && (
              <View>
                <View style={styles.item}>
                  <Text style={{ color: 'darkgray', fontSize: 15, marginTop: 20 }}>Interested City:</Text>
                  <Text>
                    {data.interestedCity &&
                      data.interestedCity.map((intr) => (
                        <Text
                          style={{
                            position: 'relative',
                            display: 'inline-block',
                            fontSize: 12,
                            background: 'lightgray',
                            padding: 4,
                            borderRadius: 10,
                            marginLeft: 5,
                          }}
                        >
                          {intr.city + ' | '}
                        </Text>
                      ))}
                  </Text>
                </View>
              </View>
            )}
            {selectedFields.includes('currEmployment') && (
              <View>
                <View
                  style={{
                    width: '100%',
                    fontWeight: 500,
                    flexDirection: 'column',
                    display: 'inline-block',
                    marginBottom: -10,
                    marginTop: 20,
                  }}
                >
                  <Text style={{ color: 'darkgray', fontSize: 15 }}>Current Employment:</Text>
                  <View>
                    {data.employment &&
                      data.employment.map(
                        (emp) =>
                          emp.tenure.current && (
                            <View>
                              <Text style={{ position: 'relative', display: 'block', fontSize: 12 }}>Title: {emp.title}</Text>
                              <Text style={{ position: 'relative', display: 'block', fontSize: 12, marginBottom: 5 }}>Company: {emp.companyName}</Text>
                              <Text style={{ position: 'relative', display: 'block', fontSize: 12, marginBottom: 5 }}>Location: {emp.companyLocation}</Text>
                              <Text style={{ position: 'relative', display: 'block', fontSize: 12, marginBottom: 5 }}>
                                Description: {emp.description ? emp.description.replace(/<[^>]+>/g, '') : 'N/A'}
                              </Text>
                              <Text style={{ position: 'relative', display: 'block', fontSize: 12, marginBottom: 5 }}>
                                Tenure: {moment(emp.tenure.startDate).format('MMM YYYY')} - {'Present'}
                              </Text>
                              <Text>
                                --------------------------------------------------------------------------------------------------------------------------------------------------------
                              </Text>
                            </View>
                          ),
                      )}
                  </View>
                </View>
              </View>
            )}
            {// const startDate = moment(entry.tenure.startDate).format("MMM YYYY");
            // const endDate = moment(entry.tenure.endDate).format("MMM YYYY")

            selectedFields.includes('prevEmployment') && (
              <View>
                <View
                  style={{
                    width: '100%',
                    fontWeight: 500,
                    flexDirection: 'column',
                    display: 'inline-block',
                    marginBottom: -10,
                    marginTop: 20,
                  }}
                >
                  <Text style={{ color: 'darkgray', fontSize: 15 }}>Previous Employment:</Text>
                  <View>
                    {data.employment &&
                      data.employment.map(
                        (emp) =>
                          !emp.tenure.current && (
                            <View>
                              <Text style={{ position: 'relative', display: 'block', fontSize: 12 }}>Title: {emp.title}</Text>
                              <Text style={{ position: 'relative', display: 'block', fontSize: 12, marginBottom: 5 }}>Company: {emp.companyName}</Text>
                              <Text style={{ position: 'relative', display: 'block', fontSize: 12, marginBottom: 5 }}>Location: {emp.companyLocation}</Text>
                              <Text style={{ position: 'relative', display: 'block', fontSize: 12, marginBottom: 5 }}>
                                Description: {emp.description ? emp.description.replace(/<[^>]+>/g, '') : 'N/A'}
                              </Text>
                              <Text style={{ position: 'relative', display: 'block', fontSize: 12, marginBottom: 5 }}>
                                Tenure: {moment(emp.tenure.startDate).format('MMM YYYY')} -{' '}
                                {emp.tenure.current == 'true' ? 'Present' : moment(emp.tenure.endDate).format('MMM YYYY')}
                              </Text>
                              <Text>
                                --------------------------------------------------------------------------------------------------------------------------------------------------------
                              </Text>
                            </View>
                          ),
                      )}
                  </View>
                </View>
              </View>
            )}
          </View>
        ) : (
          <View style={styles.mainclass}>
            <View>No field selected, Please select fields from above dropdown</View>
          </View>
        )}
      </Page>
    </Document>
  );
}
