import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from 'react-loader-spinner';
import { CSVDownloader } from 'react-papaparse';
import { TabTitle } from '../../components/common';
import './candidates.css';

const ModalContext = React.createContext();

const ALL_ACTIVITY = gql`
  subscription getCreditActivity($recruiter_id: String, $start_date: date, $end_date: date) {
    company_request(where: { status: { _eq: "approved" }, candidate: { recruiter_id: { _eq: $recruiter_id } }, date: { _gte: $start_date, _lte: $end_date } }) {
      candidate_id
      company_id
      status
      credit_amount
      date
      candidate {
        id
        name
        email
        created_at
      }
      company {
        id
        name
        email
      }
    }
  }
`;
const ActivityHeading = () => {
  return (
    <div
      className="flex items-center border-b"
      style={{
        height: 100,
        opacity: 1,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Date'}
        </div>
      </div>
      <div
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          width: 350,
        }}
      >
        <div className="flex flex-col">
          <div className="lg:p-0 text-darkblue lg:text-sm" style={{ fontWeight: 'bold' }}>
            {'Company Subscriber Company Name'}
          </div>
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 350 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Candidate Name - Credit Used on'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 350 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Revenue generated from Credit'}
        </div>
      </div>
    </div>
  );
};
export { ActivityHeading };
export { SingleActivity };

const SingleActivity = ({ activity }) => {
  return (
    <div
      className="flex items-center border-b"
      style={{
        height: 100,
        opacity: 1,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {Moment(activity.date).format('MM/DD/YYYY')}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 350 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.company ? activity.company.name : 'N/A'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 350 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.candidate ? activity.candidate.name : 'N/A'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 350 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {'$ ' + 0.5 * activity.credit_amount}
        </div>
      </div>
    </div>
  );
};

export default function RecruitercCreditActivity () {
  const [modal, setModal] = useState({ data: null, open: false });
  const [activityData, setActivityData] = useState();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  const { data: activityResponse, loading } = useSubscription(ALL_ACTIVITY, {
    variables: {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
      start_date: startDate != null ? Moment(startDate).format('YYYY-MM-DD') : null,
      end_date: Moment(endDate).format('YYYY-MM-DD'),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (activityResponse) {
      setActivityData(activityResponse.company_request);
    }
  }, [activityResponse]);

  const csv_data = activityData
    ? activityData.map((activity) => {
      return {
        Date: Moment(activity.candidate.created_at).format('MM/DD/YYYY'),
        'Company Subscriber Company Name': activity.company ? activity.company.name : 'N/A',
        'Candidate Name - Credit Used on': activity.candidate ? activity.candidate.name : 'N/A',
        'Revenue generated from Credit ': '$ ' + 0.5 * activity.credit_amount,
      };
    })
    : [];

  return (
    <div className="w-full flex-1 candidates">
      <ModalContext.Provider value={[modal, setModal]}>
        <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
          <TabTitle showBackButton={true}>{'Recruiter Credit Activity'}</TabTitle>
          {activityData && activityData.length > 0 && (
            <CSVDownloader
              data={csv_data}
              type="anchor"
              filename={'subscriber_activity'}
              className={`bg-red text-white font-medium rounded flex items-center justify-center`}
              style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
            >
              <span style={{ fontSize: 22, marginRight: 8 }}></span> Download CSV
            </CSVDownloader>
          )}
        </div>
        <div className="h-full flex flex-col" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
          <div style={{ marginRight: 20 }}>
            <span>Start Date: </span>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={Moment(new Date()).toDate()} />
          </div>
          <div>
            <span>End Date: </span>
            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={Moment(new Date()).toDate()} />
          </div>
        </div>
        <div className="flex-1 flex flex-col pb-lg relative">
          <div className={`flex-1 bg-white rounded shadow candidates-container`}>
            <ActivityHeading />
          </div>
        </div>
        {loading ? (
          <div style={{ marginLeft: 600, marginTop: 100 }}>
            <Loader type="TailSpin" color="#E31C25" height={50} width={50} />
          </div>
        ) : (
          <div className="flex-1 flex flex-col pb-lg relative">
            <div className={`flex-1 bg-white rounded shadow candidates-container candidate-blank`} style={{ overflowX: 'auto' }}>
              {activityData && activityData.length > 0 ? (
                activityData.map((activity) => <SingleActivity key={activity.id} activity={activity} />)
              ) : (
                <div
                  className={`flex justify-center items-center flex-1 candidate-blank
                                    }`}
                >
                  <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                    No data found
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </ModalContext.Provider>
    </div>
  );
}
