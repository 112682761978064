import React, { useState, useEffect, useContext } from "react";
import TrashIcon from "../jobs/TrashIcon";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { ConfirmModalContext } from "../../context";
import { navigate } from "gatsby";
import EditIcon from "../../images/edit.svg";

// graphql query to delete company on the basis of id
const DELETE_ATTRIBUTE = gql`
  mutation removeSkill($name: String) {
    delete_skill(where: { name: { _eq: $name } }) {
      affected_rows
      returning {       
        name
        category
      }
    }
  }
`;

export default function AttributeEntry({ attribute }) {
  const [modal, setModal] = useContext(ConfirmModalContext);
  const [deleteAttribute, { data, error }] = useMutation(DELETE_ATTRIBUTE);

  function removeAttribute() {
    deleteAttribute({
      variables: { name: attribute.name }
    });
  }

  const onClickTrashCan = () => {
    setModal({
      open: true,
      title: `Confirm Attribute Removal`,
      message: `Are you sure you want to remove the skill ${attribute.name}?`,
      buttonText: "Remove Attribute",
      action: removeAttribute,
    });
  };

  useEffect(() => {
    if (data) {
      setModal({
        loading: false,
        open: false,
      });
      navigate(`/dashboard/attributes`)
    }
  }, [data]);

  return (
    <div className="px-md lg:px-xl">
      <div className="flex items-center border-b"
        style={{ opacity: 1, height: 100 }}>
        <div className="flex flex-col" style={{ width: 300 }}>
          <div
            className="lg:p-0 lg:text-sm"
            style={{ fontWeight: 500 }}
          >
            {attribute.name}
          </div>
        </div>
        <div
          className="flex justify-center" style={{ width: 300 }}>
          <div
            className="font-medium text-darkgray flex items-center"
            style={{ fontWeight: 14 }}>
            {attribute.category}
          </div>
        </div>
        <TrashIcon onClick={onClickTrashCan} />
        <img
          onClick={() =>
            navigate(`/attributes/${attribute && attribute.id}/edit`, {
              state: { attribute },
            })
          }
          className="cursor-pointer"
          src={EditIcon}
          style={{ width: 40, marginBottom: 0 }}
        />
      </div>
    </div>
  );
}
