import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import InterestedCitiesField from '../fields/InterestedCitiesField';
import SalaryRange from '../fields/SalaryRange';

const LocationCheckbox = ({ name, update }) => {
  const [checked, toggleChecked] = useState(false);
  useEffect(() => {
    update(checked);
  }, [checked]);
  return (
    <div
      className="flex items-center"
      style={{
        fontSize: 14,
        marginRight: name === 'currentLocation' ? 45 : 0,
        paddingBottom: name === 'willingToRelocate' ? 0 : 25,
      }}
    >
      <div
        onClick={() => toggleChecked(!checked)}
        className={`${checked ? 'bg-green' : ''} border border-green cursor-pointer`}
        style={{
          width: 14,
          height: 14,
          marginRight: 10,
        }}
      />
      <div className="cursor-pointer font-medium">
        {name === 'currentLocation' && 'Current Location'}
        {name === 'willingToRelocate' && 'Willing to Relocate'}
        {name === 'remote' && 'Remote'}
      </div>
    </div>
  );
};

export default function CandidatePreferencesForm({ globalFormData, goBack, onSubmit }) {
  const [loading, setLoading] = useState(false);
  const { candidate } = globalFormData;
  const [error, setError] = useState('');

  const initialValues = {
    currentLocation: false,
    remote: false,
    willingToRelocate: false,
  };

  const [salary, setSalary] = useState([0, 0]);
  const [locationPreferences, setLocationPreferences] = useState(initialValues);
  const [interestedCity, setInterestedCity] = useState();

  const createCandidate = (candidate) => {
    const locationPreferencesEmpty = Object.values(locationPreferences).filter((val) => !val).length === 3;
    const noInterestedCity = locationPreferences.willingToRelocate && !interestedCity;
    if (locationPreferencesEmpty || noInterestedCity) {
      setError(locationPreferencesEmpty ? 'Please select one or more location preferences' : 'Please choose a city that the candidate is interested in relocating to');
    } else {
      onSubmit(candidate, setLoading);
    }
  };

  return (
    <React.Fragment>
      <div className="relative" style={{ width: 310 }}>
        <SalaryRange value={salary} update={setSalary} />
        <div className="absolute right-0 font-medium" style={{ fontSize: 12, color: '#c4cad3', top: 15 }}>
          OPTIONAL
        </div>
      </div>
      <div className="font-medium text-darkblue" style={{ fontSize: 16, marginTop: 45, marginBottom: 15 }}>
        Location Preference
      </div>
      <div className="flex flex-wrap" style={{ width: 310, marginTop: 15 }}>
        {Object.entries(initialValues).map(([label]) => {
          return (
            <LocationCheckbox
              update={(bool) =>
                setLocationPreferences({
                  ...locationPreferences,
                  [label]: bool,
                })
              }
              name={label}
            />
          );
        })}
      </div>

      {locationPreferences.willingToRelocate && (
        <div className="w-full">
          <InterestedCitiesField value={interestedCity} update={setInterestedCity} />
        </div>
      )}

      <div className="flex relative justify-center flex-col" style={{ marginBottom: 32, marginTop: 30 }}>
        <div className="w-full">
          {error && (
            <div style={{ maxWidth: 320.11 }} className="text-red w-full font-medium text-xs mb-md text-center">
              {error}
            </div>
          )}
        </div>
        <div className="flex justify-center" style={{ height: 55 }}>
          <button onClick={() => goBack()} className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray" style={{ width: 137, fontSize: 14 }}>
            Cancel
          </button>
          <button
            onClick={() => {
              if (locationPreferences.willingToRelocate) {
                createCandidate({
                  ...candidate,
                  salary,
                  locationPreferences,
                  interestedCity,
                });
              } else {
                createCandidate({
                  ...candidate,
                  salary,
                  locationPreferences,
                });
              }
            }}
            className="rounded font-medium text-sm bg-red text-white"
            style={{
              width: 157,
              fontSize: 14,
              boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)',
            }}
          >
            {loading ? <Loader type="TailSpin" className="flex justify-center" color="#FFFFFF" height={20} width={20} /> : 'Save & Continue'}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
