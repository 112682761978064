import React, { useEffect } from 'react'
import LocationField from "./LocationField";
import AddGreen from '../../images/add-green.png'

const InterestedCityEntry = ({value, update, deleteEntry}) => {
    return(
        <div className='flex items-center'>
            <LocationField
                isProfile={true}
                value={value} 
                update={update}/>
            <div 
                onClick={deleteEntry}
                className='ml-md font-medium px-sm relative cursor-pointer rounded hover:bg-red hover:text-white' style={{top: 8}} >
                X
            </div>
        </div>)
}

export default function InterestedCitiesField({value, update}) {
    const InterestedCities = [value ? value : {city: null, lat: null, lng: null}]

    useEffect(() => {
        if(!value) {
            update([{city: null, lat: null, lng: null}])
        }else if(!Array.isArray(value)) {
            update([value])
        }
    }, [value])

    return (
        <div className='flex flex-col'>
            {(!Array.isArray(value) ? InterestedCities : value).map((city, i) => {
                return(
                    <InterestedCityEntry
                        key={city.city ? city.city : i}
                        value={city} 
                        update={newLocation => {
                            if(Array.isArray(value)) {
                                const valueCopy = [...value]
                                valueCopy[i] = newLocation
                                update(valueCopy)
                            } else {
                                update([newLocation])
                            }
                        }}
                        deleteEntry={() => {
                            const valueCopy = [...value]
                            valueCopy.splice(i, 1)
                            update(valueCopy)
                        }} 
                        />
                )
            })}
            <div className='flex justify-center mt-md'>
                <div 
                    onClick={() => update([...value, {city: null, lat: null, lng: null}])}
                    className='cursor-pointer flex items-center bg-green px-md py-sm rounded'>
                    <img style={{height: 30, width: 30, margin: 0}} src={AddGreen}/>
                    <div className='text-sm font-medium text-white ml-sm'>
                        Add Interested City
                    </div>
                </div>
            </div>
        </div>
    )
}
