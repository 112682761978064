import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from 'react-loader-spinner';
import { CSVDownloader } from 'react-papaparse';
import { TabTitle } from '../../components/common';
import { formatSalary } from '../../utils/formatter';
import './candidates.css';

const ModalContext = React.createContext();

const ALL_ACTIVITY = gql`
  subscription getJobOutput($recruiter_id: String, $start_date: date, $end_date: date) {
    job_candidate(where: { job: { recruiter_id: { _eq: $recruiter_id }, created_date: { _gte: $start_date, _lte: $end_date } } }, order_by: { job: { created_at: desc } }) {
      candidate_id
      job_id
      status
      created_at
      candidate {
        id
        name
        email
      }
      job {
        id
        title
        location
        salaryMin
        salaryMax
        company {
          id
          name
        }
        created_at
        created_date
      }
    }
  }
`;

const ActivityHeading = () => {
  return (
    <div
      className="flex items-center border-b"
      style={{
        height: 100,
        opacity: 1,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Date Posted'}
        </div>
      </div>
      <div
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          width: 300,
        }}
      >
        <div className="flex flex-col">
          <div className="lg:p-0 text-darkblue lg:text-sm" style={{ fontWeight: 'bold' }}>
            {'Company Name'}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14 }}>
            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
              {'Job Title | '}
            </div>
            <div className="mr-sm text-darkblue" style={{ fontWeight: 'bold' }}>
              {'Job Location'}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center" style={{ width: 170 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Job Salary Range'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 170 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Candidate Name - Matched'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 250 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Candidate Email - Matched'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 250 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Candidate Email - Interested'}
        </div>
      </div>
    </div>
  );
};
export { ActivityHeading };
export { SingleActivity };

const SingleActivity = ({ activity }) => {
  return (
    <div
      className="flex items-center border-b"
      style={{
        height: 100,
        opacity: 1,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <div className="flex justify-center" style={{ width: 150 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {Moment(activity.job.created_date).format('MM/DD/YYYY')}
        </div>
      </div>
      <div
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          width: 300,
        }}
      >
        <div className="flex flex-col">
          <div className="lg:p-0 lg:text-sm" style={{ fontWeight: 500 }}>
            {activity.job.company ? activity.job.company.name : 'N/A'}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14 }}>
            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
              {activity.job.title + ' |'}
            </div>
            <div className="mr-sm text-darkgray">{activity.job.location ? activity.job.location.city : 'N/A'}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 170 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {`${formatSalary({ salary: activity.job.salaryMin })} - ${formatSalary({ salary: activity.job.salaryMax })}`}
        </div>
      </div>

      <div className="flex justify-center" style={{ width: 170 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.candidate.name}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 250 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.candidate.email}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 250 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.status.includes('interested') ? activity.candidate.email : 'N/A'}
        </div>
      </div>
    </div>
  );
};

export default function RecruiterJobOutput ({ isAdmin, inProcess, isDashboard }) {
  const [modal, setModal] = useState({ data: null, open: false });
  const [activityData, setActivityData] = useState();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  const { data: activityResponse, loading } = useSubscription(ALL_ACTIVITY, {
    variables: {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
      start_date: startDate != null ? Moment(startDate).format('YYYY-MM-DD') : null,
      end_date: Moment(endDate).format('YYYY-MM-DD'),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (activityResponse) {
      setActivityData(activityResponse.job_candidate);
    }
  }, [activityResponse]);

  const csv_data = activityData
    ? activityData.map((activity) => {
      return {
        'Date Posted': Moment(activity.job.created_date).format('MM/DD/YYYY'),
        'Company Name': activity.job.company ? activity.job.company.name : 'N/A',
        'Job Title': activity.job.title,
        'Job Location': activity.job.location ? activity.job.location.city : 'N/A',
        'Job Salary Range': `${formatSalary({ salary: activity.job.salaryMin })} - ${formatSalary({ salary: activity.job.salaryMax })}`,
        'Candidate Name - Matched': activity.candidate.name,
        'Candidate Email - Matched': activity.candidate.email,
        'Candidate Email - Interested': activity.status.includes('interested') ? activity.candidate.email : 'N/A',
      };
    })
    : [];

  return (
    <div className="recruiter-job-output-js w-full flex-1 candidates">
      <ModalContext.Provider value={[modal, setModal]}>
        <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
          <TabTitle showBackButton={true}>{'Recruiter Job Output'}</TabTitle>
          {activityData && activityData.length > 0 && (
            <CSVDownloader
              data={csv_data}
              type="anchor"
              filename={'recruiter_job_output'}
              className={`bg-red text-white font-medium rounded flex items-center justify-center`}
              style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
            >
              <span style={{ fontSize: 22, marginRight: 8 }}></span> Download CSV
            </CSVDownloader>
          )}
        </div>
        <div className="h-full flex flex-col" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
          <div style={{ marginRight: 20 }}>
            <span>Start Date: </span>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={Moment(new Date()).toDate()} />
          </div>
          <div>
            <span>End Date: </span>
            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={Moment(new Date()).toDate()} />
          </div>
        </div>
        <div className="flex-1 flex flex-col pb-lg relative">
          <div className={`flex-1 bg-white rounded shadow candidates-container`}>
            <ActivityHeading />
          </div>
        </div>
        {loading ? (
          <div style={{ marginLeft: 600, marginTop: 100 }}>
            <Loader type="TailSpin" color="#E31C25" height={50} width={50} />
          </div>
        ) : (
          <div className="flex-1 flex flex-col pb-lg relative">
            <div className={`flex-1 bg-white rounded shadow candidates-container candidate-blank`} style={{ overflowX: 'auto' }}>
              {activityData && activityData.length > 0 ? (
                activityData.map((activity) => <SingleActivity key={activity.id} activity={activity} isAdmin={isAdmin} />)
              ) : (
                <div
                  className={`flex justify-center items-center flex-1 candidate-blank
                                    }`}
                >
                  <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                    No data found
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </ModalContext.Provider>
    </div>
  );
}
