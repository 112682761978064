import React from "react";

export default function FieldTitle({ title, marginTop }) {
  return (
    <div
      className="text-darkblue w-full flex items-center font-medium flex justify-between"
      style={{
        fontSize: 18,
        marginTop: marginTop ? marginTop : 40,
        fontWeight: 400,
      }}
    >
      <div>{title}</div>
      <div className="flex-1 border-b ml-md" />
    </div>
  );
}
