import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';
import { debugProd } from '../../config';
import { UserRoleContext } from '../../context';
import PhoneInput from '../fields/PhoneInput';
import RecruiterSelect from '../fields/RecruiterSelect';
import ResumeUpload from '../fields/ResumeUpload';
import TextInput from '../fields/TextInput';
import Form from '../Form';
import Resumes from '../Resumes';

const debugForm = debugProd;

const GET_RECRUITER_NAME = gql`
  query getRecruiterName($recruiter_id: String) {
    recruiter(where: { id: { _eq: $recruiter_id } }) {
      name
    }
  }
`;

const ResumeSelect = ({ update }) => {
  const resumes = Resumes;
  const setResume = update;
  const resumeArray = Object.values(resumes);

  return (
    <React.Fragment>
      <div className="text-sm font-medium mt-sm text-darkblue text-center">Rchilli Parsed Resume Select</div>
      <select
        className="w-full px-sm border text-sm px-md mt-sm bg-white cursor-pointer"
        style={{ height: 55, width: 320, WebkitAppearance: 'none', borderColor: 'rgb(196, 202, 211)' }}
        onChange={(e) => {
          const value = e.target.value;
          const selectedResume = resumeArray[parseInt(value)];
          setResume(selectedResume);
        }}
      >
        {resumeArray.map((resume, index) => (
          <option value={index}>{resume.FullName}</option>
        ))}
      </select>
    </React.Fragment>
  );
};

const CANDIDATES_BY_EMAIL = gql`
  query candidateByEmail($email: String) {
    candidate(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

export default function CandidateProfileForm({ globalFormData, goForward, goBack }) {
  const { setResume, setCandidate } = globalFormData;
  const { data: recruiterName } = useQuery(GET_RECRUITER_NAME, { variables: { recruiter_id: firebase.auth().currentUser.uid } });
  const [findCandidateByEmail, { data: candidateEmailData }] = useLazyQuery(CANDIDATES_BY_EMAIL);
  const [loading, setLoading] = useState(false);
  const [candidateProfile, setCandidateProfile] = useState();
  const [errors, setErrors] = useState({});
  const [userRole] = useContext(UserRoleContext);
  const [fields, setFields] = useState();
  const isAdmin = userRole === 'admin';

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // there's another parseResume function in CandidateFormUtils.js
  async function parseResume(resume) {
    const resumeBase64 = await toBase64(resume);
    const config = {
      filedata: resumeBase64.substring(resumeBase64.indexOf('base64,') + 7, resumeBase64.length),
      filename: resume.name,
      userkey: 'L521RZNQSDX',
      version: '7.0.0',
      subuserid: 'CherryPicker',
    };

    return fetch('https://rest.rchilli.com/RChilliParser/Rchilli/parseResumeBinary', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(config),
    }).then((res) => res.json());
  }

  async function continueCandidateCreation() {
    const parsedResume = debugForm ? { ResumeParserData: candidateProfile.resume } : await parseResume(candidateProfile.resume);

    setResume(parsedResume.ResumeParserData);
    setCandidate(candidateProfile);
    goForward();
    setLoading(false);
  }

  const onSubmit = async (values) => {
    setLoading(true);
    const { email } = values;
    setCandidateProfile(values);
    firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then((signInMethods) => {
        if (signInMethods.length === 0) {
          findCandidateByEmail({ variables: { email } });
        } else {
          setLoading(false);
          setErrors({ email: 'A candidate with the email address you provided already exists. Please use a different email address or click Cancel to go back.' });
        }
      })
      .catch();
  };

  useEffect(() => {
    if (candidateEmailData) {
      if (candidateEmailData.candidate.length > 0) {
        setErrors({ email: 'A candidate with the email address you provided already exists. Please use a different email address or click Cancel to go back.' });
      } else {
        continueCandidateCreation();
      }
    }
  }, [candidateEmailData]);

  useEffect(() => {
    if (recruiterName) {
      const allFields = {
        // profilePhoto: {
        //   value: "",
        //   placeholder: "Profile Photo",
        //   component: PhotoUpload
        // },
        name: {
          value: '',
          component: TextInput,
          validation: Yup.string().required('Candidate name is required'),
        },
        email: {
          value: '',
          component: TextInput,
          validation: Yup.string()
            .email('A valid email is required')
            .required('Candidate email is required'),
        },
        phone: {
          value: '',
          component: PhoneInput,
          validation: Yup.mixed().test({
            name: 'Phone',
            message: 'Please enter a valid phone number',
            test: (value) => {
              return value && value.length === 14;
            },
          }),
        },
        resume: {
          value: '',
          component: debugForm ? ResumeSelect : ResumeUpload,
          validation: Yup.mixed().required('A valid candidate resume is required.'),
        },
        referralName: {
          value: '',
          component: TextInput,
          placeholder: 'Referral Name',
          optional: true,
        },
      };

      if (isAdmin) {
        setFields({ ...allFields, recruiter: { value: '', component: RecruiterSelect, placeholder: 'Select Recruiter' } });
      } else {
        setFields(allFields);
      }
    }
  }, [recruiterName]);

  return !fields ? null : (
    <Form fields={fields} onSubmit={onSubmit} customErrors={errors}>
      <div className="flex relative justify-between" style={{ height: 55, marginTop: 60 }}>
        <button onClick={() => goBack()} type="button" className="rounded border font-medium text-sm bg-lightgray text-darkgray" style={{ width: '49%', fontSize: 14 }}>
          Cancel
        </button>
        <button type="submit" className="rounded font-medium text-sm bg-red text-white" style={{ width: '49%', fontSize: 14, boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)' }}>
          {loading ? <Loader className="flex justify-center" type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Save & Continue'}
        </button>
      </div>
    </Form>
  );
}
