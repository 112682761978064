import React from 'react';
import CheckIcon from '../../../images/check-solid.svg';
import CloseIcon from '../../../images/times-solid.svg';
import EyeIcon from '../../../images/eye-solid.svg';
import UserAvatar from '../../../images/user-avatar.png';
import LinkIcon from '../../../images/link.png';
import ProfileChangeUI from './ProfileChangeUI';
import { ViewResumeButton } from '../view-resume-button';

export default function CandidateDetailsHeader(props) {
  const { changes, recruiterUser, approveProfileChangeRequest, declineProfileChangeRequest, companyUser, mutualInterest, candidate, approvedChanges } = props;

  return (
    <div className="flex w-full items-center mb-xl">
      {changes.profilePictureURL && recruiterUser && (
        <>
          <img alt="" className="absolute" src={EyeIcon} style={{ width: 17, height: 17, marginBottom: 0, top: -20, left: 32 }} />
          <div className="flex ml-md absolute" style={{ top: 87, left: 3 }}>
            <img
              alt=""
              onClick={() => approveProfileChangeRequest('profilePictureURL')}
              src={CheckIcon}
              style={{ cursor: 'pointer', position: 'relative', top: 1, width: 15, height: 15, marginBottom: 0, marginRight: 10 }}
            />
            <img
              alt=""
              onClick={() => declineProfileChangeRequest('profilePictureURL')}
              src={CloseIcon}
              style={{ cursor: 'pointer', position: 'relative', top: 1, width: 15, height: 15, marginBottom: 0, marginRight: 10 }}
            />
          </div>
        </>
      )}
      {recruiterUser && (
        <div
          style={{
            margin: 0,
            height: 80,
            width: 80,
            borderRadius: 40,
            marginRight: 15,
            backgroundImage: `url(${
              changes.profilePictureURL
                ? changes.profilePictureURL
                : approvedChanges.profilePictureURL
                ? approvedChanges.profilePictureURL
                : candidate.profilePictureURL
                ? candidate.profilePictureURL
                : UserAvatar
            })`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
      )}
      {companyUser && (
        <div
          style={{
            margin: 0,
            height: 80,
            width: 80,
            borderRadius: 40,
            marginRight: 15,
            backgroundImage: `url(${mutualInterest ? candidate.profilePictureURL : UserAvatar})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            filter: !mutualInterest && 'blur(6px)',
          }}
        />
      )}
      <div className="flex flex-col font-medium text-darkblue">
        <div className="flex items-center">
          <div className="text-darkblue mr-md">{changes.name ? changes.name : candidate.name}</div>
          {changes.name && <ProfileChangeUI approveProfileChangeRequest={approveProfileChangeRequest} declineProfileChangeRequest={declineProfileChangeRequest} type="name" />}
        </div>

        {companyUser && !mutualInterest && (
          <div className="flex" style={{ color: 'transparent', textShadow: '0 0 7px black' }}>
            Candidate Name
          </div>
        )}
        {(recruiterUser || (companyUser && mutualInterest)) && (
          <div className="flex items-center">
            <div className="text-darkgray mr-md">{approvedChanges.email ? approvedChanges.email : changes.email ? changes.email : candidate.email}</div>
            {changes.email && <ProfileChangeUI approveProfileChangeRequest={approveProfileChangeRequest} declineProfileChangeRequest={declineProfileChangeRequest} type="email" />}
          </div>
        )}
        {companyUser && !mutualInterest && (
          <div className="text-darkgray" style={{ color: 'transparent', textShadow: '0 0 7px black' }}>
            candidate@email.com
          </div>
        )}

        {(recruiterUser || (companyUser && mutualInterest)) && (
          <ViewResumeButton resumeUrl={candidate.resumeURL} candidateLoxoId={candidate.loxo_id} recruiterId={candidate.recruiter_id} />

          // <div className="flex">
          //   <div
          //     onClick={() => window.open(candidate.resumeURL)}
          //     className="bg-lightgray flex shadow cursor-pointer rounded hover:underline items-center text-darkgray"
          //     style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, marginTop: 5, fontSize: 12 }}
          //   >
          //     <img src={LinkIcon} style={{ height: 13, width: 13, marginBottom: 0, marginRight: 10 }} />
          //     View Resume
          //   </div>
          // </div>
        )}
      </div>
    </div>
  );
}
