import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { Arrow } from '../../components/common';
import CloseIcon from '../../components/common/CloseIcon';
import TrashIcon from '../../components/common/TrashIcon';
import CandidateEntry from '../candidates/candidate-entry';

function Tags({ tags }) {
  return tags.length > 0 ? (
    <div className="flex flex-wrap">
      {tags.map((tag, index) => (
        <div
          key={index}
          className="px-md cursor-pointer bg-darkgray mt-sm flex justify-between mr-sm font-medium text-white items-center"
          style={{ fontSize: 12, height: 28, borderRadius: 28 }}
        >
          {tag}
        </div>
      ))}
    </div>
  ) : null;
}

const REMOVE_CANDIDATES = gql`
  mutation removeCandidateFromProject($projectID: uuid, $removedCandidates: [String!]) {
    delete_project_candidate(where: { candidate_id: { _in: $removedCandidates }, project_id: { _eq: $projectID } }) {
      returning {
        project_id
        candidate_id
      }
    }
  }
`;

const PROJECT_CANDIDATES = gql`
  query projectCandidates($projectID: uuid) {
    project(where: { id: { _eq: $projectID } }) {
      candidates {
        candidate {
          id
          name
          email
          phone
          resumeFileName
          resumeURL
          linkedin
          facebook
          instagram
          twitter
          referralName
          location
          recruiter {
            id
            name
          }
          experience
          bio
          employment
          profilePictureURL
          education
          certifications
          attributes
          preferredSkills
          notes
          salaryMax
          salaryMin
          locationPreferences
        }
      }
    }
  }
`;

const REMOVE_PROJECT = gql`
  mutation MyMutation($projectID: uuid) {
    delete_project(where: { id: { _eq: $projectID } }) {
      affected_rows
    }
  }
`;

const CANDIDATES_QUERY = gql`
  query getCandidatesByRecruiter($user_id: String) {
    candidate(order_by: { name: asc }, where: { recruiter_id: { _eq: $user_id } }) {
      id
      name
    }
  }
`;

const REMOVE_PROJECT_CANDIDATE = gql`
  mutation removeProjectCandidate($candidate_id: String, $project_id: uuid) {
    delete_project_candidate(where: { candidate_id: { _eq: $candidate_id }, project_id: { _eq: $project_id } }) {
      affected_rows
    }
  }
`;

const ADD_PROJECT_CANDIDATE = gql`
  mutation removeProjectCandidate($candidate_id: String, $project_id: uuid) {
    insert_project_candidate(objects: { candidate_id: $candidate_id, project_id: $project_id }) {
      affected_rows
    }
  }
`;

function AddCandidateDropdown({ projectCandidates, project }) {
  const placeholder = 'Add/Remove Candidate';
  const [inputValue, setInputValue] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { data } = useSubscription(CANDIDATES_QUERY, {
    variables: {
      user_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });
  const [allCandidates, setAllCandidates] = useState([]);
  const [candidatesToShow, setCandidatesToShow] = useState([]);
  const [removeProjectCandidate] = useMutation(REMOVE_PROJECT_CANDIDATE);
  const [addProjectCandidate] = useMutation(ADD_PROJECT_CANDIDATE);
  const [openSnackbar] = useSnackbar();

  function hideDropdown() {
    setDropdownVisible(false);
  }

  useEffect(() => {
    if (dropdownVisible) {
      window.addEventListener('click', hideDropdown);
    } else {
      setInputValue('');
      return window.removeEventListener('click', hideDropdown);
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (data) {
      setAllCandidates(data.candidate);
      setCandidatesToShow(data.candidate);
    }
  }, [data]);

  return (
    <>
      <div className={`relative`} style={{ paddingRight: 30 }}>
        <input
          onClick={(e) => e.stopPropagation()}
          autoComplete="new-password"
          value={inputValue}
          onFocus={() => setDropdownVisible(true)}
          onChange={(e) => {
            const newValue = e.target.value;
            setInputValue(e.target.value);

            setCandidatesToShow(
              allCandidates.filter((a) => {
                if (newValue) {
                  return new RegExp(newValue, 'gi').test(a.name);
                }

                return true;
              }),
            );
          }}
          placeholder={placeholder}
          className="rounded shadow relative text-xs"
          style={{ height: 40, paddingLeft: 15, paddingRight: 15, zIndex: dropdownVisible ? 501 : 499, borderColor: '#c4cad3', width: 230 }}
        />
        {dropdownVisible && (
          <div className="absolute bg-white w-full border-r border-l overflow-auto shadow" style={{ top: 'calc(100% - 10px)', zIndex: 500, paddingTop: 10, maxHeight: 350 }}>
            {candidatesToShow.map((candidate, index) => {
              const selected = projectCandidates.filter((a) => a.id === candidate.id)[0] ? true : false;

              return (
                <div
                  key={index}
                  className={`cursor-pointer ${selected ? 'bg-green text-white' : 'hover:bg-lightgray'} flex justify-between items-center`}
                  style={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}
                  onClick={async (e) => {
                    e.stopPropagation();

                    if (selected) {
                      openSnackbar(`Removing ${candidate.name} from project.`);

                      await removeProjectCandidate({
                        variables: { candidate_id: candidate.id, project_id: project.id },
                      });

                      openSnackbar(`${candidate.name} removed from project.`);
                    } else {
                      openSnackbar(`Adding ${candidate.name} to project.`);

                      await addProjectCandidate({
                        variables: { candidate_id: candidate.id, project_id: project.id },
                      });

                      openSnackbar(`${candidate.name} added to project.`);
                    }
                  }}
                >
                  {candidate.name}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default function ProjectDetails({ project, viewAllProjects }) {
  const { id, name, tags } = project;
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const { data, loading, error } = useQuery(PROJECT_CANDIDATES, {
    variables: { projectID: id },
  });
  const [removeCandidatesFromProject] = useMutation(REMOVE_CANDIDATES);
  const [deleteProject] = useMutation(REMOVE_PROJECT);

  useEffect(() => {
    if (data) {
      setCandidates(data.project[0].candidates.map((val) => val.candidate));
    }
  }, [data, error]);

  function toggleCandidate(id, checked) {
    if (checked) {
      setSelectedCandidates([...selectedCandidates, id]);
    } else {
      setSelectedCandidates(selectedCandidates.filter((candidate) => candidate !== id));
    }
  }

  function removeCandidates() {
    if (selectedCandidates.length > 0) {
      removeCandidatesFromProject({
        variables: {
          projectID: id,
          removedCandidates: selectedCandidates,
        },
        refetchQueries: ['projectsByRecruiterId'],
      });
      setCandidates(candidates.filter((candidate) => !selectedCandidates.includes(candidate.id)));
      setSelectedCandidates([]);
    }
  }

  function removeProject() {
    deleteProject({
      variables: { projectID: id },
      refetchQueries: ['projectsByRecruiterId'],
    });
    viewAllProjects();
  }

  return (
    <div className="relative pb-lg w-full flex flex-col">
      <div className="flex w-full justify-between relative">
        <div className="text-darkblue font-medium flex flex-col" style={{ paddingTop: 20, paddingBottom: 30, fontSize: 21 }}>
          <div className="flex items-center mb-md" onClick={viewAllProjects}>
            <Arrow color="#9499A2" />
            <div className="text-xs hover:underline cursor-pointer ml-sm text-darkgray">Projects</div>
          </div>
          <div className="flex items-center">
            {name}
            <TrashIcon onClick={removeProject} />
          </div>
          <Tags tags={tags} />
        </div>

        <div className="flex items-end" style={{ paddingBottom: 30, right: 0, top: 10 }}>
          <div>
            <AddCandidateDropdown projectCandidates={candidates} project={project} />
          </div>

          <div className="flex items-end absolute lg:static" style={{ right: 0, top: 10 }}>
            <div className="flex items-center">
              <div className="text-sm mr-md font-medium">Remove Candidate(s)</div>
              <div
                onClick={removeCandidates}
                className={`${
                  selectedCandidates.length > 0 ? 'bg-red text-white border-red' : 'border bg-lightgray text-darkgray border-darkgray'
                } flex items-center justify-center rounded cursor-pointer`}
                style={{ height: 40, width: 40 }}
              >
                <CloseIcon style={{ height: 20, width: 20 }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`flex-1 bg-white rounded shadow`} style={{ overflowX: 'auto' }}>
        <table style={{ width: '98%', marginLeft: '1%', marginRight: '1%' }}>
          <thead>
            <tr style={{ position: 'sticky', top: 0, zIndex: 21, background: 'white' }}>
              <th>
                <div className="text-sm justify-left" style={{ fontWeight: '500', textAlign: 'left', paddingLeft: 55 }}>
                  Candidate
                </div>
              </th>

              <th>
                <div className="text-sm justify-left" style={{ fontWeight: '500', textAlign: 'center' }}>
                  Experience
                </div>
              </th>

              <th>
                <div className="text-sm justify-left" style={{ fontWeight: '500', textAlign: 'center' }}>
                  Salary
                </div>
              </th>

              <th>
                <div className="text-sm justify-left" style={{ fontWeight: '500', textAlign: 'center' }}>
                  Location
                </div>
              </th>

              <th>
                <div className="text-sm justify-left" style={{ fontWeight: '500', textAlign: 'center' }}>
                  Actions
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {(candidates || []).map((candidate, index) => {
              return (
                <CandidateEntry
                  key={index}
                  candidate={candidate}
                  selected={selectedCandidates.includes(candidate.id)}
                  toggleCandidate={(checked) => toggleCandidate(candidate.id, checked)}
                />
              );
            })}
          </tbody>
        </table>

        {loading && (
          <div className={`flex justify-center items-center flex-1 candidate-blank`} style={{ width: '100%' }}>
            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
              Loading
            </div>
          </div>
        )}

        {!loading && candidates && candidates.length === 0 && (
          <div className={`flex justify-center items-center flex-1 candidate-blank`} style={{ width: '100%' }}>
            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
              No Candidates
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
