import React, { useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import UploadIcon from '../../images/upload-icon.svg';

export default function ResumeUpload({ value, update, parseResume, loading }) {
  const [resume, setResume] = useState(value);
  const uploadRef = useRef();

  useEffect(() => {
    if (resume) {
      update(resume);
    }
  }, [resume]);

  return (
    <div
      onClick={() => uploadRef.current.click()}
      className="ResumeUpload-js w-full mt-md rounded cursor-pointer bg-lightgray flex font-main text-darkgray text-sm items-center justify-between px-md"
      style={{ height: 55 }}
    >
      <div style={{ maxWidth: 250, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        {resume ? (resume.fileName ? resume.fileName : resume.name) : 'Upload Resume'}
      </div>
      {loading ? (
        <div className="flex">
          <div className="pr-md">Parsing resume...</div>
          <Loader type="TailSpin" color="#E31C25" height={20} width={20} />
        </div>
      ) : (
        <img alt="" src={UploadIcon} style={{ height: 22, margin: 0 }} />
      )}
      <input
        onChange={({ target: { files } }) => {
          setResume(files[0]);
          if (parseResume) {
            parseResume(files[0]);
          }
        }}
        ref={uploadRef}
        accept=".pdf"
        type="file"
        style={{ display: 'none' }}
      />
    </div>
  );
}
