import React, { useState } from "react";
import Arrow from "../../components/common/Arrow";
import { navigate } from "gatsby";
import EditAttributeForm from "../../forms/attribute/EditAttributeForm";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const ADD_SKILLS = gql`
mutation addSkill($skill:[skill_insert_input!]!) {
  insert_skill(objects: $skill) {
    affected_rows
    returning {
      category
      name
    }
  }
}
`;

export default function NewAttribute() {
    const initialFields = {
        attribute_category: "",
        attribute_name: ""
    };

    const [addSkill, { data, loading }] = useMutation(ADD_SKILLS);

    const saveAttribute = async skill => {       
        let insert_data = {
            category: skill.attribute_category.category ? skill.attribute_category.category : skill.attribute_category,
            name: skill.attribute_name.trim()
        };
        addSkill({
            variables: { skill: insert_data }
        });
    };

    if (data) {
        navigate("/dashboard/attributes");
    }
    return (
        <div className="w-full h-full relative">
            <div
                className="cursor-pointer absolute text-darkgray flex items-center capitalize"
                style={{ fontSize: 12, left: 0, top: 30, zIndex: 30 }}
                onClick={() => navigate("/dashboard/attributes")
                }
            >
                <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} />
                Attributes
            </div>
            <EditAttributeForm
                initialValues={initialFields}
                saveAttribute={saveAttribute}
                navigate={navigate}
                loading={loading}
            />
        </div>
    );
}
