import { pathOr } from 'ramda';
import Attributes from '../../data/attributes';
import { getLongId } from '../../utils/getId';
function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

async function parseResume(resume) {
  const resumeBase64 = await toBase64(resume);
  const config = {
    filedata: resumeBase64.substring(resumeBase64.indexOf('base64,') + 7, resumeBase64.length),
    filename: resume.name,
    userkey: 'L521RZNQSDX',
    version: '7.0.0',
    subuserid: 'CherryPicker',
  };

  return fetch('https://rest.rchilli.com/RChilliParser/Rchilli/parseResumeBinary', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(config),
  }).then((res) => res.json());
}

const formatDate = (date) => {
  const [day, month, year] = date.split('/');
  return `${month}/${day}/${year}`;
};

function getEmployment(resume) {
  if (resume && resume.SegregatedExperience) {
    return resume.SegregatedExperience.WorkHistory.map(({ Employer, StartDate, EndDate, JobDescription, JobProfile: { Title }, JobLocation: { EmployerCity, EmployerState } }) => ({
      id: getLongId(),
      companyLogo: '',
      companyLocation: EmployerCity && EmployerState ? `${EmployerCity}, ${EmployerState}` : '',
      title: Title,
      companyName: Employer,
      description: JobDescription,
      tenure: { startDate: formatDate(StartDate), endDate: formatDate(EndDate) },
      skills: [],
    }));
  }

  return [];
}

function getEducation(resume) {
  if (resume.SegregatedQualification) {
    return resume.SegregatedQualification.EducationSplit.map(({ Institution, Degree, StartDate, EndDate }) => {
      return {
        id: getLongId(),
        school: Institution.Name,
        degree: Degree,
        tenure: { startDate: formatDate(StartDate), endDate: formatDate(EndDate) },
      };
    });
  }

  return [];
}
function getSkills(resume) {
  const skills = resume.SkillKeywords ? resume.SkillKeywords.SkillSet.map(({ Skill }) => Skill) : [];
  return skills.filter((skill) => (Attributes.includes(skill) ? true : false));
}

function getExperience(resume) {
  const exp = parseInt(pathOr('', ['WorkedPeriod', 'TotalExperienceInYear'], resume));

  if (isNaN(exp)) {
    return 0;
  } else {
    return exp;
  }
}
export { parseResume, getEducation, getEmployment, getSkills, getExperience };
