import _ from 'lodash';

export function getId() {
  return Math.random()
    .toString(28)
    .substring(2);
}

export function getLongId() {
  return (
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  );
}

export function generateInviteCode() {
  const numbers = [2, 3, 4, 5, 6, 7, 8, 9].map((a) => a.toString());
  const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ'.split('');

  const inviteCodeArray = [
    _.sample(numbers),
    _.sample(numbers),
    _.sample(numbers),
    _.sample(characters),
    _.sample(characters),
    _.sample(characters),
    _.sample(characters),
    _.sample(characters),
  ];

  const inviteCode = _.shuffle(inviteCodeArray).join('');

  return inviteCode;
}
