import { navigate } from 'gatsby';
import React from 'react';
import UserAvatar from '../../images/user-avatar.png';

export default function RecruiterEntry({ recruiter }) {
  return (
    <div className="px-md lg:px-xl">
      <div className="flex flex-col lg:flex-row items-center border-b py-md">
        <div className="flex w-full lg:w-auto" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div
            style={{
              margin: 0,
              height: 49,
              width: 49,
              marginRight: 15,
              borderRadius: 40,
              backgroundImage: `url(${recruiter.profilePhoto ? `"${recruiter.profilePhoto}"` : UserAvatar})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          />
          <div className="flex flex-col">
            <div
              className="hover:underline lg:p-0 cursor-pointer lg:text-sm"
              style={{ fontWeight: 500 }}
              onClick={() => navigate(`/recruiters/${recruiter.id}/`, { state: { recruiter } })}
            >
              {recruiter.name}
            </div>
            <div className="flex items-center font-medium" style={{ fontSize: 14, maxWidth: 402 }}>
              <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                {recruiter.title}
              </div>
              <div className="mr-sm text-darkgray">{recruiter.title && recruiter.company && '|'}</div>
              <div className="text-darkgray" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {recruiter.company && recruiter.company.name}
              </div>
            </div>
          </div>
        </div>
        <div className="hidden flex-col text-xs text-darkgray font-medium flex-1"></div>
        <div className="hidden lg:flex justify-end mr-lg" style={{ flexGrow: 1 }}>
          {recruiter.industries.map((industry, index) => (
            <div key={index} className="bg-mediumgray text-darkgray flex items-center rounded px-md font-medium mr-sm" style={{ height: 30, fontSize: 12, borderRadius: 15 }}>
              {industry}
            </div>
          ))}
        </div>
        <div className="text-xs flex font-medium text-darkblue lg:mr-md w-full lg:w-auto">
          <div className="lg:hidden" style={{ width: 65, height: 1 }} />
          {`${recruiter.candidates_aggregate.aggregate.count} candidates`}
        </div>
      </div>
    </div>
  );
}
