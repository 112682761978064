import React from 'react'

export default function TrashIcon({onClick}) {
    return (
        <div onClick={onClick} className='text-darkgray hover:text-white bg-mediumgray hover:bg-red cursor-pointer mr-sm' style={{height: 40, width: 40, borderRadius: 20}}>
            <svg width="40" height="40" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M99.8585 76.085L98.3636 116.852L105.255 117.105L106.75 76.3377L99.8585 76.085Z" fill="currentColor"/>
            <path d="M106.987 59.2097V51.7793C106.993 51.0226 106.85 50.2722 106.565 49.5713C106.279 48.8704 105.858 48.2329 105.326 47.6956C104.793 47.1583 104.159 46.7319 103.46 46.4408C102.762 46.1498 102.013 46 101.256 46H78.7219C77.9652 46 77.216 46.1498 76.5175 46.4408C75.8191 46.7319 75.1852 47.1583 74.6524 47.6956C74.1196 48.2329 73.6985 48.8704 73.4134 49.5713C73.1283 50.2722 72.9848 51.0226 72.9912 51.7793V59.2097H49V66.106H56.1876L61.8698 130.843C61.9422 131.698 62.3307 132.495 62.9593 133.079C63.5879 133.663 64.4115 133.991 65.2693 134H114.563C115.421 133.991 116.244 133.663 116.873 133.079C117.502 132.495 117.89 131.698 117.962 130.843L123.693 66.106H130.978V59.2097H106.987ZM79.6932 52.8962H100.091V59.2097H79.6932V52.8962ZM111.212 127.104H68.4261L63.0839 66.106H116.506L111.212 127.104Z" fill="currentColor"/>
            <path d="M93.437 76.2075H86.5408V116.954H93.437V76.2075Z" fill="currentColor"/>
            <path d="M80.1137 76.1106L73.222 76.3633L74.7169 117.131L81.6085 116.878L80.1137 76.1106Z" fill="currentColor"/>
            </svg>    
        </div>)
}
