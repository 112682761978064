import React, { useContext, useEffect, useState, useReducer } from 'react';
import { UserRoleContext } from '../../context';
import { GlobalFilterContext } from '../../components/layout/layout';
import AppDownloadedFilter from './AppDownloadedFilter';
import AttributesFilter from './AttributesFilter';
import ExperienceFilter from './ExperienceFilter';
import LocationCheckboxFilter from './LocationCheckboxFilter';
import LocationFilter from './LocationFilter';
import SalaryFilter from './SalaryFilter';
import SearchTermsFilter from './SearchTermsFilter';
import { isEmpty } from 'lodash';
import { QUERY_PARAMS } from '../../utils/constants';
import _ from 'lodash';

const ClearButton = ({ updateField }) => (
  <div
    onClick={updateField}
    className="absolute lowercase cursor-pointer bg-darkgray text-white font-medium px-sm"
    style={{ top: 0, marginLeft: 15, borderRadius: 45.39, lineHeight: '20px' }}
  >
    clear
  </div>
);

export default function Filter({ filterState, type, isCompany, candidateCount, showAttributesSelected = true, searchPlaceholder = '', onChange, tableContext }) {
  const [userRole] = useContext(UserRoleContext);
  const { globalFilter } = useContext(GlobalFilterContext);
  const [_forceUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
  const isSubscriber = userRole === 'company';

  const [filterValue, update] = filterState;
  const sections = {
    search: filterValue.search ? filterValue.search : [],
    experience: filterValue.experience ? filterValue.experience : { min: null, max: null },
    attributes: filterValue.attributes ? filterValue.attributes : [],
    salary: filterValue.salary ? filterValue.salary : { min: null, max: null },
    location: filterValue.location ? filterValue.location : { city: null, radius: process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 30 },
    // location: filterValue.location ? filterValue.location : [],
    preferences: filterValue.locationPreferences ? filterValue.locationPreferences : { willingToRelocate: false, remote: false },
    c_is_login: filterValue.c_is_login ? filterValue.c_is_login : false,
  };
  const [options, setOptions] = useState(_.cloneDeep(sections));

  const updateField = (value, name) => setOptions({ ...options, [name]: value });

  const clearFilterField = (label) => {
    let resetValue;
    switch (label) {
      case 'experience':
        resetValue = { min: null, max: null };
        break;
      case 'attributes':
        resetValue = [];
        break;
      case 'salary':
        resetValue = { min: null, max: null };
        break;
      case 'location':
        resetValue = { city: null, radius: process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 30 };
        break;
      case QUERY_PARAMS.LOCATION_PREFERENCES:
        label = 'preferences'
        resetValue = { willingToRelocate: false, remote: false }
      case 'preferences':
        resetValue = { willingToRelocate: false, remote: false }
      default:
        break;
    }
    updateField(resetValue, label);
  };

  // const initialValues = {
  //   currentLocation: false,
  //   remote: false,
  //   willingToRelocate: false,
  // };
  // const [locationPreferences, setLocationPreferences] = useState(initialValues);

  const renderFilterField = (name) => {
    switch (name) {
      case 'search':
        return <SearchTermsFilter type={type} value={filterValue.search} placeholder={searchPlaceholder} update={(value) => updateField(value, name)} />;
      case 'experience':
        return <ExperienceFilter value={options[name]} update={(value) => updateField(value, name)} />;

      case 'attributes':
        return <AttributesFilter attributes={options[name]} update={(value) => updateField(value, name)} />;

      case 'salary':
        return <SalaryFilter value={options[name]} update={(value) => updateField(value, name)} />;

      case 'location':
        return <LocationFilter value={options[name]} update={(value) => updateField(value, name)} />;
      case 'preferences':
        return (
          <div>
            {type === 'candidate' ? (
              <div className="flex flex-wrap" style={{ width: 310, marginTop: 15, display: 'contents' }}>
                <LocationCheckboxFilter value={options[name]} update={(value) => updateField(value, name)} />
              </div>
            ) : (
              ''
            )}
          </div>
        );
      case 'c_is_login':
        return <div>{type === 'candidate' ? <AppDownloadedFilter value={options[name]} update={(value) => updateField(value, name)} /> : ''}</div>;
      default:
        return <div></div>;
    }
  };

  const renderClearButton = (label) => {
    const value = options[label];
    switch (label) {
      case 'search':
        return null;
      case 'experience':
        if (!value.min && !value.max) {
          return null;
        }
        break;
      case 'attributes':
        if (!value?.length) {
          return null;
        }
        break;
      case 'salary':
        if (!value.min && !value.max) {
          return null;
        }
        break;
      case 'location':
        if (!value.city) {
          return null;
        }
        // if (value.length === 0) {
        //   return null
        // }
        break;
      case 'preferences':
        if (!value.preferences) {
          return null;
        }
        break;
      case 'c_is_login':
        if (!value.c_is_login) {
          return null;
        }
        break;
      default:
        break;
    }

    return <ClearButton updateField={() => clearFilterField(label)} />;
  };

  useEffect(() => {
    if (tableContext && !_.isEmpty(globalFilter) && !_.isEmpty(globalFilter[tableContext]) && !_.isEmpty(globalFilter[tableContext].filter)) {
      const opts = globalFilter[tableContext].filter;
      setOptions({
        search: opts.search ? opts.search : [],
        experience: opts.experience ? opts.experience : { min: null, max: null },
        attributes: opts.attributes ? opts.attributes : [],
        salary: opts.salary ? opts.salary : { min: null, max: null },
        location: opts.location ? opts.location : { city: null, radius: process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 30 },
        preferences: opts.locationPreferences ? opts.locationPreferences : { willingToRelocate: false, remote: false },
        c_is_login: opts.c_is_login ? opts.c_is_login : false,
      });
      update(opts)
      forceUpdate();
    }
  }, [])

  useEffect(() => {
    let filter = {};
    const { search, experience, salary, attributes, location, preferences, c_is_login } = options;

    if (search?.length > 0) {
      filter = { ...filter, search };
    }

    if (experience?.min || experience?.max) {
      filter = { ...filter, experience: { min: experience.min, max: experience.max } };
    }

    if (attributes?.length > 0) {
      filter = { ...filter, attributes };
    }

    if (salary?.min || salary?.max) {
      filter = { ...filter, salary: { min: salary.min, max: salary.max } };
    }

    // if (location.length > 0) {
    //   filter = { ...filter, location };
    // }

    if (location?.city) {
      filter = { ...filter, location };
    }

    // if (preferences.currentLocation || preferences.willingToRelocate || preferences.remote) {
    // eslint-disable-next-line max-len
    //   filter = { ...filter, locationPreferences: { currentLocation: preferences.currentLocation, willingToRelocate: preferences.willingToRelocate, remote: preferences.remote } };
    // }
    if (preferences?.willingToRelocate || preferences?.remote) {
      filter = { ...filter, locationPreferences: { willingToRelocate: preferences.willingToRelocate, remote: preferences.remote } };
    }

    if (c_is_login) {
      filter = { ...filter, c_is_login };
    }

    update(filter);
  }, [options]);

  /**
   * Apply filters once user clicks on search or reset buttn
   * @param {Object} e HTMLClickEvent
   * @param {Boolean} reset Reset filters
   */
  const handleCandidateSearch = (e, reset) => {
    e.preventDefault();
    // 1. Check if props has onChange function.
    if (onChange) {
      // Reset filters
      if (reset) {
        update({}); // Update filter state
        setTimeout(() => {
          onChange({}, true);
          setOptions(_.cloneDeep({
            search: [],
            experience: { min: null, max: null },
            attributes: [],
            salary: { min: null, max: null },
            location: { city: null, radius: process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 30 },
            preferences: { willingToRelocate: false, remote: false },
            c_is_login: false,
          }));
        }, 0);
      } else {
        // Trigger event to parent component
        onChange(filterValue);
      }
    }
  }

  const SearchWrapper = () => (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      {
        !isEmpty(filterValue) ? (
          <button
            className="bg-lightgray text-darkgray border-darkgray font-medium rounded flex items-center justify-center search-candidate-button"
            onClick={(e) => handleCandidateSearch(e, true)}
            style={{
              border: '1px solid',
              width: 204,
              height: 40,
              margin: 'auto',
              marginRight: 20
            }}>
            Reset
          </button>
        ) : null
      }

      <button
        className="bg-red text-white font-medium rounded flex items-center justify-center search-candidate-button"
        onClick={handleCandidateSearch}
        style={{
          width: 204,
          height: 40,
          margin: 'auto'
        }}>
        Search
      </button>
    </div>
  )

  return (
    <div key={_forceUpdate} className="flex w-full flex-wrap" style={{ marginBottom: 30 }}>
      {
        Object.entries(options).map(([label], index) => {
          if (isCompany && label === 'c_is_login') {
            return null;
          } else {
            return (
              <div key={index} className={`flex flex-col ${label}-filter`} style={{ flexGrow: 1, marginTop: 15, marginRight: label === 'c_is_login' ? 0 : 0 }}>
                <div
                  className={`text-darkgray flex items-center font-medium ${label !== 'experience' ? 'capitalize' : ''}`}
                  style={{ fontSize: 12, height: 20, marginBottom: 15, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {label === 'experience'
                    ? 'Years of Experience'
                    : (label === 'preferences' && type === 'job') || (label === 'c_is_login' && (type === 'candidate' || type === 'job'))
                      ? ''
                      : label}
                  <div className="flex-1 relative" style={{ height: 20 }}>
                    {renderClearButton(label)}
                  </div>
                </div>
                <div key={`${index}-${label}`}>
                  {renderFilterField(label)}
                </div>
              </div>
            );
          }
        })
      }
      {
        onChange ? <SearchWrapper /> : null
      }
      {
        type === 'candidate' && !isSubscriber && (
          <div className="flex flex-col" style={{ bottom: 0, right: 0, fontSize: 14, justifyContent: 'center', marginTop: '25px', width: '100%', textAlign: 'end' }}>
            <span className="text-darkblue">
              Total no. of candidates: <strong>{candidateCount}</strong>
            </span>
          </div>
        )
      }
      {
        showAttributesSelected && (
          <div className={`w-full flex justify-end ${isCompany ? 'company-' : ''}filter-attributes-container`}>
            {options?.attributes?.length > 0 ? <div style={{ marginTop: 16, marginRight: 10, fontWeight: 600 }}>Attributes</div> : ''}
            <div className={`flex flex-wrap ${isCompany ? 'company-' : ''}filter-attributes`} style={{ marginTop: 6, top: 40 }}>
              {options?.attributes.map((term, index) => (
                <div
                  key={index}
                  className="border flex text-darkgray border-darkgray flex items-center px-md"
                  style={{ height: 28, fontSize: 12, borderRadius: 28, marginRight: 10, marginTop: 10, whiteSpace: 'nowrap' }}
                >
                  {term}
                  <div
                    onClick={() =>
                      updateField(
                        options?.attributes?.filter((val) => val !== term),
                        'attributes',
                      )
                    }
                    className="ml-sm text-sm relative cursor-pointer"
                    style={{ left: 5, width: 10, height: 10 }}
                  >
                    <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(45deg)', borderRadius: 1.5 }} />
                    <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(-45deg)', borderRadius: 1.5 }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      }
      {/* <div className={`w-full flex justify-end ${isCompany ? 'company-' : ''}filter-attributes-container`}>
        {options.location.length > 0 ? <div style={{ marginTop: 16, marginRight: 10, fontWeight: 600 }}>Locations</div> : ""}
        <div className={`flex flex-wrap ${isCompany ? 'company-' : ''}filter-attributes`} style={{ marginTop: 6, top: 40 }}>
          {options.location.map(term => (
            <div
              className="border flex text-darkgray border-darkgray flex items-center px-md"
              style={{
                height: 28,
                fontSize: 12,
                borderRadius: 28,
                marginRight: 10,
                marginTop: 10,
                whiteSpace: 'nowrap'
              }}>
              {term.name}
              <div
                onClick={() => updateField(options.location.filter(val => val !== term), 'location')}
                className="ml-sm text-sm relative cursor-pointer"
                style={{ left: 5, width: 10, height: 10 }}>
                <div
                  className="bg-darkgray absolute"
                  style={{
                    height: 10,
                    width: 1.5,
                    transform: "rotate(45deg)",
                    borderRadius: 1.5,
                  }}
                />
                <div
                  className="bg-darkgray absolute"
                  style={{
                    height: 10,
                    width: 1.5,
                    transform: "rotate(-45deg)",
                    borderRadius: 1.5,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
}
