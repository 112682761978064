import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import CaretDownGray from '../../images/caret-down-gray.png';
import CaretDown from '../../images/caret-down.png';
import RecruiterEntry from './recruiter-entry';
import CherryPagination from '../../components/common/Pagination/Pagination';
import { DEFAULT_PER_PAGE } from '../../utils/constants';

const ALL_RECRUITERS = gql`
  subscription getAllRecruiters($perPage: Int, $offset: Int) {
    recruiter(limit: $perPage, offset: $offset) {
      availability
      email
      id
      industries
      name
      phone
      profilePhoto
      title
      company {
        id
        name
      }
      position
      candidates_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const ALL_RECRUITERS_TOTAL = gql`
  subscription getAllRecruiters {
    recruiter_aggregate {
      aggregate {
        count
      }
    }
  }
`;

function AlphabeticalSort({ sortState }) {
  const [sortType, setSortType] = sortState;
  const [direction, setDirection] = useState('desc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ type: 'alphabetical', direction: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ type: 'alphabetical', direction: 'desc' });
    }
  }

  return (
    <div className="flex mr-md">
      <div
        onClick={setType}
        className={`text-sm ${
          sortType.type === 'alphabetical' ? 'bg-darkblue text-white' : 'text-darkgray border border-darkgray'
        }   flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
      >
        A - Z
        <img
          alt=""
          src={sortType.type === 'alphabetical' ? CaretDown : CaretDownGray}
          style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }}
        />
      </div>
    </div>
  );
}

export default function Recruiter() {
  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);

  // useEffect(() => {}, [perPage, offset]);

  // Aggregate of the recruiters that is use to show the page count
  const { data: recruitersTotal } = useSubscription(ALL_RECRUITERS_TOTAL);

  useEffect(() => {
    if (recruitersTotal) {
      setTotal(recruitersTotal?.recruiter_aggregate?.aggregate.count || 0);
    }
  }, [recruitersTotal]);

  const [recruiters, setRecruiters] = useState();
  const { data: recruitersQuery, loading } = useSubscription(ALL_RECRUITERS, {
    variables: {
      perPage: perPage,
      offset: page * perPage,
    },
  });

  const [sortType, setSortType] = useState({ type: 'alphabetical', direction: 'desc' });

  useEffect(() => {
    if (recruitersQuery) {
      setRecruiters(sortRecruiters(recruitersQuery.recruiter));
    }
  }, [recruitersQuery]);

  function sortRecruiters(recruitersList) {
    if (recruitersList) {
      if (sortType.type === 'alphabetical') {
        return [...recruitersList].sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return textA < textB ? (sortType.direction === 'desc' ? -1 : 1) : textA > textB ? (sortType.direction === 'desc' ? 1 : -1) : 0;
        });
      }
    } else {
      return recruitersList;
    }
  }

  function handlePaginationChange(event) {
    setPage(event.page);
    setPerPage(event.perPage);
  }

  useEffect(() => {
    if (sortType && recruiters) {
      setRecruiters(sortRecruiters(recruiters));
    }
  }, [sortType]);

  return (
    <div className="flex flex-col h-full pb-lg">
      <div className="mb-md lg:m-0 capitalize flex items-center flex-row justify-between w-full" style={{ fontSize: 21, fontWeight: 500, marginTop: 30, marginBottom: 30 }}>
        Recruiters
        <Link className="bg-red text-white font-medium rounded flex items-center justify-center" style={{ fontSize: 14, height: 40, width: 160 }} to="/recruiters/new-recruiter">
          <span style={{ fontSize: 22, marginRight: 8 }}>+</span> Add Recruiter
        </Link>
      </div>
      <div className="flex mb-sm">
        <div className="text-sm mr-sm">Sort:</div>
        <AlphabeticalSort sortState={[sortType, setSortType]} />
      </div>
      <div className="bg-white rounded shadow flex-1 mb-lg w-full" style={{ maxWidth: 1340, height: '700px', overflow: 'auto' }}>
        {loading && (
          <div className={`flex justify-center items-center flex-1 candidate-blank`} style={{ width: '100%' }}>
            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
              Loading...
            </div>
          </div>
        )}
        {recruiters && recruiters.map((recruiter, index) => <RecruiterEntry key={index} recruiter={recruiter} />)}
      </div>
      <div className="recruiters-pagination">
        <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
      </div>
    </div>
  );
}
