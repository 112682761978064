import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { navigate } from 'gatsby';
import moment from 'moment';
import fetch from 'node-fetch';
import React, { useContext, useEffect, useState } from 'react';
import { EXPRESS_SERVER_URL, WEBSITE_URL } from '../../config';
import UserRoleContext from '../../context/UserRole';
import JobForm from '../../forms/job/JobForm';
import BackArrow from '../../images/back-arrow.svg';
import { defaultCompanyLogo } from '../../utils/constants';

const CREATE_COMPANY = gql`
  mutation createCompany($company: [companies_insert_input!]!) {
    insert_companies(objects: $company, on_conflict: { constraint: companies_name_key, update_columns: [size, industries, logoURL, websiteURL] }) {
      returning {
        id
      }
    }
  }
`;

const CREATE_JOB = gql`
  mutation createJob($job: [job_insert_input!]!) {
    insert_job(objects: $job) {
      returning {
        id
        title
        subscriber {
          id
          name
        }
      }
    }
  }
`;

const GET_COMPANY = gql`
  query getCompany($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id }}) {
      id
      email
      name
      logoURL
      adminName
      totalCredit
      remainingCredit
      subscription_type
      recruiter_id
    }
  }
`;

export default function AddJob ({ location }) {
  const [addJob, { data, loading: jobLoading }] = useMutation(CREATE_JOB);
  const [addCompany, { data: companyData, loading: companyLoading }] = useMutation(CREATE_COMPANY);

  const [job, setJob] = useState();
  const [jobLocation, setJobLocation] = useState();

  const [userRole] = useContext(UserRoleContext);
  const isSubscriber = userRole === 'company';

  // set currCompany
  const [currCompany, setCurrCompany] = useState('');

  const { data: companyResponse } = useQuery(GET_COMPANY, {
    variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid },
  });

  useEffect(() => {
    if (companyResponse) {
      setCurrCompany(companyResponse.company[0]);
    }
  }, [companyResponse]);

  const uploadPhoto = async (photo, directory) => {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/${directory}/${photo.name}`);
    const photoURL = await storageRef.put(photo).then((snapshot) => snapshot.ref.getDownloadURL());
    return photoURL;
  };

  const createJob = async (job) => {
    console.log("Creating new job", job);
    let company = job.company;
    const newJob = {
      recruiter_id: !isSubscriber ? firebase.auth().currentUser.uid : process.env.GATSBY_DEFAULT_RECRUITER_ID,
      title: job.title,
      description: job.description,
      salaryMin: job.salary[0],
      salaryMax: job.salary[1],
      compensation: job.compensation,
      compensation_list: job.compensation_list,
      location: job.location,
      industry: job.industry,
      requiredExperience: job.requiredExperience,
      requiredAttributes: job.attributes.required,
      optionalAttributes: job.attributes.optional,
      notes: job.notes ? [{ date: new Date(), value: job.notes }] : [],
      allowsRemote: job.allowsRemote,
      hiddenFromCandidates: job.hiddenFromCandidates,
      subscriber_id: !isSubscriber ? null : currCompany.id,
      adminApproved: isSubscriber ? false : true,
      // adminApproved: false,
      created_at: moment()
        .utc()
        .toISOString(),
    };

    if (isSubscriber || (company && company.id)) {
      // company =
      //   company && company.id
      //     ? company
      //     : await addCompany({
      //       variables: {
      //         company: {
      //           name: company.name,
      //           size: company.size,
      //           websiteURL: company.websiteURL,
      //           description: company.bio,
      //           logoURL: company.logoURL !== '' ? await uploadPhoto(company.logoURL, 'newcompanylogos') : defaultCompanyLogo,
      //           adminID: firebase.auth().currentUser && firebase.auth().currentUser.uid,
      //         },
      //       },
      //     });
      const response = await addJob({
        variables: { job: { ...newJob, company_id: company && company.id } },
        // doesn't seem to be used anywhere
        refetchQueries: 'getJobs',
        awaitRefetchQueries: true,
      });

      await fetch(`${EXPRESS_SERVER_URL}/matchCandidatesWithNewJob`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event: {
            data: {
              new: {
                id: response.data.insert_job.returning[0].id,
                requiredExperience: job.requiredExperience,
                salaryMin: job.salary[0],
                salaryMax: job.salary[1],
                location: job.location,
                requiredAttributes: job.attributes.required,
                closed: false,
                allowsRemote: job.allowsRemote,
              },
            },
          },
        }),
      });
    } else {
      setJob(newJob);

      addCompany({
        variables: {
          company: {
            name: job.company.name,
            size: job.company.size,
            websiteURL: job.company.websiteURL,
            description: job.company.bio,
            logoURL: job.company.logoURL !== '' ? await uploadPhoto(job.company.logoURL, 'newcompanylogos') : defaultCompanyLogo,
            adminID: firebase.auth().currentUser && firebase.auth().currentUser.uid,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (data) {
      if (isSubscriber) {
        const email = 'cjtufano@getcherrypicker.com';
        const imagelink =
          'https://firebasestorage.googleapis.com/v0/b/cherry-picker-a3314.appspot.com/o/images%2Fheader-logo.png?alt=media&token=defa4e61-56b0-438b-aaa1-9572fb2f8691';
        const content = `
    <div style="border: 2px solid red;padding: 20px;">
    <img src=${imagelink} style="max-width: 250px;width: 50%;margin:0px" />
    <p style="margin-top:10px;font-size:17px">${currCompany.name} posted a ${data.insert_job.returning[0].title} position that needs approval.</p>
    <p style="margin-top:25px;font-size:17px">
    Login into the Cherrypicker Admin account
    <a href="${WEBSITE_URL}/" target="_blank">
    ${WEBSITE_URL}/
    </a> and go to the "Job Queue" tab to approve the job posting.
    </p>
    <p style="margin-top:25px;font-size:17px">Regards,<br>Cherrypicker Support Team</p>
    </div>
  `;
        const subject = `New Job Posted By ${currCompany.name} - Needs Approval`;
        const text = 'dummy';
        fetch(`${EXPRESS_SERVER_URL}/inviteCandidateEmail`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, subject, content, text }),
        });

        const email1 = currCompany.email;
        const content1 = `
    <div style="border: 2px solid red;padding: 20px;">
    <img src=${imagelink} style="max-width: 250px;width: 50%;margin:0px" />
    <p style="margin-top:10px;;font-size:17px">Your ${data.insert_job.returning[0].title} job posting has been submitted for approval.</p>
    <p style="margin-top:25px;font-size:17px">Our admin team reviews every job posting to ensure accuracy.</p>
    <p style="margin-top:25px;font-size:17px">You will receive an email notification shortly when the job posting has been approved.</p>
    <p style="margin-top:25px;font-size:17px">If you have any questions--please contact support at<br>
    <a href="mailto:support@getcherrypicker.com">support@getcherrypicker.com</a>
    </p>
    <p style="margin-top:25px;font-size:17px">Regards,<br>Cherrypicker Support Team</p>
    </div>
  `;
        const subject1 = 'Your job posting has been submitted for approval!';
        const text1 = 'dummy';
        fetch(`${EXPRESS_SERVER_URL}/sendMail`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: email1, subject: subject1, content: content1, text: text1 }),
        });
      }
      const {
        insert_job: {
          returning: [response],
        },
      } = data;

      navigate(`/jobs/${response.id}`, { state: { jobID: response.id } });
    }
  }, [data]);

  useEffect(() => {
    if (companyData) {
      const {
        insert_companies: {
          returning: [{ id: companyId }],
        },
      } = companyData;
      addJob({
        variables: { job: { ...job, company_id: companyId } },
        refetchQueries: 'getJobs',
        awaitRefetchQueries: true,
      });
    }
  }, [companyData]);

  const getJobLocation = async () => {
    const newLocation = await getCity(location.state.job.location);
    setJobLocation(newLocation);
  };
  useEffect(() => {
    if (location.state && location.state.job && location.state.job.location) {
      getJobLocation();
    }
  }, [location]);

  async function getCity (location) {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${location.replace(' ', '%20')}&key=${process.env.GATSBY_GOOGLE_PLACES_KEY}`,
    ).then((res) => res.json());
    const {
      results: [
        {
          geometry: {
            location: { lat, lng },
          },
          formatted_address: formattedAddress,
        },
      ],
    } = response;
    return {
      city: formattedAddress,
      lat,
      lng,
    };
  }

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="container flex flex-col items-center justify-center relative">
        <div
          onClick={() =>
            navigate(location.state.search ? '/jobs/search' : isSubscriber ? '/dashboard/jobs' : '/jobs', {
              state: location.state.search ? location.state.search : {},
            })
          }
          className="absolute cursor-pointer text-darkgray flex items-center font-main text-xs"
          style={{ left: 30, top: 30 }}
        >
          <img alt="" src={BackArrow} style={{ height: 13, marginBottom: 0, marginRight: 8 }} />
          {isSubscriber ? 'My Jobs' : 'Jobs'}
        </div>
        <div className="text-darkblue font-medium text-center" style={{ fontSize: 21, paddingTop: 40, paddingBottom: 58 }}>
          Add Job
        </div>
        <div className="w-full bg-white rounded shadow flex-1 flex flex-col" style={{ paddingRight: 30, paddingLeft: 30 }}>
          {location.state?.job ? (
            jobLocation && (
              <JobForm
                job={
                  location.state &&
                  location.state.job && {
                    title: location.state.job.title,
                    description: location.state.job.summary,
                    location: jobLocation,
                  }
                }
                onSubmit={createJob}
                loading={companyLoading || jobLoading}
              />
            )
          ) : (
            <JobForm job={{
              company: currCompany || null
            }} onSubmit={createJob} loading={companyLoading || jobLoading} />
          )}
        </div>
      </div>
    </div>
  );
}
