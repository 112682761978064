import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDebounce } from '../../hooks/useDebounce';
import SearchIcon from '../../images/search.svg';

const ClearButton = ({ updateField }) => (
  <div
    onClick={updateField}
    className="absolute lowercase cursor-pointer bg-darkgray text-white font-medium px-sm"
    style={{ top: 0, marginLeft: 15, borderRadius: 45.39, lineHeight: '20px' }}
  >
    clear
  </div>
);

export default function LoxoFilter({ filterState, type }) {
  const [filterValue, update] = filterState;

  const [startDate, setStartDate] = useState(filterValue.start_date ? filterValue.start_date : null);
  const [endDate, setEndDate] = useState(filterValue.end_date ? filterValue.end_date : new Date());
  const [candidateName, setCandidateName] = useState(filterValue.name ? filterValue.name : '');
  const [jobTitle, setJobTitle] = useState(filterValue.job_title ? filterValue.job_title : '');

  const debouncedCandidateName = useDebounce(candidateName, 1000);
  const debouncedJobTitle = useDebounce(jobTitle, 1000);

  const sections = {
    name: filterValue.name ? filterValue.name : null,
    job_title: filterValue.job_title ? filterValue.job_title : null,
    start_date: filterValue.start_date ? filterValue.start_date : null,
    end_date: filterValue.end_date ? filterValue.end_date : new Date(),
  };
  const [options, setOptions] = useState(sections);

  const updateField = (value, name) => setOptions({ ...options, [name]: value });

  const clearFilterField = (label) => {
    let resetValue;
    let skipUpdate = true;

    switch (label) {
      case 'name':
        resetValue = null;
        setCandidateName('');
        break;
      case 'job_title':
        resetValue = '';
        setJobTitle('');
        break;
      case 'start_date':
        resetValue = null;
        setStartDate(null);
        skipUpdate = false;
        break;
      case 'end_date':
        resetValue = null;
        setEndDate(null);
        skipUpdate = false;
        break;
      default:
        break;
    }

    if (!skipUpdate) {
      updateField(resetValue, label);
    }
  };

  function getStartDate(date) {
    setStartDate(date);
    updateField(date, 'start_date');
  }
  function getEndDate(date) {
    setEndDate(date);
    updateField(date);
  }

  const renderFilterField = (name) => {
    switch (name) {
      case 'name':
        return (
          <div className="SearchTermsFilter-js flex-1" style={{ paddingRight: 40 }}>
            <div className="relative flex-1" style={{}}>
              <img alt="" src={SearchIcon} style={{ position: 'absolute', left: 10, top: 11, width: 16 }} />
              <input
                value={candidateName}
                onChange={(e) => setCandidateName(e.target.value)}
                style={{ height: 40, paddingLeft: 35 }}
                className="SearchTermsFilter-js bg-white w-full shadow rounded p-sm text-xs placeholder-primary font-normal"
                placeholder="Candidate Names"
              />
            </div>
          </div>
        );
      case 'job_title':
        return (
          <div className="SearchTermsFilter-js flex-1" style={{ paddingRight: 40 }}>
            <div className="relative flex-1" style={{}}>
              <img alt="" src={SearchIcon} style={{ position: 'absolute', left: 10, top: 11, width: 16 }} />
              <input
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                style={{ height: 40, paddingLeft: 35 }}
                className="SearchTermsFilter-js bg-white w-full shadow rounded p-sm text-xs placeholder-primary font-normal"
                placeholder="Job Title"
              />
            </div>
          </div>
        );
      case 'start_date':
        return (
          <div className="flex-1">
            <div className="relative flex-1">
              <DatePicker
                selected={startDate}
                onChange={(date) => getStartDate(date)}
                maxDate={moment(new Date()).toDate()}
                className="bg-white shadow rounded p-sm text-xs"
                placeholderText={'Start date'}
                style={{ width: 300 }}
              />
            </div>
          </div>
        );
      case 'end_date':
        return (
          <div className="flex-1">
            <div className="relative flex-1">
              <DatePicker
                selected={endDate}
                onChange={(date) => getEndDate(date)}
                maxDate={moment(new Date()).toDate()}
                className="bg-white shadow rounded p-sm text-xs"
                placeholderText={'End date'}
              />
            </div>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  const renderClearButton = (label) => {
    const value = options[label];

    switch (label) {
      case 'name':
        if (!candidateName) {
          return null;
        }
        break;
      case 'job_title':
        if (!jobTitle) {
          return null;
        }
        break;
      case 'start_date':
        if (value === null) {
          return null;
        }
        break;
      case 'end_date':
        if (value === null) {
          return null;
        }
        break;
      default:
        break;
    }

    return <ClearButton updateField={() => clearFilterField(label)} />;
  };

  useEffect(() => {}, [filterValue]);

  useEffect(() => {
    let filter = {};
    const { start_date: startDate, end_date: endDate } = options;

    filter = { ...filter, name: debouncedCandidateName };
    filter = { ...filter, job_title: debouncedJobTitle };

    if (startDate) {
      filter = { ...filter, start_date: startDate };
    }

    if (endDate) {
      filter = { ...filter, end_date: endDate };
    }

    update(filter);
  }, [options, debouncedCandidateName, debouncedJobTitle]);

  return (
    <div className="flex w-full flex-wrap" style={{ marginBottom: 30 }}>
      {Object.entries(sections).map(([label]) => {
        return (
          <div key={label.toString()} className={`flex flex-col ${label}-filter`} style={{ flexGrow: 1 }}>
            <div
              className="text-darkgray flex items-center capitalize font-medium"
              style={{ fontSize: 12, height: 20, marginBottom: 15, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {options && (
                <div className="flex-1 relative" style={{ height: 20 }}>
                  {renderClearButton(label)}
                </div>
              )}
            </div>
            {renderFilterField(label)}
          </div>
        );
      })}
    </div>
  );
}
