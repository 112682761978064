import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVDownloader } from 'react-papaparse';
import { TabTitle } from '../../components/common';
import './candidates.css';

const ModalContext = React.createContext();

const SUBSCRIBER_CREDIT_REPORT = gql`
  subscription getSubscriberCreditReport($start_date: date, $end_date: date) {
    company_request(where: { status: { _eq: "approved" }, date: { _gte: $start_date, _lte: $end_date } }) {
      candidate_id
      company_id
      credit_amount
      date
      status
      company {
        id
        name
        email
      }
      candidate {
        id
        name
        email
        recruiter {
          id
          name
          email
          phone
          company {
            id
            name
          }
        }
      }
    }
  }
`;

const ActivityHeading = () => {
  return (
    <div className="flex items-center border-b" style={{ height: 100, opacity: 1, transition: 'opacity 0.3s ease-in-out' }}>
      <div className="flex justify-center" style={{ width: 100 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Date'}
        </div>
      </div>
      <div style={{ paddingLeft: 0, paddingRight: 0, minWidth: 250 }}>
        <div className="flex flex-col">
          <div className="lg:p-0 text-darkblue lg:text-sm" style={{ fontWeight: 'bold' }}>
            {'Subscriber Company Name'}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14 }}>
            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
              {'Subscriber Contact email'}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Name of Candidate - Credit Used on'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Recruiter Name Rep for Candidate'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 200 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Recruiter Company Name'}
        </div>
      </div>
      <div style={{ paddingLeft: 0, paddingRight: 0, minWidth: 200 }}>
        <div className="flex flex-col">
          <div className="lg:p-0 text-darkblue lg:text-sm" style={{ fontWeight: 'bold' }}>
            {'Recruiter Email'}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14 }}>
            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
              {'Recruiter Phone'}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Revenue from Credit Purchased'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Recruiter Share Revenue'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkblue flex items-center" style={{ fontSize: 14, fontWeight: 'bold' }}>
          {'Cherrypicker Share'}
        </div>
      </div>
    </div>
  );
};
export { ActivityHeading };
export { SingleActivity };

const SingleActivity = ({ activity }) => {
  return (
    <div className="flex items-center border-b" style={{ height: 100, opacity: 1, transition: 'opacity 0.3s ease-in-out' }}>
      <div className="flex justify-center" style={{ width: 100 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {moment(activity.date).format('MM/DD/YYYY')}
        </div>
      </div>
      <div style={{ paddingLeft: 0, paddingRight: 0, width: 250 }}>
        <div className="flex flex-col">
          <div className="lg:p-0 lg:text-sm" style={{ fontWeight: 500 }}>
            {activity.company ? activity.company.name : 'N/A'}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14 }}>
            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
              {activity.company ? activity.company.email : 'N/A'}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.candidate ? activity.candidate.name : 'N/A'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.candidate.recruiter ? activity.candidate.recruiter.name : 'N/A'}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.candidate.recruiter ? (activity.candidate.recruiter.company ? activity.candidate.recruiter.company.name : 'N/A') : 'N/A'}
        </div>
      </div>
      <div style={{ paddingLeft: 0, paddingRight: 0, width: 200 }}>
        <div className="flex flex-col">
          <div className="lg:p-0 lg:text-sm" style={{ fontWeight: 500 }}>
            {activity.candidate.recruiter ? activity.candidate.recruiter.email : 'N/A'}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14 }}>
            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
              {activity.candidate.recruiter ? activity.candidate.recruiter.phone : 'N/A'}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {activity.credit_amount}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {0.5 * activity.credit_amount}
        </div>
      </div>
      <div className="flex justify-center" style={{ width: 140 }}>
        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14 }}>
          {0.5 * activity.credit_amount}
        </div>
      </div>
    </div>
  );
};

export default function SubscriberActivity ({ isAdmin, inProcess, isDashboard }) {
  const [modal, setModal] = useState({ data: null, open: false });
  const [activityData, setActivityData] = useState();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  const { data: subscriberCreditReport, loading } = useSubscription(SUBSCRIBER_CREDIT_REPORT, {
    variables: {
      start_date: startDate != null ? moment(startDate).format('YYYY-MM-DD') : null,
      end_date: moment(endDate).format('YYYY-MM-DD'),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (subscriberCreditReport) {
      setActivityData(subscriberCreditReport.company_request);
    }
  }, [subscriberCreditReport]);

  const csv_data = activityData
    ? activityData.map((activity) => {
      return {
        Date: moment(activity.date).format('MM/DD/YYYY'),
        'Company Subscriber Company Name': activity.company ? activity.company.name : 'N/A',
        'Subscriber Contact Email': activity.company ? activity.company.email : 'N/A',
        'Subscriber Name': activity.company ? activity.company.name : 'N/A',
        'Name of Candidate - Credit Used on': activity.candidate ? activity.candidate.name : 'N/A',
        'Recruiter Name Rep for Candidate': activity.candidate.recruiter ? activity.candidate.recruiter.name : 'N/A',
        'Recruiter Company Name': activity.candidate.recruiter ? (activity.candidate.recruiter.company ? activity.candidate.recruiter.company.name : 'N/A') : 'N/A',
        'Recruiter Email': activity.candidate.recruiter ? activity.candidate.recruiter.email : 'N/A',
        'Recruiter Phone': activity.candidate.recruiter ? activity.candidate.recruiter.phone : 'N/A',
        'Revenue from Credit Purchased': '$ ' + activity.credit_amount,
        'Recruiter Share Revenue': '$ ' + 0.5 * activity.credit_amount,
        'Cherrypicker Share': '$ ' + 0.5 * activity.credit_amount,
      };
    })
    : [];

  return (
    <div className="w-full flex-1 candidates">
      <ModalContext.Provider value={[modal, setModal]}>
        <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
          <TabTitle showBackButton={true}>{'Subscriber Credit Report'}</TabTitle>
          {activityData && activityData.length > 0 && (
            <CSVDownloader
              data={csv_data}
              type="anchor"
              filename={'subscriber_credit_report'}
              className={`bg-red text-white font-medium rounded flex items-center justify-center`}
              style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
            >
              <span style={{ fontSize: 22, marginRight: 8 }}></span> Download CSV
            </CSVDownloader>
          )}
        </div>
        <div className="h-full flex flex-col" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
          <div style={{ marginRight: 20 }}>
            <span>Start Date: </span>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={moment(new Date()).toDate()} />
          </div>
          <div>
            <span>End Date: </span>
            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={moment(new Date()).toDate()} />
          </div>
        </div>

        <div className={`flex-1 bg-white rounded shadow`} style={{ overflowX: 'auto', height: '700px', minHeight: 500, maxHeight: 'calc(100vh - 200px)', marginBottom: 100 }}>
          <table style={{ width: '98%', marginLeft: '1%', marginRight: '1%' }}>
            <thead style={{ fontSize: 14, fontWeight: 'bold' }}>
              <tr style={{ position: 'sticky', top: 0, background: 'white' }}>
                <th style={{ textAlign: 'center', maxWidth: 110 }}>Date</th>
                <th style={{ textAlign: 'left' }}>
                  Subscriber Company Name
                  <br />
                  Subscriber Contact email
                </th>
                <th style={{ textAlign: 'left' }}>Name of Candidate - Credit Used on</th>
                <th style={{ textAlign: 'left' }}>Recruiter Name Rep for Candidate</th>
                <th style={{ textAlign: 'left' }}>Recruiter Company Name</th>
                <th style={{ textAlign: 'left' }}>
                  Recruiter Email
                  <br />
                  Recruiter Phone
                </th>
                <th style={{ textAlign: 'left' }}>Revenue from Credit Purchased</th>
                <th style={{ textAlign: 'left' }}>Recruiter Share Revenue</th>
                <th style={{ textAlign: 'left' }}>Cherrypicker Share</th>
              </tr>
            </thead>

            <tbody>
              {(activityData || []).map((activity) => {
                return (
                  <tr style={{ fontSize: 14 }}>
                    <td style={{ maxWidth: 110 }}>
                      <div className="font-medium text-darkgray">{moment(activity.date).format('MM/DD/YYYY')}</div>
                    </td>

                    <td style={{ maxWidth: 250 }}>
                      <div className="flex flex-col">
                        <div className="lg:p-0 lg:text-sm" style={{ fontWeight: 500 }}>
                          {activity.company ? activity.company.name : 'N/A'}
                        </div>
                        <div className="font-medium">
                          <div className="text-darkblue mr-sm">{activity.company ? activity.company.email : 'N/A'}</div>
                        </div>
                      </div>
                    </td>

                    <td style={{ maxWidth: 140 }}>
                      <div className="font-medium text-darkgray">{activity.candidate ? activity.candidate.name : 'N/A'}</div>
                    </td>

                    <td style={{ maxWidth: 140 }}>
                      <div className="font-medium text-darkgray">{activity.candidate.recruiter ? activity.candidate.recruiter.name : 'N/A'}</div>
                    </td>

                    <td style={{ maxWidth: 140 }}>
                      <div className="font-medium text-darkgray">
                        {activity.candidate.recruiter ? (activity.candidate.recruiter.company ? activity.candidate.recruiter.company.name : 'N/A') : 'N/A'}
                      </div>
                    </td>

                    <td style={{ maxWidth: 200 }}>
                      <div className="flex flex-col">
                        <div className="lg:p-0 lg:text-sm" style={{ fontWeight: 500 }}>
                          {activity.candidate.recruiter ? activity.candidate.recruiter.email : 'N/A'}
                        </div>
                        <div className="font-medium">
                          <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                            {activity.candidate.recruiter ? activity.candidate.recruiter.phone : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td style={{ maxWidth: 140 }}>
                      <div className="font-medium text-darkgray">{activity.credit_amount}</div>
                    </td>

                    <td style={{ maxWidth: 140 }}>
                      <div className="font-medium text-darkgray">{0.5 * activity.credit_amount}</div>
                    </td>

                    <td style={{ maxWidth: 140 }}>
                      <div className="font-medium text-darkgray">{0.5 * activity.credit_amount}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {loading && (
            <div className={`flex justify-center items-center flex-1 candidate-blank${isDashboard ? '-dash' : ''}`} style={{ width: '100%' }}>
              <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                Loading
              </div>
            </div>
          )}

          {!loading && activityData && activityData.length === 0 && (
            <div className={`flex justify-center items-center flex-1 candidate-blank${isDashboard ? '-dash' : ''}`} style={{ width: '100%' }}>
              <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                No Records found
              </div>
            </div>
          )}
        </div>
      </ModalContext.Provider>
    </div>
  );
}
