import _, { get } from 'lodash';
import React from 'react';
import { CSVDownloader } from 'react-papaparse';

export default function MatchedCandidates({ candidates, activeTab, job }) {
  const newCandidates = _.cloneDeep(candidates || []);

  newCandidates &&
    newCandidates.filter((candidate) => {
      const currentEmployment = candidate.employment && candidate.employment.filter(({ tenure }) => tenure.current);
      candidate.employment = currentEmployment;

      candidate.interestedCities = candidate.interestedCity && candidate.interestedCity.map((int) => int.city).join(';');
    });

  const csvData =
    newCandidates.length > 0
      ? newCandidates.map((candidate) => {
          return {
            Name: candidate.name,
            Email: candidate.email,
            Phone: candidate.phone && candidate.phone.replace(/[- )(]/g, ''),
            'Linkedin URL': candidate.linkedin,
            'Referral Name': candidate.referralName,
            Location: candidate.location && candidate.location.city,
            Experience: candidate.experience,
            'Salary Min ($)': candidate.salaryMin,
            'Facebook URL': candidate.facebook,
            'Instagram Handle': candidate.instagram,
            'Twitter Handle': candidate.twitter,
            'Short Bio': candidate.bio,
            'Current Company': candidate.employment && candidate.employment[0] && candidate.employment[0].title ? candidate.employment[0].companyName : '',
            'Current Job Title': candidate.employment && candidate.employment[0] && candidate.employment[0].title ? candidate.employment[0].title : '',
            'Job Title': job && job.title ? job.title : '',
            'Job Company': job && job.company && job.company.name ? job.company.name : '',
            'Job Preferences': candidate.preferredSkills && candidate.preferredSkills.map((attribute) => attribute),
            'Candidate Attributes': candidate.attributes && candidate.attributes.map((attribute) => attribute),
            'Remote (Y/N)': candidate.locationPreferences && candidate.locationPreferences.remote ? 'Y' : 'N',
            'Willing to Relocate (Y/N)': candidate.locationPreferences && candidate.locationPreferences.willingToRelocate ? 'Y' : 'N',
            'Interested Cities': candidate.interestedCities ? candidate.interestedCities : '',
            'Candidate Profile ID': get(candidate, 'id', '-'),
            'Candidate Invite Code': get(candidate, 'invite_code', '-'),
            'App Downloaded (Y/N)': get(candidate, 'is_login', '0') === '1' && (get(candidate, 'device_token', '') || '').length > 0 ? 'Y' : 'N',
          };
        })
      : [];

  return (
    <CSVDownloader
      data={csvData}
      type="anchor"
      filename={
        activeTab == 'matched' ? 'matched_candidates' : activeTab == 'interested' ? 'interested_candidates' : activeTab == 'interviewing' ? 'interviewing_candidates' : 'candidates'
      }
      className={`bg-red text-white font-medium rounded flex items-center justify-center`}
      style={{ fontSize: 14, height: 40, width: 150, marginRight: 10 }}
    >
      <span style={{ fontSize: 22, marginRight: 8 }}></span> Download Data
    </CSVDownloader>
  );
}
