import React from 'react';
import DollarIcon from '../../images/dollarsign.svg';

export default function SalaryFilter({ value, update, style }) {
  return (
    <div className="flex items-center">
      <div className="relative">
        <input
          placeholder="0"
          min="0"
          value={value.min || ''}
          onChange={(e) => update({ ...value, min: e.target.value })}
          type="number"
          className="bg-white rounded shadow text-xs"
          style={{ WebkitAppearance: 'none', height: 40, width: 150, paddingLeft: 25, paddingRight: 20 }}
        />
        <img alt="" src={DollarIcon} style={{ height: 14, left: 10, top: 13, margin: 0 }} className="absolute" />
      </div>
      <div className="flex items-center mx-sm">-</div>
      <div className="relative">
        <input
          placeholder="0"
          value={value.max || ''}
          onChange={(e) => update({ ...value, max: e.target.value })}
          type="number"
          min="1"
          className="bg-white rounded shadow text-xs"
          style={{ WebkitAppearance: 'none', height: 40, width: 150, paddingLeft: 25, paddingRight: 20 }}
        />
        <img alt="" src={DollarIcon} style={{ height: 14, left: 10, top: 13, margin: 0 }} className="absolute" />
      </div>
    </div>
  );
}
