import { useSubscription } from '@apollo/react-hooks';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { CSVDownloader } from 'react-papaparse';
import { SearchBox, TabTitle } from '../../components/common';
import CherryPagination from '../../components/common/Pagination/Pagination';
import AlphabeticalSortButton from '../../components/common/AlphabeticalSortButton';
import { ALL_COMPANIES, ALL_COMPANIES_TOTAL } from '../../queries';
import SubscriberEntry from './subscriber-entry';
import { DEFAULT_PER_PAGE } from '../../utils/constants';

export default function Subscribers() {
  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  // Aggregate of the subscribers that is use to show the page count
  const { data: companiesTotal } = useSubscription(ALL_COMPANIES_TOTAL);

  useEffect(() => {
    if (companiesTotal) {
      setTotal(companiesTotal?.company_aggregate?.aggregate.count || 0);
    }
  }, [companiesTotal]);

  const [subscribers, setSubscribers] = useState();
  const { data: subscribersQuery, loading } = useSubscription(ALL_COMPANIES, {
    variables: {
      perPage: perPage,
      offset: page * perPage,
      is_like: `%${searchInput}%`,
      order_by: { name: sortDirection },
    },
  });

  useEffect(() => {
    if (subscribersQuery) {
      setSubscribers([]);
      setTimeout(() => {
        setSubscribers(subscribersQuery.company);
      }, 1);
    }
  }, [subscribersQuery]);

  function _prepareSubscriber(subs) {
    return {
      Name: subs.name,
      Email: subs.email,
      Size: subs.size,
      'Website URL': subs.websiteURL,
      'Logo URL': subs.logoURL,
      'Candidate Requested': subs.candidate_requests_aggregate.aggregate.count,
      'Total Credit': subs.totalCredit,
      'Remaining Credit': subs.remainingCredit,
      Bio: subs.bio,
    };
  }

  function handlePaginationChange(event) {
    setPage(event.page);
    setPerPage(event.perPage);
  }

  const csvData = subscribers ? subscribers.map(_prepareSubscriber) : [];

  return (
    <div className="flex flex-col h-full pb-lg">
      <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
        <TabTitle>{'Subscribers'}</TabTitle>
        <CSVDownloader
          data={csvData}
          type="anchor"
          filename={'subscribers'}
          className={`bg-red text-white font-medium rounded flex items-center justify-center`}
          style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
        >
          <span style={{ fontSize: 22, marginRight: 8 }}></span> Download CSV
        </CSVDownloader>
        <div className="import_csv flex justify-between lg:mt-0 mt-sm">
          <Link
            className={`bg-green text-white font-medium rounded flex items-center justify-center`}
            style={{ fontSize: 14, height: 40, width: 160 }}
            to="/subscribers/new-subscriber"
          >
            <span style={{ fontSize: 22, marginRight: 8 }}>+</span> Add Subscriber
          </Link>
        </div>
      </div>
      <div className="mb-4">
        <SearchBox value={searchInput} placeholder="Search by company name or email" update={setSearchInput} />
      </div>

      <AlphabeticalSortButton sortDirection={sortDirection} setSortDirection={setSortDirection} />
      <div className="bg-white rounded shadow flex-1 w-full mt-4" style={{ maxWidth: 1340, overflow: 'auto', height: '700px' }}>
        {subscribers ? (
          subscribers.length > 0 ? (
            subscribers.map((subscriber, index) => <SubscriberEntry key={index} subscriber={subscriber} />)
          ) : (
            <div className="w-full h-full flex items-center justify-center text-darkgray">No companies available</div>
          )
        ) : null}
      </div>
      <div className="subscribers-js" style={{ float: 'right' }}>
        <div className="subscription-pagination">
          <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
        </div>
      </div>
    </div>
  );
}
