import { navigate } from 'gatsby';
import React from 'react';
import DollarSign from '../../images/dollarsign.svg';
import BriefcaseIcon from '../../images/experience.png';
import LocationPin from '../../images/location.svg';
import UserAvatar from '../../images/user-avatar.png';
import { formatSalary } from '../../utils/formatter';
import './candidates.css';

export default function CandidateEntry({ candidate, selected, toggleCandidate }) {
  const { profilePictureURL, name, employment, location, experience, salaryMin } = candidate;

  return (
    <tr>
      <td>
        <div className="flex items-center candidate-name" style={{}}>
          <div
            style={{
              margin: 0,
              height: 40,
              width: 40,
              borderRadius: 40,
              backgroundImage: `url(${profilePictureURL ? profilePictureURL : UserAvatar})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              marginRight: 15,
            }}
          />
          <div className="flex-1" style={{ paddingLeft: 0 }}>
            <div className="flex flex-1 flex-col">
              <div
                className="hover:underline lg:p-0 cursor-pointer lg:text-sm"
                style={{ fontWeight: 500 }}
                onClick={() => navigate(`/candidates/${candidate.id}`, { state: { candidate } })}
              >
                {name}
              </div>
              {employment && employment.length > 0 && (
                <div className="flex items-center font-medium overflow-hidden relative" style={{ fontSize: 14, height: 21 }}>
                  <div className="absolute inset-0">
                    <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                      {employment[0].title}
                    </div>
                    <div className="mr-sm text-darkgray" tyle={{ whiteSpace: 'nowrap' }}>
                      |
                    </div>
                    <div className="text-darkgray">{employment[0].companyName}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </td>

      <td>
        <div style={{}}>
          <div className="text-xs text-darkgray flex items-center mr-sm lg:mr-0" style={{ fontWeight: 500, justifyContent: 'center', textAlign: 'center' }}>
            <img alt="" src={BriefcaseIcon} style={{ height: 20, margin: 0, marginLeft: 2, marginRight: 8 }} />
            {experience} years
          </div>
        </div>
      </td>

      <td>
        <div style={{ textAlign: 'center' }}>
          <div className="text-xs text-darkgray flex items-center mr-sm lg:mr-0" style={{ fontWeight: 500, justifyContent: 'center', textAlign: 'center' }}>
            {salaryMin ? <img alt="" src={DollarSign} style={{ height: 14, margin: 0, marginLeft: 2, marginRight: 12 }} /> : ''}
            {salaryMin ? `${formatSalary({ salary: salaryMin })} +` : 'Negotiable'}
          </div>
        </div>
      </td>

      <td>
        <div style={{ textAlign: 'center' }}>
          <div className="text-xs text-darkgray flex items-center" style={{ fontWeight: 500, justifyContent: 'center', textAlign: 'center' }}>
            <img alt="" src={LocationPin} style={{ height: 14, margin: 0, marginRight: 10 }} />
            {location && location.city}
          </div>
        </div>
      </td>

      <td>
        <div className="flex" style={{ textAlign: 'center', justifyContent: 'center' }}>
          <div className="absolute lg:static" style={{ right: 0, top: 25 }}>
            <div
              onClick={() => toggleCandidate(!selected)}
              className={`border border-green cursor-pointer ${selected ? 'bg-green' : ''}`}
              style={{ width: 14, height: 14, marginRight: 10 }}
            />
          </div>
        </div>
      </td>
    </tr>
  );
}
