import React from 'react';
import { CSVDownloader } from 'react-papaparse';

export default function SampleCsvJobs() {
  return (
    <CSVDownloader
      data={[
        {
          Title: 'Test job one',
          Description: 'description lorem ipsum',
          Company: 'Test company one',
          'Min Salary ($)': '10',
          'Max Salary ($) ': '50',
          Compensation: 'any compensation info',
          Location: 'New York',
          Industry: 'Accounting',
          'Min Experience': 2,
          'Max Experience': 10,
          Notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
          'Optional Attributes': 'Accounting Manager,Adobe',
          'Required Attributes': 'Accounting Manager,Adobe',
          'Allow Remote ?': 'Yes',
          'Hide Company from Candidates ?': 'No',
        },
        {
          Title: 'Test job two',
          Description: 'description lorem ipsum',
          Company: 'Test company two',
          'Min Salary ($)': '5',
          'Max Salary ($) ': '40',
          Compensation: 'any compensation info',
          Location: 'Miami, FL, USA',
          Industry: 'Advertising',
          'Min Experience': 5,
          'Max Experience': 11,
          Notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
          'Optional Attributes': 'Coding,Architecture',
          'Required Attributes': 'Coding,Architecture',
          'Allow Remote ?': 'No',
          'Hide Company from Candidates ?': 'Yes',
        },
      ]}
      type="anchor"
      filename={'sample_jobs'}
      className={`bg-red text-white font-medium rounded flex items-center justify-center`}
      style={{ fontSize: 14, height: 40, width: 195, marginRight: 10 }}
    >
      <span style={{ fontSize: 22, marginRight: 8 }}></span> Download Sample CSV
    </CSVDownloader>
  );
}
