import React, { useEffect, useState } from "react";
import CreateCompanyForm from "../../forms/company/CreateCompanyForm";

export default function EditCompany({ location }) {

    return (
        <CreateCompanyForm 
            company={location.state.company}
        />
    );
}
