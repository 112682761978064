import React, { useState, useEffect } from 'react';
import AddIcon from '../../../images/add-green.png';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import Moment from 'moment';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

const ADD_NOTE = gql`
  mutation addNote($candidate_id: String, $notes: jsonb) {
    update_candidate(where: { id: { _eq: $candidate_id } }, _set: { notes: $notes }) {
      affected_rows
    }
  }
`;

export default function AdditionalNotes({ notes, candidateID }) {
  const [value, setValue] = useState();
  const [candidateNotes, setCandidateNotes] = useState(notes ? (typeof notes === 'string' ? JSON.parse(notes) : notes) : []);
  const [addCandidateNote, { data }] = useMutation(ADD_NOTE);

  const isToday = (someDate) => {
    const today = new Date();
    return someDate.getDate() == today.getDate() && someDate.getMonth() == today.getMonth() && someDate.getFullYear() == today.getFullYear();
  };

  const addNote = () => {
    if (value) {
      const newNote = { date: new Date(), value };
      addCandidateNote({
        variables: {
          candidate_id: candidateID,
          notes: [newNote, ...candidateNotes],
        },
      });
      setCandidateNotes([newNote, ...candidateNotes]);
      setValue('');
    }
  };

  useEffect(() => {
    if (notes) {
      setCandidateNotes(typeof notes === 'string' ? JSON.parse(notes) : notes);
    }
  }, [notes]);

  return (
    <div className="bg-white flex flex-col rounded shadow mb-lg w-full text-darkgray text-center  text-sm h-full" style={{ maxWidth: 767, padding: 40, height: 318 }}>
      <div className="flex-1 w-full flex flex-col overflow-auto" style={{ height: 163 }}>
        {candidateNotes.map(({ date, ...note }) => {
          const dateString = isToday(new Date(date)) ? Moment(date).format('h:mm a') : Moment(date).format('MMM. D');
          return (
            <div className="text-left text-darkblue mb-md" style={{ fontSize: 12 }}>
              <div className="text-darkgray font-medium">{dateString}</div>
              {note.value}
            </div>
          );
        })}
      </div>
      <div className="relative">
        <input
          onChange={(e) => setValue(e.target.value)}
          value={value}
          placeholder="Add note..."
          className={`w-full rounded border placeholder-primary text-sm px-md`}
          style={{ height: 55, marginTop: 20, borderColor: '#c4cad3' }}
        />
        <div onClick={addNote} className="absolute cursor-pointer" style={{ right: 15, top: 34 }}>
          <img src={AddIcon} style={{ margin: 0, height: 25 }} />
        </div>
      </div>
    </div>
  );
}
