import React, { useEffect, useState } from 'react';
import { TabTitle } from '../../components/common';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { gql } from 'apollo-boost';
import { useSubscription } from '@apollo/react-hooks';
import SubscriberDropdown from '../../components/Filter/SubscriberFilter';
import { CSVDownloader } from 'react-papaparse';

const PAYMENT_HISTORY = gql`
  subscription candidates($company_id: [uuid!], $start_date: String, $end_date: String) {
    payment_history(order_by: { date: desc }, where: { company_id: { _in: $company_id }, date: { _gte: $start_date, _lte: $end_date } }) {
      company {
        id
        name
        email
      }
      date
      credits
      type
      candidate {
        id
        name
      }
      company_id
    }
  }
`;

export default function CreditTransactionHistory () {
  const [startDate, setStartDate] = useState(
    moment()
      .startOf('month')
      .toDate(),
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [companyIds, setCompanyIds] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [selectedSubscribers, setSelectedSubscribers] = useState([]);

  const { data: payments, loading } = useSubscription(PAYMENT_HISTORY, {
    variables: {
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      end_date: moment(endDate).format('YYYY-MM-DD'),
      company_id: companyIds.length ? companyIds : null,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (payments) {
      setActivityData(payments.payment_history);
    }
  }, [payments]);

  useEffect(() => {
    setCompanyIds(selectedSubscribers.map((a) => a.id));
  }, [selectedSubscribers]);

  function _mapData (activity) {
    let activityType = '';

    switch (activity.type) {
      case 1:
        activityType = 'Purchased';
        break;
      case 2:
        activityType = 'Used';
        break;
      case 3:
        activityType = 'Admin added';
        break;
      case 4:
        activityType = 'Admin removed';
        break;
      default:
        break;
    }

    return {
      Date: moment(activity.date).format('MM/DD/YYYY'),
      'Subscriber Company Name': activity.company ? activity.company.name : 'N/A',
      'Subscriber Contact email': activity.company ? activity.company.email : 'N/A',
      Type: activityType,
      Credits: activity.credits,
      'Name of Candidate': activity.candidate ? activity.candidate.name : 'N/A',
    };
  }

  const csvData = activityData ? activityData.map(_mapData) : [];

  return (
    <div className="w-full flex-1 candidates">
      <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
        <TabTitle showBackButton={true}>Credit Transaction History</TabTitle>
        {activityData && activityData.length > 0 && (
          <CSVDownloader
            data={csvData}
            type="anchor"
            filename={'credit_transaction_history'}
            className={`bg-red text-white font-medium rounded flex items-center justify-center`}
            style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
          >
            <span style={{ fontSize: 22, marginRight: 8 }}></span>Download CSV
          </CSVDownloader>
        )}
      </div>
      <div className="h-full flex flex-col" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
        <div style={{ marginRight: 20 }}>
          <span>Start Date: </span>
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={moment(new Date()).toDate()} />
        </div>

        <div style={{ marginRight: 20 }}>
          <span>End Date: </span>
          <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={moment(new Date()).toDate()} />
        </div>

        <div style={{ marginRight: 20 }}>
          <span>Subscriber: </span>
          <SubscriberDropdown
            value={selectedSubscribers}
            update={(e) => {
              setSelectedSubscribers(e);
            }}
          />
        </div>
      </div>

      <div className={`w-full flex flex-col`} style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
        {selectedSubscribers.length > 0 ? <div style={{ marginTop: 16, marginRight: 10, fontWeight: 600 }}>Subscribers</div> : ''}
        <div className={`flex flex-wrap filter-attributes`} style={{ marginTop: 6, top: 40 }}>
          {selectedSubscribers.map((subscriber, index) => (
            <div
              key={index}
              className="border flex text-darkgray border-darkgray flex items-center px-md"
              style={{ height: 28, fontSize: 12, borderRadius: 28, marginRight: 10, marginTop: 10, whiteSpace: 'nowrap' }}
            >
              {subscriber.name}
              <div
                onClick={() => setSelectedSubscribers(selectedSubscribers.filter((val) => val.id !== subscriber.id))}
                className="ml-sm text-sm relative cursor-pointer"
                style={{ left: 5, width: 10, height: 10 }}
              >
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(45deg)', borderRadius: 1.5 }} />
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(-45deg)', borderRadius: 1.5 }} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={`flex-1 bg-white rounded shadow`} style={{ overflowX: 'auto', height: '700px', minHeight: 500, maxHeight: 'calc(100vh - 200px)', marginBottom: 100 }}>
        <table style={{ width: '98%', marginLeft: '1%', marginRight: '1%' }}>
          <thead style={{ fontSize: 14, fontWeight: 'bold' }}>
            <tr style={{ position: 'sticky', top: 0, background: 'white' }}>
              <th style={{ textAlign: 'center', maxWidth: 110 }}>Date</th>
              <th style={{ textAlign: 'left' }}>Subscriber Company Name</th>
              <th style={{ textAlign: 'left' }}>Subscriber Contact email</th>
              <th style={{ textAlign: 'left' }}>Type</th>
              <th style={{ textAlign: 'left' }}>Credits</th>
              <th style={{ textAlign: 'left' }}>Name of Candidate</th>
            </tr>
          </thead>

          <tbody>
            {(loading ? [] : activityData || []).map((activity) => {
              let activityType = '';

              switch (activity.type) {
                case 1:
                  activityType = 'Purchased';
                  break;
                case 2:
                  activityType = 'Used';
                  break;
                case 3:
                  activityType = 'Admin added';
                  break;
                case 4:
                  activityType = 'Admin removed';
                  break;
                default:
                  break;
              }

              return (
                <tr style={{ fontSize: 14 }}>
                  <td>
                    <div className="font-medium text-darkgray">{moment(activity.date).format('MM/DD/YYYY')}</div>
                  </td>

                  <td>
                    <div className="font-medium text-darkgray">{activity.company ? activity.company.name : 'N/A'}</div>
                  </td>

                  <td>
                    <div className="font-medium text-darkgray">{activity.company ? activity.company.email : 'N/A'}</div>
                  </td>

                  <td>
                    <div className="font-medium text-darkgray">{activityType}</div>
                  </td>

                  <td>
                    <div className="font-medium text-darkgray">{activity.credits}</div>
                  </td>

                  <td>
                    <div className="font-medium text-darkgray">{activity.candidate ? activity.candidate.name : 'N/A'}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {loading && (
          <div className={`flex justify-center items-center flex-1 candidate-blank`} style={{ width: '100%' }}>
            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
              Loading
            </div>
          </div>
        )}

        {!loading && activityData && activityData.length === 0 && (
          <div className={`flex justify-center items-center flex-1 candidate-blank`} style={{ width: '100%' }}>
            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
              No Records found
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
