import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import DeleteUsersButton from '../../../components/common/DeleteUsersButton';

export default function MobileCandidateOptions({ candidate, isAdmin }) {
  const [open, setOpen] = useState(false);

  const hideDropdown = () => setOpen(false);

  useEffect(() => {
    if (open) {
      window.addEventListener('click', hideDropdown);
    } else {
      window.removeEventListener('click', hideDropdown);
    }
  }, [open]);

  return (
    <div className="relative sm:hidden">
      <div
        className="flex"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        {Array(3)
          .fill('')
          .map((a, index) => (
            <div key={index} style={{ height: 6, width: 6, borderRadius: 6, marginRight: 2 }} className="bg-darkgray " />
          ))}
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className={`absolute bg-white rounded shadow ${open ? 'flex' : 'hidden'} flex-col text-sm font-medium text-darkblue overflow-hidden`}
        style={{ zIndex: 300, right: -10, width: 200, top: 30 }}
      >
        {isAdmin ? null : (
          <div
            onClick={() => navigate('/chat', { state: { candidate: { id: candidate.id, name: candidate.name, profilePicture: candidate.profilePictureURL, messages: [] } } })}
            className="px-md py-sm hover:bg-lightgray"
          >
            Chat with Candidate
          </div>
        )}
        <div onClick={() => navigate(`/candidates/${candidate.id}/edit`, { state: { candidate } })} className="px-md py-sm hover:bg-lightgray">
          Edit Candidate
        </div>
        <DeleteUsersButton selectedCandidates={[candidate && candidate.id]} componentType="mobile" type="candidate" />
      </div>
    </div>
  );
}
