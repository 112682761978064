export const subscriber = [
  {
    key: 'icon',
    label: ' ',
    customClasses: 'pl-4',
    customDivStyles: {},
  },
  {
    key: 'name',
    label: 'Candidate Details',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'experience',
    label: 'Years of Experience',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'salaryMin',
    label: 'Minimum Salary Requirement',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'location.city',
    label: 'Location',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'favorites',
    label: 'Favorites',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'action',
    label: 'Action',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
];

export const admin = [
  {
    key: 'icon',
    label: '',
    customClasses: 'pl-4',
    customDivStyles: {},
  },
  {
    key: 'name',
    label: 'Candidate Details',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'name',
    label: 'Recruiter Name',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'experience',
    label: 'Experience',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'salaryMin',
    label: 'Salary',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'location.city',
    label: 'Location',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'invitation',
    label: 'Invitation',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'notify',
    label: '',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'action',
    label: 'Select/DeSelect',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
];

export const recuriter = [
  {
    key: 'icon',
    label: '',
    customClasses: 'pl-4',
    customDivStyles: {},
  },
  {
    key: 'name',
    label: 'Candidate Details',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'experience',
    label: 'Experience',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'salaryMin',
    label: 'Salary',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'location.city',
    label: 'Location',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'status',
    label: 'Status',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'chat',
    label: 'Chat',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'action',
    label: 'Select/DeSelect',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
];