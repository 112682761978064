import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { UserRoleContext } from '../../context';

const ALL_RECRUITER_ACTIVITY = gql`
  subscription getRecruiterActivity {
    recruiter(order_by: { created_at: desc }) {
      id
      name
      email
      created_at
      candidates_aggregate {
        aggregate {
          count
        }
      }
      jobs_aggregate(where: { subscriber_id: { _is_null: true } }) {
        aggregate {
          count
        }
        nodes {
          id
          filled
          closed
          created_date
        }
      }
      candidates {
        company_requests {
          candidate_id
          company_id
          date
          status
          isDirectRequest
          company {
            id
            adminID
          }
        }
      }
      company {
        id
        name
      }
    }
  }
`;

const ALL_SUBSCRIBER_ACTIVITY = gql`
  subscription getSubscriberActivity {
    company {
      id
      name
      email
      remainingCredit
      subscription_type
      candidate_requests_aggregate {
        aggregate {
          count
        }
        nodes {
          candidate_id
          company_id
          status
          credit_amount
          date
        }
      }
      jobs_aggregate {
        aggregate {
          count
        }
        nodes {
          id
          filled
          closed
        }
      }
      company_credits {
        id
        candidate_id
        company_id
        credits
        credit_amount
        date
        type
      }
    }
  }
`;

const SUBSCRIBER_CREDIT_REPORT = gql`
  subscription getSubscriberCreditReport {
    company_request(where: { status: { _eq: "approved" } }) {
      candidate_id
      company_id
      credit_amount
      date
      status
      company {
        id
        name
        email
      }
      candidate {
        id
        name
        email
        recruiter {
          id
          name
          email
          phone
          company {
            id
            name
          }
        }
      }
    }
  }
`;

const RECRUITER_CREDIT_ACTIVITY = gql`
  subscription getCreditActivity($recruiter_id: String, $start_date: date, $end_date: date) {
    company_request(where: { status: { _eq: "approved" }, candidate: { recruiter_id: { _eq: $recruiter_id } }, date: { _gte: $start_date, _lte: $end_date } }) {
      candidate_id
      company_id
      status
      credit_amount
      date
      candidate {
        id
        name
        email
        created_at
      }
      company {
        id
        name
        email
      }
    }
  }
`;

const RECRUITER_JOB_OUTPUT = gql`
  subscription getJobOutput($recruiter_id: String) {
    job_candidate(where: { job: { recruiter_id: { _eq: $recruiter_id } } }, order_by: { job: { created_at: desc } }) {
      candidate_id
      job_id
      status
      created_at
      candidate {
        id
        name
        email
      }
      job {
        id
        title
        location
        salaryMin
        salaryMax
        company {
          id
          name
        }
        created_at
      }
    }
  }
`;

const CANDIDATE_INTRODUCTION_REPORT = gql`
  subscription getCandidateIntroductionReport {
    company_request_history_aggregate {
      aggregate {
        count
      }
    }
  }
`;

const CardWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;

  @media only screen and (max-width: 640px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export default function Reports () {
  const [userRole] = useContext(UserRoleContext);
  const isRecruiter = userRole === 'recruiter';
  const isAdmin = userRole === 'admin';

  const [totalRecruiterActivityRow, setTotalRecruiterActivityRow] = useState();
  const [totalSubscriberActivityRow, setTotalSubscriberActivityRow] = useState();
  const [totalSubscriberCreditReportRow, setTotalSubscriberCreditReportRow] = useState();
  const [totalCandidateIntroductionReportRow, setTotalCandidateIntroductionReportRow] = useState();

  const [totalRecruiterCreditActivity, setTotalRecruiterCreditActivity] = useState();
  const [totalRecruiterJobOutput, setTotalRecruiterJobOutput] = useState();

  const { data: recruiterActivity } = useSubscription(ALL_RECRUITER_ACTIVITY, {
    fetchPolicy: 'network-only',
  });

  const { data: subscriberActivity } = useSubscription(ALL_SUBSCRIBER_ACTIVITY, {
    fetchPolicy: 'network-only',
  });

  const { data: recruiterCreditActivity } = useSubscription(RECRUITER_CREDIT_ACTIVITY, {
    variables: {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
    fetchPolicy: 'network-only',
  });

  const { data: subscriberCreditReport } = useSubscription(SUBSCRIBER_CREDIT_REPORT, {
    fetchPolicy: 'network-only',
  });

  const { data: recruiterJobOutput } = useSubscription(RECRUITER_JOB_OUTPUT, {
    variables: {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
    fetchPolicy: 'network-only',
  });

  const { data: candidateIntroductionReport } = useSubscription(CANDIDATE_INTRODUCTION_REPORT, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (recruiterActivity) {
      setTotalRecruiterActivityRow(recruiterActivity.recruiter.length);
    } else {
      setTotalRecruiterActivityRow(0);
    }

    if (subscriberActivity) {
      setTotalSubscriberActivityRow(subscriberActivity.company.length);
    } else {
      setTotalSubscriberActivityRow(0);
    }

    if (subscriberCreditReport) {
      setTotalSubscriberCreditReportRow(subscriberCreditReport.company_request.length);
    } else {
      setTotalSubscriberCreditReportRow(0);
    }

    if (recruiterCreditActivity) {
      setTotalRecruiterCreditActivity(recruiterCreditActivity.company_request.length);
    } else {
      setTotalRecruiterCreditActivity(0);
    }

    if (recruiterJobOutput) {
      setTotalRecruiterJobOutput(recruiterJobOutput.job_candidate.length);
    } else {
      setTotalRecruiterJobOutput(0);
    }

    if (candidateIntroductionReport) {
      setTotalCandidateIntroductionReportRow(candidateIntroductionReport.company_request_history_aggregate.aggregate.count);
    } else {
      setTotalCandidateIntroductionReportRow(0);
    }
  }, [recruiterActivity, subscriberActivity, subscriberCreditReport, recruiterCreditActivity, recruiterJobOutput, candidateIntroductionReport]);

  return (
    <div className={`flex flex-col flex-1`} style={{ maxHeight: 'calc(100vh - 176px)' }}>
      <div className="flex justify-between mb-xxl lg:mb-lg" style={{ marginTop: 30 }}>
        <div className="font-medium" style={{ fontSize: 21 }}>
          Reports
        </div>
      </div>
      <div className="flex flex-wrap pb-lg flex-1">
        <div className="flex self-start content-start flex-wrap w-full">
          {isAdmin && (
            <CardWrapper className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
              <div className="bg-white h-full rounded shadow flex flex-col" style={{ padding: 30 }}>
                <div className="font-medium text-darkblue" style={{ fontSize: 18 }}>
                  <div className="cursor-pointer">Recruiter Activity</div>
                  <div
                    className="cursor-pointer text-darkgray flex items-center px-sm "
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {totalRecruiterActivityRow}
                  </div>
                </div>
                <div
                  className="text-green hover:underline cursor-pointer font-semibold flex justify-end"
                  style={{ fontSize: 14 }}
                  onClick={() => navigate('/dashboard/recruiter-activity')}
                >
                  View More
                </div>
              </div>
            </CardWrapper>
          )}
          {isAdmin && (
            <CardWrapper className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
              <div className="bg-white h-full rounded shadow flex flex-col" style={{ padding: 30 }}>
                <div className="font-medium text-darkblue" style={{ fontSize: 18 }}>
                  <div className="cursor-pointer">Subscriber Activity</div>
                  <div
                    className="cursor-pointer text-darkgray flex items-center px-sm "
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {totalSubscriberActivityRow}
                  </div>
                </div>
                <div
                  className="text-green hover:underline cursor-pointer font-semibold flex justify-end"
                  style={{ fontSize: 14 }}
                  onClick={() => navigate('/dashboard/subscriber-activity')}
                >
                  View More
                </div>
              </div>
            </CardWrapper>
          )}
          {isAdmin && (
            <CardWrapper className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
              <div className="bg-white h-full rounded shadow flex flex-col" style={{ padding: 30 }}>
                <div className="font-medium text-darkblue" style={{ fontSize: 18 }}>
                  <div className="cursor-pointer">Subscriber Credit Report</div>
                  <div
                    className="cursor-pointer text-darkgray flex items-center px-sm "
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {totalSubscriberCreditReportRow}
                  </div>
                </div>
                <div
                  className="text-green hover:underline cursor-pointer font-semibold flex justify-end"
                  style={{ fontSize: 14 }}
                  onClick={() => navigate('/dashboard/subscriber-credit-report')}
                >
                  View More
                </div>
              </div>
            </CardWrapper>
          )}
          {isAdmin && (
            <CardWrapper className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
              <div className="bg-white h-full rounded shadow flex flex-col" style={{ padding: 30 }}>
                <div className="font-medium text-darkblue" style={{ fontSize: 18 }}>
                  <div className="cursor-pointer">Candidate Introduction Report</div>
                  <div className="cursor-pointer text-darkgray flex items-center px-sm " style={{ fontWeight: 'bold' }}>
                    {totalCandidateIntroductionReportRow}
                  </div>
                </div>
                <div
                  className="text-green hover:underline cursor-pointer font-semibold flex justify-end"
                  style={{ fontSize: 14 }}
                  onClick={() => navigate('/dashboard/candidate-introduction-report')}
                >
                  View More
                </div>
              </div>
            </CardWrapper>
          )}
          {isAdmin && (
            <CardWrapper className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
              <div className="bg-white h-full rounded shadow flex flex-col" style={{ padding: 30 }}>
                <div className="font-medium text-darkblue" style={{ fontSize: 18 }}>
                  <div className="cursor-pointer">Credit Transaction History</div>
                </div>
                <div
                  className="text-green hover:underline cursor-pointer font-semibold flex justify-end"
                  style={{ fontSize: 14 }}
                  onClick={() => navigate('/dashboard/credit-transaction-history')}
                >
                  View More
                </div>
              </div>
            </CardWrapper>
          )}
          {isRecruiter && (
            <CardWrapper className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
              <div className="bg-white h-full rounded shadow flex flex-col" style={{ padding: 30 }}>
                <div className="font-medium text-darkblue" style={{ fontSize: 18 }}>
                  <div className="cursor-pointer">Recruiter Credit Activity</div>
                  <div
                    className="cursor-pointer text-darkgray flex items-center px-sm "
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {totalRecruiterCreditActivity}
                  </div>
                </div>
                <div
                  className="text-green hover:underline cursor-pointer font-semibold flex justify-end"
                  style={{ fontSize: 14 }}
                  onClick={() => navigate('/dashboard/recruiter-credit-activity')}
                >
                  View More
                </div>
              </div>
            </CardWrapper>
          )}
          {isRecruiter && (
            <CardWrapper className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
              <div className="bg-white h-full rounded shadow flex flex-col" style={{ padding: 30 }}>
                <div className="font-medium text-darkblue" style={{ fontSize: 18 }}>
                  <div className="cursor-pointer">Recruiter Job Output</div>
                  <div
                    className="cursor-pointer text-darkgray flex items-center px-sm "
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {totalRecruiterJobOutput}
                  </div>
                </div>
                <div
                  className="text-green hover:underline cursor-pointer font-semibold flex justify-end"
                  style={{ fontSize: 14 }}
                  onClick={() => navigate('/dashboard/recruiter-job-output')}
                >
                  View More
                </div>
              </div>
            </CardWrapper>
          )}
        </div>
      </div>
    </div>
  );
}
