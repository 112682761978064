import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVDownloader } from 'react-papaparse';
import { TabTitle } from '../../components/common';
import FacebookIcon from '../../images/facebook.svg';
import InstagramIcon from '../../images/instagram.svg';
import LinkedInIcon from '../../images/linkedin-logo.png';
import TwitterIcon from '../../images/twitter.svg';
import './candidates.css';

const ModalContext = React.createContext();

const ALL_COMPANY_REQUEST_HISTORY = gql`
  query candidateIntroductionReport($startDate: String, $endDate: String) {
    company_request_history(order_by: { created_at: desc }, where: { created_at: { _gte: $startDate, _lte: $endDate } }) {
      candidate_id
      status
      id
      created_at
      accepted_at
      company_id
      company {
        id
        name
        email
      }
      candidate {
        name
        id
        email
        phone
        twitter
        linkedin
        facebook
        instagram
        location
        employment
        is_login
        device_token
        invite_code
        recruiter {
          id
          name
          email
          phone
        }
      }
    }
  }
`;

export default function CandidateIntroductionReport({ isDashboard }) {
  const [modal, setModal] = useState({ data: null, open: false });
  const [companyRequestHistoryData, setCompanyRequestHistoryData] = useState();

  const [startDate, setStartDate] = useState(
    moment()
      .startOf('month')
      .toDate(),
  );
  const [endDate, setEndDate] = useState(new Date());

  const { data: companyRequestHistoryResponse, loading } = useSubscription(ALL_COMPANY_REQUEST_HISTORY, {
    variables: {
      startDate: startDate != null ? moment(startDate).format('YYYY-MM-DD') : null,
      endDate: moment(endDate).format('YYYY-MM-DD'),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (companyRequestHistoryResponse) {
      setCompanyRequestHistoryData(companyRequestHistoryResponse.company_request_history);
    }
  }, [companyRequestHistoryResponse]);

  function _mapData(row) {
    const { created_at: createdAt, candidate, company, accepted_at: acceptedAt } = row;
    const { recruiter } = candidate || { recruiter: {} };

    const lastEmployment = (candidate || { employment: [] }).employment[0] || { companyName: '', title: '' };
    const location = (candidate || {}).location || { city: '' };

    return {
      'Request Sent On': createdAt || '-',
      'Request Accepted On': acceptedAt || '-',
      'Subscriber Company Name': get(company, 'name', '-'),
      'Subscriber Company Email': get(company, 'email', '-'),
      'Candidate Full Name': get(candidate, 'name', '-'),
      'Candidate Email': get(candidate, 'email', '-'),
      'Candidate Phone': get(candidate, 'phone', '-'),
      'Recruiter Full Name': get(recruiter, 'name', '-'),
      'Recruiter Email': get(recruiter, 'email', '-'),
      'Recruiter Phone': get(recruiter, 'phone', '-'),
      'Candidate Linkedin URL': get(candidate, 'linkedin', '-'),
      'Candidate Facebook URL': get(candidate, 'facebook', '-'),
      'Candidate Instagram': get(candidate, 'instagram', '') ? `https://instagram.com/${candidate.instagram}` : '-',
      'Candidate Twitter Handle': get(candidate, 'twitter', '') ? `https://twitter.com/${candidate.twitter}` : '-',
      'Candidate Location': location.city || '-',
      'Candidate Current Company': lastEmployment.companyName || '-',
      'Candidate Current Job Title': lastEmployment.title || '-',
      'Candidate Profile ID': get(candidate, 'id', '-'),
      'Candidate Invite Code': get(candidate, 'invite_code', '-'),
      'App Downloaded (Y/N)': get(candidate, 'is_login', '0') === '1' && (get(candidate, 'device_token', '') || '').length > 0 ? 'Y' : 'N',
    };
  }

  const csvData = companyRequestHistoryData ? companyRequestHistoryData.map(_mapData) : [];

  return (
    <div className="w-full flex-1 candidates">
      <ModalContext.Provider value={[modal, setModal]}>
        <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
          <TabTitle showBackButton={true}>Candidate Introduction Report</TabTitle>
          {companyRequestHistoryData && companyRequestHistoryData.length > 0 && (
            <CSVDownloader
              data={csvData}
              type="anchor"
              filename={'candidate_introduction_report'}
              className={`bg-red text-white font-medium rounded flex items-center justify-center`}
              style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
            >
              <span style={{ fontSize: 22, marginRight: 8 }}></span>Download CSV
            </CSVDownloader>
          )}
        </div>
        <div className="h-full flex flex-col" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
          <div style={{ marginRight: 20 }}>
            <span>Start Date: </span>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={moment(new Date()).toDate()} />
          </div>
          <div>
            <span>End Date: </span>
            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={moment(new Date()).toDate()} />
          </div>
        </div>
        <div className={`flex-1 bg-white rounded shadow candidates-container candidate-blank-dash`} style={{ overflowX: 'auto' }}>
          <table style={{ width: '98%', marginLeft: '1%', marginRight: '1%' }}>
            <thead style={{ fontSize: 14, fontWeight: 'bold' }}>
              <tr style={{ position: 'sticky', top: 0, background: 'white' }}>
                <th style={{ textAlign: 'center', minWidth: 120 }}>Request Sent On</th>
                <th style={{ textAlign: 'center', minWidth: 130 }}>Request Accepted On</th>
                <th style={{ textAlign: 'left' }}>
                  <div className="flex flex-col">
                    <div className="lg:p-0">Company Name</div>
                    <div className="lg:p-0 font-medium">
                      <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                        Company Email
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="lg:p-0">Candidate Name</div>
                    <div className="lg:p-0 font-medium">
                      <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                        Candidate Email
                      </div>
                    </div>
                    <div className="lg:p-0 font-medium">
                      <div className="mr-sm text-darkgray">Candidate Phone</div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="lg:p-0">Recruiter Name</div>
                    <div className="lg:p-0 font-medium">
                      <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                        Recruiter Email
                      </div>
                    </div>
                    <div className="lg:p-0 font-medium">
                      <div className="mr-sm text-darkgray">Recruiter Phone</div>
                    </div>
                  </div>
                </th>
                <th style={{ textAlign: 'center' }}>Links</th>
                <th style={{ textAlign: 'center' }}>Location</th>
                <th style={{ wordWrap: 'break-word', maxWidth: 200 }}>
                  <div className="flex flex-col">
                    <div className="lg:p-0">Current Company</div>
                    <div className="lg:p-0 font-medium">
                      <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                        Job Title
                      </div>
                    </div>
                  </div>
                </th>
                <th style={{ textAlign: 'center' }}>Profile id</th>
                <th style={{ textAlign: 'center' }}>App Downloaded</th>
              </tr>
            </thead>

            <tbody>
              {(loading ? [] : companyRequestHistoryData || []).map((row) => {
                const { created_at: createdAt, candidate, company, accepted_at: acceptedAt } = row;
                const { recruiter } = candidate || { recruiter: {} };

                const lastEmployment = (candidate || { employment: [] }).employment[0] || { companyName: '', title: '' };
                const location = (candidate || {}).location || { city: '' };

                return (
                  <tr style={{ fontSize: 14 }}>
                    <td>
                      <div className="font-medium" style={{ textAlign: 'center' }}>
                        {createdAt || '-'}
                      </div>
                    </td>

                    <td>
                      <div className="font-medium" style={{ textAlign: 'center' }}>
                        {acceptedAt || '-'}
                      </div>
                    </td>

                    <td>
                      <div className="flex flex-col">
                        <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                          {get(company, 'name', '-')}
                        </div>
                        <div className="lg:p-0 font-medium">
                          <div className="text-darkblue mr-sm">{get(company, 'email', '-')}</div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="flex flex-col">
                        <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                          {get(candidate, 'name', '-')}
                        </div>
                        <div className="lg:p-0 font-medium">
                          <div className="text-darkblue mr-sm">{get(candidate, 'email', '-')}</div>
                        </div>
                        <div className="lg:p-0 font-medium">
                          <div className="mr-sm text-darkgray">{get(candidate, 'phone', '-')}</div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="flex flex-col">
                        <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                          {get(recruiter, 'name', '-')}
                        </div>
                        <div className="lg:p-0 font-medium">
                          <div className="text-darkblue mr-sm">{get(recruiter, 'email', '-')}</div>
                        </div>
                        <div className="lg:p-0 font-medium">
                          <div className="mr-sm text-darkgray">{get(recruiter, 'phone', '-')}</div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="flex mb-md items-center" style={{ justifyContent: 'center' }}>
                        {get(candidate, 'linkedin', '') && (
                          <a rel="noreferrer" href={candidate.linkedin} target="_blank" className="cursor-pointer mr-sm flex">
                            <img alt="" src={LinkedInIcon} style={{ width: 18, margin: 0 }} />
                          </a>
                        )}

                        {get(candidate, 'facebook', '') && (
                          <a rel="noreferrer" href={candidate.facebook} target="_blank" className="cursor-pointer mr-sm flex">
                            <img alt="" src={FacebookIcon} style={{ width: 18, margin: 0 }} />
                          </a>
                        )}

                        {get(candidate, 'instagram', '') && (
                          <a rel="noreferrer" href={`https://instagram.com/${candidate.instagram}`} target="_blank" className="cursor-pointer mr-sm flex">
                            <img alt="" src={InstagramIcon} style={{ width: 18, margin: 0 }} />
                          </a>
                        )}

                        {get(candidate, 'twitter', '') && (
                          <a rel="noreferrer" href={`https://twitter.com/${candidate.twitter}`} target="_blank" className="cursor-pointer mr-sm flex">
                            <img alt="" src={TwitterIcon} style={{ width: 18, margin: 0 }} />
                          </a>
                        )}

                        {!get(candidate, 'linkedin', '') && !get(candidate, 'facebook', '') && !get(candidate, 'instagram', '') && !get(candidate, 'twitter', '') && '-'}
                      </div>
                    </td>

                    <td>
                      <div className="font-medium" style={{ textAlign: 'center' }}>
                        {location.city || '-'}
                      </div>
                    </td>

                    <td>
                      <div className="flex flex-col">
                        <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                          {lastEmployment.companyName || '-'}
                        </div>
                        <div className="lg:p-0 font-medium">
                          <div className="text-darkblue mr-sm">{lastEmployment.title || '-'}</div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="font-medium" style={{ textAlign: 'center' }}>
                        {get(candidate, 'id', '-')}
                      </div>
                    </td>

                    <td>
                      <div className="font-medium" style={{ textAlign: 'center' }}>
                        {get(candidate, 'is_login', '0') === '0' ? 'N' : 'Y'}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {loading && (
            <div className={`flex justify-center items-center flex-1 candidate-blank${isDashboard ? '-dash' : ''}`} style={{ width: '100%' }}>
              <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                Loading
              </div>
            </div>
          )}
        </div>
      </ModalContext.Provider>
    </div>
  );
}
