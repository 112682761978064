import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import TextInput from '../fields/TextInput';
import useDropdownList from '../../hooks/useDropdownList';
import { navigate } from 'gatsby';
import firebase from 'firebase/app';
import 'firebase/auth';
import ProfileAvatar from '../../images/user-avatar.png';
import Arrow from '../../components/common/Arrow';
import CandidateSkills from '../fields/CandidateSkills';
import * as Yup from 'yup';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorMessage from '../fields/ErrorMessage';

const RECRUITER_CANDIDATES = gql`
  query candidatesByRecruiterId($recruiterID: String) {
    candidate(where: { recruiter_id: { _eq: $recruiterID } }) {
      id
      name
    }
  }
`;
function CandidateDropdown({ value, update, error }) {
  const { data } = useQuery(RECRUITER_CANDIDATES, {
    variables: {
      recruiterID: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });
  const [candidates, setCandidates] = useState([]);
  const [dropdownOpen, toggleDropdown] = useState(false);
  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    if (data) {
      setCandidates(data.candidate);
    }
  }, [data]);

  const hideDropdown = () => toggleDropdown(false);

  useEffect(() => {
    if (dropdownOpen) {
      window.addEventListener('click', hideDropdown);
    } else {
      return window.removeEventListener('click', hideDropdown);
    }
  }, [dropdownOpen]);

  return (
    <div>
      <div className="relative">
        <input
          value={inputValue}
          onFocus={() => toggleDropdown(true)}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            const text = e.target.value;
            setInputValue(text);
          }}
          placeholder="Select Candidates"
          className="relative placeholder-primary w-full border rounded text-sm"
          style={{
            zIndex: 501,
            height: 55,
            marginTop: 20,
            borderColor: error ? '#E31C25' : '#c4cad3',
            paddingLeft: 15,
            paddingRight: 15,
          }}
        />
        <div className="absolute" style={{ zIndex: 502, right: 20, top: 42 }}>
          <Arrow color={'#c4cad3'} direction="down" />
        </div>

        {dropdownOpen && candidates.length > 0 && <DropdownOptions value={value} candidates={candidates} inputValue={inputValue} update={update} />}
      </div>
      <div className="border rounded overflow-auto" style={{ borderColor: '#c4cad3', marginTop: 20, height: 220 }}>
        {value.length > 0 ? (
          value.map((candidate) => (
            <div className="text-sm py-sm px-md border-b cursor-pointer hover:bg-lightgray flex justify-between items-center">
              {candidate.name}
              <Xbutton close={() => update(value.filter(({ id }) => id !== candidate.id))} />
            </div>
          ))
        ) : (
          <div className="text-sm text-darkgray h-full w-full flex items-center justify-center">No selected candidates</div>
        )}
      </div>
    </div>
  );
}

const Xbutton = ({ close }) => {
  return (
    <div onClick={close} className="ml-sm text-sm relative" style={{ left: 5, width: 10, height: 10 }}>
      <div className="absolute inset-0">
        <div
          className="bg-darkgray absolute"
          style={{
            height: 10,
            width: 1.5,
            transform: 'rotate(45deg)',
            borderRadius: 1.5,
          }}
        />
        <div
          className="bg-darkgray absolute"
          style={{
            height: 10,
            width: 1.5,
            transform: 'rotate(-45deg)',
            borderRadius: 1.5,
          }}
        />
      </div>
    </div>
  );
};

function DropdownOptions({ candidates, inputValue, value, update }) {
  const [options, filterOptions] = useDropdownList(candidates, 'name');
  const addedCandidates = value;

  const toggleCandidate = (candidate) => {
    if (addedCandidates.filter(({ id }) => id === candidate.id).length > 0) {
      update(addedCandidates.filter(({ id }) => id !== candidate.id));
    } else {
      update([...addedCandidates, { id: candidate.id, name: candidate.name }]);
    }
  };

  useEffect(() => {
    filterOptions(inputValue);
  }, [inputValue]);

  return (
    <div
      className="absolute bg-white w-full border-r border-l overflow-auto shadow"
      style={{
        top: 'calc(100% - 10px)',
        zIndex: 500,
        paddingTop: 10,
        maxHeight: 350,
      }}
    >
      {options.map((option) => {
        const isAdded = addedCandidates.filter((candidate) => candidate.id === option.id).length > 0;
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              toggleCandidate(option);
            }}
            style={{ height: 50 }}
            className={`${isAdded ? 'bg-green text-white' : 'bg-white text-darkblue hover:bg-lightgray'} text-sm border-b cursor-pointer flex justify-between items-center`}
          >
            <div className="flex items-center">
              <div
                className="ml-sm"
                style={{
                  height: 30,
                  width: 30,
                  backgroundImage: `url(${option.profilePicture ? option.profilePicture : ProfileAvatar})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
              <div className="px-md">{option.name}</div>
            </div>
            <input type="checkbox" checked={isAdded} className="relative cursor-pointer mr-md" style={{ bottom: 1, right: 5 }} />
          </div>
        );
      })}
    </div>
  );
}

export default function ProjectForm({ onSubmit }) {
  const initialValues = {
    name: '',
    candidates: [],
    tags: [],
  };

  const projectSchema = Yup.object().shape({
    name: Yup.string().required('Project Title is required'),
    candidates: Yup.mixed().test({
      name: 'Candidates',
      message: 'At least one candidate is required',
      test: (collection) => collection.length > 0,
    }),
    tags: Yup.mixed().test({
      name: 'Tag',
      message: 'At least one tag is required',
      test: (collection) => collection.length > 0,
    }),
  });

  return (
    <div className="w-full bg-white rounded shadow mb-lg" style={{ padding: 30 }}>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={projectSchema}>
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <TextInput value={values.name} update={(name) => setFieldValue('name', name)} placeholder="Project Title" />
            {touched.name && errors.name && <ErrorMessage error={touched.name && errors.name} />}
            <CandidateDropdown value={values.candidates} update={(candidates) => setFieldValue('candidates', candidates)} />
            {touched.candidates && errors.candidates && <ErrorMessage error={touched.candidates && errors.candidates} />}
            <CandidateSkills value={values.tags} type="skills" placeholder="Add Tags" update={(tags) => setFieldValue('tags', tags)} />
            {touched.tags && errors.tags && <ErrorMessage error={touched.tags && errors.tags} />}
            <div
              className="flex relative justify-center"
              style={{
                height: 55,
                marginBottom: 32,
                marginTop: 60,
              }}
            >
              <button
                onClick={() => navigate('/dashboard/projects')}
                type="button"
                className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray"
                style={{ width: '49%', fontSize: 14 }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded bg-red ml-sm font-medium  flex justify-center text-sm text-white items-center"
                style={{
                  width: '49%',
                  fontSize: 14,
                  boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)',
                }}
              >
                Save & Complete
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
