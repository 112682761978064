import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import Calendar from "react-calendar";
import ProfileAvatar from "../../images/user-avatar.png";
import { navigate } from "gatsby";
import { gql } from "apollo-boost";
import Moment from "moment";
import firebase from "firebase/app";
import "firebase/auth";


const RECRUITER_INTERVIEWS = gql`
query findAllRecruiterInterviews($id: String) {
  job_interview(where: {candidate: {recruiter_id: {_eq: $id}}}, order_by: {date: asc}) {
    id
    type
    time
    date
    job_id
    job {
      company {
        name
      }
      title
    }
    candidate_id
    candidate {
      name
      profilePictureURL
    }
  }
  interview(where: {recruiter_id: {_eq: $id}}, order_by: {date: asc}) {
    time
    date
    candidate_id
    candidate {
        name
        profilePictureURL
    }
  }
}

`;

function CalendarColumn ({ interviews }) {
  const [date, setDate] = useState(new Date());
  const [dateInterviews, setDateInterviews] = useState([]);

  const isSameDate = (someDate, today = new Date()) => {
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    if (interviews) {
      setDateInterviews(
        interviews.filter(
          interview => Moment(date).format("YYYY-MM-DD") === interview.date
        )
      );
    }
  }, [interviews, date]);

  const getTitle = () => {
    let dayName;
    if (isSameDate(date)) {
      dayName = "Today";
    } else if (
      isSameDate(
        date,
        Moment(new Date())
          .add(1, "days")
          .toDate()
      )
    ) {
      dayName = "Tomorrow";
    } else if (
      isSameDate(
        date,
        Moment(new Date())
          .add(-1, "days")
          .toDate()
      )
    ) {
      dayName = "Yesterday";
    } else {
      dayName = Moment(date).format("dddd");
    }

    return `${dayName}, ${Moment(date).format("MMMM D")}`;
  };

  return (
    <div
      className="bg-white rounded shadow flex flex-col items-center sm:flex-row lg:flex-col mb-xl lg:mb-0 lg:mr-xl px-sm w-full lg:w-auto"
      style={{ paddingTop: 30, minHeight: 520, maxHeight: "calc(100vh - 272px)" }}>
      <Calendar
        value={date}
        onChange={setDate}
        formatShortWeekday={(locale, date) => Moment(date).format("dd")}
      />
      <div
        style={{ height: 'calc(100% - 120px)', overflowY: 'auto' }}
        className="flex flex-col border-l-0 sm:border-l lg:border-l-0 sm:pl-md pt-md sm:pt-0 border-t sm:border-t-0 mx-sm my-md mt-md sm:mt-0 lg:mt-md w-full px-md">
        <div className="font-semibold text-darkblue" style={{ fontSize: 14 }}>
          {getTitle()}
        </div>
        {dateInterviews.length > 0 ? (
          dateInterviews.map(interview => (
            <div
              className="flex flex-col font-medium text-darkgray"
              style={{ fontSize: 14, marginTop: 20 }}>
              <div style={{ marginBottom: 5 }}>{interview.time != 'undefined undefined' ? interview.time : 'No time set'}</div>
              <div
                onClick={() =>
                  navigate(`/candidates/${interview.candidate_id}`, {
                    state: {
                      candidate: {
                        id: interview.candidate_id,
                        ...interview.candidate,
                      },
                      from: "/dashboard/interviews",
                    },
                  })
                }
                className="text-darkblue hover:underline cursor-pointer"
                style={{ fontSize: 16 }}>
                {interview.candidate.name}
              </div>
              {interview.job &&
                <div
                  onClick={() =>
                    navigate(`/jobs/${interview.job_id}`, {
                      state: {
                        job_id: interview.job_id,
                        from: "/dashboard/interviews"
                      },
                    })
                  }
                  className="hover:underline cursor-pointer">
                  {interview.job.title} @ {interview.job.company.name}
                </div>}
            </div>))
        ) : (
          <div className="mt-sm text-darkgray" style={{ fontSize: 14 }}>
            No interviews
          </div>
        )}
      </div>
    </div>
  );
}

function InterviewEntry ({ interview }) {
  const [fade, setFade] = useState(false);

  useEffect(() => {
    setFade(true);
  }, []);

  return (
    <div
      className="flex items-center border-b flex-wrap py-md"
      style={{
        borderColor: "#e8ecf2",
        opacity: fade ? 1 : 0,
        transition: "opacity 0.3s ease-in-out",
      }}>
      <div
        className="flex flex-col font-medium order-2 sm:order-1"
        style={{ width: 115 }}>
        <div className="text-darkblue" style={{ fontSize: 16 }}>
          {Moment(interview.date).format("MMMM D")}
        </div>
        <div className="text-darkgray" style={{ fontSize: 14 }}>
          {interview.time != 'undefined undefined' ? interview.time : 'No time set'}
        </div>
      </div>
      <div className="flex items-center order-1 sm:order-2 w-full sm:w-auto mb-md">
        <div
          style={{
            height: 48,
            width: 48,
            borderRadius: 24,
            marginRight: 16,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: interview.candidate.profilePictureURL
              ? `url(${interview.candidate.profilePictureURL})`
              : `url(${ProfileAvatar}`,
          }}
        />
        <div className="flex flex-col font-medium">
          <div
            onClick={() =>
              navigate(`/candidates/${interview.candidate_id}`, {
                state: {
                  candidate: {
                    id: interview.candidate_id,
                    ...interview.candidate,
                  },
                  from: "/dashboard/interviews",
                },
              })
            }
            className="text-darkblue hover:underline cursor-pointer"
            style={{ fontSize: 16 }}>
            {interview.candidate.name}
          </div>
          {interview.job &&
            <div
              onClick={() =>
                navigate(`/jobs/${interview.job_id}`, {
                  state: {
                    job_id: interview.job_id,
                    from: "/dashboard/interviews",
                  },
                })
              }
              className="text-darkblue flex cursor-pointer hover:underline"
              style={{ fontSize: 14 }}>
              <div>{`${interview.job.title}`} &nbsp;</div>
              <div className="text-darkgray">
                |&nbsp;
                {`${interview.job.company.name}`}
              </div>
            </div>}
        </div>
      </div>
      <div className="flex flex-1 justify-end order-3">
        <button
          onClick={() => {
            navigate("/chat", {
              state: {
                candidate: {
                  id: interview.candidate_id,
                  name: interview.candidate.name,
                  profilePicture: interview.candidate.profilePictureURL,
                  messages: [],
                },
                from: "Interviews",
              },
            });
          }}
          className="border border-green rounded text-green flex items-center justify-center hover:bg-green hover:text-white px-md py-sm"
          style={{
            fontSize: 14,
          }}>
          Chat
        </button>
      </div>
    </div>
  );
}

export default function Interviews () {
  const [interviews, setInterviews] = useState()
  const { data } = useQuery(RECRUITER_INTERVIEWS, {
    variables: {
      id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data) {
      const { job_interview, interview } = data
      setInterviews([...job_interview, ...interview].sort((a, b) => {
        const aDate = new Date(`${a.date} ${a.time}`)
        const bDate = new Date(`${b.date} ${b.time}`)
        return bDate - aDate
      }))
    }
  }, [data])

  const filteredInterviews = interviews && interviews.length > 0 &&
    interviews.filter(interview => {
      return Moment().add(-1, 'days').isSameOrBefore(Moment(interview.date))
    })

  return (
    <div className="flex flex-col flex-1 pb-lg">
      <div
        className="font-medium text-darkblue text-center lg:text-left"
        style={{ fontSize: 21, marginTop: 40, marginBottom: 20 }}>
        Upcoming Interviews
      </div>
      <div
        className="flex flex-1 flex-wrap lg:flex-no-wrap"
        style={{ minHeight: "calc(100vh - 276px)" }}>
        <CalendarColumn interviews={interviews} />
        <div
          className="flex flex-col bg-white rounded shadow w-full"
          style={{
            paddingRight: 40,
            paddingLeft: 40,
            height: "calc(100vh - 272px)",
            overflowY: 'auto'
          }}>
          {interviews && interviews.length > 0 ?
            filteredInterviews.length > 0 ?
              filteredInterviews.map(interview => <InterviewEntry interview={interview} />) :
              <div className="flex items-center justify-center w-full h-full font-medium text-darkgray">
                <div className='bg-lightgray py-sm px-md' style={{ borderRadius: 43 }}>
                  No upcoming interviews scheduled
                </div>
              </div> :
            <div className="flex items-center justify-center w-full h-full font-medium text-darkgray">
              <div className='bg-lightgray py-sm px-md' style={{ borderRadius: 43 }}>
                No interviews scheduled
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
}
