import React, { useState, useContext, useEffect } from "react";
import UserRoleContext from "../../context/UserRole";
import Loader from "react-loader-spinner";
import { gql } from "apollo-boost";
import { useQuery, useMutation, useSubscription } from "@apollo/react-hooks";
import firebase from "firebase/app";

const EMAIL_NOTIFICATIONS = gql`
query getNotifications {
    email_notifications(where: {user_type: {_eq: "recruiter"}}) {
      id
      notification_id
      notification_text
      user_type
    }
}
`;

const NOTIFICATION_SETTING = gql`
subscription getNotiSettings($recruiter_id:String) {
    notification_settings(where: {recruiter_id: {_eq: $recruiter_id}}) {
      id
      notification_ids
      user_type
      recruiter_id
    }
  }  
`;

const UPDATE_NOTIFICATION = gql`
mutation updateNotificationSetting($recruiter_id:String,$notification_ids:jsonb) {
    update_notification_settings(where: {recruiter_id: {_eq: $recruiter_id}}, _set: {notification_ids: $notification_ids}) {
      affected_rows
      returning {
        id
      }
    }
  }   
`;
const CREATE_NOTIFICATION = gql`  
  mutation createNotificationSetting($notification_ids:jsonb,$recruiter_id:String,$user_type:String) {
    insert_notification_settings(objects: {notification_ids: $notification_ids, recruiter_id: $recruiter_id, user_type: $user_type}) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export default function EmailNotifications({
    navigate
}) {
    const [userRole] = useContext(UserRoleContext);
    const isSubscriber = userRole === "company";

    const [list, setList] = useState();
    const [flag, setFlag] = useState(true);

    const { data } = useQuery(EMAIL_NOTIFICATIONS);

    useEffect(() => {
        if (data) {
            setList(data.email_notifications);
        }
    }, [data]);

    const [selectedNotifications, setSelectedNotifications] = useState([]);

    const { data: settings } = useSubscription(NOTIFICATION_SETTING,
        {
            variables: {
                recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid
            }
        });
    useEffect(() => {
        if (settings && settings.notification_settings && settings.notification_settings[0] && settings.notification_settings[0].notification_ids) {
            setSelectedNotifications(settings.notification_settings[0].notification_ids);
            setFlag(true);
        } else {
            setFlag(false);
        }
    }, [settings]);

    const toggleNotification = notification_id => {
        if (selectedNotifications.includes(notification_id)) {
            setSelectedNotifications(
                selectedNotifications.filter(id => id !== notification_id)
            );
        } else {
            setSelectedNotifications([
                ...selectedNotifications,
                notification_id,
            ]);
        }
    }

    const [updateNotificationSetting, { data: updatedata, loading: updateloading }] = useMutation(UPDATE_NOTIFICATION);
    const [createNotificationSetting, { data: createdata, loading: createloading, error }] = useMutation(CREATE_NOTIFICATION);

    const updateNotification = () => {
        if (flag) {
            updateNotificationSetting({
                variables: {
                    recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
                    notification_ids: selectedNotifications
                }
            });
        } else {
            createNotificationSetting({
                variables: {
                    notification_ids: selectedNotifications,
                    recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
                    user_type: "recruiter",
                }
            });
        }
    }

    useEffect(() => {
        if (createdata || updatedata) {
            navigate('Profile');
        }
    }, [createdata, updatedata]);

    return (
        <div
            className="flex justify-center items-center pb-lg mx-md"
            style={{ top: 20, left: 0, right: 0, minHeight: "100%" }}>
            <div
                className="bg-white rounded flex flex-col"
                style={{ width: 420, minHeight: `${isSubscriber ? 650 : 756}`, padding: 40 }}
                onClick={e => e.stopPropagation()}>
                <div className="text-lg font-medium pb-sm border-b mb-lg">
                    Email Notification Settings
                </div>
                <div>
                    Notify me when:
                </div>
                {list && list.length > 0 && list.map(noti =>
                (
                    <div className="flex items-center border-b">
                        <div className="flex flex-col notificationcss">
                            {noti.notification_text}
                        </div>
                        <div
                            onClick={() => { toggleNotification(noti.notification_id) }}
                            className={`${selectedNotifications.includes(noti.notification_id) ? "bg-green" : ""}
                            } border border-green cursor-pointer`}
                            style={{
                                width: 14,
                                height: 14,
                                marginLeft: 30
                            }}
                        />
                    </div>
                )
                )}
                <div className="flex w-full" style={{ marginTop: 20 }}>
                    <button
                        onClick={() => navigate("Profile")}
                        type="button"
                        style={{ height: 55 }}
                        className="bg-lightgray flex items-center justify-center shadow text-xs font-medium flex-1 text-darkgray rounded mr-sm p-sm"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => updateNotification()}
                        type="submit"
                        style={{
                            height: 55,
                            boxShadow: "0 14px 10px 0 rgba(255, 0, 0, 0.18)",
                        }}
                        className="bg-red flex items-center justify-center  shadow ml-sm text-xs font-medium flex-1 text-white rounded p-sm"
                    >
                        {(updateloading || createloading) ? (
                            <Loader
                                type="TailSpin"
                                color="#FFFFFF"
                                height={20}
                                width={20}
                            />
                        ) : (
                            "Update"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}
