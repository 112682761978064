import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { CandidateListItem } from '.';
import { USER_ROLES } from '../../../utils/constants';
import { CandidateEntry } from '../../candidates/candidates';
import RecruiterCandidateDetail from '../recruiter-candidate-detail';
import SubscriberCandidateDetail from '../subscriber-candidate-detail';

const GET_COMPANY_ID = gql`
  query getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      remainingCredit
      subscription_type
    }
  }
`;

/**
 * 
 * @param {Object} props Table Props
 * @param {String} props.ROLE User Role
 * @param {Array<Candidate>} props.selectedCandidates Selected Candidates
 * @param {Function} props.onCandidateSelect Updated selected candidate state
 * @param {Function} props.onChange Update candidate rows
 * @returns {React.FunctionComponent} CandidateList
 */
const CandidateList = forwardRef((props, ref) => {
  const { data } = useQuery(GET_COMPANY_ID, {
    variables: {
      admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });


  const [detailModal, setDetailModal] = useState({ data: null, open: false });

  useImperativeHandle(ref, () => ({}));

  if ([USER_ROLES.ADMIN, USER_ROLES.RECURITER].includes(props.ROLE)) {
    return (
      <>
        {props.candidates.map(({ candidate }, index) => (
          <CandidateEntry
            key={index}
            candidate={candidate}
            isAdmin={props.ROLE === USER_ROLES.ADMIN}
            isSelected={props.selectedCandidates.includes(candidate.id)}
            toggleCandidate={() => {
              if (props.selectedCandidates.includes(candidate.id)) {
                props.onCandidateSelect(props.selectedCandidates.filter((id) => id !== candidate.id));
              } else {
                props.onCandidateSelect([...props.selectedCandidates, candidate.id]);
              }
            }}
            state={[setDetailModal]} />
        ))}
        <tr className="modal-row" key={'recuriter-candidate-detail-modal'}>
          <td>
            <RecruiterCandidateDetail state={[detailModal, setDetailModal]} />
          </td>
        </tr>
      </>)
  }

  return (
    <>
      {props.candidates.map((candidate, index) => (
        <tr className="CandidateList_row p-4 border-b-2 text-sm  " key={(props.candidate && props.candidate.id) || index}>
          <CandidateListItem
            candidate={candidate}
            ROLE={props.ROLE}
            companyID={data && data.company && data.company[0] && data.company[0].id}
            remainingCredit={data && data.company && data.company[0] && data.company[0].remainingCredit}
            subscriptionType={data && data.company && data.company[0] && data.company[0].subscription_type}
            isRequestedCandidates={props.isRequestedCandidates}
            isInterestedCandidates={props.isInterestedCandidates}
            isSavedCandidates={props.isSavedCandidates}
            isFavoriteCandidates={props.isFavoriteCandidates}
            state={[setDetailModal]}
            onChange={props.onChange}
          />
        </tr>
      ))}
      <SubscriberCandidateDetail state={[detailModal, setDetailModal]} />
    </>
  );
});

export default CandidateList;