import React, { useEffect, useState } from "react";

export default function FormSlide({
  children,
  index,
  currentSlide,
  noWhiteBox,
  maxHeightState,
}) {
  const [isActive, setIsActive] = useState(
    index === currentSlide ? true : false
  );
  const [isVisible, setIsVisible] = useState(isActive);

  useEffect(() => {
    if (isActive && index !== currentSlide) {
      setIsActive(false);
      setTimeout(() => {
        setIsVisible();
      }, 300);
    } else if (index === currentSlide) {
      setIsActive(true);
      setIsVisible(true);
    }
  }, [currentSlide]);

  return (
    <div
      className="FormSlide-js w-full flex pb-lg justify-center absolute"
      style={{
        opacity: isActive ? 1 : 0,
        /* maxHeight: !isActive && 'calc(100vh - 170px)', */ transition:
          "opacity 0.3s ease-in-out",
        zIndex: isActive ? 1000 : 0,
        minWidth: 320,
      }}>
      {isVisible && (
        <div
          className={`${!noWhiteBox &&
            "w-full bg-white rounded shadow"} flex flex-col items-center`}
          style={{
            minWidth: 380,
            display: isVisible ? "flex" : "none",
            padding: noWhiteBox ? 0 : 30,
            transition: "transform 0.3s ease-in-out",
            transform: `translateX(${
              isActive ? 0 : index > currentSlide ? 100 : -100
            }%)`,
          }}>
          {children}
        </div>
      )}
    </div>
  );
}
