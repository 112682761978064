import { navigate } from 'gatsby';
import React, { useState } from 'react';
import BackArrow from '../../images/back-arrow.svg';
import FormSlide from './FormSlide';

const Indicator = ({ isActive }) => (
  <div
    className="rounded"
    style={{
      height: 3,
      width: isActive ? 20 : 10,
      marginRight: 10,
      transition: 'all 0.3s ease-in-out',
      backgroundColor: isActive ? '#6ACA63' : '#c4cad3',
    }}
  />
);

export default function FormSlider({ slides, action, globalFormData, noWhiteBox, back, isEdit }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [maxHeight, setMaxHeight] = useState();
  const slideEntries = Object.entries(slides);

  const goBack = (index) => {
    if (index > 0) {
      setCurrentSlide(index - 1);
    } else {
      if (back) {
        navigate(back.link);
      } else {
        window.history.back();
      }
    }
  };

  const goForward = (index) => {
    setCurrentSlide(index + 1);
  };

  return (
    <div className="FormSlider-js py-lg w-full h-full relative container flex flex-col items-center">
      {back && (
        <div
          onClick={() => navigate(back.link)}
          className="lg:absolute w-full lg:w-auto cursor-pointer text-darkgray flex items-center font-main text-xs"
          style={{ left: 30, top: 30 }}
        >
          <img src={BackArrow} style={{ height: 13, marginBottom: 0, marginRight: 8 }} />
          {back.label}
        </div>
      )}
      <div className="font-main text-darkblue" style={{ fontWeight: 500, fontSize: 21 }}>
        {slideEntries[currentSlide][0]}
      </div>
      <div className="flex p-md mb-md">
        {slideEntries.map((_, index) => (
          <Indicator key={index} isActive={index === currentSlide ? true : false} />
        ))}
      </div>
      <div className="flex flex-1 w-full justify-center relative" /* style={{transform: `translateX(${slide ? -206 : 198}px)`,transition: "transform 0.3s ease-in-out"}} */>
        {slideEntries.map(([_, component], index) => {
          const Form = component;
          return (
            <FormSlide key={index} maxHeightState={[maxHeight, setMaxHeight]} currentSlide={currentSlide} index={index} noWhiteBox={index === noWhiteBox ? true : false}>
              <Form onSubmit={action} globalFormData={globalFormData} goBack={() => goBack(index)} goForward={() => goForward(index)} isEdit={isEdit} />
            </FormSlide>
          );
        })}
      </div>
    </div>
  );
}
