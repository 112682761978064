import React from 'react';
import CandidateDashboard from '../candidate-dashboard';
import { REQUEST_STATUSES as STATUSES } from '../../../utils/constants';

const InterestedCandidates = () => <CandidateDashboard
  BASE_FILTER={`where: {company_requests: {_and: {status: {_in: ["${STATUSES.interested}", "${STATUSES.accepted}"]}, company: {adminID: {_eq: $admin_id}}}}}`}
  searchPlaceholder="Job title"
  context="interested-candidate"
  showInterestedCandidates={true}
/>

export default InterestedCandidates;
