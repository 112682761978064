import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { TabTitle } from '../../components/common';
import CheckIcon from '../../components/common/CheckIcon';
import XIcon from '../../components/common/XIcon';
import { EXPRESS_SERVER_URL } from '../../config';
import { ConfirmModalContext } from '../../context';
import CaretDownGray from '../../images/caret-down-gray.png';
import CaretDown from '../../images/caret-down.png';
import EmailIcon from '../../images/email.svg';
import LinkedinLogo from '../../images/linkedin-logo.png';
import LocationIcon from '../../images/location.svg';
import PhoneIcon from '../../images/phone.svg';
import UserAvatar from '../../images/user-avatar.png';

const GET_CANDIDATE_APPLICANTS = gql`
  query getCandidatesByRecruiter($user_id: String) {
    candidate(where: { approved: { _eq: false }, recruiter_id: { _eq: $user_id } }) {
      id
      profilePictureURL
      name
      email
      phone
      resumeFileName
      resumeURL
      linkedin
      referralName
      recruiter {
        name
      }
      location
      salaryMax
      salaryMin
      facebook
      instagram
      twitter
      experience
      bio
      employment
      education
      certifications
      attributes
      preferredSkills
      notes
      locationPreferences
      interestedCity
      is_login
      device_token
      invite_code
    }
  }
`;

const REMOVE_CANDIDATES = gql`
  mutation removeCandidates($users: [String!]) {
    delete_candidate(where: { id: { _in: $users } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

function CandidateQueueEntry({ candidate, refetchCandidates }) {
  const [, setModal] = useContext(ConfirmModalContext);
  const [deleteUsers] = useMutation(REMOVE_CANDIDATES);
  const [openSnackbar] = useSnackbar();
  const linkedInLink = (candidate.linkedin || '').replace('https://', '');

  return (
    <div className="flex px-lg">
      <div className="flex border-b w-full items-center" style={{ height: 100 }}>
        <div style={{ width: 64, paddingRight: 15 }}>
          <div
            style={{
              margin: 0,
              height: 49,
              width: 49,
              borderRadius: 40,
              backgroundImage: `url(${candidate.profilePictureURL ? candidate.profilePictureURL : UserAvatar})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          />
        </div>
        <div className="flex flex-col" style={{ width: 300 }}>
          <div
            className="text-sm text-darkblue font-medium hover:underline lg:p-0 cursor-pointer lg:text-sm"
            onClick={() => navigate(`/candidates/${candidate.id}`, { state: { candidate } })}
          >
            {candidate.name}
          </div>
          {candidate.linkedin && (
            <div
              onClick={() => window.open(`https://${linkedInLink}`)}
              className="flex text-darkgray font-medium items-center hover:underline cursor-pointer"
              style={{ fontSize: 12 }}
            >
              <div className="flex items-center justify-center border border-darkgray" style={{ height: 25, width: 25, borderRadius: 25, borderWidth: 2, marginRight: 5 }}>
                <img alt="" src={LinkedinLogo} style={{ height: 10, width: 10, margin: 0 }} />
              </div>
              View Linkedin
            </div>
          )}
        </div>
        <div className="flex text-xs text-darkgray font-medium" style={{ width: 180 }}>
          <img alt="" src={PhoneIcon} style={{ width: 15, height: 18, margin: 0, position: 'relative', top: 2, marginRight: 5 }} />
          {candidate.phone}
        </div>
        <div className="flex text-xs text-darkgray font-medium" style={{ width: 250, paddingRight: 20 }}>
          <img alt="" src={EmailIcon} style={{ width: 15, height: 18, margin: 0, position: 'relative', top: 3, marginRight: 5 }} />
          <div style={{ maxWidth: 215, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{candidate.email}</div>
        </div>
        {candidate.location && candidate.location.city && (
          <div className="flex text-xs text-darkgray font-medium">
            <img alt="" src={LocationIcon} style={{ width: 15, height: 18, margin: 0, position: 'relative', top: 2, marginRight: 5 }} />
            <div style={{ maxWidth: 180, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{candidate.location.city}</div>
          </div>
        )}
        <div className="flex flex-1 justify-end">
          <div
            onClick={() => {
              setModal({
                open: true,
                title: 'Decline Candidate',
                message: 'Are you sure you would like to decline this candidate?',
                buttonText: `I'm Sure`,
                cancelText: 'Nevermind',
                closeInstant: true,
                action: () => {
                  setModal({});
                  openSnackbar(`Deleting Candidate: ${candidate.name}`);

                  fetch(`${EXPRESS_SERVER_URL}/removeCandidates`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ candidates: [candidate.id] }),
                  })
                    .then((res) => res.json())
                    .then(async (users) => {
                      await deleteUsers({
                        variables: { users: [candidate.id] },
                      });

                      openSnackbar(`Candidate deleted: ${candidate.name}`);

                      refetchCandidates();
                    });
                },
              });
            }}
            className="rounded py-sm px-md bg-lightgray text-darkgray hover:bg-red hover:text-white font-medium text-sm mr-md cursor-pointer flex items-center"
          >
            <div className="mr-sm">Decline</div>
            <XIcon />
          </div>
          <div
            onClick={() => {
              setModal({
                open: true,
                title: 'Approve Candidate',
                message: 'Are you sure you would like to approve this candidate?',
                buttonText: `I'm Sure`,
                cancelText: 'Nevermind',
                closeInstant: true,
                action: () => {
                  setModal({});
                  navigate(`/candidates/${candidate && candidate.id}/edit`, { state: { candidate, approveCandidate: true } });
                },
              });
            }}
            className="rounded py-sm px-md bg-lightgreen text-green hover:bg-green hover:text-white font-medium text-sm mr-md cursor-pointer flex items-center"
          >
            <div className="mr-sm">Approve</div>
            <CheckIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

//there's a component for this in the common folder
function AlphabeticalSort({ sortState }) {
  const [sortType, setSortType] = sortState;
  const [direction, setDirection] = useState('desc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ type: 'alphabetical', direction: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ type: 'alphabetical', direction: 'desc' });
    }
  }

  return (
    <div className="flex mr-md">
      <div
        onClick={setType}
        className={`text-sm ${
          sortType.type === 'alphabetical' ? 'bg-darkblue text-white' : 'text-darkgray border border-darkgray'
        }   flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
      >
        A - Z
        <img
          alt=""
          src={sortType.type === 'alphabetical' ? CaretDown : CaretDownGray}
          style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }}
        />
      </div>
    </div>
  );
}

export default function CandidateQueue() {
  const [candidates, setCandidates] = useState();
  const [sortType, setSortType] = useState({ type: 'alphabetical', direction: 'desc' });
  const { data, refetch: refetchCandidates } = useQuery(GET_CANDIDATE_APPLICANTS, {
    variables: {
      user_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
    fetchPolicy: 'network-only',
  });

  function sortCandidates(candidatesList) {
    if (candidatesList) {
      if (sortType.type === 'alphabetical') {
        return [...candidatesList].sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return textA < textB ? (sortType.direction === 'desc' ? -1 : 1) : textA > textB ? (sortType.direction === 'desc' ? 1 : -1) : 0;
        });
      } else {
        return [...candidatesList].sort((a, b) => {
          return a.experience === b.experience ? 0 : a.experience > b.experience ? (sortType.direction === 'desc' ? -1 : 1) : sortType.direction === 'desc' ? 1 : -1;
        });
      }
    } else {
      return candidatesList;
    }
  }

  useEffect(() => {
    if (data) {
      setCandidates(sortCandidates(data.candidate));
    }
  }, [data, sortType]);

  return (
    <div className="flex-1 flex flex-col pb-lg" style={{ paddingTop: 30 }}>
      <TabTitle>Queue</TabTitle>
      <div className="flex py-md">
        <div className="mr-sm text-sm">Sort:</div>
        <AlphabeticalSort sortState={[sortType, setSortType]} />
      </div>
      <div className="flex-1 bg-white rounded shadow relative overflow-auto">
        <div className="absolute inset-0" style={{ maxWidth: 1340 }}>
          {candidates && candidates.map((candidate) => <CandidateQueueEntry candidate={candidate} refetchCandidates={refetchCandidates} />)}
        </div>
      </div>
    </div>
  );
}
