import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import axios from 'axios';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { EXPRESS_SERVER_URL } from '../../config';
const buttonRef = React.createRef();

const CREATE_JOB = gql`
  mutation CreateJob($job: [job_insert_input!]!) {
    insert_job(objects: $job) {
      returning {
        id
      }
    }
  }
`;

export default function JobImportModal({ state }) {
  const [modal, setModal] = state;
  const [fadeDelay, setFadeDelay] = useState(false);
  const modalRef = useRef();

  const [addJob, { data, loading, error: jobError }] = useMutation(CREATE_JOB);

  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (modal.open) {
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (jobsdata) => {
    const recruiterID = firebase.auth().currentUser.uid;

    if (jobsdata.length > 0) {
      setDataLoading(true);
      for (var i = 1; i < jobsdata.length; i++) {
        if (jobsdata[i].data[0] && jobsdata[i].data[1] && jobsdata[i].data[2] && jobsdata[i].data[5] && jobsdata[i].data[6] && jobsdata[i].data[7] && jobsdata[i].data[12]) {
          // const { data: jobData } = await axios.post(`${EXPRESS_SERVER_URL}/checkJob`, {
          //     job_title: jobsdata[i].data[0],
          //     recruiter_id: recruiterID
          // });

          // if (jobData.length == 0) {
          const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${jobsdata[i].data[6]}&key=${process.env.GATSBY_GOOGLE_PLACES_KEY}`);
          const result = res.data.results[0].geometry.location;

          const { data: companyData } = await axios.post(`${EXPRESS_SERVER_URL}/checkAndAddCompany`, {
            company_name: jobsdata[i].data[2].trim(),
            adminID: recruiterID,
          });

          jobsdata[i].data[11] &&
            (await jobsdata[i].data[11].split(',').map((attribute) => {
              axios.post(`${EXPRESS_SERVER_URL}/checkAndAddSkill`, {
                attribute: attribute.trim(),
              });
            }));
          jobsdata[i].data[12] &&
            (await jobsdata[i].data[12].split(',').map((attribute) => {
              axios.post(`${EXPRESS_SERVER_URL}/checkAndAddSkill`, {
                attribute: attribute.trim(),
              });
            }));

          const newJob = {
            recruiter_id: recruiterID,
            title: jobsdata[i].data[0],
            description: (jobsdata[i].data[1] || '').replace(/\n/g, '<br />'),
            company_id: companyData.length ? companyData[0].id : companyData.returning[0].id,
            salaryMin: jobsdata[i].data[3] ? jobsdata[i].data[3] : 45,
            salaryMax: jobsdata[i].data[4] ? jobsdata[i].data[4] : 80,
            compensation: jobsdata[i].data[5],
            compensation_list: jobsdata[i].data[5].split(', '),
            location: { ...result, city: jobsdata[i].data[6] },
            industry: jobsdata[i].data[7],
            requiredExperience: [jobsdata[i].data[8] ? jobsdata[i].data[8] : '5', jobsdata[i].data[9] ? jobsdata[i].data[9] : '10'],
            notes: jobsdata[i].data[10] ? [{ date: new Date(), value: jobsdata[i].data[10] }] : [],
            optionalAttributes: jobsdata[i].data[11] ? jobsdata[i].data[11].split(',').map((el) => el.trim()) : [],
            requiredAttributes: jobsdata[i].data[12] ? jobsdata[i].data[12].split(',').map((el) => el.trim()) : [],
            allowsRemote: jobsdata[i].data[13].toLowerCase() == 'yes' ? 'true' : 'false',
            hiddenFromCandidates: jobsdata[i].data[14].toLowerCase() == 'yes' ? 'true' : 'false',
            created_at: Moment()
              .utc()
              .toISOString(),
            adminApproved: true,
          };

          addJob({
            variables: { job: newJob },
            refetchQueries: 'getJobs',
          });
          // }
        }
      }
      if (buttonRef.current) {
        buttonRef.current.removeFile(data);
      }

      if (!loading) {
        setDataLoading(false);
        setModal({ open: false });
        navigate('/jobs/all-jobs');
      }
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  return (
    <React.Fragment>
      <div
        onClick={() => setModal({ data: null, open: true })}
        className={`job-import-modal-js fixed inset-0 ${modal.open ? 'flex' : 'hidden'} items-center justify-center font-main`}
        style={{ backgroundColor: 'rgba(34, 46, 66, 0.9)', zIndex: 20000 }}
        data-keyboard="false"
      >
        <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ minHeight: 200, minWidth: 450 }}>
          <div className="text-darkblue font-medium border-b flex justify-between pb-sm" style={{ maxWidth: 'calc(100vw - 30px)' }}>
            Import Jobs
            <div onClick={() => setModal({ data: null, open: false })} className="text-darkgray hover:text-darkblue cursor-pointer">
              x
            </div>
          </div>
          <div className="text-darkblue text-sm text-center py-lg">
            <CSVReader ref={buttonRef} onFileLoad={handleOnFileLoad} onError={handleOnError} noClick noDrag>
              {({ file }) => (
                <aside
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 10,
                  }}
                >
                  <button
                    type="button"
                    onClick={handleOpenDialog}
                    style={{
                      borderRadius: 0,
                      marginLeft: 0,
                      marginRight: 0,
                      width: '40%',
                      paddingLeft: 0,
                      paddingRight: 0,
                      background: '#366992',
                      color: '#FFF',
                    }}
                  >
                    {dataLoading ? 'loading...' : 'Select to upload CSV file'}
                  </button>
                  <div
                    style={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: '#ccc',
                      height: 45,
                      lineHeight: 2.5,
                      paddingLeft: 13,
                      paddingTop: 3,
                      width: '60%',
                    }}
                  >
                    {file && file.name}
                  </div>
                </aside>
              )}
            </CSVReader>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
