import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import './Pagination.css';

const Pagination = (props) => {
  const [options, setOptions] = useState([25, 50, 100]);
  const [page, setPage] = useState(props.page || 0);
  const [perPage, setPerPage] = useState(props.perPage || 25);
  const [pageCount, setPageCount] = useState(props.total || 0);
  const [displayPerPage, setDisplayPerPage] = useState(true);

  useEffect(() => {
    if (props) {
      if (props.options && Array.isArray(props.options)) {
        setOptions(props.options);
      }
      if ('page' in props && !isNaN(props.page)) {
        setPage(+props.page);
      }
      if (props.perPage && !isNaN(props.perPage)) {
        setPerPage(+props.perPage);
      }
      if (props.total && !isNaN(props.total)) {
        setPageCount(Math.ceil(props.total / (props.perPage || perPage)));
      }
      if (props.displayPerPage !== undefined && !props.displayPerPage) {
        setDisplayPerPage(props.displayPerPage);
      }
    }
  }, [props]);

  useEffect(() => {
    if (props && props.onChange) {
      const changes = {
        page,
        perPage,
        offSet: page * perPage,
      };
      props.onChange(changes);
    }
  }, [page, perPage]);

  return (
    <div className="cherry-picker-pagination container">
      {/* Per Page Selector */}
      {displayPerPage ? (
        <div className="per-page-container">
          <span>Rows per page: </span>
          <select
            className="per-page"
            value={perPage}
            placeholder={props.perPagePlaceHolder || 'Per Page'}
            onChange={(e) => {
              setPerPage(+e.target.value);
              setPage(0);
            }}
          >
            {options.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      {/* Page Selector */}
      <div className="page-container">
        <ReactPaginate
          forcePage={page}
          initialPage={page}
          previousLabel={'❮'}
          nextLabel={'❯'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={(e) => {
            const selectedPage = +e?.selected;
            setPage(selectedPage);
          }}
          containerClassName={'pagination-container'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      </div>
    </div>
  );
};

Pagination.propTypes = {
  options: PropTypes.arrayOf(PropTypes.number),
  page: PropTypes.number,
  perPage: PropTypes.number,
  displayPerPage: PropTypes.bool,
  total: PropTypes.number,
  perPagePlaceHolder: PropTypes.string,
  onChange: PropTypes.func,
};

export default Pagination;
