import { navigate } from 'gatsby';
import React from 'react';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';
import RichTextArea from '../../forms/fields/RichTextArea';
import { IndustrySelect, LocationField, PhotoUpload, TextInput } from '../fields';
import CompanySize from '../fields/CompanySize';
import Form from '../Form';

export default function NewCompanyForm({ action, globalFormData, isEdit, loading }) {
  const [newAccount, setNewAccount] = globalFormData;

  /*Defining the form elements*/
  const adminFields = {
    name: {
      placeholder: 'Company Name',
      component: TextInput,
      value: newAccount ? newAccount.name : '',
      validation: Yup.string().required("Please enter the company's name"),
    },
    websiteURL: {
      placeholder: 'website',
      component: TextInput,
      value: newAccount ? newAccount.websiteURL : '',
      validation: Yup.string().required('Please enter the company website url'),
    },
    description: {
      placeholder: 'description',
      component: RichTextArea,
      value: newAccount ? newAccount.description : '',
    },
    logoURL: {
      placeholder: 'Company Logo',
      component: PhotoUpload,
      value: newAccount ? newAccount.logoURL : '',
    },
    industries: {
      value: newAccount ? newAccount.industries : [],
      component: IndustrySelect,
    },
    location: {
      placeholder: 'City, State',
      component: LocationField,
      value: newAccount && newAccount.location != null ? newAccount.location : '',
      validation: Yup.string().required("Please enter the company's location"),
    },
    size: {
      component: CompanySize,
      value: newAccount ? newAccount.size : '',
      validation: Yup.string().required("Please enter the company's size"),
    },
  };

  return (
    <div className="NewCompanyForm-js py-lg w-full h-full relative container flex flex-col items-center">
      <div className="font-main text-darkblue" style={{ fontWeight: 500, fontSize: 21 }}>
        {isEdit ? 'Edit Company' : 'Create Company'}
      </div>
      <div className="flex p-md mb-md"></div>
      <div
        className="flex flex-1 w-full justify-center relative"
        style={{
          opacity: 1,
          transition: 'opacity 0.3s ease-in-out 0s',
          zIndex: 1000,
          minWidth: 320,
        }}
      >
        <div
          className={`NewCompanyForm-js w-full bg-white rounded shadow flex flex-col items-center`}
          style={{
            minWidth: 380,
            display: 'flex',
            padding: 30,
            transition: 'transform 0.3s ease-in-out',
            transform: `translateX(0%)`,
          }}
        >
          <Form fields={adminFields} onSubmit={action}>
            <div className="flex w-full mt-lg">
              <button
                onClick={() =>
                  isEdit
                    ? navigate(`/companies/${newAccount.id}/`, {
                        state: {
                          company_id: newAccount.id,
                        },
                      })
                    : navigate(`/dashboard/companies`)
                }
                type="button"
                className="flex-1 p-md mr-sm cursor-pointer bg-lightgray font-main text-darkgray rounded text-sm"
              >
                Cancel
              </button>
              <button type="submit" className="flex-1 p-md ml-sm cursor-pointer bg-red font-main text-white rounded flex justify-center text-xs">
                {loading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Save & Continue'}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
