import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import SelectDropdown from './SelectDropdown';

const ALL_RECRUITERS = gql`
  {
    recruiter {
      id
      name
      profilePhoto
    }
  }
`;

export default function RecruiterSelect({ update, value, error }) {
  const [selectedRecruiter, setSelectedRecruiter] = useState(value ? value : { name: '' });
  const { data: recruiters } = useQuery(ALL_RECRUITERS);

  useEffect(() => {
    update(selectedRecruiter, 'recruiter');
  }, [selectedRecruiter]);

  return (
    <SelectDropdown
      value={selectedRecruiter.name}
      error={error}
      update={(val) => val && setSelectedRecruiter(val)}
      placeholder="Select Recruiter"
      list={recruiters && recruiters.recruiter}
    />
  );
}
