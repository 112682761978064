import _ from 'lodash';
import { EXPRESS_SERVER_URL } from '../config';

export async function getCoordinates({ address }) {
  const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.GATSBY_GOOGLE_PLACES_KEY}`);

  const data = await response.json();

  const latitude = _.get(data, 'results[0].geometry.location.lat', '');
  const longitude = _.get(data, 'results[0].geometry.location.lng', '');
  const formattedAddress = _.get(data, 'results[0].formatted_address', '');

  if (formattedAddress) {
    return { lat: latitude, lng: longitude, city: formattedAddress };
  }

  return null;
}

export async function generateInviteUrl({ inviteCode }) {
  try {
    let data = await fetch(`${EXPRESS_SERVER_URL}/generate-deep-link`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ inviteCode }),
    });

    data = await data.json();
    const { inviteDeepLink } = data;

    return inviteDeepLink;
  } catch (e) {
    console.log(e);

    return '';
  }
}
