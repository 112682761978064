// checkbox icons: https://fontawesome.com/license

import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import { navigate } from 'gatsby';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Arrow, TabTitle } from '../../components/common';
import CherryPagination from '../../components/common/Pagination/Pagination';
import { GlobalFilterContext } from '../../components/layout/layout';
import { ApolloContext } from '../../context/Apollo';
import CheckboxEmpty from '../../images/checkbox-empty.svg';
import CheckboxSelected from '../../images/checkbox-selected.svg';
import UserAvatar from '../../images/user-avatar.png';
import { DEFAULT_PER_PAGE } from '../../utils/constants';

const READ_NOTIFICATION = gql`
  mutation readNotification($id: uuid) {
    update_notification(where: { id: { _eq: $id } }, _set: { read: true }) {
      affected_rows
    }
  }
`;

const UNREAD_NOTIFICATION = gql`
  mutation unreadNotification($id: uuid) {
    update_notification(where: { id: { _eq: $id } }, _set: { read: false }) {
      affected_rows
    }
  }
`;

const RECRUITER_NOTIFICATIONS = gql`
  query recruiterNotifications($recruiter_id: String, $type: String, $offset: Int, $limit: Int) {
    notification_aggregate(where: { recruiter_id: { _eq: $recruiter_id }, type: { _eq: $type } }) {
      aggregate {
        count
      }
    }

    notification(where: { recruiter_id: { _eq: $recruiter_id }, type: { _eq: $type } }, order_by: { created_at: desc }, offset: $offset, limit: $limit) {
      id
      created_at
      candidate {
        id
        profilePictureURL
        name
        email
        phone
        resumeFileName
        resumeURL
        linkedin
        referralName
        recruiter {
          id
          name
        }
        location
        facebook
        instagram
        twitter
        experience
        bio
        employment
        education
        certifications
        attributes
        preferredSkills
        notes
        salaryMax
        salaryMin
        locationPreferences
      }
      type
      read
      payload
    }
  }
`;

const READ_ALL_NOTIFICATIONS = gql`
  mutation readAllNotifications($id: String) {
    update_notification(where: { recruiter_id: { _eq: $id } }, _set: { read: true }) {
      affected_rows
    }
  }
`;

const GET_COMPANY_IDS_FROM_JOB_IDS = gql`
  query getCompanyIdsFromJobIds($jobIds: [uuid!]) {
    job(where: { id: { _in: $jobIds } }) {
      id
      company_id
    }
  }
`;

const GET_ALL_RECRUITER_COMPANY_IDS = gql`
  query getCompanyIds($adminId: String) {
    companies(where: { adminID: { _eq: $adminId } }) {
      id
    }
  }
`;

const notificationTypes = {
  all: 'All Notifications',
  profile_change_request: 'Profile Edit',
  interview: 'Interviews',
  'job-interest': 'Job Interest',
  'company-interest': 'Company',
  chat: 'Chat',
  reassignment: 'New Match',
};

function NotificationFilterSelect({ value, update }) {
  return (
    <div className="relative">
      <select
        value={value}
        onChange={(e) => update(e.target.value)}
        className="rounded font-medium p-sm border border-darkgray cursor-pointer"
        style={{ fontSize: 14, WebkitAppearance: 'none', backgroundColor: '#D0D2D5', color: '#686868', paddingRight: 30 }}
      >
        {React.Children.toArray(
          Object.entries(notificationTypes).map(([type, label]) => {
            return <option value={type}>{label}</option>;
          }),
        )}
      </select>
      <Arrow color="#686868" className="absolute" style={{ zIndex: 21, right: 12, top: 13 }} direction="down" />
    </div>
  );
}

export default function Notifications() {
  const { apolloClient } = useContext(ApolloContext);

  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);

  const [notifications, setNotifications] = useState([]);
  const [userCompanyIds, setUserCompanyIds] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [setNotificationRead] = useMutation(READ_NOTIFICATION);
  const [setNotificationUnread] = useMutation(UNREAD_NOTIFICATION);
  const [readAllNotifications] = useMutation(READ_ALL_NOTIFICATIONS);
  const { globalFilter, setGlobalFilter } = useContext(GlobalFilterContext);
  const [notificationType, setNotificationType] = useState(globalFilter && globalFilter.notificationType);
  const [stillLoading, setStillLoading] = useState(false);

  async function _getCompanyIds() {
    try {
      const response = await apolloClient.query({
        query: GET_ALL_RECRUITER_COMPANY_IDS,
        variables: { adminId: firebase.auth().currentUser && firebase.auth().currentUser.uid },
      });

      setUserCompanyIds(response.data.companies.map((a) => a.id));
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    _getCompanyIds();
  }, []);

  const { data: notificationsData, loading } = useQuery(RECRUITER_NOTIFICATIONS, {
    variables: {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
      type: notificationType === 'all' ? null : notificationType,
      perPage: perPage,
      offset: page * perPage,
    },
    fetchPolicy: 'network-only',
  });

  async function _setNotification() {
    setNotifications([]);
    setTotal(0);
    setStillLoading(true);

    let companyIdsFromJobIds = [];
    const jobIdsToFetch = [];

    notificationsData.notification.forEach((notification) => {
      if (notification.type === 'job-interest') {
        const companyId = _.get(notification, 'payload.company.id', '');

        if (!companyId && notification.payload.id) {
          jobIdsToFetch.push(notification.payload.id);
        }
      }
    });

    try {
      const response = await apolloClient.query({
        query: GET_COMPANY_IDS_FROM_JOB_IDS,
        variables: { jobIds: jobIdsToFetch },
      });

      companyIdsFromJobIds = response.data.job;

      notificationsData.notification.forEach((notification) => {
        if (notification.type === 'job-interest') {
          const companyId = _.get(notification, 'payload.company.id', '');

          if (!companyId && notification.payload.id) {
            const companyId = (companyIdsFromJobIds.filter((job) => job.id === notification.payload.id)[0] || { company_id: '' }).company_id;

            _.set(notification, 'payload.company.id', companyId);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }

    setNotifications(notificationsData.notification);
    setTotal(notificationsData?.notification_aggregate?.aggregate.count || 0);
    setStillLoading(false);
  }

  useEffect(() => {
    if (notificationsData) {
      _setNotification();
    }
  }, [notificationsData]);

  function unReadNotification({ id }) {
    setNotificationUnread({ variables: { id }, refetchQueries: ['recruiterNotifications'] });
  }

  function readSingleNotification({ id }) {
    setNotificationRead({ variables: { id }, refetchQueries: ['recruiterNotifications'] });
  }

  function unReadNotifications() {
    selectedNotifications.forEach((notification) => unReadNotification(notification));
    setSelectedNotifications([]);
  }

  function readNotifications() {
    selectedNotifications.forEach((notification) => readSingleNotification(notification));
    setSelectedNotifications([]);
  }

  function checkIfChecked(notification) {
    return selectedNotifications.includes(notification);
  }

  function toggleCheckBox(notification) {
    const alreadyChecked = checkIfChecked(notification);

    if (alreadyChecked) {
      const newSelectedArray = selectedNotifications.filter((n) => n !== notification);
      setSelectedNotifications(newSelectedArray);
    } else {
      const newSelectedArray = [...selectedNotifications, notification];
      setSelectedNotifications(newSelectedArray);
    }
  }

  function handlePaginationChange(event) {
    setPage(event.page);
    setPerPage(event.perPage);
  }

  useEffect(() => {
    if (notificationType) {
      setGlobalFilter({ ...globalFilter, notificationType });
    }
  }, [notificationType]);

  return (
    <>
      <br />

      <div className="relative md:flex items-center justify-between">
        <TabTitle>Notifications</TabTitle>

        <div className="flex flex-shrink-0">
          <NotificationFilterSelect value={notificationType} update={setNotificationType} />

          <div className="hidden lg:flex justify-end items-center ml-3">
            {selectedNotifications.length === 0 && (
              <div
                onClick={() => {
                  readAllNotifications({ variables: { id: firebase.auth().currentUser.uid } });
                }}
                className="text-darkgray font-medium hover:underline cursor-pointer"
                style={{ fontSize: 14 }}
              >
                Read All Notifications
              </div>
            )}
            {selectedNotifications.length > 0 && (
              <div onClick={() => readNotifications()} className="text-darkgray font-medium hover:underline cursor-pointer" style={{ fontSize: 14, marginRight: 15 }}>
                Mark as Read
              </div>
            )}
            {selectedNotifications.length > 0 && (
              <div onClick={() => unReadNotifications()} className="text-darkgray font-medium hover:underline cursor-pointer" style={{ fontSize: 14 }}>
                Mark as Unread
              </div>
            )}
          </div>
        </div>
      </div>

      <br />

      <div className={`flex-1 bg-white rounded shadow`} style={{ overflowX: 'auto', height: '700px', minHeight: 500, maxHeight: 'calc(100vh - 200px)', marginBottom: 50 }}>
        <table style={{ width: '98%', marginLeft: '1%', marginRight: '1%' }}>
          {/* <thead style={{ fontSize: 14, fontWeight: 'bold' }}>
            <tr style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
              <th style={{ textAlign: 'left' }}>Type</th>
              <th style={{ textAlign: 'left' }}>Date</th>
              <th style={{ textAlign: 'left' }}>Avatar</th>
              <th style={{ textAlign: 'left' }}>Notification</th>
              <th style={{ textAlign: 'left' }}>Actions</th>
            </tr>
          </thead> */}

          <tbody>
            {(loading || stillLoading ? [] : notifications || []).map((notification, index) => {
              const checkboxSelected = checkIfChecked(notification);

              return (
                <SingleNotification
                  key={index}
                  notification={notification}
                  userCompanyIds={userCompanyIds}
                  toggleCheckBox={toggleCheckBox}
                  checkboxSelected={checkboxSelected}
                  setNotificationRead={setNotificationRead}
                />
              );
            })}
          </tbody>
        </table>

        {(loading || stillLoading) && (
          <div className={`flex justify-center items-center flex-1`} style={{ width: '100%' }}>
            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
              Loading
            </div>
          </div>
        )}

        {!(loading || stillLoading) && notifications && notifications.length === 0 && (
          <div className={`flex justify-center items-center flex-1`} style={{ width: '100%' }}>
            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
              No Records found
            </div>
          </div>
        )}
      </div>

      <div className="notification-pagination">
        <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
      </div>
    </>
  );
}

function SingleNotification({ notification, userCompanyIds, toggleCheckBox, checkboxSelected, setNotificationRead }) {
  const { created_at, type } = notification;

  let companyId = '';

  if (notification.type === 'job-interest') {
    companyId = _.get(notification, 'payload.company.id', '');
  } else if (notification.type === 'company-interest') {
    companyId = _.get(notification, 'payload.id', '');
  }

  const isToday = (someDate) => {
    const today = new Date();
    return someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear();
  };

  const dateString = isToday(new Date(created_at)) ? moment(created_at).format('h:mm a') : moment(created_at).format('MMM. D');

  function getMessage() {
    let tempMessage;
    const { type, candidate, payload } = notification;

    switch (type) {
      case 'profile_change_request':
        tempMessage = `${candidate.name} requested to change their profile.`;
        break;
      case 'interview':
        tempMessage = `${candidate.name} scheduled an interview`;
        break;
      case 'job-interest':
        tempMessage = `${candidate.name} is interested in the ${payload.title} job`;

        if (userCompanyIds.includes(companyId)) {
          tempMessage += ` at ${notification.payload.company ? notification.payload.company.name : ''}`;
        }

        break;
      case 'company-interest':
        tempMessage = `A company is interested in more information about ${candidate.name}`;

        if (userCompanyIds.includes(companyId)) {
          tempMessage = `${payload.companyName} is interested in more information about ${candidate.name}`;
        }

        break;
      case 'chat':
        tempMessage = `${candidate.name} sent you a new message`;
        break;
      case 'reassignment':
        tempMessage = `You've been matched with ${candidate.name}!`;
        break;
      default:
        tempMessage = '';
        break;
    }

    return tempMessage;
  }

  function readNotification() {
    const { id, type, candidate } = notification;
    let companyId = '';

    if (notification.type === 'job-interest') {
      companyId = _.get(notification, 'payload.company.id', '');
    } else if (notification.type === 'company-interest') {
      companyId = _.get(notification, 'payload.id', '');
    }

    setNotificationRead({ variables: { id }, refetchQueries: ['recruiterNotifications'] });

    switch (type) {
      case 'job-interest':
        if (userCompanyIds.includes(companyId)) {
          navigate(`/jobs/${notification.payload.id}`, { state: { jobID: notification.payload.id, from: '/notifications' } });
          // navigate(`/companies/${companyId}`, { state: { company_id: companyId } });
        } else {
          navigate(`/candidates/${notification.candidate.id}`, { state: { candidate: notification.candidate } });
        }
        break;
      case 'chat':
        navigate('/chat', { state: { candidate: { id: candidate.id, name: candidate.name, profilePicture: candidate.profilePictureURL, messages: [] } } });
        break;
      case 'company-interest':
        if (userCompanyIds.includes(companyId)) {
          navigate(`/companies/${companyId}`, { state: { company_id: companyId } });
        } else {
          navigate(`/candidates/${notification.candidate.id}`, { state: { candidate: notification.candidate } });
        }

        break;
      default:
        navigate(`/candidates/${candidate.id}`, { state: { candidate } });
    }
  }

  return (
    <tr style={{ fontSize: 14 }} className="hover:bg-lightgray cursor-pointer">
      <th style={{ textAlign: 'left' }} onClick={readNotification}>
        <div
          className="text-darkgray border border-darkgray font-medium rounded text-center"
          style={{ fontSize: 14, whiteSpace: 'nowrap', paddingTop: 5, paddingBottom: 5, width: 110, borderRadius: 20 }}
        >
          {notificationTypes[type]}
        </div>
      </th>

      <th style={{ textAlign: 'left' }} onClick={readNotification}>
        <div className="font-medium">{dateString}</div>
      </th>

      <th style={{ textAlign: 'left' }} onClick={readNotification}>
        <div
          className="w-8 h-8 lg:h-12 lg:w-12"
          style={{
            backgroundImage: `url(${notification.candidate.profilePictureURL ? notification.candidate.profilePictureURL : UserAvatar})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: 48,
            margin: 0,
          }}
        />
      </th>

      <th style={{ textAlign: 'left' }} onClick={readNotification}>
        <div className={`${notification.read ? 'font-medium' : 'font-bold'} px-md flex items-center`} style={{ fontSize: 14 }}>
          {getMessage()}
        </div>
      </th>

      <th style={{ textAlign: 'left' }}>
        <div role="button" className="cursor-pointer px-sm relative right-0  " onClick={() => toggleCheckBox(notification)}>
          <img alt="" src={checkboxSelected ? CheckboxSelected : CheckboxEmpty} style={{ height: 25, margin: 0 }} />
        </div>
      </th>
    </tr>
  );
}
