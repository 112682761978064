import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;

  @media only screen and (max-width: 640px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export default function ProjectCard({ project, viewProject }) {
  return (
    <CardWrapper className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
      <div className="bg-white h-full rounded shadow flex flex-col" style={{ padding: 30 }}>
        <div className="font-medium text-darkblue" style={{ fontSize: 18 }}>
          <div className="hover:underline cursor-pointer" onClick={() => viewProject(project)}>
            {project.name}
          </div>
          <div className="text-dark text-darkgray" style={{ fontSize: 14, marginTop: 15 }}>
            Attribute Tags:
            <div className="flex flex-wrap w-full" style={{ marginTop: 10 }}>
              {project.tags.map((tag, index) => (
                <div
                  key={index}
                  className="bg-lightgray cursor-pointer hover:underline text-darkgray flex items-center px-sm "
                  style={{ fontSize: 12, height: 28, borderRadius: 28, marginRight: 12, marginBottom: 12 }}
                >
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap flex-1 items-end" style={{ marginTop: 20 }}>
          <div className="w-full" style={{ height: 42 }}>
            <div className="font-medium text-darkgray w-full" style={{ fontSize: 14 }}>
              {`${project.candidates_aggregate.aggregate.count} ${project.candidates_aggregate.aggregate.count === 1 ? 'Candidate' : 'Candidates'}`}
            </div>
            <div className="flex justify-end">
              <div className="text-green hover:underline cursor-pointer font-semibold" style={{ fontSize: 14 }} onClick={() => viewProject(project)}>
                View More
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
}
