import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

export default function NewAttributeForm({ cancel, update, showErrors }) {
  const categorySchema = Yup.object().shape({
    category: Yup.string().required('Attribute category is required'),
  })

  const fieldsInitialState = {
    category: ""
  };
  const submitRef = useRef();

  return (
    <div className="flex flex-col" style={{ maxWidth: 320 }}>
      <div
        className="text-green w-full font-main text-sm relative font-medium mt-sm flex justify-between items-center"
        style={{ top: 5 }}
      >
        New Category
        <div
          onClick={cancel}
          className="cursor-pointer text-darkgray text-lg hover:text-red"
        >
          x
        </div>
      </div>
      <Formik onSubmit={update}
        initialValues={fieldsInitialState}
        validationSchema={categorySchema}>
        {({ values, setFieldValue }) => (
          <Form>
            {Object.entries(values).map(([label]) => {
              update(values);
              switch (label) {
                case "category":
                  return (
                    <Field name="category">
                      {({ field, form, meta }) => {
                        return (
                          <>
                            <input
                              type="text"
                              className="text-sm  px-md rounded w-full border border-darkgray"
                              style={{
                                height: 55,
                                marginTop: 16,
                                borderColor: "#c4cad3",
                              }}
                              placeholder="Category Name"
                              {...field}
                            />
                            {form.errors[label] && form.touched[label] && (
                              <div className="text-xs text-red font-medium mt-sm text-center">
                                {form.errors[label]}
                              </div>
                            )}
                          </>
                        );
                      }}
                    </Field>
                  );
              }
            })}
            <button type="submit" ref={submitRef} className="hidden">
              submit category
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
