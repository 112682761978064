const attributesList = [
  {
    Attributes: "Accounting Manager",
  },
  {
    Attributes: "Accounting Services",
  },
  {
    Attributes: "Accounting Supervisor",
  },
  {
    Attributes: "Accounts Payable",
  },
  {
    Attributes: "Accounts Receivable",
  },
  {
    Attributes: "Actimize",
  },
  {
    Attributes: "ADP",
  },
  {
    Attributes: "Advisory",
  },
  {
    Attributes: "AML",
  },
  {
    Attributes: "Architecture",
  },
  {
    Attributes: "ASC740 Tax Provision",
  },
  {
    Attributes: "Asset Management",
  },
  {
    Attributes: "Assistant Controller",
  },
  {
    Attributes: "Assistant Fund Controller",
  },
  {
    Attributes: "Audit",
  },
  {
    Attributes: "Bank Reconciliation",
  },
  {
    Attributes: "Banking",
  },
  {
    Attributes: "Big 4",
  },
  {
    Attributes: "Biotechnology",
  },
  {
    Attributes: "Bookkeeping",
  },
  {
    Attributes: "Boutique CPA Firm",
  },
  {
    Attributes: "Budgeting",
  },
  {
    Attributes: "Business Management",
  },
  {
    Attributes: "Business Valuation",
  },
  {
    Attributes: "CAM",
  },
  {
    Attributes: "CAO",
  },
  {
    Attributes: "CFO",
  },
  {
    Attributes: "Change Management",
  },
  {
    Attributes: "CIA",
  },
  {
    Attributes: "CISA",
  },
  {
    Attributes: "Client Billing",
  },
  {
    Attributes: "Commercial Real Estate",
  },
  {
    Attributes: "Consolidations",
  },
  {
    Attributes: "Construction",
  },
  {
    Attributes: "Consulting Firm",
  },
  {
    Attributes: "Consumer Products",
  },
  {
    Attributes: "Controller",
  },
  {
    Attributes: "Corporate Accounting",
  },
  {
    Attributes: "Corporate Tax",
  },
  {
    Attributes: "Cost Accounting",
  },
  {
    Attributes: "CPA",
  },
  {
    Attributes: "DelTek",
  },
  {
    Attributes: "Digital Media",
  },
  {
    Attributes: "Director of Accounting",
  },
  {
    Attributes: "Director of Finance",
  },
  {
    Attributes: "Director of SEC Reporting",
  },
  {
    Attributes: "Distribution",
  },
  {
    Attributes: "Enrolled Agent",
  },
  {
    Attributes: "Entertainment/Media",
  },
  {
    Attributes: "Excel",
  },
  {
    Attributes: "Family Office",
  },
  {
    Attributes: "Finance Manager",
  },
  {
    Attributes: "Financial Analyst",
  },
  {
    Attributes: "Financial Planning & Analysis",
  },
  {
    Attributes: "Financial Reporting",
  },
  {
    Attributes: "Financial Services",
  },
  {
    Attributes: "Financial Statement Preparation",
  },
  {
    Attributes: "Forecasting",
  },
  {
    Attributes: "Forensic Accounting",
  },
  {
    Attributes: "Full Time",
  },
  {
    Attributes: "Fund Accountant",
  },
  {
    Attributes: "Fund Accounting",
  },
  {
    Attributes: "Fund Accounting Manager",
  },
  {
    Attributes: "Fund Admin",
  },
  {
    Attributes: "Fund Controller",
  },
  {
    Attributes: "Fund of Funds",
  },
  {
    Attributes: "General Ledger",
  },
  {
    Attributes: "Great Plains",
  },
  {
    Attributes: "Head of Finance",
  },
  {
    Attributes: "Healthcare",
  },
  {
    Attributes: "Hedge Funds",
  },
  {
    Attributes: "Hospitality",
  },
  {
    Attributes: "Hyperion",
  },
  {
    Attributes: "IFRS",
  },
  {
    Attributes: "Individual Tax",
  },
  {
    Attributes: "Insurance",
  },
  {
    Attributes: "Internal Audit",
  },
  {
    Attributes: "Internal Audit Director",
  },
  {
    Attributes: "Internal Audit Manager",
  },
  {
    Attributes: "Internet",
  },
  {
    Attributes: "Investment Banking",
  },
  {
    Attributes: "IT Audit",
  },
  {
    Attributes: "JD",
  },
  {
    Attributes: "JD Edwards",
  },
  {
    Attributes: "Journal Entries",
  },
  {
    Attributes: "KYC",
  },
  {
    Attributes: "Law Firm",
  },
  {
    Attributes: "Lawson",
  },
  {
    Attributes: "LLM",
  },
  {
    Attributes: "Mantas",
  },
  {
    Attributes: "Manufacturing",
  },
  {
    Attributes: "Midsize CPA Firm",
  },
  {
    Attributes: "Month End Close",
  },
  {
    Attributes: "MRI",
  },
  {
    Attributes: "National CPA Firm",
  },
  {
    Attributes: "Operations",
  },
  {
    Attributes: "Oracle",
  },
  {
    Attributes: "Partnership Tax",
  },
  {
    Attributes: "Paychex",
  },
  {
    Attributes: "Payroll",
  },
  {
    Attributes: "Payroll Manager",
  },
  {
    Attributes: "Payroll Tax",
  },
  {
    Attributes: "Peachtree",
  },
  {
    Attributes: "Pharmaceuticals",
  },
  {
    Attributes: "Pivot Tables, Vlookups",
  },
  {
    Attributes: "Private Equity",
  },
  {
    Attributes: "Professional Services",
  },
  {
    Attributes: "Project Accounting",
  },
  {
    Attributes: "Property Accounting",
  },
  {
    Attributes: "Public Accounting",
  },
  {
    Attributes: "Public/Private Mix",
  },
  {
    Attributes: "Quickbooks",
  },
  {
    Attributes: "Real Estate",
  },
  {
    Attributes: "REIT",
  },
  {
    Attributes: "Resdential Real Estate",
  },
  {
    Attributes: "Retail",
  },
  {
    Attributes: "Revenue Recognition",
  },
  {
    Attributes: "Revenue/Expense Analysis",
  },
  {
    Attributes: "SAAS",
  },
  {
    Attributes: "SAP",
  },
  {
    Attributes: "SEC Accountant",
  },
  {
    Attributes: "SEC Reporting",
  },
  {
    Attributes: "SEC Reporting Manager",
  },
  {
    Attributes: "SEC Senior Accountant",
  },
  {
    Attributes: "Senior Accountant",
  },
  {
    Attributes: "Senior Financial Analyst",
  },
  {
    Attributes: "Senior Fund Accountant",
  },
  {
    Attributes: "Senior Fund Accounting Manager",
  },
  {
    Attributes: "Senior Internal Audit Manager",
  },
  {
    Attributes: "Senior Internal Auditor",
  },
  {
    Attributes: "SOX",
  },
  {
    Attributes: "Sports",
  },
  {
    Attributes: "Staff Accountant",
  },
  {
    Attributes: "STAT",
  },
  {
    Attributes: "Technical Accounting/Policy",
  },
  {
    Attributes: "Temp to Hire",
  },
  {
    Attributes: "Temporary Position",
  },
  {
    Attributes: "Timberline",
  },
  {
    Attributes: "Trust & Estates",
  },
  {
    Attributes: "Venture Capital",
  },
  {
    Attributes: "Wealth Management",
  },
  {
    Attributes: "Yardi",
  },
  {
    Attributes: "C Level Support",
  },
  {
    Attributes: "Calendar Management",
  },
  {
    Attributes: "Call Center",
  },
  {
    Attributes: "Clerical",
  },
  {
    Attributes: "Data Entry",
  },
  {
    Attributes: "Excel",
  },
  {
    Attributes: "Expense Reports",
  },
  {
    Attributes: "Lawson Software",
  },
  {
    Attributes: "Marketing",
  },
  {
    Attributes: "Outlook",
  },
  {
    Attributes: "Powerpoint",
  },
  {
    Attributes: "Travel Arrangements",
  },
  {
    Attributes: "Word",
  },
  {
    Attributes: "Adobe Photoshop",
  },
  {
    Attributes: "ADP",
  },
  {
    Attributes: "Answering Telephones",
  },
  {
    Attributes: "Business Correspondence",
  },
  {
    Attributes: "C-Level Experience",
  },
  {
    Attributes: "Calling Clients",
  },
  {
    Attributes: "Client Relations",
  },
  {
    Attributes: "Communication",
  },
  {
    Attributes: "Concur",
  },
  {
    Attributes: "Correspondence",
  },
  {
    Attributes: "Customer Service",
  },
  {
    Attributes: "Directing Clients",
  },
  {
    Attributes: "Editing",
  },
  {
    Attributes: "Email",
  },
  {
    Attributes: "Filing",
  },
  {
    Attributes: "Google Suite",
  },
  {
    Attributes: "Greeting Clients",
  },
  {
    Attributes: "Greeting Employees",
  },
  {
    Attributes: "Greeting Visitors",
  },
  {
    Attributes: "Interoersonal",
  },
  {
    Attributes: "Listening",
  },
  {
    Attributes: "Meeting Minutes",
  },
  {
    Attributes: "Microsoft Office",
  },
  {
    Attributes: "Oral Communication",
  },
  {
    Attributes: "CRM",
  },
  {
    Attributes: "ATS",
  },
  {
    Attributes: "Database",
  },
  {
    Attributes: "Presentation",
  },
  {
    Attributes: "Public Relations",
  },
  {
    Attributes: "Public Speaking",
  },
  {
    Attributes: "Quickbooks",
  },
  {
    Attributes: "Receptionist",
  },
  {
    Attributes: "Human Resources",
  },
  {
    Attributes: "Health Benefit Administration",
  },
  {
    Attributes: "Employee Relations",
  },
  {
    Attributes: "Salesforce",
  },
  {
    Attributes: "Stenography",
  },
  {
    Attributes: "Teamwork",
  },
  {
    Attributes: "Travel Booking",
  },
  {
    Attributes: "Typing Speed-WPM",
  },
  {
    Attributes: "Writing",
  },
  {
    Attributes: "Written Communication",
  },
  {
    Attributes: "Human Resources Director",
  },
  {
    Attributes: "Human Resources Manager",
  },
  {
    Attributes: "Human Resources Specialist",
  },
  {
    Attributes: "Talent Acquisition",
  },
  {
    Attributes: "Head of Talent Acquisition",
  },
  {
    Attributes: "Executive Admin",
  },
  {
    Attributes: "Office Manager",
  },
  {
    Attributes: "Account Management",
  },
  {
    Attributes: "Ad Operations",
  },
  {
    Attributes: "Adobe Photoshop",
  },
  {
    Attributes: "Adobe InDesign",
  },
  {
    Attributes: "Analytics",
  },
  {
    Attributes: "Data Science",
  },
  {
    Attributes: "Art",
  },
  {
    Attributes: "Creative",
  },
  {
    Attributes: "Copy Writer",
  },
  {
    Attributes: "CSS",
  },
  {
    Attributes: "Digital Media",
  },
  {
    Attributes: "Digital Strategy",
  },
  {
    Attributes: "Graphic Design",
  },
  {
    Attributes: "HTML",
  },
  {
    Attributes: "Omni Channel Media Strategy",
  },
  {
    Attributes: "Paid Search/SEM",
  },
  {
    Attributes: "Paid Social",
  },
  {
    Attributes: "Product Management",
  },
  {
    Attributes: "Product Marketing",
  },
  {
    Attributes: "Programmatic",
  },
  {
    Attributes: "SEO",
  },
  {
    Attributes: "UI Design",
  },
  {
    Attributes: "UX Design",
  },
  {
    Attributes: "Wireframes",
  },
  {
    Attributes: "Application Development",
  },
  {
    Attributes: "Project Mangement",
  },
  {
    Attributes: "Engineer",
  },
  {
    Attributes: "Affordable Housing",
  },
  {
    Attributes: "Airport",
  },
  {
    Attributes: "Architecture",
  },
  {
    Attributes: "Assistant Project Manager",
  },
  {
    Attributes: "AutoCAD",
  },
  {
    Attributes: "Bid Package",
  },
  {
    Attributes: "Civil",
  },
  {
    Attributes: "Civil Engineering",
  },
  {
    Attributes: "Commercial",
  },
  {
    Attributes: "Concrete",
  },
  {
    Attributes: "Construction Administration",
  },
  {
    Attributes: "Construction Documents",
  },
  {
    Attributes: "Contruction Project Manager",
  },
  {
    Attributes: "Construction Superintendent",
  },
  {
    Attributes: "Corporate Interiors",
  },
  {
    Attributes: "Custom Homes",
  },
  {
    Attributes: "Development",
  },
  {
    Attributes: "Director of Construction",
  },
  {
    Attributes: "Drainage",
  },
  {
    Attributes: "EIT Certification",
  },
  {
    Attributes: "Estimating",
  },
  {
    Attributes: "Estimator",
  },
  {
    Attributes: "Faith Based",
  },
  {
    Attributes: "Field Work",
  },
  {
    Attributes: "Fit Outs",
  },
  {
    Attributes: "Geotechnical Engineering",
  },
  {
    Attributes: "Government",
  },
  {
    Attributes: "Grading",
  },
  {
    Attributes: "Ground Up Construction",
  },
  {
    Attributes: "Higher Education",
  },
  {
    Attributes: "Historic",
  },
  {
    Attributes: "Hospitality",
  },
  {
    Attributes: "Hotel",
  },
  {
    Attributes: "Hurricanes",
  },
  {
    Attributes: "HVAC",
  },
  {
    Attributes: "Interior Design",
  },
  {
    Attributes: "Interiors",
  },
  {
    Attributes: "International",
  },
  {
    Attributes: "K-12",
  },
  {
    Attributes: "Low Income",
  },
  {
    Attributes: "Luxury Residential",
  },
  {
    Attributes: "Mechanical Engineering",
  },
  {
    Attributes: "MEP",
  },
  {
    Attributes: "Multifamily",
  },
  {
    Attributes: "Multifamily Garden Style",
  },
  {
    Attributes: "Multifamily High Rise",
  },
  {
    Attributes: "Multi Family Mid Rise",
  },
  {
    Attributes: "NCIDQ Certification",
  },
  {
    Attributes: "Owner's Representative",
  },
  {
    Attributes: "PE Certification",
  },
  {
    Attributes: "Port",
  },
  {
    Attributes: "Project Coordinator",
  },
  {
    Attributes: "Project Engineer",
  },
  {
    Attributes: "Project Executive",
  },
  {
    Attributes: "Project Manager",
  },
  {
    Attributes: "Project Management",
  },
  {
    Attributes: "Project Sizes $100MM+",
  },
  {
    Attributes: "Project Sizes $10MM+",
  },
  {
    Attributes: "Project Sizes $1MM+",
  },
  {
    Attributes: "Project Sizes $50MM+",
  },
  {
    Attributes: "RA Certification",
  },
  {
    Attributes: "Residential",
  },
  {
    Attributes: "Restaurant",
  },
  {
    Attributes: "Retail",
  },
  {
    Attributes: "Revit",
  },
  {
    Attributes: "Senior Project Manager",
  },
  {
    Attributes: "Senior/Assisted Living",
  },
  {
    Attributes: "Shell",
  },
  {
    Attributes: "Site Development",
  },
  {
    Attributes: "Space Planning",
  },
  {
    Attributes: "Stick Frame",
  },
  {
    Attributes: "Structural Engineering",
  },
  {
    Attributes: "Submittal",
  },
  {
    Attributes: "Superintendent",
  },
  {
    Attributes: "TI/Build Out",
  },
  {
    Attributes: "Tilt Up",
  },
  {
    Attributes: "Assembler",
  },
  {
    Attributes: "Electrical Engineer",
  },
  {
    Attributes: "Mechanical Engineer",
  },
  {
    Attributes: "Production Engineer",
  },
  {
    Attributes: "Progam Manager",
  },
  {
    Attributes: "Quality Inspector",
  },
  {
    Attributes: "Scheduler",
  },
  {
    Attributes: "Software Engineer",
  },
  {
    Attributes: "Systems Engineer",
  },
  {
    Attributes: "Technician",
  },
  {
    Attributes: "Budget Analysis",
  },
  {
    Attributes: "CCAR",
  },
  {
    Attributes: "Compliance",
  },
  {
    Attributes: "Corporate Banking",
  },
  {
    Attributes: "Credit Analyst",
  },
  {
    Attributes: "Credit Officer",
  },
  {
    Attributes: "Credit Review",
  },
  {
    Attributes: "Data Junkie",
  },
  {
    Attributes: "Front Office - Credit",
  },
  {
    Attributes: "Front Office - Fund",
  },
  {
    Attributes: "Fund Accounting",
  },
  {
    Attributes: "Investment Banking",
  },
  {
    Attributes: "Investor Relations",
  },
  {
    Attributes: "Leveraged Finance",
  },
  {
    Attributes: "Loan Capital Markets",
  },
  {
    Attributes: "Operations",
  },
  {
    Attributes: "Project Finance",
  },
  {
    Attributes: "Quant",
  },
  {
    Attributes: "Risk- Capital Markets",
  },
  {
    Attributes: "Sales and Marketing",
  },
  {
    Attributes: "Advanced Excel",
  },
  {
    Attributes: "Trade Finance",
  },
  {
    Attributes: "Assisted Living",
  },
  {
    Attributes: "Emergency Care",
  },
  {
    Attributes: "Nursing Home",
  },
  {
    Attributes: "Private Care",
  },
  {
    Attributes: "Utilization Review",
  },
  {
    Attributes: "Appeal Claims",
  },
  {
    Attributes: "Assessment Skills",
  },
  {
    Attributes: "Behavioral Health",
  },
  {
    Attributes: "Case Mangement",
  },
  {
    Attributes: "Communication Skills",
  },
  {
    Attributes: "Computer Skills",
  },
  {
    Attributes: "Conflict Resolution",
  },
  {
    Attributes: "Counselling Skills",
  },
  {
    Attributes: "Crisis Intervention",
  },
  {
    Attributes: "Critical Care",
  },
  {
    Attributes: "Customer Service Skills",
  },
  {
    Attributes: "Data Entry Skills",
  },
  {
    Attributes: "Dental Assisting",
  },
  {
    Attributes: "Detoxification Procedures",
  },
  {
    Attributes: "Medical Billing",
  },
  {
    Attributes: "Discharge Planning",
  },
  {
    Attributes: "Electrong Medical Record Skills",
  },
  {
    Attributes: "Eye Surgery",
  },
  {
    Attributes: "Foster Care",
  },
  {
    Attributes: "Group Therapy",
  },
  {
    Attributes: "Home Care",
  },
  {
    Attributes: "Homeless",
  },
  {
    Attributes: "Housing Assistance",
  },
  {
    Attributes: "Individual Therapy",
  },
  {
    Attributes: "Interpersonal Skills",
  },
  {
    Attributes: "LBGTQ Population",
  },
  {
    Attributes: "Manual Therapy",
  },
  {
    Attributes: "Medical Assisting Skills",
  },
  {
    Attributes: "Medical Surgical",
  },
  {
    Attributes: "Medical Terminology",
  },
  {
    Attributes: "MICA",
  },
  {
    Attributes: "Multi-Disciplinary Interventions",
  },
  {
    Attributes: "Operating Room",
  },
  {
    Attributes: "Organizational Skills",
  },
  {
    Attributes: "Phyical Therapist",
  },
  {
    Attributes: "Registered Nurse",
  },
  {
    Attributes: "Organizational Skills",
  },
  {
    Attributes: "Phlebotomoy",
  },
  {
    Attributes: "Sterilization",
  },
  {
    Attributes: "Substance Abuse",
  },
  {
    Attributes: "Therapy",
  },
  {
    Attributes: "Trauma",
  },
  {
    Attributes: "Ultrasound",
  },
  {
    Attributes: "XRAY",
  },
  {
    Attributes: "MRI",
  },
  {
    Attributes: "Nurse Practioner",
  },
  {
    Attributes: "Hospital",
  },
  {
    Attributes: "Attorney",
  },
  {
    Attributes: "Attorney at Law",
  },
  {
    Attributes: "Coding",
  },
  {
    Attributes: "Concordance",
  },
  {
    Attributes: "Corporate Attorney",
  },
  {
    Attributes: "Corporate Counsel",
  },
  {
    Attributes: "Counselor",
  },
  {
    Attributes: "Counselor at Law",
  },
  {
    Attributes: "Document Production",
  },
  {
    Attributes: "Document Review Experience",
  },
  {
    Attributes: "Entertainment Attorney",
  },
  {
    Attributes: "Executive Assistant",
  },
  {
    Attributes: "Indexing",
  },
  {
    Attributes: "Intellectual Property Attorney",
  },
  {
    Attributes: "Lawyer",
  },
  {
    Attributes: "Legal Advisor",
  },
  {
    Attributes: "Legal Assistant",
  },
  {
    Attributes: "Legal Counsel",
  },
  {
    Attributes: "Litigation Attorney",
  },
  {
    Attributes: "Notary",
  },
  {
    Attributes: "Paralegal",
  },
  {
    Attributes: "Practioner",
  },
  {
    Attributes: "Proofreaders",
  },
  {
    Attributes: "Real Estate Attorney",
  },
  {
    Attributes: "Records/Files",
  },
  {
    Attributes: "Relativity",
  },
  {
    Attributes: "Scanning",
  },
  {
    Attributes: "Summation",
  },
  {
    Attributes: "Trust & Estate Attorney",
  },
  {
    Attributes: "Word Processing Operator",
  },
  {
    Attributes: "Accounts Payable",
  },
  {
    Attributes: "Accounts Receivable",
  },
  {
    Attributes: "Acquisitions",
  },
  {
    Attributes: "Adobe",
  },
  {
    Attributes: "AIA Billing",
  },
  {
    Attributes: "Asset Manager",
  },
  {
    Attributes: "Assistant Property Manager",
  },
  {
    Attributes: "AutoCAD",
  },
  {
    Attributes: "Bid",
  },
  {
    Attributes: "Blubeam",
  },
  {
    Attributes: "Build Outs",
  },
  {
    Attributes: "CAM",
  },
  {
    Attributes: "Change Orders",
  },
  {
    Attributes: "C-Level/Executives",
  },
  {
    Attributes: "Client Services",
  },
  {
    Attributes: "Commercial Real Estate",
  },
  {
    Attributes: "Condo",
  },
  {
    Attributes: "Condominium",
  },
  {
    Attributes: "Construction",
  },
  {
    Attributes: "Construction Management",
  },
  {
    Attributes: "COOP",
  },
  {
    Attributes: "Cooperative",
  },
  {
    Attributes: "Corporate Interiors",
  },
  {
    Attributes: "Custom Homes",
  },
  {
    Attributes: "Design Assistant",
  },
  {
    Attributes: "Design Build",
  },
  {
    Attributes: "Designer",
  },
  {
    Attributes: "Developers",
  },
  {
    Attributes: "DHCR",
  },
  {
    Attributes: "Drafter",
  },
  {
    Attributes: "EE",
  },
  {
    Attributes: "Entitlements",
  },
  {
    Attributes: "Escalations",
  },
  {
    Attributes: "Excel",
  },
  {
    Attributes: "Facilities Manager",
  },
  {
    Attributes: "Fortune 1000",
  },
  {
    Attributes: "Private Equity Real Estate",
  },
  {
    Attributes: "Fund Raising Specialists",
  },
  {
    Attributes: "Government",
  },
  {
    Attributes: "Graphic Design",
  },
  {
    Attributes: "Ground Up",
  },
  {
    Attributes: "High Rise",
  },
  {
    Attributes: "Hospital",
  },
  {
    Attributes: "Illustrator",
  },
  {
    Attributes: "Interior Designer",
  },
  {
    Attributes: "Mortage Banking",
  },
  {
    Attributes: "Investor Relations",
  },
  {
    Attributes: "Job Captain",
  },
  {
    Attributes: "Job Costing",
  },
  {
    Attributes: "Lease Administrator",
  },
  {
    Attributes: "LEED",
  },
  {
    Attributes: "Local 32BJ",
  },
  {
    Attributes: "Maintenance",
  },
  {
    Attributes: "Medical",
  },
  {
    Attributes: "MRI Software",
  },
  {
    Attributes: "Multifamily",
  },
  {
    Attributes: "New Construction",
  },
  {
    Attributes: "Off Site",
  },
  {
    Attributes: "Owner Operator",
  },
  {
    Attributes: "Owners Representative",
  },
  {
    Attributes: "Procore",
  },
  {
    Attributes: "Project Accountant",
  },
  {
    Attributes: "Project Administrator",
  },
  {
    Attributes: "Project Engineer",
  },
  {
    Attributes: "Project Manager",
  },
  {
    Attributes: "Prolog",
  },
  {
    Attributes: "Property Management",
  },
  {
    Attributes: "Property Controller",
  },
  {
    Attributes: "Property Accountant",
  },
  {
    Attributes: "Property Manager",
  },
  {
    Attributes: "Property Technology",
  },
  {
    Attributes: "PropTech",
  },
  {
    Attributes: "Real Estate Service Providers",
  },
  {
    Attributes: "REIT",
  },
  {
    Attributes: "Rent Stabilized",
  },
  {
    Attributes: "Residential Real Estate",
  },
  {
    Attributes: "Revit",
  },
  {
    Attributes: "Schools",
  },
  {
    Attributes: "SE",
  },
  {
    Attributes: "Sketchup",
  },
  {
    Attributes: "Structural Engineering",
  },
  {
    Attributes: "Submittals",
  },
  {
    Attributes: "Superintendent",
  },
  {
    Attributes: "Take Offs",
  },
  {
    Attributes: "Real Estate Tax",
  },
  {
    Attributes: "Tenant Improvements",
  },
  {
    Attributes: "Timberline",
  },
  {
    Attributes: "Tilt up",
  },
  {
    Attributes: "Type V",
  },
  {
    Attributes: "Wood framing",
  },
  {
    Attributes: "Word",
  },
  {
    Attributes: "Yardi",
  },
  {
    Attributes: "AB Initio Developer",
  },
  {
    Attributes: "Access/VBA Developer",
  },
  {
    Attributes: "Active Directory",
  },
  {
    Attributes: "Agile",
  },
  {
    Attributes: "Alteryx",
  },
  {
    Attributes: "Amazon Aurora",
  },
  {
    Attributes: "Amazon Dyanmo",
  },
  {
    Attributes: "Analytics",
  },
  {
    Attributes: "Anaplan",
  },
  {
    Attributes: "Android Developer",
  },
  {
    Attributes: "AngularJS Developer",
  },
  {
    Attributes: "Ansible",
  },
  {
    Attributes: "Ant",
  },
  {
    Attributes: "API",
  },
  {
    Attributes: "Application Architect",
  },
  {
    Attributes: "Application Security",
  },
  {
    Attributes: "Articulate LMS",
  },
  {
    Attributes: "ASP.NET",
  },
  {
    Attributes: "Audacity",
  },
  {
    Attributes: "Automated QA Tester",
  },
  {
    Attributes: "Amazon Web Services",
  },
  {
    Attributes: "Azure",
  },
  {
    Attributes: "Backbone.JS",
  },
  {
    Attributes: "Back-End Developer",
  },
  {
    Attributes: "Bamboo",
  },
  {
    Attributes: "Blue Team",
  },
  {
    Attributes: "Business Objects Developer",
  },
  {
    Attributes: "Business Systems Analyst",
  },
  {
    Attributes: "C",
  },
  {
    Attributes: "C#.NET",
  },
  {
    Attributes: "C++",
  },
  {
    Attributes: "CAKE PHP",
  },
  {
    Attributes: "Captivate LMS",
  },
  {
    Attributes: "Cassandra",
  },
  {
    Attributes: "Checkpoint",
  },
  {
    Attributes: "Chef",
  },
  {
    Attributes: "Cisco Hardware",
  },
  {
    Attributes: "Cisco Engineer",
  },
  {
    Attributes: "CISO",
  },
  {
    Attributes: "Citrix",
  },
  {
    Attributes: "Client Onboarding",
  },
  {
    Attributes: "Cloud Data Engineer",
  },
  {
    Attributes: "Cloud Engineer",
  },
  {
    Attributes: "Cloudera",
  },
  {
    Attributes: "CodeIgniter",
  },
  {
    Attributes: "Coffee Script",
  },
  {
    Attributes: "Cognos Developer",
  },
  {
    Attributes: "CouchDB",
  },
  {
    Attributes: "CRM",
  },
  {
    Attributes: "CRM Analyst",
  },
  {
    Attributes: "CRM Developer",
  },
  {
    Attributes: "Crystal Reports",
  },
  {
    Attributes: "CSIRT",
  },
  {
    Attributes: "CSS",
  },
  {
    Attributes: "CTO",
  },
  {
    Attributes: "Cucumber",
  },
  {
    Attributes: "Customer Success",
  },
  {
    Attributes: "CVS Version Control",
  },
  {
    Attributes: "Cyber Security",
  },
  {
    Attributes: "Data Analyst",
  },
  {
    Attributes: "Data Architect",
  },
  {
    Attributes: "Data Classification",
  },
  {
    Attributes: "Data Engineer",
  },
  {
    Attributes: "Data Governance",
  },
  {
    Attributes: "Data Modeler",
  },
  {
    Attributes: "Data Science",
  },
  {
    Attributes: "Data Warehouse Engineer",
  },
  {
    Attributes: "Data Warehouse",
  },
  {
    Attributes: "Data",
  },
  {
    Attributes: "Database Analyst",
  },
  {
    Attributes: "Database Architect",
  },
  {
    Attributes: "Database Engineer",
  },
  {
    Attributes: "Decision Analytics",
  },
  {
    Attributes: "Desktop Support",
  },
  {
    Attributes: "Devops",
  },
  {
    Attributes: "DHCP",
  },
  {
    Attributes: "Distributed Systems",
  },
  {
    Attributes: "DNS",
  },
  {
    Attributes: "Docker",
  },
  {
    Attributes: "DoD",
  },
  {
    Attributes: "E-Commerce Analyst",
  },
  {
    Attributes: "E-Commerce Developer",
  },
  {
    Attributes: "Electronic Warefare EW",
  },
  {
    Attributes: "Email Campaigns",
  },
  {
    Attributes: "Email Developer",
  },
  {
    Attributes: "Embedded C++",
  },
  {
    Attributes: "Ember.JS",
  },
  {
    Attributes: "ERP Developer",
  },
  {
    Attributes: "ERP Engineer",
  },
  {
    Attributes: "Excel/VBA Developer",
  },
  {
    Attributes: "Exchange",
  },
  {
    Attributes: "Exchange Administrator",
  },
  {
    Attributes: "Express",
  },
  {
    Attributes: "F#",
  },
  {
    Attributes: "F5",
  },
  {
    Attributes: "FCRA",
  },
  {
    Attributes: "Firewall",
  },
  {
    Attributes: "FPGA",
  },
  {
    Attributes: "Fraud Analytics",
  },
  {
    Attributes: "Front Office Developer",
  },
  {
    Attributes: "Front-End Developer",
  },
  {
    Attributes: "Full-Stack Developer",
  },
  {
    Attributes: "Gherkin",
  },
  {
    Attributes: "Git",
  },
  {
    Attributes: "Google Cloud Platform/GCP",
  },
  {
    Attributes: "Governance",
  },
  {
    Attributes: "GRC",
  },
  {
    Attributes: "GroupPolicy",
  },
  {
    Attributes: "GUI Developer",
  },
  {
    Attributes: "Hadoop",
  },
  {
    Attributes: "Help Desk",
  },
  {
    Attributes: "Hive",
  },
  {
    Attributes: "Horton",
  },
  {
    Attributes: "HTML",
  },
  {
    Attributes: "Hyper-V",
  },
  {
    Attributes: "IaaS",
  },
  {
    Attributes: "IAM",
  },
  {
    Attributes: "IBM Websphere",
  },
  {
    Attributes: "Implementation Specialist",
  },
  {
    Attributes: "Incident Response",
  },
  {
    Attributes: "Informatica Developer",
  },
  {
    Attributes: "Information Assurance",
  },
  {
    Attributes: "Infrastructure Engineer",
  },
  {
    Attributes: "IOS/Swift Developer",
  },
  {
    Attributes: "IT Audit",
  },
  {
    Attributes: "Java",
  },
  {
    Attributes: "JavaScript",
  },
  {
    Attributes: "Jenkins",
  },
  {
    Attributes: "Jira",
  },
  {
    Attributes: "Jmeter",
  },
  {
    Attributes: "Jquery",
  },
  {
    Attributes: "Jquer Developer",
  },
  {
    Attributes: "Junit",
  },
  {
    Attributes: "Kafka",
  },
  {
    Attributes: "LAN/WAN",
  },
  {
    Attributes: "Landesk",
  },
  {
    Attributes: "Laravel",
  },
  {
    Attributes: "Linux",
  },
  {
    Attributes: "Linux Engineer",
  },
  {
    Attributes: "Linux Systems Admin",
  },
  {
    Attributes: "Linux Devops",
  },
  {
    Attributes: "Machine Learning Engineer",
  },
  {
    Attributes: "Manual QA Tester",
  },
  {
    Attributes: "MapReduce",
  },
  {
    Attributes: "MariaDB",
  },
  {
    Attributes: "Maven",
  },
  {
    Attributes: "Mercurial",
  },
  {
    Attributes: "Meteor",
  },
  {
    Attributes: "Microsoft Server",
  },
  {
    Attributes: "Middleare Developer",
  },
  {
    Attributes: "Mobile Developer",
  },
  {
    Attributes: "MongoDB",
  },
  {
    Attributes: "MS BI Developer",
  },
  {
    Attributes: "MS Power BI Developer",
  },
  {
    Attributes: "MS Project Server",
  },
  {
    Attributes: "MS Team Foundation Server",
  },
  {
    Attributes: "MsSQL",
  },
  {
    Attributes: "Multi-threading",
  },
  {
    Attributes: "MVC",
  },
  {
    Attributes: "MySQL",
  },
  {
    Attributes: "MySQL Developer",
  },
  {
    Attributes: "NLP Engineer",
  },
  {
    Attributes: "Natural Language Processing",
  },
  {
    Attributes: "Node.JS Developer",
  },
  {
    Attributes: "NoSQL",
  },
  {
    Attributes: "NoSQL Developer",
  },
  {
    Attributes: "Office365",
  },
  {
    Attributes: "Oracle",
  },
  {
    Attributes: "Oracle Developer",
  },
  {
    Attributes: "PaaS",
  },
  {
    Attributes: "Pandas",
  },
  {
    Attributes: "Penetration Tester",
  },
  {
    Attributes: "Pentaho",
  },
  {
    Attributes: "PeopleSoft",
  },
  {
    Attributes: "Perl",
  },
  {
    Attributes: "PHP",
  },
  {
    Attributes: "Pig",
  },
  {
    Attributes: "PosgreSQL Developer",
  },
  {
    Attributes: "PowerShell",
  },
  {
    Attributes: "Product Security",
  },
  {
    Attributes: "Puppet",
  },
  {
    Attributes: "Python",
  },
  {
    Attributes: "Python/Django Developer",
  },
  {
    Attributes: "Python Developer",
  },
  {
    Attributes: "Qlikview Developer",
  },
  {
    Attributes: "Quant Developer",
  },
  {
    Attributes: "Quantitative Developer",
  },
  {
    Attributes: "R",
  },
  {
    Attributes: "RAD Developer",
  },
  {
    Attributes: "React",
  },
  {
    Attributes: "React Developer",
  },
  {
    Attributes: "Red Team",
  },
  {
    Attributes: "RedShift",
  },
  {
    Attributes: "Risk Assessment",
  },
  {
    Attributes: "Robotic Process Automation",
  },
  {
    Attributes: "RPA",
  },
  {
    Attributes: "SaaS",
  },
  {
    Attributes: "Salesforce",
  },
  {
    Attributes: "Salesforce Developer",
  },
  {
    Attributes: "SAS",
  },
  {
    Attributes: "Scala",
  },
  {
    Attributes: "SCCM",
  },
  {
    Attributes: "SDET",
  },
  {
    Attributes: "Security Engineer",
  },
  {
    Attributes: "Selenium",
  },
  {
    Attributes: "ServiceNow Developer",
  },
  {
    Attributes: "ServiceNow Administrator",
  },
  {
    Attributes: "SharePoint",
  },
  {
    Attributes: "SharePoint Administrator",
  },
  {
    Attributes: "SharePoint Developer",
  },
  {
    Attributes: "Site Reliability Engineer",
  },
  {
    Attributes: "Security Operations Center Analyst",
  },
  {
    Attributes: "SOC Analyst",
  },
  {
    Attributes: "SOX",
  },
  {
    Attributes: "Spark",
  },
  {
    Attributes: "Splunk",
  },
  {
    Attributes: "Spring",
  },
  {
    Attributes: "SQL",
  },
  {
    Attributes: "SQL Developer",
  },
  {
    Attributes: "SSIS Developer",
  },
  {
    Attributes: "SSRS",
  },
  {
    Attributes: "Symfony",
  },
  {
    Attributes: "Tableau",
  },
  {
    Attributes: "Tableau Developer",
  },
  {
    Attributes: "Talend Developer",
  },
  {
    Attributes: "Technical Product Manager",
  },
  {
    Attributes: "Technical Progam Manager",
  },
  {
    Attributes: "Technical Project Coordinator",
  },
  {
    Attributes: "Technical Project Manager",
  },
  {
    Attributes: "Teradata",
  },
  {
    Attributes: "Terraform",
  },
  {
    Attributes: "Threat Intelligence",
  },
  {
    Attributes: "Tomcar",
  },
  {
    Attributes: "Unix Engineer",
  },
  {
    Attributes: "Unix Systems Admin",
  },
  {
    Attributes: "UX/UI Designer",
  },
  {
    Attributes: "UX/UI Developer",
  },
  {
    Attributes: "UX/UI Engineer",
  },
  {
    Attributes: "Validation Testing",
  },
  {
    Attributes: "VB.NET",
  },
  {
    Attributes: "Visual Studio",
  },
  {
    Attributes: "VM Ware",
  },
  {
    Attributes: "VOIP/Telecom Engineer",
  },
  {
    Attributes: "Vue.JS Developer",
  },
  {
    Attributes: "Vulnerability Scanning",
  },
  {
    Attributes: "Windows OS",
  },
  {
    Attributes: "Windows Systems Admin",
  },
  {
    Attributes: "Windows Devops",
  },
  {
    Attributes: "Windows Engineer",
  },
  {
    Attributes: "Wintel",
  },
  {
    Attributes: "WordPress",
  },
  {
    Attributes: "Workday Financials",
  },
  {
    Attributes: "Workday HCM",
  },
  {
    Attributes: "XML",
  },
  {
    Attributes: "Yardi",
  },
  {
    Attributes: "Zend",
  },
];

export default attributesList.map(attribute => attribute["Attributes"]);
