import React from "react";
// import TrashIconRed from '../../images/trash-red.svg';
// import TrashIconGray from '../../images/trash-gray.svg';

export default function TrashIcon({ onClick }) {
  return (
    <button
      onClick={onClick}
      className="cursor-pointer text-darkgray hover:text-white hover:bg-red bg-mediumgray"
      style={{ width: 40, height: 40, marginLeft: 10, borderRadius: 40 }}
    >
      <svg
        width={40}
        height={40}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        fill="currentColor"
      >
        <title>Trash Icon</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <rect
              fill="currentColor"
              className="cls-2"
              x="18.51"
              y="20.65"
              width="8.4"
              height="1.42"
              transform="translate(0.53 43.27) rotate(-87.9)"
            />
            <path
              fill="currentColor"
              className="cls-2"
              d="M23.62,13.66V12.13a1.18,1.18,0,0,0-1.18-1.19H17.8a1.18,1.18,0,0,0-1.18,1.19v1.53H11.68v1.42h1.48l1.17,13.33a.71.71,0,0,0,.7.65H25.18a.71.71,0,0,0,.7-.65l1.18-13.33h1.5V13.66ZM18,12.36H22.2v1.3H18Zm6.49,15.28H15.68l-1.1-12.56h11Z"
            />
            <rect
              fill="currentColor"
              className="cls-2"
              x="19.41"
              y="17.16"
              width="1.42"
              height="8.39"
            />
            <rect
              fill="currentColor"
              className="cls-2"
              x="16.82"
              y="17.16"
              width="1.42"
              height="8.4"
              transform="translate(-0.77 0.66) rotate(-2.1)"
            />
          </g>
        </g>
      </svg>
    </button>
  );
}
