import { navigate } from "gatsby";
import React, { useState, useEffect, useRef } from "react";

export default function PaymentSuccessModal({ state }) {
    const [modal, setModal] = state;
    const [fadeDelay, setFadeDelay] = useState(false);
    const modalRef = useRef();

    useEffect(() => {
        if (modal.open) {
            setFadeDelay(true);
        } else {
            setTimeout(() => {
                setFadeDelay(false);
            }, 300);
        }
    }, [modal]);

    return (
        <React.Fragment>
            <div
                onClick={() => setModal({ open: false })}
                className={`fixed inset-0 ${modal.open ? "flex" : "hidden"
                    } items-center justify-center font-main`}
                style={{ backgroundColor: "rgba(34, 46, 66, 0.9)", zIndex: 20000 }}
                data-backdrop="static" data-keyboard="false" >
                <div
                    ref={modalRef}
                    onClick={e => e.stopPropagation()}
                    className="bg-white px-xl py-md rounded shadow flex flex-col"
                    style={{ minHeight: 200 }}>
                    <div
                        className="text-darkblue font-medium border-b flex justify-between pb-sm"
                        style={{ maxWidth: "calc(100vw - 30px)" }}>
                        Congratulations !
                    <div
                            onClick={() => navigate("/dashboard")}
                            className="text-darkgray hover:text-darkblue cursor-pointer">
                            x
                    </div>
                    </div>
                    <div className="text-darkblue text-sm text-center py-lg">
                        You have successfully purchased {modal.data && modal.data.purchased_credit} credits.
                    </div>
                    <div
                        className="flex flex-1 justify-between items-end"
                        style={{ marginTop: 40, marginBottom: 20 }}>
                        <button
                            onClick={() => navigate("/dashboard")}
                            className="rounded bg-red ml-md font-medium  text-sm text-white text"
                            style={{
                                width: 180,
                                height: 55,
                                fontSize: 14,
                                boxShadow: "0 14px 10px 0 rgba(255, 0, 0, 0.18)",
                                marginLeft: 80
                            }}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
