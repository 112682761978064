import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { TabTitle } from '../../components/common';
import AttrFilter from '../../components/Filter/AttrFilter';
import { GlobalFilterContext } from '../../components/layout/layout';
import AttributeEntry from './attribute-entry';
import AttributeImportModal from './attribute-import-modal';

// graphql query to get all attributes
const ALL_SKILLS = gql`
  subscription getSkills {
    skill(order_by: { name: asc }) {
      category
      name
      id
    }
  }
`;

export default function Attributes () {
  const { globalFilter, setGlobalFilter } = useContext(GlobalFilterContext);
  const [filter, setFilter] = useState(globalFilter[`attributes-filter`] ? globalFilter[`attributes-filter`] : {});

  const [attributes, setAttributes] = useState();
  const [allAttributes, setAllAttributes] = useState();

  const [modal, setModal] = useState({ data: null, open: false });

  const { data: attributesQuery, loading } = useSubscription(ALL_SKILLS);

  // setting initial state of the companies data
  useEffect(() => {
    if (attributesQuery) {
      setAllAttributes(attributesQuery.skill);
    }
  }, [attributesQuery]);

  function isEmpty (obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  function _filterAttributes (attribute) {
    for (const name in filter) {
      const value = filter[name];
      switch (name) {
        case 'search':
          if (!attribute.name.toLowerCase().includes(value.toLowerCase())) {
            return false;
          }
          break;
        case 'category':
          if (!attribute.category.toLowerCase().includes(value.toLowerCase())) {
            return false;
          }
          break;
        default:
          break;
      }
    }
    return true;
  }

  /* Filter by attribute name */
  function filterAttributes () {
    if (isEmpty(filter)) {
      setAttributes(allAttributes);
    } else {
      const filteredAttributes = !allAttributes ? [] : allAttributes.filter(_filterAttributes);
      setAttributes(filteredAttributes);
    }
  }

  useEffect(() => {
    filterAttributes();
    setGlobalFilter({
      ...globalFilter,
      [`attributes-filter`]: filter,
    });
  }, [filter]);

  useEffect(() => {
    if (!isEmpty(filter)) {
      filterAttributes();
    } else {
      setAttributes(allAttributes);
    }
  }, [allAttributes]);

  return (
    <div className="flex flex-col h-full pb-lg">
      <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
        <TabTitle>{'Attributes'}</TabTitle>
        <div className="import_csv flex justify-between lg:mt-0 mt-sm">
          <Link
            className={`bg-green text-white font-medium rounded flex items-center justify-center`}
            style={{ fontSize: 14, height: 40, width: 160 }}
            to="/attributes/new-attribute"
          >
            <span style={{ fontSize: 22, marginRight: 8 }}>+</span> Add Attribute
          </Link>
        </div>
      </div>
      <AttrFilter filterState={[filter, setFilter]} type="attributes" />
      <div className="bg-white rounded shadow flex-1 w-full" style={{ maxWidth: 1340, overflowX: 'auto', height: '700px' }}>
        {loading ? (
          <div style={{ marginLeft: 600, marginTop: 100 }}>
            <Loader type="TailSpin" color="#E31C25" height={50} width={50} />
          </div>
        ) : attributes ? (
          attributes.map((attribute, index) => <AttributeEntry key={index} attribute={attribute} />)
        ) : null}
      </div>
      <AttributeImportModal state={[modal, setModal]} />
    </div>
  );
}
