import React, { useState, useEffect } from "react";
import { gql } from "apollo-boost";
import { useSubscription, useMutation } from "@apollo/react-hooks";
import AllMessages from "./AllMessages";
import ChatWindow from "./ChatWindow";
import FindChat from "./FindChat";
import BackArrow from "../../images/back-arrow.svg";
import { navigate } from "gatsby";
import firebase from "firebase/app";
import { pickBy, includes, toLower } from "ramda";

const SET_MESSAGES_DELIVERED = gql`
  mutation setChatDelivered($id: String) {
    update_chat_message(
      where: { recruiter_id: { _eq: $id }, from: { _eq: "candidate" } }
      _set: { delivered: true }
    ) {
      affected_rows
    }
  }
`;

const RECRUITER_MESSAGES = gql`
  subscription recruiterMessages($id: String) {
    chat_message(
      where: { recruiter_id: { _eq: $id } }
      order_by: { created_at: asc }
    ) {
      message
      read
      from
      created_at
      type
      fileName
      candidate {
        id
        name
        profilePictureURL
      }
    }
  }
`;

export default function Chat({ location }) {
  const [messages, setMessages] = useState();
  const [searchValue, setSearchValue] = useState()
  const [selectedCandidate, setSelectedCandidate] = useState(
    location.state && location.state.candidate
  );
  const { data: allMessages, loading, error } = useSubscription(
    RECRUITER_MESSAGES,
    {
      variables: {
        id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
      },
    }
  );
  const [setChatDelivered] = useMutation(SET_MESSAGES_DELIVERED);

  function formatAllMessages() {
    return allMessages.chat_message.reduce((chatStore, message) => {
      if (chatStore[message.candidate.id]) {
        chatStore[message.candidate.id] = {
          ...chatStore[message.candidate.id],
          messages: [
            ...chatStore[message.candidate.id].messages,
            {
              message: message.message,
              date: message.created_at,
              read: message.read,
              from: message.from,
              type: message.type,
              fileName: message.fileName,
            },
          ],
        };
        return chatStore;
      } else {
        chatStore[message.candidate.id] = {
          name: message.candidate.name,
          profilePictureURL: message.candidate.profilePictureURL,
          messages: [
            {
              message: message.message,
              date: message.created_at,
              read: message.read,
              from: message.from,
              type: message.type,
              fileName: message.fileName,
            },
          ],
        };
        return chatStore;
      }
    }, {});
  }
  useEffect(() => {
    if (allMessages) {
      setChatDelivered({
        variables: {
          id: firebase.auth().currentUser.uid,
        },
      });
      const initialMessages = formatAllMessages();

      setMessages(initialMessages);
      setChatDelivered({
        variables: {
          id: firebase.auth().currentUser.uid,
        },
      });
    }
  }, [allMessages]);

  useEffect(() => {
    if (messages && selectedCandidate) {
      setSelectedCandidate({
        ...selectedCandidate,
        messages: messages[selectedCandidate.id]
          ? messages[selectedCandidate.id].messages
          : [],
      });
    }
  }, [messages]);

  function updateSearch(v) {
    if ((!v || v.length === 0) && allMessages) {
      const initialMessages = formatAllMessages();
      setMessages(initialMessages);
    } else {
      const initialMessages = formatAllMessages();
      const filteredMessages = pickBy(
        msg => includes(toLower(v), toLower(msg["name"])),
        initialMessages
      );
      setMessages(filteredMessages);
    }
  }

  return (
    <div className="flex-1 flex flex-col pb-md h-full items-center">
      <div
        className="w-full relative"
        style={{ marginTop: 60, marginBottom: 16 }}>
        <div className="text-center">
          {selectedCandidate ? (
            <div
              className="cursor-pointer hover:underline"
              onClick={() =>
                navigate(`/candidates/${selectedCandidate.id}`, {
                  state: { candidate: selectedCandidate },
                })
              }>
              {selectedCandidate.name}
            </div>
          ) : (
              "Chat"
            )}
        </div>
        {!selectedCandidate &&
          <FindChat
            value={searchValue}
            update={value => {
              updateSearch(value)
              setSearchValue(value)
            }} />}
        {selectedCandidate && (
          <div
            onClick={() => {
              if (location.state.from) {
                window.history.back();
              } else if (location.state.candidate) {
                window.history.back();
              } else {
                setSelectedCandidate();
              }
            }}
            style={{ top: 3 }}
            className="cursor-pointer absolute left-0 text-darkgray flex items-center font-main text-xs">
            <img
              alt="back"
              src={BackArrow}
              style={{ height: 13, marginBottom: 0, marginRight: 8 }}
            />
            {location.state.from ? `Back to ${location.state.from}` : location.state.candidate ? "Back" : "Chat"}
          </div>
        )}
      </div>
      <div
        className="w-full bg-white flex-1 flex flex-col rounded shadow mb-lg w-full"
        style={{ minHeight: "calc(100vh - 220px)" }}>
        {messages && selectedCandidate ? (
          <ChatWindow
            queryLoading={loading}
            candidateState={[selectedCandidate, setSelectedCandidate]}
          />
        ) : messages && Object.keys(messages).length > 0 ? (
          <AllMessages
            setSelectedCandidate={setSelectedCandidate}
            messages={messages}
          />
        ) : (
              messages && (
                <div className="text-center p-lg text-darkgray font-medium text-sm">
                  No messages
                </div>
              )
            )}
      </div>
    </div>
  );
}
