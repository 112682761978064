import React, { useState, useEffect } from "react";
import SelectDropdown from "./SelectDropdown";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import NewAttributeForm from "../attribute/NewAttributeForm";

const ALL_SKILLS_CATEGORY = gql`
subscription getSkills {
    skill(distinct_on: [category], order_by: [{category: asc}]) {
      category
    }
  }
`;

export default function AttributeSelect({ update, error, value }) {
    const [skill, setSkill] = useState(value);
    const [attributes] = useState([]);

    const { data } = useSubscription(ALL_SKILLS_CATEGORY);

    useEffect(() => {
        update(skill);
    }, [skill]);

    useEffect(() => {
        if (data) {
            data.skill.map(skill => {
                attributes.push(skill.category)
            })
        }
    }, [data]);

    const [isNewAttribute, toggleIsNewAttribute] = useState(false);
    const [category, setCategory] = useState(value);

    useEffect(() => {
        update(category);
    }, [category]);

    return (
        <>
            {isNewAttribute ? (
                <NewAttributeForm
                    showErrors={error}
                    update={setCategory}
                    cancel={() => toggleIsNewAttribute(false)}
                />)
                : (
                    <SelectDropdown
                        value={skill}
                        error={error}
                        action={e => toggleIsNewAttribute(!isNewAttribute)}
                        update={setSkill}
                        list={attributes}
                        placeholder={"Category"}
                        dontClear={true}
                    />)
            }
            {error && !isNewAttribute && (
                <div className="text-xs text-red font-medium mt-sm text-center">
                    {error}
                </div>
            )}
        </>
    );
}
