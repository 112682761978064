import React from "react";
import Arrow from "../../components/common/Arrow";
import { navigate } from "gatsby";
import CreateSubscriberForm from "../../forms/subscriber/CreateSubscriberForm";

export default function NewSubscriber({ location }) {

    return (
        <div className="w-full h-full relative">
            <div
                className="cursor-pointer absolute text-darkgray flex items-center capitalize"
                style={{ fontSize: 12, left: 0, top: 30, zIndex: 30 }}
                onClick={() =>
                    location.state && location.state.from
                        ? navigate(location.state.from)
                        : navigate("/dashboard/subscribers")
                }
            >
                <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} />
            Subscribers
            </div>
            <CreateSubscriberForm /> 
        </div>
    );
} 
