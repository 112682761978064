import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import 'firebase/auth';
import React, { useState } from 'react';
import { DownloadProfileData } from './download-profile-data';

export default function DownloadCandidateProfile({ location }) {
  // const [testImage, setTestImage] = useState();

  // var storageRef = firebase.storage().ref();
  // // Create a reference to the file we want to download
  // var starsRef = storageRef.child('companylogos/636204207636854515-AP-Obama.jpg');

  // // Get the download URL
  // starsRef.getDownloadURL()
  //     .then((url) => {
  //         // This can be downloaded directly:
  //         var xhr = new XMLHttpRequest();
  //         xhr.responseType = 'blob';
  //         xhr.onload = (event) => {
  //             var blob = xhr.response;
  //         };
  //         xhr.open('GET', url);
  //         xhr.send();

  //         console.log("url")
  //         console.log(url)

  //         setTestImage(url);
  //     })
  //     .catch((error) => {
  //         console.log(error);
  //     });

  const [candidateDetail, setCandidateDetail] = useState();
  const [show, setHide] = useState(false);

  const [selectedFields, setSelectedFields] = useState([]);

  const fields = [
    { value: 'all', text: 'All' },
    { value: 'name', text: 'Name' },
    { value: 'email', text: 'Email' },
    { value: 'phone', text: 'Phone' },
    { value: 'linkedin', text: 'Linked In' },
    { value: 'referralName', text: 'Referral Name' },
    { value: 'recruiterName', text: 'Recruiter Name' },
    { value: 'location', text: 'Location' },
    { value: 'salary', text: 'Salary' },
    { value: 'experience', text: 'Experience' },
    { value: 'bio', text: 'Short Bio' },
    { value: 'currEmployment', text: 'Current Employment' },
    { value: 'prevEmployment', text: 'Previous Employment' },
    { value: 'education', text: 'Education' },
    { value: 'attributes', text: 'Attributes' },
    { value: 'locationPreferences', text: 'Location Preferences' },
    { value: 'interestedCity', text: 'Interested Cities' },
  ];

  const field_values = [
    'name',
    'email',
    'phone',
    'linkedin',
    'referralName',
    'recruiterName',
    'location',
    'salary',
    'experience',
    'bio',
    'currEmployment',
    'prevEmployment',
    'education',
    'attributes',
    'locationPreferences',
    'interestedCity',
  ];

  const [allFields, setAllFields] = useState(fields);

  const setFields = (e) => {
    if (e.target.value == 'all') {
      setSelectedFields(field_values);

      setAllFields([]);

      //setting candidate data
      setCandidateDetail(location.state.candidate);

      //setting 'show' to true when there is atleast one field
      setHide(true);
    } else {
      if (!selectedFields.includes(e.target.value)) {
        setSelectedFields([...selectedFields, e.target.value]);
      }

      setAllFields(allFields.filter((ev) => ev.value !== e.target.value));

      e.target.value = 'selectFields';

      //setting candidate data
      setCandidateDetail(location.state.candidate);

      //setting 'show' to true when there is atleast one field
      setHide(true);
    }
  };

  const onRemoveClick = (field) => {
    setSelectedFields(selectedFields.filter((val) => val !== field));
    setAllFields([...allFields, { value: field, text: field }]);
  };

  return (
    <div className="flex flex-col w-full py-lg container">
      <div className="flex justify-start"></div>
      <div className="flex flex-col items-center flex-1 lg:items-start lg:flex-row" style={{ marginTop: 20 }}>
        <div className="bg-white rounded shadow flex-1 mb-lg flex text-sm" style={{ padding: '30px 35px' }}>
          <div className="container">
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <h2 style={{ display: 'inline-block' }}>Candidate Fields</h2>
              <select
                style={{
                  padding: 7,
                  position: 'relative',
                  left: 10,
                }}
                className="select"
                onChange={setFields}
              >
                <option defaultValue="selectFields">Select fields</option>
                {allFields.map((field, index) => {
                  return (
                    <option className="capitalize" key={index} value={field.value}>
                      {field.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-wrap" style={{ marginTop: 6 }}>
              {selectedFields.length > 0 &&
                selectedFields.map((field) => (
                  <div
                    className="border flex font-main text-darkgray border-darkgray flex items-center px-md"
                    style={{ height: 28, fontSize: 12, borderRadius: 28, marginRight: 10, marginTop: 10 }}
                  >
                    {field}
                    <div onClick={() => onRemoveClick(field)} className="ml-sm text-sm relative cursor-pointer" style={{ left: 5, width: 10, height: 10 }}>
                      <div
                        className="bg-darkgray absolute"
                        style={{
                          height: 10,
                          width: 1.5,
                          transform: 'rotate(45deg)',
                          borderRadius: 1.5,
                        }}
                      />
                      <div
                        className="bg-darkgray absolute"
                        style={{
                          height: 10,
                          width: 1.5,
                          transform: 'rotate(-45deg)',
                          borderRadius: 1.5,
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <PDFDownloadLink
          document={<DownloadProfileData data={candidateDetail} selectedFields={selectedFields.length > 0 ? selectedFields : 'null'} />}
          fileName="candidate_profile.pdf"
          className={`bg-red text-white font-medium rounded flex items-center justify-center`}
          style={{ fontSize: 14, height: 40, width: 135, marginLeft: 10 }}
        >
          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download Pdf')}
        </PDFDownloadLink>
      )}
      <PDFViewer style={{ minHeight: 750, marginTop: 20 }}>
        <DownloadProfileData data={location.state.candidate} selectedFields={selectedFields.length > 0 ? selectedFields : 'null'} />
      </PDFViewer>
    </div>
  );
}
