import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import UserRoleContext from '../../context/UserRole';
import EditIcon from '../../images/edit.svg';
import Logo from '../../images/header-logo.svg';
import CreditCost from '../settings/credit-cost';
import Integrations from '../settings/integrations/integrations';
import EmailNotifications from './email-notifications';
import EditProfile from './profile/edit-profile';
import ViewProfile from './profile/view-profile';
import ChangePassword from './security/change-password';
import './settings.css';

const RECRUITER_PROFILE = gql`
  query getRecruiterProfile($recruiter_id: String) {
    recruiter(where: { id: { _eq: $recruiter_id } }) {
      id
      availability
      company_id
      company {
        id
        name
      }
      phone
      position
      profilePhoto
      title
      industries
      name
      email
    }
  }
`;

const COMPANY_PROFILE = gql`
  query getCompanyProfile($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      name
      logoURL
      email
      adminName
      websiteURL
      size
      bio
      totalCredit
      remainingCredit
      subscription_type
    }
  }
`;

const SETTINGS = gql`
  query getSettings {
    settings {
      id
      credit_rate
      daily_candidate_request_limit
      weekly_candidate_request_limit
      monthly_candidate_request_limit
    }
  }
`;

export default function Settings () {
  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';
  const isRecruiter = userRole === 'recruiter';
  const isSubscriber = userRole === 'company';

  const navItems = ['Profile', 'Security', 'Notifications', 'Integrations', 'CreditCost'];

  const [nav, setNav] = useState('Profile');

  const viewOtherSetting = (item) => {
    if (item === 'terms' || item === 'privacy') {
      navigate('https://www.getcherrypicker.com/terms');
    } else {
      setNav(item);
    }
  };

  const [currentUser, updateCurrentUser] = useState();

  const { data } = useQuery(
    isRecruiter ? RECRUITER_PROFILE : COMPANY_PROFILE,
    isRecruiter
      ? { variables: { recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid } }
      : { variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid } },
  );

  useEffect(() => {
    if (data && isRecruiter) {
      // updateCurrentUser(data && data.recruiter && data.recruiter[0]);
      updateCurrentUser({
        id: data && data.recruiter ? data.recruiter[0].id : null,
        name: data && data.recruiter ? data.recruiter[0].name : '',
        title: data && data.recruiter ? data.recruiter[0].title : '',
        industries: data && data.recruiter ? data.recruiter[0].industries : [],
        company_id: data && data.recruiter ? data.recruiter[0].company_id : '',
        email: data && data.recruiter ? data.recruiter[0].email : '',
        phone: data && data.recruiter ? data.recruiter[0].phone : '',
        profilePhoto: data && data.recruiter ? data.recruiter[0].profilePhoto : null,
        availability: data && data.recruiter ? data.recruiter[0].availability : {},
      });
    } else if (data && isSubscriber) {
      updateCurrentUser({
        adminName: data.company[0].adminName,
        bio: data.company[0].bio,
        email: data.company[0].email,
        id: data.company[0].id,
        logoURL: data.company[0].logoURL,
        name: data.company[0].name,
        remainingCredit: data.company[0].remainingCredit,
        size: data.company[0].size,
        totalCredit: data.company[0].totalCredit,
        websiteURL: data.company[0].websiteURL,
        adminID: data.company[0] ? data.company[0].adminID : null,
      });
    } else if (isAdmin) {
      updateCurrentUser({
        name: 'Administrator',
        email: 'admin@getcherrypicker.com',
        profilePhoto: Logo,
      });
    }
  }, [data]);

  useEffect(() => {
    document.title = 'Settings | Cherry Picker';
  }, []);

  const [settings, setSettings] = useState({});

  const { data: settingData } = useQuery(SETTINGS);
  useEffect(() => {
    if (settingData && settingData.settings && settingData.settings[0].credit_rate) {
      setSettings(settingData.settings[0]);
    }
  }, [settingData]);

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex flex-col flex-shrink bg-white shadow" style={{ height: 'calc(100vh - 150px)', marginTop: 50, width: 300, justifyContent: 'space-between' }}>
        <div className="p-lg rounded flex flex-col" style={{}}>
          {navItems &&
            navItems.map((item, index) =>
              isAdmin && (item === 'Profile' || item === 'CreditCost') ? (
                <div key={index} style={{ padding: 15 }}>
                  <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }} onClick={() => viewOtherSetting(item)}>
                    <div className={`flex flex-col font-medium cursor-pointer ${item === nav ? 'text-darkblue' : 'text-darkgray'}`}>
                      {item === 'terms' ? 'Terms and Conditions' : item === 'privacy' ? 'Privacy Policy' : item === 'CreditCost' ? 'Cost per credit' : item}
                    </div>
                  </div>
                </div>
              ) : isRecruiter && item !== 'CreditCost' ? (
                <div style={{ padding: 15 }}>
                  <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }} onClick={() => viewOtherSetting(item)}>
                    <div className={`flex flex-col font-medium cursor-pointer ${item === nav ? 'text-darkblue' : 'text-darkgray'}`}>
                      {item === 'terms' ? 'Terms and Conditions' : item === 'privacy' ? 'Privacy Policy' : item === 'credit_cost' ? 'Cost per credit' : item}
                    </div>
                  </div>
                  {item === 'Profile' && (
                    <img
                      alt=""
                      onClick={() => viewOtherSetting('editProfile')}
                      className="cursor-pointer"
                      src={EditIcon}
                      style={{ width: 25, marginTop: -35, marginLeft: 195, position: 'absolute' }}
                    />
                  )}
                </div>
              ) : item === 'CreditCost' || item === 'Integrations' || item === 'Notifications' ? (
                ''
              ) : (
                <div key={index} style={{ padding: 15 }}>
                  <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }} onClick={() => viewOtherSetting(item)}>
                    <div className={`flex flex-col font-medium cursor-pointer ${item === nav ? 'text-darkblue' : 'text-darkgray'}`}>
                      {item === 'terms' ? 'Terms and Conditions' : item === 'privacy' ? 'Privacy Policy' : item === 'credit_cost' ? 'Cost per credit' : item}
                    </div>
                  </div>
                  {item === 'Profile' && (
                    <img
                      alt=""
                      onClick={() => viewOtherSetting('editProfile')}
                      className="cursor-pointer"
                      src={EditIcon}
                      style={{ width: 25, marginTop: -35, marginLeft: 195, position: 'absolute' }}
                    />
                  )}
                </div>
              ),
            )}
          {!isAdmin && (
            <div style={{ padding: 15 }}>
              <a href={`https://www.getcherrypicker.com/terms`} target="_blank" rel="noreferrer">
                <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }}>
                  <div className={`flex flex-col font-medium cursor-pointer ${nav === 'terms' ? 'text-darkblue' : 'text-darkgray'}`}>Terms and Conditions</div>
                </div>
              </a>
            </div>
          )}
          {!isAdmin && (
            <div style={{ padding: 15 }}>
              <a href={`https://www.getcherrypicker.com/privacypolicy`} target="_blank" rel="noreferrer">
                <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }}>
                  <div className={`flex flex-col font-medium cursor-pointer ${nav === 'privacy' ? 'text-darkblue' : 'text-darkgray'}`}>Privacy Policy</div>
                </div>
              </a>
            </div>
          )}
        </div>
        <div className="p-lg rounded flex flex-col" style={{}}>
          <div style={{ padding: 15 }}>
            <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }}>
              <div
                className="flex flex-col font-medium text-darkgray cursor-pointer"
                onClick={async () => {
                  await firebase.auth().signOut();
                  navigate('/');
                }}
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 lg:ml-lg w-full lg:w-auto" style={{ marginTop: 50 }}>
        <div className="bg-white rounded shadow flex-1" style={{ height: 700, width: '100%' }}>
          {nav === 'Profile' ? (
            <ViewProfile currentUser={currentUser} isAdmin={isAdmin} isSubscriber={isSubscriber} />
          ) : nav === 'Security' ? (
            <ChangePassword navigate={setNav} />
          ) : nav === 'Notifications' ? (
            <EmailNotifications navigate={setNav} />
          ) : nav === 'Integrations' ? (
            <Integrations />
          ) : nav === 'CreditCost' ? (
            <CreditCost navigate={setNav} settings={settings} />
          ) : nav === 'editProfile' ? (
            <EditProfile navigate={setNav} currentUser={currentUser} />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
