import React, { useState, useEffect } from "react";

export default function useDropdownList(list, searchTerm) {
  const [options, setOptions] = useState(list);

  const filterOptions = (value) => {
    setOptions(
      value && value.length > 0
        ? list.filter(option => {
            if (searchTerm) {
              return option[searchTerm]
                .toLowerCase()
                .includes(value.toLowerCase());
            } else {
              return option.toLowerCase().includes(value.toLowerCase());
            }
          })
        : list
    );
  };

  return [options, filterOptions];
}
