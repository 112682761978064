import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import CaretDownGray from '../../images/caret-down-gray.png';
import { Results } from '../../views/candidates/company-candidates';
import { subscriber as subscriberTableHeaders, admin as adminTableHeaders, recuriter as recuriterTable } from '../common/Table/headers';
import _ from 'lodash';
import '../common/Table/Table.css';
import { USER_ROLES } from '../../utils/constants';

/**
 * 
 * @param {Object} props Table Props
 * @param {String} props.ROLE User Role
 * @param {Array<Candidate>} props.selectedCandidates Selected Candidates
 * @param {Function} props.onCandidateSelect Updated selected candidate state
 * @param {String} props.onChange Update candidate rows
 * @param {Function} props.onSortChange User Role
 * @returns {React.FunctionComponent}
 */
const CandidatesTable = forwardRef((props, ref) => {

  // Refs
  const resultRef = useRef();

  useImperativeHandle(ref, () => ({
    selectedCandidates: resultRef?.current?.selectedCandidates || [],
  }));

  const headers = {
    [USER_ROLES.ADMIN]: adminTableHeaders,
    [USER_ROLES.RECURITER]: recuriterTable,
    [USER_ROLES.SUBSCRIBER]: subscriberTableHeaders,
  }

  const tableHeaders = props.ROLE ? headers[props.ROLE] : headers[USER_ROLES.SUBSCRIBER];

  return (
    <div className="CandidatesTable-js bg-white overflow-auto">
      <table className="CandidatesTable_table " style={{ marginBottom: 0 }}>
        <thead className="CandidatesTable_header font-medium text-sm border-b-2">
          <tr>
            {
              _.cloneDeep(tableHeaders).map((tableRow, index) => (
                <th key={index} className={`${tableRow.customClasses} table-header-td`}>
                  <div className={`table-header-data ${tableRow.canSort ? 'sortable' : ''}`} style={tableRow.customDivStyles} onClick={() => tableRow.canSort && props.onSortChange(tableRow.key, props.sort[tableRow.key])}>
                    {/* Label */}
                    <span>
                      {tableRow.label}
                    </span>
                    <span>
                      {/* Sort */}
                      {
                        tableRow.canSort && props.sort[tableRow.key] ? (
                          <div className="transform -translate-x-2">
                            <img
                              src={CaretDownGray}
                              style={{
                                height: 5,
                                width: 7,
                                margin: 0,
                                transform: `rotate(${props.sort[tableRow.key] === 'desc' ? '0' : '180'}deg) `
                              }} />
                          </div>
                        ) : null
                      }
                    </span>
                  </div>
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          <Results
            candidates={props.candidates}
            selectedCandidates={props.selectedCandidates}
            ref={resultRef}
            filter={props.filter}
            ROLE={props.ROLE}
            isRequestedCandidates={props.isRequestedCandidates}
            isInterestedCandidates={props.isInterestedCandidates}
            isFavoriteCandidates={props.isFavoriteCandidates}
            isSavedCandidates={props.isSavedCandidates}
            isLoading={props.isLoading}
            filterBySearch={props.filterBySearch}
            onCandidateSelect={props.onCandidateSelect}
            onChange={props.onChange}
          />
        </tbody>
      </table>
    </div>
  );
})

export default CandidatesTable;
