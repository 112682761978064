import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';
import UserRoleContext from '../../../context/UserRole';
import { CompanyField, DaySelect, IndustrySelect, TimeSelect } from '../../../forms/fields';
import CompanySize from '../../../forms/fields/CompanySize';
import PhotoUpload from '../../../forms/fields/PhotoUpload';

const UPDATE_RECRUITER_AVAILABILITY = gql`
  mutation updateRecruiterAvailability($recruiter_id: String, $availability: jsonb) {
    update_recruiter(where: { id: { _eq: $recruiter_id } }, _set: { availability: $availability }) {
      affected_rows
      returning {
        availability
      }
    }
  }
`;

function EditInterviewAvailability({ availability, goBack, profileState }) {
  const [error, showError] = useState(false);
  const [days, setDays] = useState(availability.days);
  const [schedule, setSchedule] = useState(availability.schedule);
  const [updateRecruiterAvailability, { data: updateResponse }] = useMutation(UPDATE_RECRUITER_AVAILABILITY);
  const [profile, updateProfile] = profileState;

  const saveInterviewAvailability = () => {
    ({ schedule, days });
    if (days.length > 0) {
      //   updateProfile({ ...profile, availability: { days, schedule } });
      updateRecruiterAvailability({
        variables: {
          recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
          availability: { days, schedule },
        },
      });
      //   goBack();
    } else {
      showError(true);
    }
  };

  useEffect(() => {
    if (updateResponse && updateResponse.update_recruiter && updateResponse.update_recruiter.returning[0] && updateResponse.update_recruiter.returning[0].availability) {
      setDays(updateResponse.update_recruiter.returning[0].availability.days);
      setSchedule(updateResponse.update_recruiter.returning[0].availability.schedule);

      goBack();
    }
  }, [updateResponse, days]);

  useEffect(() => {
    if (error) {
      const newDays = Object.entries(days)
        .filter(([_, value]) => (value.active ? true : false))
        .map(([label]) => label);
      if (newDays.length > 0) {
        showError(false);
      }
    }
  }, [days]);

  return (
    <div className="w-full flex flex-col items-center justify-end">
      <div className="flex-1 w-full">
        <div className="font-medium text-center mb-md" style={{ fontSize: 16 }}>
          Edit Interview Availability
        </div>
        {error && <div className="font-main text-xs text-red mb-md text-center">Please select the days of your work week</div>}
        <DaySelect value={days} update={setDays} />
        <div className="flex w-full">
          <TimeSelect value={schedule} update={setSchedule} />
        </div>
      </div>
      <div className="flex w-full">
        <button onClick={() => goBack()} className="flex-1 p-md mr-sm cursor-pointer bg-lightgray font-main text-darkgray rounded text-xs">
          Cancel
        </button>
        <button onClick={() => saveInterviewAvailability()} className="flex-1 p-md ml-sm cursor-pointer bg-red font-main text-white rounded text-xs">
          Save
        </button>
      </div>
    </div>
  );
}

export default function EditProfileForm({ initialValues, saveProfile, navigate, loading, availability, profileState, errors }) {
  const [userRole] = useContext(UserRoleContext);
  const isSubscriber = userRole === 'company';

  const [interviewAvailabilityVisible, showInterviewAvailability] = useState(false);

  const ProfileSchema = isSubscriber
    ? Yup.object().shape({
        logoURL: Yup.mixed().required(),
        companyName: Yup.string().required('Please enter your name'),
        email: Yup.string()
          .email('The email you entered is not valid')
          .required('Please enter a valid email'),
        websiteURL: Yup.string().required('Please enter the company website url'),
      })
    : Yup.object().shape({
        profilePhoto: Yup.mixed().required(),
        name: Yup.string().required('Please enter your name'),
        title: Yup.string().required('Please enter your job title'),
        industries: Yup.mixed().test({
          name: 'One-Industry',
          message: 'Please select at least one industry',
          test: (industries) => industries.length > 0,
          exclusive: true,
        }),
        company: Yup.mixed().test({
          name: 'Is-Company-Object',
          params: { initialValues },
          message: 'Please select or add your company',
          test: (value) => {
            return true;
          },
          exclusive: true,
        }),
        industries: Yup.string().required('Please select your industry'),
        // position: Yup.string().required("Please enter your position"),
        email: Yup.string()
          .email('The email you entered is not valid')
          .required('Please enter a valid email'),
      });

  const options = ['1-4', '5-9', '10-19', '20-49', '50-99', '100-249', '500-999', '1000+'];
  const inputLevels = ['companyName', 'Email', 'websiteURL'];

  return (
    <div className="edit-profile-form-js flex flex-col flex-1 w-full">
      {!interviewAvailabilityVisible ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            saveProfile(values);
          }}
          validationSchema={ProfileSchema}
        >
          {({ setFieldValue }) => (
            <Form className="edit-profile-form-js flex flex-col h-full w-full">
              {Object.entries(initialValues).map(([label, value]) => {
                switch (label) {
                  case 'logoURL':
                    return (
                      <Field name="logoURL">
                        {(props) => (
                          <>
                            <PhotoUpload placeholder="Company Logo" update={(newPhoto) => setFieldValue('logoURL', newPhoto)} value={value} {...props} />
                            {props.form.errors[label] && <div className="text-xs text-red font-medium mt-sm text-center">{props.form.errors[label]}</div>}
                          </>
                        )}
                      </Field>
                    );
                  case 'profilePhoto':
                    return (
                      <Field name="profilePhoto">
                        {(props) => (
                          <>
                            <PhotoUpload placeholder="Profile Photo" update={(newPhoto) => setFieldValue('profilePhoto', newPhoto)} value={value} {...props} />
                            {props.form.errors[label] && <div className="text-xs text-red font-medium mt-sm text-center">{props.form.errors[label]}</div>}
                          </>
                        )}
                      </Field>
                    );
                  case 'industries':
                    return (
                      <Field name="industries">
                        {({ field, form }) => {
                          return (
                            <>
                              <IndustrySelect update={(industries) => setFieldValue('industries', industries)} value={field.value} />
                              {form.errors[label] && <div className="text-xs text-red font-medium mt-sm text-center">{form.errors[label]}</div>}
                            </>
                          );
                        }}
                      </Field>
                    );
                  case 'company':
                    return (
                      <Field name="company">
                        {({ field, form }) => (
                          <>
                            <CompanyField
                              value={field.value}
                              update={(company) => {
                                setFieldValue('company', company);
                              }}
                            />
                            {form.errors[label] && form.touched[label] && <div className="text-xs text-red font-medium mt-sm text-center">{form.errors[label]}</div>}
                          </>
                        )}
                      </Field>
                    );
                  case 'size':
                    return (
                      <Field name="size">
                        {({ field, form }) => (
                          <>
                            <CompanySize
                              value={field.value}
                              update={(size) => {
                                setFieldValue('size', size);
                              }}
                            />
                            {form.errors[label] && form.touched[label] && <div className="text-xs text-red font-medium mt-sm text-center">{form.errors[label]}</div>}
                          </>
                        )}
                      </Field>
                    );
                  default:
                    return (
                      <Field name={label}>
                        {({ field, form, meta }) => {
                          return (
                            <>
                              {isSubscriber &&
                                inputLevels.map((key) =>
                                  key.toLowerCase() == label.toLowerCase() ? (
                                    <label style={{ marginTop: 15, marginBottom: -15, fontWeight: 500 }}>
                                      {key == 'websiteURL' ? 'Website URL' : key == 'companyName' ? 'Company Name' : key}
                                    </label>
                                  ) : (
                                    ''
                                  ),
                                )}
                              <input
                                className="text-sm  px-md rounded w-full border border-darkgray"
                                style={{
                                  height: 55,
                                  marginTop: 16,
                                  borderColor: '#c4cad3',
                                }}
                                placeholder={label}
                                {...field}
                              />
                              {errors && errors.email && label == 'email' && <div className="text-xs text-red font-medium mt-sm text-center">{errors.email}</div>}
                              {form.errors[label] && form.touched[label] && <div className="text-xs text-red font-medium mt-sm text-center">{form.errors[label]}</div>}
                            </>
                          );
                        }}
                      </Field>
                    );
                }
              })}
              {!isSubscriber && (
                <div className="w-full flex justify-center ">
                  <div onClick={() => showInterviewAvailability(true)} className="text-darkblue underline relative font-medium cursor-pointer" style={{ fontSize: 14, top: 25 }}>
                    Edit Interview Availability
                  </div>
                </div>
              )}

              <div className="flex w-full" style={{ marginTop: 67 }}>
                <button
                  onClick={() => navigate('Profile')}
                  type="button"
                  style={{ height: 55 }}
                  className="bg-lightgray flex items-center justify-center shadow text-xs font-medium flex-1 text-darkgray rounded mr-sm p-sm"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  style={{
                    height: 55,
                    boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)',
                  }}
                  className="bg-red flex items-center justify-center  shadow ml-sm text-xs font-medium flex-1 text-white rounded p-sm"
                >
                  {loading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Save'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <EditInterviewAvailability profileState={profileState} goBack={() => showInterviewAvailability(false)} availability={availability} />
      )}
    </div>
  );
}
