import React from 'react';
import { CSVDownloader } from 'react-papaparse';

export default function SampleCsvCandidates() {
  return (
    <CSVDownloader
      data={[
        {
          Name: 'Cherry First',
          Email: 'cherryfirst@gmail.com',
          Phone: '9865400000',
          'Linkedin URL': 'https://www.linkedin.com/in/kumarharshvardhan/',
          'Referral Name': 'Any',
          Location: 'Miami, FL, USA',
          'Salary Min ($)': '25',
          'Facebook URL': 'https://www.facebook.com/unicef/',
          'Instagram Handle': 'cherrypick',
          'Twitter Handle': 'narendramodi',
          'Short Bio': "Lorem Ipsum has been the industry's standard dummy text",
          'Current Employment': 'Company Name,Job title,Miami FL USA,Feb 2021-Present',
          'Past Employment': 'Company 1,Job title 1,Miami FL USA,Jan 2020-Jan 2021;Company 2,Job title 2,Newyork USA,Jun 2019-Dec 2019',
          Education: 'School 1,Degree 1,Jan 2021-present;School 2,Degree 2,Jan 2020-Dec 2020',
          'Job Preferences': 'Coding,Architecture',
          'Candidate Attributes': 'Coding,Architecture',
          'Remote (Y/N)': 'Y',
          'Willing to Relocate (Y/N)': 'Y',
          'Interested Cities': 'Newyork,Miami',
        },
      ]}
      type="anchor"
      filename={'sample_candidates'}
      className={`bg-red text-white font-medium rounded flex items-center justify-center`}
      style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
    >
      <span style={{ fontSize: 22, marginRight: 8 }}></span> Download Sample CSV
    </CSVDownloader>
  );
}
