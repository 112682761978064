import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import axios from 'axios';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { EXPRESS_SERVER_URL } from '../../config';
const buttonRef = React.createRef();

/*graphql query to create new company*/
const CREATE_COMPANY = gql`
  mutation CreateCompany($name: String, $websiteURL: String, $description: String, $size: String, $industries: jsonb, $adminID: String, $location: jsonb) {
    insert_companies(objects: { name: $name, websiteURL: $websiteURL, description: $description, size: $size, industries: $industries, adminID: $adminID, location: $location }) {
      returning {
        id
        name
        websiteURL
        description
        size
        industries
        adminID
        location
      }
    }
  }
`;

const COMPANIES_BY_NAME = gql`
  query companyByName($name: String) {
    companies(where: { name: { _eq: $name } }) {
      id
      name
    }
  }
`;

export default function CompanyImportModal({ state, isAdmin }) {
  const [modal, setModal] = state;
  const [fadeDelay, setFadeDelay] = useState(false);
  const modalRef = useRef();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (modal.open) {
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  const [addCompany, { data, loading }] = useMutation(CREATE_COMPANY);

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data) => {
    if (data.length > 0) {
      setDataLoading(true);
      for (var i = 1; i < data.length; i++) {
        if (data[i].data[0] && data[i].data[1] && data[i].data[3] && data[i].data[5]) {
          const { data: companyData } = await axios.post(`${EXPRESS_SERVER_URL}/checkCompany`, {
            company_name: data[i].data[0],
            adminID: !isAdmin ? firebase.auth().currentUser && firebase.auth().currentUser.uid : null,
          });

          if (companyData.length == 0) {
            const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${data[i].data[5]}&key=${process.env.GATSBY_GOOGLE_PLACES_KEY}`);
            const result = res.data.results[0].geometry.location;

            const companyObject = {
              name: data[i].data[0],
              websiteURL: data[i].data[1],
              description: (data[i].data[2] || '').replace(/\n/g, '<br />'),
              size: data[i].data[3],
              industries: data[i].data[4] ? data[i].data[4].split(',') : [],
              adminID: !isAdmin ? firebase.auth().currentUser && firebase.auth().currentUser.uid : null,
              location: { ...result, city: data[i].data[5] },
            };

            addCompany({
              variables: companyObject,
            });
          }
        }
      }

      if (buttonRef.current) {
        buttonRef.current.removeFile(data);
      }

      if (!loading) {
        setDataLoading(false);
        setModal({
          open: false,
        });
        navigate('/dashboard/companies');
      }
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  return (
    <React.Fragment>
      <div
        onClick={() => setModal({ data: null, open: true })}
        className={`fixed inset-0 ${modal.open ? 'flex' : 'hidden'} items-center justify-center font-main`}
        style={{ backgroundColor: 'rgba(34, 46, 66, 0.9)', zIndex: 20000 }}
        data-keyboard="false"
      >
        <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ minHeight: 200, minWidth: 450 }}>
          <div className="text-darkblue font-medium border-b flex justify-between pb-sm" style={{ maxWidth: 'calc(100vw - 30px)' }}>
            Import Companies
            <div onClick={() => setModal({ data: null, open: false })} className="text-darkgray hover:text-darkblue cursor-pointer">
              x
            </div>
          </div>
          <div className="text-darkblue text-sm text-center py-lg">
            <CSVReader
              ref={buttonRef}
              onFileLoad={handleOnFileLoad}
              onError={handleOnError}
              noClick
              noDrag
              config={{
                skipEmptyLines: true,
              }}
            >
              {({ file }) => (
                <aside
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 10,
                  }}
                >
                  <button
                    type="button"
                    onClick={handleOpenDialog}
                    disabled={dataLoading ? true : false}
                    style={{
                      borderRadius: 0,
                      marginLeft: 0,
                      marginRight: 0,
                      width: '40%',
                      paddingLeft: 0,
                      paddingRight: 0,
                      background: '#366992',
                      color: '#FFF',
                    }}
                  >
                    {dataLoading ? 'loading...' : 'Select to upload CSV file'}
                  </button>
                  <div
                    style={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: '#ccc',
                      height: 45,
                      lineHeight: 2.5,
                      paddingLeft: 13,
                      paddingTop: 3,
                      width: '60%',
                    }}
                  >
                    {file && file.name}
                  </div>
                </aside>
              )}
            </CSVReader>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
