import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import ChangePasswordForm from "./change-password-form";
import { EXPRESS_SERVER_URL } from '../../../config';

export default function ChangePassword({
    navigate
}) {

    const [error, setError] = useState({});
    const [errors, setErrors] = useState({});

    const initialFields = {
        old_password: "",
        new_password: "",
        confirm_password: ""
    }
    const [loading, setLoading] = useState(false);

    const logIn = async credentials => {
        try {
            await firebase
                .auth()
                .signInWithEmailAndPassword(credentials.email, credentials.password);
        } catch ({ code }) {
            switch (code) {
                case "auth/invalid-email":
                    setError({
                        email: "Please enter a valid email address",
                    });
                    break;
                case "auth/user-not-found":
                    setError({
                        email: "A user with that email was not found.",
                    });
                    break;
                case "auth/wrong-password":
                    setError({
                        password: "The password provided was incorrect",
                    });
                    break;
                case "auth/too-many-requests":
                    setError({
                        password:
                            "Too many failed log-in attempts. Please reset your password or try again later.",
                    });
                    break;
                default:
                    break;
            }
        }
    };

    const saveProfile = async profile => {
        setLoading(true);

        // logIn({ email: currentUser.email, password: profile.old_password });

        // if (!error) {
        const user = await fetch(
            `${EXPRESS_SERVER_URL}/updateUserFirebaseAccount`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ uid: firebase.auth().currentUser && firebase.auth().currentUser.uid, password: profile.new_password }),
            }
        ).then(response => response.json());

        if (user.statusCode == 200) {
            navigate("Profile");
        } else {
            setErrors({
                old_password: "Some error occured !"
            })
        }
        // } else {
        //   setErrors({
        //     old_password: "Old password is not correct"
        //   })
        // }
        setLoading(false)
    };

    return (
        <div
            className="flex justify-center items-center pb-lg mx-md"
            style={{ top: 20, left: 0, right: 0 }}>
            <div
                className="w-full bg-white rounded flex flex-col"
                style={{ minHeight: 370, padding: 40 }}
                onClick={e => e.stopPropagation()}>
                <ChangePasswordForm
                    initialValues={initialFields}
                    saveProfile={saveProfile}
                    navigate={navigate}
                    loading={loading}
                    errors={errors}
                />
            </div>
        </div>
    );
}
