import { useMutation, useSubscription } from '@apollo/react-hooks';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Link, navigate } from 'gatsby';
import _, { get } from 'lodash';
import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import Loader from 'react-loader-spinner';
import { useSnackbar } from 'react-simple-snackbar';
import { TabTitle } from '../../components/common';
import DeleteUsersButton from '../../components/common/DeleteUsersButton';
import InterviewModal from '../../components/common/InterviewModal';
import { Filter } from '../../components/Filter';
import { GlobalFilterContext } from '../../components/layout/layout';
import { EXPRESS_SERVER_URL } from '../../config';
import { TextInput } from '../../forms/fields';
import RichTextArea from '../../forms/fields/RichTextArea';
import CaretDownGray from '../../images/caret-down-gray.png';
import CheckIcon from '../../images/check-white.png';
import DollarSign from '../../images/dollarsign.svg';
import DownIcon from '../../images/down-arrow.png';
import BriefcaseIcon from '../../images/experience.png';
import EyeIcon from '../../images/eye-solid.svg';
import LocationPin from '../../images/location.svg';
import LoginIcon from '../../images/login-icon.png';
import UserAvatar from '../../images/user-avatar.png';
import RecruiterIcon from '../../images/user-icon.png';
import { DEFAULT_EMAIL_INVITE_BODY, DEFAULT_PER_PAGE, USER_ROLES } from '../../utils/constants';
import { formatSalary } from '../../utils/formatter';
import { generateInviteCode } from '../../utils/getId';
import { generateInviteUrl } from '../../utils/google.helper';
import { ApolloContext } from './../../context/Apollo';
import CandidateImportModal from './candidate-import-modal';
import './candidates.css';
import FilteredCandidates from './filtered-candidates';
import RecruiterCandidateDetail from './recruiter-candidate-detail';
import SampleCsvCandidates from './sample-csv-candidates';
import CandidateDashboard from './candidate-dashboard';
import CherryPagination from '../../components/common/Pagination/Pagination';

const ModalContext = React.createContext();

const UPDATE_CANDIDATE_STATUS = gql`
  mutation updateCandidateStatus($id: String, $status: String) {
    update_candidate(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
      returning {
        id
        status
      }
    }
  }
`;
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const StatusSelect = ({ candidate }) => {
  const [value, setValue] = useState(candidate.status);
  const [updateCandidateStatus] = useMutation(UPDATE_CANDIDATE_STATUS);
  const [modal, setModal] = useContext(ModalContext);

  const optionColors = {
    'Pending Hire': 'pink',
    'Awaiting Interview': 'orange',
    'Phone Interview Scheduled': 'blue',
    'In-Person Interview Scheduled': 'pink',
    'Second Interview Scheduled': 'orange',
    'Final Interview Scheduled': 'blue',
  };

  useEffect(() => {
    if (modal.newStatus && modal.data.candidate.id === candidate.id) {
      setValue(modal.newStatus);
    } else if (modal.reset) {
      setValue(candidate.status);
    }
  }, [modal]);

  function getInterviewType(label) {
    switch (label) {
      case 'In-Person Interview Scheduled':
        return 'in-person';
      case 'Phone Interview Scheduled':
        return 'phone';
      case 'Second Interview Scheduled':
        return 'second-interview';
      case 'Final Interview Scheduled':
        return 'final-interview';
      default:
        break;
    }
  }

  return (
    <div className="relative">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={`inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 bg-bg${optionColors[value]}  px-md rounded relative cursor-pointer`}
            style={{ width: '200px', fontWeight: 600, fontSize: 12 }}
          >
            {value}
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg  ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer  bg-white z-50`}
          >
            <div className={`rounded-md bg-bg${optionColors[value]}`}>
              {Object.keys(optionColors).map((option, index) => (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(active && `text-text${optionColors[value]}`, `  block px-4 py-2 text-sm`)}
                      key={index}
                      onClick={() => {
                        setValue(option);
                        if (option !== 'Awaiting Interview' && option !== 'Pending Hire') {
                          setModal({ open: true, data: { candidate, type: getInterviewType(option) } });
                        } else {
                          updateCandidateStatus({ variables: { id: candidate.id, status: option } });
                        }
                      }}
                    >
                      {option}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const CandidateEntry = ({ candidate, isSelected, toggleCandidate, isAdmin, state }) => {
  const { profilePictureURL, name, employment, location, experience, salaryMin, recruiter_id: recruiterId, recruiter } = candidate;
  const currentEmployment =
    employment && typeof employment === 'string' ? JSON.parse(employment)?.filter(({ tenure: { current } }) => current) : employment?.filter(({ tenure: { current } }) => current);

  const [setDetailModal] = state;
  const [inviteLoading, setInviteLoading] = useState(false);
  const { apolloClient } = useContext(ApolloContext);
  const [isInviteModalOpen, setIsInviteModalOpen] = React.useState(false);
  const [inviteEmailSubject, setInviteEmailSubject] = useState('Inviting you to CherryPicker');
  const [inviteEmailBody, setInviteEmailBody] = useState(DEFAULT_EMAIL_INVITE_BODY);
  const [openSnackbar] = useSnackbar();

  const handleInviteModalClose = () => {
    setInviteLoading(false);

    setIsInviteModalOpen(false);
  };

  const handleInviteModalOpen = async () => {
    setInviteLoading(true);

    // Fallback for old candidates as invite code is not yet generated for them.
    if (!candidate.invite_code || !candidate.invite_deeplink || candidate.invite_code_used_on) {
      // Generate invite code and deeplink
      const inviteCode = generateInviteCode();

      const deeplink = await generateInviteUrl({ inviteCode });

      // Save generated code in database
      const candidateUpdated = await apolloClient.mutate({
        mutation: gql`
          mutation UpdateCandidateInviteCode {
            update_candidate(where: { id: { _eq: "${candidate.id}" } }, _set: { invite_code: "${inviteCode}", invite_code_used_on: null, invite_deeplink: "${deeplink}" }) {
              affected_rows
              returning {
                id
                name
                email
                invite_code
                invite_deeplink
                invite_code_used_on
              }
            }
          }
        `,
      });

      const tempCandidate = candidateUpdated.data.update_candidate.returning[0];

      setInviteEmailBody(
        DEFAULT_EMAIL_INVITE_BODY.replaceAll('##_CANDIDATE_NAME_##', tempCandidate.name)
          .replaceAll('##_CANDIDATE_FIRST_NAME_##', (tempCandidate.name || '').split(' ')[0])
          .replaceAll('##_INVITE_CODE_##', tempCandidate.invite_code)
          .replaceAll('##_INVITE_CODE_LINK_##', tempCandidate.invite_deeplink),
      );
    } else {
      setInviteEmailBody(
        DEFAULT_EMAIL_INVITE_BODY.replaceAll('##_CANDIDATE_NAME_##', candidate.name)
          .replaceAll('##_CANDIDATE_FIRST_NAME_##', (candidate.name || '').split(' ')[0])
          .replaceAll('##_INVITE_CODE_##', candidate.invite_code)
          .replaceAll('##_INVITE_CODE_LINK_##', candidate.invite_deeplink),
      );
    }

    setIsInviteModalOpen(true);
  };

  const getCandidateDetail = (candidate) => {
    setDetailModal({ open: true, data: { candidate } });
  };

  const inviteCandidate = async () => {
    handleInviteModalClose();

    openSnackbar('Sending invite...');

    setInviteLoading(true);

    const email = candidate.email;
    const password = Math.random()
      .toString(36)
      .slice(-8);

    const user = await fetch(`${EXPRESS_SERVER_URL}/getUserFirebaseAccount`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    }).then((response) => response.json());

    if (user.statusCode === 200) {
      // await fetch(
      //   `${EXPRESS_SERVER_URL}/updateCandidateFirebaseAccount`,
      //   {
      //     method: "POST",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify({ uid: user.data.uid, password }),
      //   }
      // ).then(response => response.json());
    } else {
      await fetch(`${EXPRESS_SERVER_URL}/createCandidateFirebaseAccount`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, name: candidate.name }),
      }).then((response) => response.json());
    }

    const text = 'dummy';
    const response = await fetch(`${EXPRESS_SERVER_URL}/inviteCandidateEmail`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, subject: inviteEmailSubject, content: inviteEmailBody, text }),
    });
    const resData = await response.json();
    if (resData.statusCode === 200) {
      setInviteLoading(false);

      openSnackbar('Invite sent');
    } else {
      openSnackbar('Invite not sent. Please try again.');
    }
  };

  let sendInvitationButtonText = 'Send Invitation';

  if (candidate && candidate.invite_code_used_on) {
    sendInvitationButtonText = 'Send Another Invitation';
  }

  return (
    <>
      <tr>
        <td style={{ paddingLeft: '35px', paddingRight: 0 }}>
          <div style={{ width: 64, paddingRight: 15 }}>
            <div
              style={{
                margin: 0,
                height: 49,
                width: 49,
                borderRadius: 40,
                backgroundImage: `url(${profilePictureURL ? profilePictureURL : UserAvatar})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
              className="hover:underline lg:p-0 cursor-pointer"
              onClick={() => navigate(`/candidates/${candidate.id}`, { state: { candidate } })}
            />
          </div>
        </td>
        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div style={{ paddingLeft: 0, paddingRight: 0, width: 320 }}>
            <div className="flex flex-col">
              <div style={{ display: 'inline-flex' }}>
                <div
                  className="hover:underline lg:p-0 cursor-pointer lg:text-sm"
                  style={{ fontWeight: 500 }}
                  onClick={() => navigate(`/candidates/${candidate.id}`, { state: { candidate } })}
                >
                  {name}
                </div>
                <img
                  alt=""
                  src={EyeIcon}
                  onClick={() => getCandidateDetail(candidate)}
                  style={{ width: 17, height: 17, cursor: `pointer`, marginLeft: 10, marginTop: 2, marginBottom: 0 }}
                />
              </div>
              {employment && currentEmployment.length > 0 && (
                <div className="items-center font-medium" style={{ fontSize: 14, maxWidth: 402 }}>
                  <span className="text-darkblue mr-sm">{currentEmployment[0].title}</span>
                  <br />
                  <span
                    className="text-darkgray"

                  // onClick={() => navigate(`/companies/${currentEmployment[0].id}`, { state: { company: currentEmployment[0] } })}
                  >
                    {currentEmployment[0].companyName}
                  </span>
                </div>
              )}
            </div>
          </div>
        </td>
        {isAdmin && (
          <td style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="text-xs text-darkgray flex items-center mr-sm lg:mr-0 justify-start" style={{ paddingLeft: 20, paddingRight: 0, width: 250 }}>
              {recruiter && (
                <div
                  onClick={() => navigate(`/recruiters/${recruiterId}`, { state: { recruiter: { id: recruiterId, ...recruiter }, from: '/dashboard/candidates' } })}
                  className="font-medium cursor-pointer hover:underline text-darkgray flex items-center"
                  style={{ fontSize: 14 }}
                >
                  <img alt="" src={RecruiterIcon} style={{ marginBottom: 0, marginRight: 10, height: 16 }} />
                  {recruiter.name}
                </div>
              )}
            </div>
          </td>
        )}
        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="text-xs text-darkgray flex items-center mr-sm lg:mr-0 justify-center" style={{ paddingLeft: 0, paddingRight: 0, width: 130 }}>
            <img alt="" src={BriefcaseIcon} style={{ height: 20, margin: 0, marginLeft: 2, marginRight: 8 }} />
            {experience ? experience : 0} {experience ? (experience === 1 ? 'year' : 'years') : 'year'}
          </div>
        </td>
        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="text-xs text-darkgray flex items-center mr-sm lg:mr-0 justify-center" style={{ paddingLeft: 0, paddingRight: 0, width: 150 }}>
            {salaryMin ? <img alt="" src={DollarSign} style={{ height: 14, margin: 0, marginLeft: 2, marginRight: 12 }} /> : ''}
            {salaryMin ? `${formatSalary({ salary: salaryMin })} +` : 'Negotiable'}
          </div>
        </td>
        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div style={{ paddingLeft: 0, paddingRight: 0, width: 150 }}>
            {location && location !== '' && (
              <div className="text-xs text-darkgray flex items-center justify-center" style={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                <img alt="" src={LocationPin} style={{ height: 14, margin: 0, marginRight: 10 }} />
                {typeof location === 'string' ? JSON.parse(location).city : location.city}
              </div>
            )}
          </div>
        </td>
        {isAdmin && (
          <td style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div style={{ paddingLeft: 20, paddingRight: 0, width: 250 }}>
              <button
                className={`bg-red text-white font-medium rounded flex items-center justify-center`}
                style={{ fontSize: 14, height: 40, width: 200, marginLeft: 10 }}
                disabled={inviteLoading ? true : false}
                onClick={() => handleInviteModalOpen()}
              >
                {inviteLoading ? <Loader type="TailSpin" className="flex justify-center" color="#FFFFFF" height={20} width={20} /> : sendInvitationButtonText}
              </button>
            </div>
          </td>
        )}
        {!isAdmin && (
          <td style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div style={{ paddingLeft: 20, paddingRight: 0, width: 250 }}>
              <div className="flex flex-1 justify-center relative">
                <StatusSelect candidate={candidate} key={'status-' + candidate?.id} />
              </div>
            </div>
          </td>
        )}
        {!isAdmin && (
          <td style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div
              onClick={() => navigate('/chat', { state: { candidate: { id: candidate.id, name, profilePicture: profilePictureURL, messages: [] } } })}
              className="text-green cursor-pointer text-center"
              style={{ fontSize: 14 }}
            >
              Chat
            </div>
          </td>
        )}
        {
          isAdmin ? (
            <td style={{ paddingLeft: 0, paddingRight: 0 }}>
              <div className="text-green cursor-pointer" style={{ fontSize: 14, width: 80, paddingLeft: 20 }}>
                {candidate.is_login === '1' && candidate.device_token && (
                  <div
                    style={{ margin: 0, height: 30, width: 30, backgroundImage: `url(${LoginIcon})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
                    className="hover:underline lg:p-0 cursor-pointer custom-candidate-icon"
                  />
                )}
              </div>
            </td>
          ) : null
        }
        <td style={{ paddingRight: '35px', paddingLeft: '20px' }}>
          <div onClick={toggleCandidate} className={`${isSelected ? 'bg-green' : ''} border border-green cursor-pointer`} style={{ width: 14, height: 14, margin: 'auto' }} />
        </td>
      </tr>

      <Dialog open={isInviteModalOpen} onClose={handleInviteModalClose} scroll="paper" fullWidth>
        <DialogTitle>
          <p style={{ fontFamily: 'montserrat', margin: 0 }}>Send Invitation to {candidate ? candidate.name : ''}</p>
        </DialogTitle>

        <DialogContent dividers>
          <p style={{ paddingBottom: 0, margin: 0, fontFamily: 'montserrat' }}>Subject:</p>
          <TextInput error={!inviteEmailSubject} value={inviteEmailSubject} placeholder="Subject" update={(value) => setInviteEmailSubject(value)} />

          <br />

          <p style={{ paddingBottom: 0, margin: 0, fontFamily: 'montserrat' }}>Content:</p>

          <div className="w-full">
            <RichTextArea value={inviteEmailBody} error={!inviteEmailBody} update={(value) => setInviteEmailBody(value)} height={400} />
          </div>
        </DialogContent>

        <DialogActions>
          <button
            className={`bg-lightgray text-black font-medium rounded flex items-center justify-center`}
            style={{ fontSize: 14, height: 40, width: 100, marginLeft: 10, fontFamily: 'montserrat' }}
            onClick={handleInviteModalClose}
          >
            Cancel
          </button>

          <button
            className={`bg-red text-white font-medium rounded flex items-center justify-center`}
            style={{ fontSize: 14, height: 40, width: 100, marginLeft: 10, fontFamily: 'montserrat' }}
            onClick={inviteCandidate}
          >
            Send
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { CandidateEntry };

export default function Candidates({ isAdmin, inProcess, isDashboard, location = {} }) {
  const [flag, setFlag] = useState(true);
  const [candCount, setCandCount] = useState(0);
  const [selectedImport, setSelectedImport] = useState('');

  const { globalFilter, setGlobalFilter } = useContext(GlobalFilterContext);
  const [filter, setFilter] = useState(globalFilter[`candidates${inProcess ? 'in-process' : ''}-filter`] ? globalFilter[`candidates${inProcess ? 'in-process' : ''}-filter`] : {});

  const [modal, setModal] = useState({ data: null, open: false });
  const [candidates, setCandidates] = useState();
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  // const [locations, setLocations] = useState([]);
  const locations = [];
  const { apolloClient } = useContext(ApolloContext);
  const [loading, setLoading] = useState(false);

  const [importModal, setImportModal] = useState({ data: null, open: false });

  // status: inProcess ? ['Awaiting Interview', 'In-Person Interview Scheduled', 'Second Interview', 'Final Interview'] : null,
  const [detailModal, setDetailModal] = useState({ data: null, open: false });
  // Refs
  const dashboardRef = useRef();

  useEffect(() => {
    if (selectedImport) {
      if (selectedImport === 'import_from_loxo') {
        navigate('/candidate-imports/loxo');
      }
      if (selectedImport === 'import_from_csv') {
        setImportModal({ data: null, open: true });
      }
    }
  }, [selectedImport]);

  const renderCandidates = () => {
    if (loading) {
      return null;
    }

    if (candidates && candidates.length === 0) {
      return null;
    }

    return (
      <>
        {candidates &&
          candidates.map((candidate) => (
            <CandidateEntry
              key={candidate.id}
              candidate={candidate}
              isAdmin={isAdmin}
              isSelected={selectedCandidates.includes(candidate.id)}
              toggleCandidate={() => {
                if (selectedCandidates.includes(candidate.id)) {
                  setSelectedCandidates(selectedCandidates.filter((id) => id !== candidate.id));
                } else {
                  setSelectedCandidates([...selectedCandidates, candidate.id]);
                }
              }}
              state={[setDetailModal]}
            />
          ))}
      </>
    );
  };

  return (
    <div className="candidates-js w-full flex-1 candidates">
      <ModalContext.Provider value={[modal, setModal]}>
        <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
          <TabTitle>{isAdmin ? 'Candidates' : inProcess ? 'Candidates in Process' : 'Candidate Pool'}</TabTitle>
          <div className="import_csv flex justify-between lg:mt-0 mt-sm">
            <FilteredCandidates candidates={candidates} dashboardRef={dashboardRef} />
            {!isAdmin && (
              <div className="relative overflow-hidden rounded" style={{ marginRight: 6 }}>
                <select
                  value={selectedImport}
                  onChange={(e) => setSelectedImport(e.target.value)}
                  className={`bg-white rounded text-darkblue flex items-center cursor-pointer relative`}
                  style={{ opacity: 1, height: 40, width: 200, fontSize: 14, paddingLeft: 15, zIndex: 20, WebkitAppearance: 'none' }}
                >
                  <option value="">+ Import Candidates</option>
                  <option value="import_from_csv">Import from CSV</option>
                  <option value="download_sample_csv">Download Sample CSV</option>
                  <option value="import_from_loxo">Import from Loxo</option>
                </select>
                <img alt="" className="absolute" src={DownIcon} style={{ width: 15, height: 7.5, right: 15, top: 17, zIndex: 21 }} />
                <div className="bg-white absolute flex inset-0 items-center text-darkgray" style={{ fontSize: 14, paddingLeft: 15, right: 6, width: 200 }}>
                  Add to Project
                </div>
              </div>
            )}
            {!isAdmin && selectedImport === 'download_sample_csv' && <SampleCsvCandidates />}
            <Link
              className={`${isAdmin ? 'bg-green' : 'bg-red'} text-white font-medium rounded flex items-center justify-center`}
              style={{ fontSize: 14, height: 40, width: 160, marginRight: 10, position: 'relative', textAlign: 'center', padding: '3 0' }}
              to="/candidates/new-candidate"
            >
              <span style={{ fontSize: 22, marginRight: 8 }}>+</span> Add Candidate
            </Link>
          </div>
        </div>
        <div className="candidates-js flex-1 flex flex-col pb-lg relative">
          {/* Candidates */}
          <CandidateDashboard
            ROLE={isAdmin ? USER_ROLES.ADMIN : USER_ROLES.RECURITER}
            context="admin-dashboard"
            BASE_FILTER={isAdmin ? `where: {}` : `where: { recruiter_id: { _eq: $admin_id }}`}
            canSort={true}
            ref={dashboardRef}
            showSavedCandidates={true}
            filterBySearch={true}
            includeSalaryMetrics={true}
          />
        </div>
        <InterviewModal state={[modal, setModal]} />
        {/* <RecruiterCandidateDetail state={[detailModal, setDetailModal]} /> */}
        <CandidateImportModal state={[importModal, setImportModal]} />
      </ModalContext.Provider>
    </div>
  );
}