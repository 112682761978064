import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Arrow } from '../../components/common';
import { GlobalFilterContext, HasJobsOrCandidatesContext } from '../../components/layout/layout';
import TabNav from '../../components/layout/TabNav';
import UserRoleContext from '../../context/UserRole';
import DollarSign from '../../images/dollarsign.svg';
import LocationPin from '../../images/location.svg';
import { formatSalary } from '../../utils/formatter';
import '../dashboard/dashboard.css';
import JobList from './job-list';
import JobSearch from './job-search';

const ALL_RECRUITER_JOBS = gql`
  subscription getJobs($recruiterID: String) {
    job(where: { recruiter_id: { _eq: $recruiterID }, subscriber_id: { _is_null: true } }, order_by: { created_at: desc }) {
      id
      industry
      location
      notes
      company {
        name
        logoURL
      }
      optionalAttributes
      requiredAttributes
      requiredExperience
      salaryMax
      salaryMin
      status
      title
      hiddenFromCandidates
      filled
      closed
      description
      compensation
      compensation_list
      subscriber_id
      adminApproved
    }
  }
`;

const ALL_SUBSCRIBER_JOBS = gql`
  subscription getJobs($subscriber_id: uuid) {
    job(where: { subscriber_id: { _eq: $subscriber_id } }, order_by: { created_at: desc }) {
      id
      industry
      location
      notes
      company {
        name
        logoURL
      }
      optionalAttributes
      requiredAttributes
      requiredExperience
      salaryMax
      salaryMin
      status
      title
      hiddenFromCandidates
      filled
      closed
      description
      compensation
      compensation_list
      subscriber_id
      adminApproved
      subscriber {
        id
        name
        logoURL
      }
      created_at
    }
  }
`;

const ALL_COMPLETED_SUBSCRIBER_JOBS = gql`
  subscription getJobs($subscriber_id: uuid) {
    job(where: { _or: [{ filled: { _eq: true } }, { closed: { _eq: true } }], subscriber_id: { _eq: $subscriber_id } }, order_by: { created_at: desc }) {
      id
      industry
      location
      notes
      company {
        name
        logoURL
      }
      optionalAttributes
      requiredAttributes
      requiredExperience
      salaryMax
      salaryMin
      status
      title
      hiddenFromCandidates
      filled
      closed
      description
      compensation
      compensation_list
      subscriber_id
      adminApproved
      subscriber {
        id
        name
        logoURL
      }
      created_at
    }
  }
`;

const ALL_JOBS = gql`
  subscription getJobs {
    job(order_by: { created_at: desc }) {
      id
      industry
      location
      notes
      company {
        name
        logoURL
      }
      optionalAttributes
      requiredAttributes
      requiredExperience
      salaryMax
      salaryMin
      status
      title
      hiddenFromCandidates
      filled
      description
      compensation
      compensation_list
      recruiter_id
      recruiter {
        availability
        email
        industries
        name
        phone
        profilePhoto
        title
        company {
          name
        }
        position
        candidates_aggregate {
          aggregate {
            count
          }
        }
      }
      subscriber_id
      adminApproved
      created_at
    }
  }
`;

const GET_COMPANY = gql`
  query getCompany($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      email
      name
      logoURL
      adminName
      totalCredit
      remainingCredit
      subscription_type
    }
  }
`;

const StatusSelect = ({ options }) => {
  const [value, setValue] = useState(options[0]);
  const textRef = useRef();
  const [width, setWidth] = useState();

  const colors = {
    pink: {
      text: 'rgb(255, 76, 138)',
      bg: 'rgba(255, 76, 138, 0.29)',
    },
    orange: {
      text: 'rgb(244, 144, 74)',
      bg: 'rgba(244, 144, 74, 0.29)',
    },
    blue: {
      text: 'rgb(0, 102, 255)',
      bg: 'rgba(0, 102, 255, 0.29)',
    },
  };

  useEffect(() => {
    const textWidth = window.getComputedStyle(textRef.current).width;
    const newWidth = parseInt(textWidth.slice(0, textWidth.length - 2)) + 60;
    setWidth(newWidth === 201 ? 231 : newWidth);
  }, [value]);

  return (
    <div className="relative">
      <div className="absolute right-0 z-10 invisible" ref={textRef} style={{ fontWeight: 600, fontSize: 12 }}>
        {value.value}
      </div>
      <Arrow
        className="absolute"
        direction="down"
        style={{
          right: 15,
          top: 10,
        }}
        color={colors[value.color]['text']}
      />
      <select
        className="px-md py-sm rounded relative cursor-pointer z-20"
        style={{
          WebkitAppearance: 'none',
          width,
          fontWeight: 600,
          fontSize: 12,
          color: colors[value.color]['text'],
          backgroundColor: colors[value.color]['bg'],
        }}
        value={value.value}
        onChange={({ target }) => {
          setValue(options.filter((option) => option.value === target.value)[0]);
        }}
      >
        {options.map((option) => (
          <option>{option.value}</option>
        ))}
      </select>
    </div>
  );
};

const JobEntry = ({ job, fillPosition }) => {
  const { id, company, title, location, salaryMax, salaryMin, status } = job;
  const statusOptions = [
    {
      value: 'Awaiting Scheduling',
      color: 'blue',
    },
    {
      value: 'Phone Interview Scheduled',
      color: 'pink',
    },
    {
      value: 'Pending Hire',
      color: 'orange',
    },
  ];

  return (
    <div className="flex items-center w-full border-b" style={{ height: 100 }}>
      <div style={{ minWidth: 98 }}>
        <img src={company.logoURL} style={{ margin: 0, maxHeight: 40, maxWidth: 80 }} />
      </div>
      <div style={{ width: 320 }}>
        <div className="flex flex-col">
          <Link to={`jobs/${job.id}`} state={{ job_id: job.id }} className="hover:underline py-sm lg:p-0 cursor-pointer lg:text-sm" style={{ fontWeight: 500 }}>
            {title}
          </Link>
          <div className="lg:text-xs text-darkgray lg:text-left">{company.name}</div>
        </div>
      </div>
      <div style={{ width: 250 }}>
        <div className="flex lg:flex-col items-start">
          <div className="text-xs text-darkgray flex items-center mr-sm lg:mr-0 lg:mb-sm" style={{ fontWeight: 500 }}>
            <img src={DollarSign} style={{ height: 14, margin: 0, marginLeft: 2, marginRight: 12 }} />
            {`${formatSalary({ salary: salaryMin })} - ${formatSalary({ salary: salaryMax })}`}
          </div>
          <div className="text-xs text-darkgray flex items-center" style={{ fontWeight: 500 }}>
            <img src={LocationPin} style={{ height: 14, margin: 0, marginRight: 10 }} />
            {typeof location !== 'string' && location.city}
          </div>
        </div>
      </div>
      <div className="flex justify-end relative mt-sm lg:mt-0 lg:mr-lg" style={{ width: 255 }}>
        <StatusSelect options={statusOptions} />
      </div>
      <div className="flex justify-center" style={{ paddingRight: 30, width: 177.64 }}>
        <div
          className={`bg-${job.filled ? 'lightred text-red' : 'white text-darkgray'} flex font-semibold text-xs px-md rounded hover:bg-lightred hover:text-red cursor-pointer`}
          style={{ paddingTop: 5, paddingBottom: 5 }}
        >
          {job.filled ? (
            'Closed'
          ) : (
            <>
              <span className="text-md mr-sm">X</span>
              Close Position
            </>
          )}
        </div>
      </div>
      <div>
        <button
          onClick={() => fillPosition(id, job.filled ? false : true)}
          className={`${job.filled ? 'bg-lightgreen text-green' : 'hover:bg-lightgreen text-green'
            }  border-green flex items-center justify-center px-md rounded border w-full text-xs font-medium`}
          style={{ height: 48, width: 130.47 }}
        >
          {job.filled ? 'Unfill Position' : 'Fill Position'}
        </button>
      </div>
    </div>
  );
};

const FILL_JOB = gql`
  mutation fillJob($id: uuid, $value: Boolean) {
    update_job(where: { id: { _eq: $id } }, _set: { filled: $value }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const CLOSE_JOB = gql`
  mutation fillJob($id: uuid, $value: Boolean) {
    update_job(where: { id: { _eq: $id } }, _set: { closed: $value }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export default function CompletedJobs ({ path, location, admin }) {
  const tabIndexes = {
    '/jobs/open-jobs': 0,
    '/jobs/all-jobs': 1,
    '/jobs/search': 2,
  };
  const [userRole] = useContext(UserRoleContext);
  const [hasJobsOrCandidates] = useContext(HasJobsOrCandidatesContext);
  const { globalFilter, setGlobalFilter } = useContext(GlobalFilterContext);
  const isAdmin = userRole === 'admin';
  const isSubscriber = userRole === 'company';
  const [jobs, setJobs] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [allJobs, setAllJobs] = useState([]);
  const [filter, setFilter] = useState({});
  const [direction, setDirection] = useState('right');
  const [setJobFilled] = useMutation(FILL_JOB);
  const [setJobClosed] = useMutation(CLOSE_JOB);
  const [sortType, setSortType] = useState({ type: '', direction: '' });

  //set currCompany
  const [currCompany, setCurrCompany] = useState('');

  const { data: companyResponse } = useQuery(GET_COMPANY, {
    variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid },
  });

  const { data, error, loading } = useSubscription(
    isAdmin ? ALL_JOBS : isSubscriber ? ALL_COMPLETED_SUBSCRIBER_JOBS : ALL_RECRUITER_JOBS,
    isAdmin
      ? { fetchPolicy: 'network-only' }
      : isSubscriber
        ? {
          variables: {
            subscriber_id: currCompany.id,
          },
          fetchPolicy: 'network-only',
        }
        : {
          variables: {
            recruiterID: firebase.auth().currentUser && firebase.auth().currentUser.uid,
          },
          fetchPolicy: 'network-only',
        },
  );

  useEffect(() => {
    if (companyResponse) {
      setCurrCompany(companyResponse.company[0]);
    }
  }, [companyResponse]);

  function fillPosition (jobID, value) {
    setJobs(
      jobs.map((job) => {
        if (job.id === jobID) {
          return { ...job, filled: value };
        } else {
          return job;
        }
      }),
    );

    setJobFilled({
      variables: {
        id: jobID,
        value,
      },
      refetchQueries: ['getJobs'],
    });
  }

  function closePosition (jobID, value) {
    setJobClosed({
      variables: {
        id: jobID,
        value,
      },
      refetchQueries: ['getJobs'],
    });
  }

  function isEmpty (obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  function calcDistance (lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d * 0.621371;
  }
  function toRad (Value) {
    return (Value * Math.PI) / 180;
  }

  function filterJobs () {
    if (isEmpty(filter)) {
      setJobs(sortJobs(allJobs));
    } else {
      const filteredJobs = allJobs.filter((job) => {
        for (var name in filter) {
          const value = filter[name];

          switch (name) {
            case 'search':
              for (var index in value) {
                let found = false;
                if (job.company && job.company.name.toLowerCase().includes(value[index].toLowerCase())) {
                  found = true;
                }
                if (job.title.toLowerCase().includes(value[index].toLowerCase())) {
                  found = true;
                }
                if (!found) {
                  return false;
                }
              }
              break;
            case 'experience':
              const { requiredExperience } = job;
              if (parseInt(value.min) < parseInt(requiredExperience[0]) || parseInt(value.max) > parseInt(requiredExperience[1])) return false;
              break;
            case 'salary':
              const { salaryMax, salaryMin } = job;

              if (parseInt(value.min) > salaryMax || parseInt(value.max) < salaryMin) {
                return false;
              }

              break;
            case 'attributes':
              let found = true;
              const attributes = value;
              for (var index in attributes) {
                if (!job.requiredAttributes.includes(attributes[index])) {
                  found = false;
                }
              }
              if (!found) {
                return false;
              }
              break;
            case 'location':
              // const locations = value;
              // const filtered_locations = locations.filter(location => (location.lat === job.location.lat && location.lng === job.location.lng));
              // if (filtered_locations.length === 0) {
              //   return false;
              // }

              if (value.city) {
                const {
                  city: { lat, lng },
                } = value;
                const radius = value.radius ? value.radius : process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 30;
                const distance = calcDistance(lat, lng, job.location.lat, job.location.lng);
                if (distance > radius) {
                  return false;
                }
              }

            default:
              break;
          }
        }
        return true;
      });
      setJobs(sortJobs(filteredJobs));
    }
  }

  useEffect(() => {
    if (data) {
      const sortedJobs = sortJobs(data.job);
      setAllJobs(sortedJobs);
      setJobs(sortedJobs);
    }
  }, [data, error]);

  useEffect(() => {
    if (path === '/jobs') {
      navigate('/jobs/open-jobs');
    }
  }, []);

  useEffect(() => {
    filterJobs();
    if (path === '/jobs/open-jobs' && JSON.stringify(globalFilter['open-jobs-filter']) !== JSON.stringify(filter)) {
      setGlobalFilter({ ...globalFilter, ['open-jobs-filter']: filter });
    } else if (path === '/jobs/all-jobs' && JSON.stringify(globalFilter['all-jobs-filter']) !== JSON.stringify(filter)) {
      setGlobalFilter({ ...globalFilter, ['all-jobs-filter']: filter });
    } else if (location.pathname === '/dashboard/jobs' && JSON.stringify(globalFilter['admin-jobs-filter']) !== JSON.stringify(filter)) {
      setGlobalFilter({ ...globalFilter, ['admin-jobs-filter']: filter });
    }
  }, [filter]);

  useEffect(() => {
    if (location && location.pathname.split('/').length > 2) {
      setActiveTab(location.pathname);

      if (location.state) {
        if (location.state.companyName) {
          setFilter({
            search: [location.state.companyName],
          });
        } else if (location.state.previousTab) {
          setDirection(tabIndexes[location.state.previousTab] > tabIndexes[location.pathname] ? 'left' : 'right');
        }
      }
    } else if (!admin) {
      navigate('/jobs/open-jobs');
    }
  }, [location]);

  useEffect(() => {
    if (path === '/jobs/open-jobs' && globalFilter['open-jobs-filter']) {
      setFilter(globalFilter['open-jobs-filter']);
    } else if (path === '/jobs/all-jobs' && globalFilter['all-jobs-filter']) {
      setFilter(globalFilter['all-jobs-filter']);
    }
  }, [path]);

  useEffect(() => {
    if (location && location.pathname === '/dashboard/jobs' && JSON.stringify(globalFilter['admin-jobs-filter'])) {
      setFilter(globalFilter['admin-jobs-filter']);
    }
  }, [location]);

  useEffect(() => {
    filterJobs();
  }, [allJobs]);

  function sortJobs (jobsList) {
    if (jobsList) {
      if (sortType.type === 'alphabetical') {
        return [...jobsList].sort((a, b) => {
          const textA = isSubscriber || isAdmin ? a.title.toUpperCase() : a.company.name.toUpperCase();
          const textB = isSubscriber || isAdmin ? b.title.toUpperCase() : b.company.name.toUpperCase();
          return textA < textB ? (sortType.direction === 'desc' ? -1 : 1) : textA > textB ? (sortType.direction === 'desc' ? 1 : -1) : 0;
        });
      } else {
        return jobsList;
      }
    } else {
      return jobsList;
    }
  }

  useEffect(() => {
    if (sortType && jobs) {
      setJobs(sortJobs(jobs));
    }
  }, [sortType]);

  const renderJobTab = () => {
    switch (activeTab) {
      case '/jobs/open-jobs':
        return (
          <JobList
            activeTab={activeTab}
            filterState={[filter, setFilter]}
            sortState={[sortType, setSortType]}
            loading={loading}
            fillPosition={fillPosition}
            closePosition={closePosition}
            jobs={jobs.filter((job) => !job.filled && !job.closed)}
          />
        );
      case '/jobs/all-jobs':
        return (
          <JobList
            activeTab={activeTab}
            filterState={[filter, setFilter]}
            sortState={[sortType, setSortType]}
            fillPosition={fillPosition}
            closePosition={closePosition}
            loading={loading}
            jobs={jobs}
          />
        );
      // this is a hidden menu
      case '/jobs/search':
        return <JobSearch nav={location} />;
    }
  };

  return (
    activeTab && (
      <div className="completed-jobs-js flex flex-col container h-full">
        {!admin && <TabNav route={activeTab} routeIndex={activeTab === '/jobs/open-jobs' ? 0 : activeTab === '/jobs/all-jobs' ? 1 : 2} type="jobs" />}
        {!admin ? (
          <ReactCSSTransitionGroup
            className={`dash-slider relative`}
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
            transitionName={{
              enter: `enter-${direction}`,
              enterActive: `enter-${direction}-active`,
              leave: `leave-${direction}`,
              leaveActive: `leave-${direction}-active`,
              appear: `appear-${direction}`,
              appearActive: `appear-${direction}-active`,
            }}
          >
            <div key={activeTab} className="dash-tab">
              <div className="absolute flex flex-col w-full" style={{ minHeight: 'calc(100vh - 156px)' }}>
                {renderJobTab()}
              </div>
            </div>
          </ReactCSSTransitionGroup>
        ) : !isSubscriber ? (
          <JobList
            isAdmin={true}
            sortState={[sortType, setSortType]}
            filterState={[filter, setFilter]}
            closePosition={closePosition}
            fillPosition={fillPosition}
            loading={loading}
            jobs={jobs}
          />
        ) : (
          <JobList
            activeTab={activeTab}
            filterState={[filter, setFilter]}
            sortState={[sortType, setSortType]}
            loading={loading}
            fillPosition={fillPosition}
            closePosition={closePosition}
            jobs={jobs}
            isSubscriber={true}
          />
        )}
      </div>
    )
  );
}
