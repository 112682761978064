import React, { useContext } from 'react';
import { UserRoleContext } from '../../../context';
import CandidateDetails from './candidate-details';
import CompanyCandidateDetails from './candidate-details-comp';

export default function CandidateDetailsSwitch ({ location, navigate, isRequestedCandidates }) {
  const [user] = useContext(UserRoleContext);
  const isCompany = user === 'company';

  if (location.state) {
    return isCompany ? (
      <CompanyCandidateDetails location={location} isRequestedCandidates={location.state && location.state.isRequestedCandidates} isInterestedCandidates={location.state && location.state.isInterestedCandidates} />
    ) : (
      <CandidateDetails location={location} navigate={navigate} />
    );
  } else {
    window.location.href = '/';
  }
}
