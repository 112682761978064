import React from "react";

export default function ExperienceFilter({ value, update, company }) {
  const updateFilter = (type, amount) => {
    update({
      ...value,
      [type]: amount
    })
  }

  return (
    <div
      className="flex items-center text-center"
      style={{ paddingLeft: company ? 0 : 4 }}>
      <div className="relative">
        <input
          placeholder="0"
          min="0"
          value={value.min ? value.min : ""}
          onChange={e => {
            updateFilter("min", e.target.value)}
          }
          type="number"
          className="bg-white rounded shadow text-center text-xs"
          style={{ height: 40, width: 40, paddingLeft: 10 }}
        />
      </div>
      <div className="flex items-center mx-sm">-</div>
      <div className="relative">
        <input
          placeholder="0"
          value={value.max ? value.max : ""}
          onChange={e => updateFilter("max", e.target.value)}
          type="number"
          min="1"
          className="bg-white rounded shadow text-xs"
          style={{
            WebkitAppearance: "none",
            height: 40,
            width: 100,
            paddingLeft: 15,
            paddingRight: 50,
          }}
        />
        <div
          style={{ height: 14, left: 10, top: 13, margin: 0 }}
          className="absolute"
        />
        <div
          className="text-xs absolute text-darkgray"
          style={{ right: 10, top: 10 }}>
          years
        </div>
      </div>
    </div>
  );
}
