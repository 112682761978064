import { useQuery, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { navigate } from 'gatsby';
import parse from 'html-react-parser';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Arrow } from '../../../components/common';
import CreditModal from '../../../components/common/CreditModal';
import FieldTitle from '../../../components/common/FieldTitle';
import EditIcon from '../../../images/edit.svg';
import { CandidateEntry } from '../../jobs/job-details';
import ProfilePicture from './profilepicture';
import './subscriber-detail.css';

// query for getting the candidates requested to this company
const COMPANY_REQUESTS = gql`
  query getAllCompanyRequests($id: uuid) {
    company_request(where: { company_id: { _eq: $id } }) {
      candidate_id
      company_id
      status
      candidate {
        id
        name
        employment
        profilePictureURL
        phone
        email
        location
      }
    }
  }
`;

const GET_COMPANY = gql`
  subscription getCompany($id: uuid) {
    company(where: { id: { _eq: $id }, adminID: { _is_null: false } }) {
      id
      email
      name
      websiteURL
      size
      logoURL
      bio
      adminName
      totalCredit
      remainingCredit
      subscription_type
      candidate_requests_aggregate {
        aggregate {
          count
        }
      }
      subscribers_recruiter {
        id
        name
      }
    }
  }
`;

const GET_TOTAL_CREDITS = gql`
  query interestedCandidates($companyId: uuid, $startDate: String, $endDate: String) {
    payment_history(where: { company_id: { _eq: $companyId }, type: { _eq: "1" }, date: { _gte: $startDate, _lte: $endDate } }, order_by: { date: desc }) {
      type
      id
      date
      credits
      candidate_id
    }
  }
`;

const PAYMENT_HISTORY = gql`
  subscription getPaymentHistory($company_id: uuid) {
    payment_history(where: { company_id: { _eq: $company_id } }, order_by: { date: desc }) {
      id
      company_id
      credits
      date
      type
      candidate {
        id
        name
        profilePictureURL
      }
    }
  }
`;

export default function SubscriberDetails ({ location }) {
  const [modal, setModal] = useState({ data: null, open: false });
  const [subscriber, setSubscriber] = useState();
  const [totalCredits, setTotalCredits] = useState(0);
  const [startDate, setStartDate] = useState(
    moment()
      .startOf('year')
      .toDate(),
  );
  const [endDate, setEndDate] = useState(moment().toDate());

  const { data: candidates } = useQuery(COMPANY_REQUESTS, {
    variables: { id: location.state.subscriber.id },
  });

  const { data } = useSubscription(GET_COMPANY, {
    variables: { id: location.state.subscriber.id },
  });

  const { data: totalCreditsData, refetch: refetchTotalCredits } = useQuery(GET_TOTAL_CREDITS, {
    variables: {
      companyId: location.state.subscriber.id,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    },
  });

  useEffect(() => {
    refetchTotalCredits();
  }, [startDate, endDate]);

  useEffect(() => {
    if (totalCreditsData) {
      const tempTotal = _.sum(totalCreditsData.payment_history.map((a) => a.credits));

      setTotalCredits(tempTotal);
    }
  }, [totalCreditsData]);

  useEffect(() => {
    if (data) {
      setSubscriber(data.company[0]);
    }
  }, [data]);

  // updating the totalCredit and remainingCredit after adding new credit by the admin for this company
  useEffect(() => {
    if (modal.newTotalCredit && modal.newRemainingCredit && modal.data.subscriber.id === subscriber.id) {
      setSubscriber({ ...subscriber, totalCredit: modal.newTotalCredit, remainingCredit: modal.newRemainingCredit });
    } else if (modal.reset) {
    }
  }, [modal]);

  const updateTotalCredit = () => {
    setModal({ open: true, data: { subscriber }, isFresh: true });
  };

  console.log(location.state.subscriber.id);

  // subscription to get the payment history
  const { data: payments } = useSubscription(PAYMENT_HISTORY, {
    variables: {
      company_id: location.state.subscriber.id,
    },
  });

  return (
    <div className="w-full h-full pb-lg">
      <div className="flex pb-sm pt-md">
        <div
          className="cursor-pointer text-darkgray flex items-center capitalize hover:underline"
          style={{ fontSize: 12 }}
          onClick={() => navigate(location.state.from ? location.state.from : location.state.activeTab ? location.state.activeTab : '/dashboard/subscribers')}
        >
          <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} />
          {location.state.activeTab ? 'Jobs' : 'Subscribers'}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col w-full" style={{ maxWidth: 530 }}>
          <div className="flex py-sm mb-sm justify-between items-center">
            <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
              Subscriber Details
            </div>
            <div className="flex mr-md">
              <img
                alt=""
                onClick={() => navigate(`/subscribers/${subscriber && subscriber.id}/edit`, { state: { subscriber } })}
                src={EditIcon}
                style={{ width: 40, marginBottom: 0, cursor: 'pointer' }}
              />
              <button
                className="bg-lightgreen hover:bg-green hover:text-white rounded text-green text-sm py-sm px-md font-medium"
                style={{ fontSize: 14 }}
                onClick={updateTotalCredit}
              >
                Update Credit
              </button>
            </div>
          </div>
          <div className="bg-white p-lg rounded  flex flex-col shadow" style={{ minHeight: 370 }}>
            {subscriber && (
              <React.Fragment>
                <div className="flex items-center">
                  <ProfilePicture source={subscriber.logoURL} />
                  <div className="flex flex-col">
                    <div className="text-darkblue font-medium">{subscriber.name}</div>
                  </div>
                </div>
                <div className="flex flex-wrap font-medium text-sm">
                  <div className="flex flex-col w-1/2 mt-xl">
                    <div className="capitalize">Email</div>
                    <div className="text-darkgray">{subscriber.email}</div>
                  </div>
                  <div className="flex flex-col w-1/2 mt-xl">
                    <div className="capitalize">Website Link</div>
                    <div className="text-darkgray">
                      <a href={subscriber.websiteURL} target="_blank" rel="noreferrer">
                        {subscriber.websiteURL ? subscriber.websiteURL : 'N/A'}
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2 mt-xl">
                    <div className="capitalize">Number of employees</div>
                    <div className="text-darkgray">{subscriber.size ? subscriber.size : 'N/A'}</div>
                  </div>
                  <div className="flex flex-col w-1/2 mt-xl">
                    <div className="capitalize">Sent Request Count</div>
                    <div className="text-darkgray">{subscriber.candidate_requests_aggregate.aggregate.count}</div>
                  </div>

                  <div className="flex flex-col w-1/2 mt-xl">
                    <div className="capitalize">Hiring Manager</div>
                    <div className="text-darkgray">{subscriber.adminName ? subscriber.adminName : 'N/A'}</div>
                  </div>
                  <div className="flex flex-col w-1/2 mt-xl">
                    <div className="capitalize">Remaining Credits</div>
                    <div className="text-darkgray">{subscriber.remainingCredit}</div>
                  </div>

                  <div className="flex flex-col w-1/2 mt-xl">
                    <div className="capitalize">Total Credits</div>
                    <div className="text-darkgray">{subscriber.totalCredit}</div>
                  </div>

                  <FieldTitle title="Total Credits Purchased" />

                  <div className="flex flex-col w-1/2">
                    <div className="capitalize">Start Date</div>
                    <ReactDatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={new Date()} />
                  </div>

                  <div className="flex flex-col w-1/2">
                    <div className="capitalize">End Date</div>
                    <ReactDatePicker selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={new Date()} />
                  </div>

                  <div className="w-full">
                    <div className="flex">
                      <div className="flex-1">
                        <br />
                        <div className="capitalize">Credits Purchased: {totalCredits}</div>
                        <div className="text-darkgray">(Does not include credits added by admin)</div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="flex">
                      <div className="flex-1">
                        <FieldTitle title="Company description" />
                        <div className="text-darkgray mt-sm" style={{ fontSize: 14 }}>
                          {subscriber.bio ? parse(subscriber.bio) : 'N/A'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="flex flex-col flex-1 lg:ml-lg w-full lg:w-auto">
          <div className="flex py-sm mb-sm items-center justify-between">
            <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
              Candidates
            </div>
            <div style={{ height: 40 }} />
          </div>
          <div className="bg-white rounded shadow flex-1" style={{ minHeight: 400, maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}>
            {candidates && candidates.company_request.length > 0 ? (
              candidates.company_request.map((candidate) => (
                <>
                  <CandidateEntry candidate={candidate.candidate} disableChat={true} />
                </>
              ))
            ) : (
              <div className="w-full h-full flex text-sm items-center py-xl lg:py-0 text-center justify-center text-darkgray font-medium">There is no candidate request found</div>
            )}
          </div>
          <div className="flex py-sm mb-sm items-center justify-between">
            <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
              History
            </div>
            <div style={{ height: 40 }} />
          </div>
          <div className="bg-white rounded shadow flex-1" style={{ minHeight: 400, maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}>
            {payments && payments.payment_history.length > 0 ? (
              payments.payment_history.map((payment) => (
                <div style={{ padding: 30, paddingBottom: 0 }}>
                  <div className="flex border-b" style={{ paddingBottom: 20 }}>
                    <div className="flex flex-col" style={{ paddingLeft: 15, flexGrow: 1, minWidth: 225, maxWidth: 225 }}>
                      <div className="font-medium text-darkgray cursor-pointer hover:underline" style={{ fontSize: 16, whiteSpace: 'nowrap', paddingRight: 10 }}>
                        {payment.type == 1 ? 'Purchased' : payment.type == 3 ? 'Admin added' : payment.type == 4 ? 'Admin removed' : 'Used'} {payment.credits}{' '}
                        {payment.credits > 1 ? 'credits' : 'credit'} {payment.type == 2 ? 'for ' + (payment.candidate && payment.candidate.name ? payment.candidate.name : '') : ''}{' '}
                        on {moment(payment.date).format('MM/DD/YYYY')}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full h-full flex text-sm items-center py-xl lg:py-0 text-center justify-center text-darkgray font-medium">No history available</div>
            )}
          </div>
        </div>
      </div>
      <CreditModal state={[modal, setModal]} />
    </div>
  );
}
