import 'firebase/auth';
import React, { useRef } from 'react';
import Loader from 'react-loader-spinner';

export default function RemoveConfirmModal({ open, onSuccess, onFailure, loading }) {
  const modalRef = useRef();

  return (
    <React.Fragment>
      <div
        className={`approval-confirm-modal-js fixed inset-0 ${open ? 'flex' : 'hidden'} items-center justify-center font-main`}
        style={{ backgroundColor: 'rgba(34, 46, 66, 0.9)', zIndex: 20000000000000000 }}
      >
        <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col">
          <div className="text-darkblue font-medium border-b flex justify-between pb-sm" style={{ maxWidth: 'calc(100vw - 30px)' }}>
            Are you sure?
            <div onClick={onFailure} className="text-darkgray hover:text-darkblue cursor-pointer">
              x
            </div>
          </div>
          <div className="text-darkblue text-sm text-center py-lg">By removing this candidate, you are indicating that you are no longer interested in this candidate.</div>
          <div className="flex justify-center pt-lg">
            <button
              onClick={onFailure}
              type="button"
              className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray"
              style={{ width: 137, height: 55, fontSize: 14 }}
            >
              Cancel
            </button>
            <button
              onClick={onSuccess}
              className="rounded bg-red ml-md font-medium flex justify-center items-center text-sm text-white"
              style={{ width: 180, height: 55, fontSize: 14, boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)' }}
            >
              {loading ? <Loader type="TailSpin" className="flex justify-center" color="#FFFFFF" height={20} width={20} /> : 'Yes'}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
