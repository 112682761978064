import { useMutation, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';

const RECRUITER_PROJECTS = gql`
  subscription recruiterProjects($recruiter_id: String) {
    project(where: { recruiter_id: { _eq: $recruiter_id } }) {
      id
      name
      recruiter_id
      candidates {
        candidate_id
      }
    }
  }
`;

const REMOVE_PROJECT_CANDIDATE = gql`
  mutation removeProjectCandidate($candidate_id: String, $project_id: uuid) {
    delete_project_candidate(where: { candidate_id: { _eq: $candidate_id }, project_id: { _eq: $project_id } }) {
      affected_rows
    }
  }
`;

const ADD_PROJECT_CANDIDATE = gql`
  mutation removeProjectCandidate($candidate_id: String, $project_id: uuid) {
    insert_project_candidate(objects: { candidate_id: $candidate_id, project_id: $project_id }) {
      affected_rows
    }
  }
`;

export default function AddToProjectButton({ candidate }) {
  const id = candidate && candidate.id;
  const { data, loading } = useSubscription(RECRUITER_PROJECTS, {
    variables: {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });
  const [removeProjectCandidate] = useMutation(REMOVE_PROJECT_CANDIDATE);
  const [addProjectCandidate] = useMutation(ADD_PROJECT_CANDIDATE);
  const [projects, setProjects] = useState();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const hideDropdown = () => setDropdownVisible(false);

  function toggleCandidate({ project, added }) {
    if (added) {
      removeProjectCandidate({
        variables: { candidate_id: id, project_id: project.id },
      });
      setProjects(
        projects.map((proj) => {
          if (proj.id === project.id) {
            return {
              ...project,
              candidates: project.candidates.filter((candidate) => candidate.candidate_id !== id),
            };
          } else {
            return proj;
          }
        }),
      );
    } else {
      addProjectCandidate({
        variables: { candidate_id: id, project_id: project.id },
      });
      setProjects(
        projects.map((proj) => {
          if (proj.id === project.id) {
            return {
              ...project,
              candidates: [...project.candidates, { candidate_id: id }],
            };
          } else {
            return proj;
          }
        }),
      );
    }
  }

  useEffect(() => {
    if (dropdownVisible) {
      window.addEventListener('click', hideDropdown);
    } else {
      return window.removeEventListener('click', hideDropdown);
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (data) {
      setProjects(data.project);
    }
  }, [data]);

  return (
    <div className="text-green text-sm relative flex items-center font-medium cursor-pointer" style={{ right: 0 }}>
      <div
        className="flex items-center justify-center"
        onClick={(e) => {
          setDropdownVisible(true);
          e.stopPropagation();
        }}
      >
        <span style={{ marginRight: 5, fontSize: 25 }}>+</span> Add to Project
      </div>
      {projects && (
        <div className={`absolute bg-white rounded shadow ${!dropdownVisible ? 'hidden' : ''}`} style={{ top: 40, zIndex: 100, width: 250, right: -20 }}>
          {projects.length === 0 ? (
            <div className="text-darkblue text-sm py-md px-lg text-center">
              No Projects
              <div onClick={() => navigate('/dashboard/create-project')} className="bg-green text-white rounded py-sm mt-md text-xs cursor-pointer">
                Add Project
              </div>
            </div>
          ) : (
            projects.map((project) => {
              const added = project.candidates.map(({ candidate_id }) => candidate_id).includes(candidate && candidate.id);
              return (
                <div
                  className="py-md flex text-darkblue items-center justify-between text-xs px-lg hover:bg-lightgray"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleCandidate({ project, added });
                  }}
                >
                  <div className="flex-1 overflow-hidden" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {project.name}
                  </div>
                  <div className={`flex items-center ml-md justify-center text-white bg-${added ? 'green' : 'darkgray'}`} style={{ height: 20, width: 20, borderRadius: '100%' }}>
                    {project.candidates.length}
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
    </div>
  );
}
