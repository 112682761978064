import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const GET_APPROVED_COMPANIES = gql`
  query approvedCompanies($candidateID: String) {
    company_request(
      where: {
        candidate_id: { _eq: $candidateID }
        status: { _eq: "approved" }
      }
    ) {
      company {
        id
        name
        logoURL
      }
    }
  }
`;

export default function ApprovedCompanies ({ candidateID }) {
  const [companies, setCompanies] = useState([]);
  const { data, loading } = useQuery(GET_APPROVED_COMPANIES, {
    variables: { candidateID },
  });

  useEffect(() => {
    if (data) {
      setCompanies(data.company_request);
    }
  }, [data]);

  return (
    <React.Fragment>
      <div
        className="flex items-center justify-between py-sm"
        style={{ height: 56 }}>
        <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
          Companies
        </div>
      </div>
      <div
        className="bg-white rounded shadow mb-lg w-full text-darkgray text-center  text-sm h-full"
        style={{ maxWidth: 767, minHeight: 103, maxHeight: 300, overflow: 'auto' }}>
        {companies.length > 0 ? (
          companies.map(({ company }) => (
            <div
              className="flex border-b border-lightgray"
              style={{
                paddingTop: 40,
                paddingBottom: 40,
                marginRight: 40,
                marginLeft: 40,
              }}>
              <div className="flex flex-col justify-center text-left">
                <div
                  className="text-darkblue font-medium"
                  style={{ fontSize: 16 }}>
                  {company.name}
                </div>
              </div>
            </div>
          ))
        ) : loading ? null : (
          <div style={{ padding: 40 }}>
            Not currently interested in any jobs
          </div>
        )}
      </div>
    </React.Fragment>
  );
}