import React, { useEffect } from "react";
import ProjectForm from "../../forms/project/ProjectForm";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const CREATE_PROJECT = gql`
  mutation CreateProject(
    $name: String
    $recruiterID: String
    $candidates: [project_candidate_insert_input!]!
    $tags: jsonb
  ) {
    insert_project(
      objects: {
        name: $name
        recruiter_id: $recruiterID
        tags: $tags
        candidates: { data: $candidates }
      }
    ) {
      returning {
        id
        name
        tags
        recruiter_id
      }
    }
  }
`;

export default function CreateProject({ navigate }) {
  const [addProject, { data, error }] = useMutation(CREATE_PROJECT);

  const createProject = project => {
    const newProject = {
      ...project,
      recruiterID: firebase.auth().currentUser.uid,
      candidates: project.candidates.map(({ id }) => ({ candidate_id: id })),
    };
    addProject({
      variables: newProject,
      refetchQueries: ["projectsByRecruiterId"],
    });
  };

  useEffect(() => {
    if (data) {
      const {
        insert_project: {
          returning: [project],
        },
      } = data;
      navigate("/dashboard/projects", { state: { newProject: project } });
    }
  }, [data]);

  return (
    <div className="flex-1 h-full w-full flex flex-col items-center">
      <div
        className="py-lg font-main text-darkblue"
        style={{ fontWeight: 500, fontSize: 21 }}>
        Create Project
      </div>
      <ProjectForm onSubmit={createProject} />
    </div>
  );
}
