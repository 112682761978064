import { gql } from 'apollo-boost';

// not sure if this is redundant with the one below
const ALL_COMPANIES_QUERY = gql`
  query AllCompanies {
    company {
      id
      logoURL
      name
    }
  }
`;

// graphql query to get all companies, not sure if this is redundant with the one above
const ALL_COMPANIES = gql`
  subscription getAllCompanies(
    $perPage: Int, 
    $offset: Int, 
    $is_like: String,
    $order_by:company_order_by!
  ) {
    company(
      order_by: [$order_by]
      where: { 
        adminID: { _is_null: false }, 
        _or: [
          { name: { _ilike: $is_like } },
          { email: { _ilike: $is_like } }
        ]
      }
      limit: $perPage, 
      offset: $offset 
      
    ) {
      id
      email
      name
      logoURL
      size
      websiteURL
      bio
      adminName
      totalCredit
      remainingCredit
      subscription_type
      candidate_requests_aggregate {
        aggregate {
          count
        }
      }
      adminID
    }
  }
`;

const ALL_COMPANIES_TOTAL = gql`
  subscription getAllCompanies {
    company_aggregate(where: { adminID: { _is_null: false } }) {
      aggregate {
        count
      }
    }
  }
`;

export { ALL_COMPANIES_QUERY, ALL_COMPANIES, ALL_COMPANIES_TOTAL };
