import { Router } from '@reach/router';
import React from 'react';
import NewCandidateForm from '../forms/candidate/NewCandidateForm';
import {
  AddJob,
  Attributes,
  CandidateDetails,
  Candidates,
  Chat,
  Checkout,
  Companies,
  CompanyDetails,
  CompletedJobs,
  CreateAccount,
  Dashboard,
  EditAttribute,
  EditCandidate,
  EditCompany,
  EditJob,
  EditRecruiter,
  EditSubscriber,
  Gateway,
  JobDetails,
  JobQueue,
  Jobs,
  NewAttribute,
  NewCompany,
  NewRecruiter,
  NewSubscriber,
  Notifications,
  RecruiterDetails,
  Recruiters,
  Reports,
  Settings,
  SubscriberDetails,
  Subscribers,
} from '../views';
import DownloadCandidateProfile from '../views/candidates/candidate-details/download-candidate-profile';
import { FavoriteCandidates, SavedCandidates } from '../views/candidates/company-candidates';
import LoxoCandidates from '../views/candidates/loxo-candidates';
import CreateProject from '../views/projects/create-project';

function PrivateRoute({ component: Route, ...props }) {
  return <Route {...props} />;
}

export default function AppRouter() {
  const Routes = {
    Gateway: {
      component: Gateway,
      paths: ['/login', '/new-account', '/reset-password', '/cpcjadmin320'],
    },
    Notifications: {
      component: Notifications,
      paths: ['/notifications'],
    },
    Chat: {
      component: Chat,
      paths: ['/chat'],
    },
    CreateAccount: {
      component: CreateAccount,
      paths: ['/create-account'],
    },
    Dashboard: {
      component: Dashboard,
      paths: [
        '/',
        '/dashboard',
        '/dashboard/index',
        '/dashboard/candidates-in-process',
        '/dashboard/interviews',
        '/dashboard/candidates',
        '/dashboard/projects',
        '/dashboard/find-candidate',
        '/dashboard/saved-candidates',
        '/dashboard/jobs',
        '/dashboard/completed-jobs',
        '/dashboard/job-queue',
        '/dashboard/recruiters',
        '/dashboard/subscribers',
        '/dashboard/companies',
        '/dashboard/reports',
        '/dashboard/attributes',
        '/dashboard/queue',
        '/dashboard/requests-sent',
        '/dashboard/interested-candidates',
        '/dashboard/recruiter-activity',
        '/dashboard/subscriber-activity',
        '/dashboard/subscriber-credit-report',
        '/dashboard/candidate-introduction-report',
        '/dashboard/credit-transaction-history',
        '/dashboard/recruiter-credit-activity',
        '/dashboard/recruiter-job-output',
      ],
    },
    Settings: {
      component: Settings,
      paths: ['/settings'],
    },
    SavedCandidates: {
      component: SavedCandidates,
      paths: ['/saved-candidates'],
    },
    FavoriteCandidates: {
      component: FavoriteCandidates,
      paths: ['/favorite-candidates'],
    },
    Jobs: {
      component: Jobs,
      paths: ['/jobs', '/jobs/open-jobs', '/jobs/all-jobs', '/jobs/completed-jobs', '/jobs/search'],
    },
    CompletedJobs: {
      component: CompletedJobs,
      paths: ['/completed-jobs'],
    },
    JobQueue: {
      component: JobQueue,
      paths: ['/job-queue'],
    },
    JobDetails: {
      component: JobDetails,
      paths: ['/jobs/:id', '/dashboard/jobs/:id'],
    },
    AddJob: {
      component: AddJob,
      paths: ['/jobs/new-job'],
    },
    EditJob: {
      component: EditJob,
      paths: ['/jobs/:id/edit'],
    },
    CreateCandidate: {
      component: NewCandidateForm,
      paths: ['/candidates/new-candidate'],
    },
    Candidates: {
      component: Candidates,
      paths: ['/candidates'],
    },
    EditCandidate: {
      component: EditCandidate,
      paths: ['/candidates/:id/edit'],
    },
    CandidateDetails: {
      component: CandidateDetails,
      paths: ['/candidates/:id'],
    },
    DownloadCandidateProfile: {
      component: DownloadCandidateProfile,
      paths: ['/download-candidates/:id'],
    },
    ProjectForm: {
      component: CreateProject,
      paths: ['/dashboard/create-project'],
    },
    Recruiters: {
      component: Recruiters,
      paths: ['/recruiters'],
    },
    NewRecruiter: {
      component: NewRecruiter,
      paths: ['/recruiters/new-recruiter'],
    },
    RecruiterDetails: {
      component: RecruiterDetails,
      paths: ['/recruiters/:id'],
    },
    EditRecruiter: {
      component: EditRecruiter,
      paths: ['/recruiters/:id/edit'],
    },
    Subscribers: {
      component: Subscribers,
      paths: ['/subscribers'],
    },
    SubscriberDetails: {
      component: SubscriberDetails,
      paths: ['/subscribers/:id'],
    },
    NewSubscriber: {
      component: NewSubscriber,
      paths: ['/subscribers/new-subscriber'],
    },
    EditSubscriber: {
      component: EditSubscriber,
      paths: ['/subscribers/:id/edit'],
    },
    Companies: {
      component: Companies,
      paths: ['/companies'],
    },
    Reports: {
      component: Reports,
      paths: ['/reports'],
    },
    Attributes: {
      component: Attributes,
      paths: ['/attributes'],
    },
    NewAttribute: {
      component: NewAttribute,
      paths: ['/attributes/new-attribute'],
    },
    EditAttribute: {
      component: EditAttribute,
      paths: ['/attributes/:id/edit'],
    },
    NewCompany: {
      component: NewCompany,
      paths: ['/companies/new-company'],
    },
    CompanyDetails: {
      component: CompanyDetails,
      paths: ['/companies/:id'],
    },
    EditCompany: {
      component: EditCompany,
      paths: ['/companies/:id/edit'],
    },
    Checkout: {
      component: Checkout,
      paths: ['/', '/checkout'],
    },
    LoxoCandidates: {
      component: LoxoCandidates,
      paths: ['/candidate-imports/loxo'],
    },
  };

  return (
    <Router className="flex-1">
      {Object.values(Routes).map(({ component, paths }) => {
        return paths.map((path) => <PrivateRoute path={path} component={component} />);
      })}
    </Router>
  );
}
